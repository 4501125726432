import ModalWrapper from "./ModalWrapper";
import { useState } from "react";
import { Select } from '../Select/Select';
import INPUTS from '../../constants/inputsData';
import { api } from 'settings/Api';

function ExpensesModal({ modalToggler, getExpenses }) {
	const [values, setValues] = useState("");

	const inputChangeHandler = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const postExpenses = async (e) => {
		e.preventDefault();
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		const body = JSON.stringify(values);

		let response = await api("/api/sout/admin/accounting/expenses", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body,
		});
		let data = await response.json();

		getExpenses();
		modalToggler(false);
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => modalToggler(false)}
			>
				&#10005;
			</button>

			<label className="modal__input-title">Описание:</label>
			<input
				className="modal__input input"
				type="text"
				name="description"
				value={values.description}
				onChange={inputChangeHandler}
			/>

			<Select
				onChange={inputChangeHandler}
				dataSrc="local"
				data={INPUTS.ExpenseTypeId}
				className="modal__input "
				labelClassName="modal__input-title"
			/>

			<label className="modal__input-title">Сумма расхода:</label>
			<input
				className="modal__input input"
				type="number"
				name="amount"
				value={values.amount}
				onChange={inputChangeHandler}
			/>

			<label className="modal__input-title">Дата:</label>
			<input
				className="modal__input input"
				type="date"
				name="paymentDate"
				value={values.paymentDate}
				onChange={inputChangeHandler}
			/>

			<div className="modal__buttons-wrapper">
				<button className="button button--red" onClick={postExpenses}>
					Да
				</button>
				<button
					className="button close-button"
					onClick={() => modalToggler(false)}
				>
					Нет
				</button>
			</div>
		</ModalWrapper>
	);
}

export default ExpensesModal;
