import { api } from 'settings/Api';
const rejectProject = async (projectId) => {
  const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;

  const res = await api(`/api/sout/workflow/reject/${projectId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
      // 'Authorization':  'String(authTokens.access) ,
    },
  })
  return res
}
export default rejectProject;