import { departmentTypeKey } from '../utils/BaseUrl';
import { getBaseUrl } from '../utils/BaseUrl';
import { api } from 'settings/Api';

const PaymentInfoTableButtons = ({paytId}) => {
  const body = {
    "contractId": paytId,
}

  const deletePayment = async() => {
    const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;
    const departmentType = localStorage.getItem(departmentTypeKey);
    const url = getBaseUrl(departmentType, `accounting/payments/${paytId}`)

    const response = await api(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }) 
    let data = await response.json();
  }

  return <>
    <button className="button button--square-small icon-trash" title='Удалить'
      onClick={() => deletePayment()}
    ></button>
  </>;
};

export default PaymentInfoTableButtons;
