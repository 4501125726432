import { api } from 'settings/Api';

export const suspendProject = async (projectID) => {
	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
	let res = await api(`/api/sout/workflow/separated-project/suspend/${projectID}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
	let data = await res.json();
	return res;
};