function StatisticTableHeaders({ headers, tableType }) {
  return (
    <thead>
      <tr>
        {(tableType === "income" ||
          tableType === "expenses" ||
          tableType === "total_expenses") &&
          headers.map((header) => <td key={header}>{header}</td>)}
        {tableType === "workload" &&
          headers.map((header) => (
            <td key={header}>
              {
                <>
                  <p>{header[0]}</p>
                  <span>{header[1]}</span>
                </>
              }
            </td>
          ))}
        {tableType === "income_by_paid" &&
          headers.map((header) => (
            <td key={header}>
              {
                <>
                  <p>{header[0]}</p>
                  <span style={{ fontWeight: "700" }}>{header[1]}</span>
                </>
              }
            </td>
          ))}
        {tableType === "returns" &&
          headers.map((header) => <td key={header}>{header}</td>)}
        {tableType === "agent_reports" &&
          headers.map((header) => (
            <td key={header}>
              {
                <>
                  <p>{header[0]}</p>
                  <span>{header[1]}</span>
                  <span>{header[2]}</span>
                </>
              }
            </td>
          ))}
      </tr>
    </thead>
  );
}

export default StatisticTableHeaders;
