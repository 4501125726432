import React from "react";
import Sidebar from "../components/Sidebar";
import StatisticTable from "../components/common/StatisticTable";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import {
	isAccountantUser,
	isLabUser,
	isMedUser,
	isTenderUser,
	isСhieftain,
} from "../utils/defineUser";
import AddPaymentModal from "./sout/accountant/modals/AddPaymentModal";
import AddPercentageModal from "./sout/accountant/modals/AddPercentageModal";
import { api } from 'settings/Api';

function IncomeByPaid() {
	const defaultValues = {
		from: "2022-01",
		to: "2022-01",
		on: null,
	};

	const [values, setValues] = useState(defaultValues);
	const [income, setIncome] = useState([]);
	const [returns, setReturns] = useState([]);
	const [expenses, setExpenses] = useState([]);
	const [incomeByPaid, setIncomeByPaid] = useState([]);

	const [activeFunds, setActiveFunds] = useState("");
	const [fundsAvailable, setFundsAvailable] = useState("");

	const tableDataHandler = (tableType, data) => {
		switch (tableType) {
			case "income_by_paid":
				setIncomeByPaid(data);
				break;
			default:
				alert("Данные для таблиц не получены");
				break;
		}
	};

	const [incomeTab, setIncomeTab] = useState("common");

	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
	const [isPercentageModalOpen, setIsPercentageModalOpen] = useState(false);

	const incomeTabsHandler = (e) => {
		const currentTab = e.target.getAttribute("data-tab");

		setIncomeTab(currentTab);
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const getQueryString = () => {
		let url = "";
		for (var key in values) {
			url += key;
			url += "=";
			url += values[key];
			url += "&";
		}
		return url;
	};

	const getProjectType = () => {
		if (localStorage.getItem("__user-area") === "med") return "Med";
		if (localStorage.getItem("__user-area") === "sout") return "Sout";
	};

	const fetchStatistics = async (tableType, dataType) => {
		const url = `/api/stats/income_by_paid?${getQueryString()}projectType=${getProjectType()}`;
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		let response = await api(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		let data = await response.json();

		if (dataType === "total_returns") {
			tableDataHandler(dataType, data);
		} else {
			tableDataHandler(tableType, data.stats);
		}
	};

	const fetchDataHandler = async (e) => {
		e.preventDefault();
		await fetchStatistics("income_by_paid");
	};

	const togglePaymentModal = (bool) => setIsPaymentModalOpen(bool);

	const togglePercentageModal = (bool) => setIsPercentageModalOpen(bool);

	const donwloadReport = (e) => {
		e.preventDefault();
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		const url = `/api/documents/payment_report`;

		let defaultFilename = "report.csv";

		api(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {
				let header = response.headers.get("content-disposition");
				defaultFilename = header.split(";")[1].split("=")[1];
				return response.blob();
			})
			.then((blob) => {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = defaultFilename;
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
	};

	return (
		<main className="workspace">
			<Sidebar />
			<div className="workspace__content">
				<section className="workspace__wrapper">
					<h3 className="workspace__title workspace__page-title">Статистика оплаты</h3>
					<form onSubmit={fetchDataHandler}>
						<p style={{ marginBottom: "30px" }}>
							Проекты, у которых оплата с
							<input
								onChange={onChange}
								className="input"
								name="from"
								type="month"
								value={values.from}
								style={{
									width: "140px",
									minWidth: "0",
									display: "inline",
									padding: "5px",
									margin: "0 10px",
								}}
							/>
							по
							<input
								onChange={onChange}
								className="input"
								name="to"
								type="month"
								value={values.to}
								style={{
									width: "140px",
									minWidth: "0",
									display: "inline",
									padding: "5px",
									margin: "0 10px",
								}}
							/>
							<button
								className="button"
								type="submit"
								style={{ padding: "4px 10px 8px 10px" }}
							>
								<FaSearch />
							</button>
						</p>
					</form>
					<div style={{ margin: "10px 0" }}>
						<button className="button" onClick={() => togglePaymentModal(true)}>
							Добавить оплату
						</button>
						<button
							className="button"
							onClick={() => togglePercentageModal(true)}
							style={{ marginLeft: "20px" }}
						>
							Добавить процент
						</button>
						<button
							className="button button--blue"
							onClick={(e) => donwloadReport(e)}
							style={{ marginLeft: "20px" }}
						>
							Уточняющая ведомость
						</button>
						<button
							className="button button--blue"
							style={{ marginLeft: "20px" }}
						>
							Сфромировать отчёт
						</button>
					</div>
					<StatisticTable tableType="income_by_paid" projects={incomeByPaid} />
				</section>
			</div>
			{isPaymentModalOpen && (
				<AddPaymentModal
					setOpenModal={setIsPaymentModalOpen}
					isAddPaymentStat={true}
				/>
			)}
			{isPercentageModalOpen && (
				<AddPercentageModal setOpenModal={setIsPercentageModalOpen} />
			)}
		</main>
	);
}
export default IncomeByPaid;
