// import { getBaseUrl, departmentTypeKey } from '../utils/BaseUrl';
import AgentContext from "context/ParticipantsOfProcessContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "settings/Api";

function BlockAgentModal({ closeModal, user, action }) {
  const ADMIN_ID = 1;
  const navigate = useNavigate();
  const { isChangeState, setIsChangeState } = useContext(AgentContext);

  const blockAgent = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    // const departmentType = localStorage.getItem(departmentTypeKey);

    api(`/api/sout/admin/organizations/block?id=${user.state.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Агент заблокирован!");
        closeModal(false);
        setIsChangeState(!isChangeState);
        navigate("/ListOfAgents");
      } else if (res.status === 500 || !res.ok) {
        alert("Агент не заблокирован!");
        closeModal(false);
      }
    });
  };

  return (
    <>
      <section className="modal" data-action="modal-exit">
        <div className="modal__content">
          <button
            onClick={() => closeModal(false)}
            className="modal__close-button close-button"
          >
            &#10005;
          </button>
          <h3 className="modal__description">
            Заблокировать агента
            <b>
              &nbsp;{user.state.name}&nbsp;{user.state.lastName}
            </b>
            . Вы уверены?
          </h3>
          <div className="modal__buttons-wrapper">
            <button
              onClick={blockAgent}
              className="button button--red"
              // disabled={ADMIN_ID === user.state.id ? true : false}
            >
              Да
            </button>
            <button
              onClick={() => closeModal(false)}
              className="button close-button"
            >
              Нет
            </button>
          </div>
        </div>
      </section>
    </>
  );
}
export default BlockAgentModal;
