import { SidebarList } from "components/SidebarList";
import React from "react";
import { FaSearch, FaBars } from "react-icons/fa";
import {
  isAccountantUser,
  isAdmin,
  isCommercialUser,
  isLabEmployee,
  isLabExpert,
  isLabHead,
  isLabHeadDeputy,
  isTenderUser,
} from "utils/defineUser";

const MAIN_ITEM_START = [
  {
    link: "/sout/tender/global-search",
    content: (
      <>
        <FaSearch className="sidebar__icon-search" />{" "}
        <b className="sidebar__search-span">Общий поиск</b>
      </>
    ),
    type: "default",
  },
  {
    link: "/sout/tender/actual",
    content: (
      <>
        <FaBars className="sidebar__icon-search" />{" "}
        <b className="sidebar__search-span">Все актуальные </b>
      </>
    ),
    type: "default",
  },
  {
    link: "/sout/tender/draft",
    content: "Черновики",
    type: "default",
  },
  {
    link: "/sout/tender/estimated",
    content: "Оцененные",
    type: "default",
  },
  {
    link: "/sout/tender/approved",
    content: "Одобренные",
    type: "default",
  },
];

const MAIN_ITEM_END = [
  {
    link: "/sout/tender/finish",
    content: "Завершенные",
    type: "default",
  },
  {
    link: "/sout/tender/archive",
    content: "Архив",
    type: "archive",
  },
  {
    link: "/sout/tender/basket",
    content: "Корзина",
    type: "no-result-space",
  },
  {
    link: "/sout/tender/not-filed",
    content: "Не поданные",
    type: "no-result",
  },
  {
    link: "/sout/tender/lost",
    content: "Не выигранные",
    type: "no-result",
  },
  {
    link: "/sout/tender/lostHasNoWinner",
    content: "победитель неизвестен",
    type: "sub-no-result",
  },
  {
    link: "/sout/tender/lostHasWinner",
    content: "победитель известен",
    type: "sub-no-result",
  },
  {
    link: "/sout/tender/not-signed",
    content: "Не подписанные",
    type: "no-result",
  },
];

const TenderSidebar = ({ toggleSidebar, isOpen }) => {
  return (
    <>
      {isCommercialUser() || isAdmin() ? (
        <SidebarList
          title="Коммерческий отдел"
          titleIsOpen={isOpen.commercial}
          toggleSidebar={() => toggleSidebar("commercial")}
          color="purple"
          items={[
            {
              type: "line",
            },
          ]}
        />
      ) : null}
      {isTenderUser() || isAdmin() ? (
        <SidebarList
          title="Тендерный отдел"
          titleIsOpen={isOpen.tender}
          toggleSidebar={() => toggleSidebar("tender")}
          color="purple"
          items={[
            ...MAIN_ITEM_START,
            {
              link: "/sout/tender/accepted-to-work",
              content: "Принятые в работу по заявке",
              type: "default",
            },
            {
              link: "/sout/tender/filed",
              content: "Поданные",
              type: "default",
            },
            {
              link: "/sout/tender/won-and-signing",
              content: "Победа и подписание",
              type: "default",
            },
            ...MAIN_ITEM_END,
          ]}
        />
      ) : null}
      {isLabHead() || isAdmin() ? (
        <SidebarList
          title="Начальник лаборатории"
          titleIsOpen={isOpen.lab_chief}
          toggleSidebar={() => toggleSidebar("lab_chief")}
          color="purple"
          items={[
            ...MAIN_ITEM_START,
            {
              link: "/sout/tender/sent-to-tender",
              content: "Отправлено в тендерный отдел",
              type: "default",
            },
            ...MAIN_ITEM_END,
          ]}
        />
      ) : null}
      {isLabHeadDeputy() || isAdmin() ? (
        <SidebarList
          title="Заведующий лабораторией"
          titleIsOpen={isOpen.lab_head_deputy}
          toggleSidebar={() => toggleSidebar("lab_head_deputy")}
          color="purple"
          items={[
            ...MAIN_ITEM_START,
            {
              link: "/sout/tender/sent-to-tender",
              content: "Отправлено в тендерный отдел",
              type: "default",
            },
            ...MAIN_ITEM_END,
          ]}
        />
      ) : null}
      {isLabExpert() || isAdmin() ? (
        <SidebarList
          title="Эксперт лаборатории"
          titleIsOpen={isOpen.lab_expert}
          toggleSidebar={() => toggleSidebar("lab_expert")}
          color="purple"
          items={[
            ...MAIN_ITEM_START,
            {
              link: "/sout/tender/sent-to-tender",
              content: "Отправлено в тендерный отдел",
              type: "default",
            },
            {
              link: "/sout/tender/finish",
              content: "Завершенные",
              type: "default",
            },
            {
              link: "/sout/tender/archive",
              content: "Архив",
              type: "archive",
            },
            {
              link: "/sout/tender/basket",
              content: "Корзина",
              type: "no-result-space",
            },
            {
              link: "/sout/tender/not-filed",
              content: "Не поданные",
              type: "no-result",
            },
            {
              link: "/sout/tender/lost",
              content: "Не выигранные",
              type: "no-result",
            },
            {
              link: "/sout/tender/lostHasNoWinner",
              content: "победитель неизвестен",
              type: "sub-no-result",
            },
            {
              link: "/sout/tender/lostHasWinner",
              content: "победитель известен",
              type: "sub-no-result",
            },
            {
              link: "/sout/tender/not-signed",
              content: "Не подписанные",
              type: "no-result",
            },
          ]}
        />
      ) : null}
      {isLabEmployee() || isAdmin() ? (
        <SidebarList
          title="Сотрудник лаборатории"
          titleIsOpen={isOpen.lab_assist}
          toggleSidebar={() => toggleSidebar("lab_assist")}
          color="purple"
          items={[
            {
              type: "line",
            },
          ]}
        />
      ) : null}
      {isAccountantUser() || isAdmin() ? (
        <SidebarList
          title="Бухгалтерия"
          titleIsOpen={isOpen.accountant}
          toggleSidebar={() => toggleSidebar("accountant")}
          color="purple"
          items={[
            {
              type: "line",
            },
          ]}
        />
      ) : null}
      {isAdmin() ? (
        <SidebarList
          title="Админ"
          titleIsOpen={isOpen.admin}
          toggleSidebar={() => toggleSidebar("admin")}
          color="purple"
          items={[
            ...MAIN_ITEM_START,
            {
              link: "/sout/tender/accepted-to-work",
              content: "Принятые в работу по заявке",
              type: "default",
            },
            {
              link: "/sout/tender/filed",
              content: "Поданные",
              type: "default",
            },
            {
              link: "/sout/tender/won-and-signing",
              content: "Победа и подписание",
              type: "default",
            },
            ...MAIN_ITEM_END,
          ]}
        />
      ) : null}
    </>
  );
};

export default TenderSidebar;
