import { generateToken } from 'shared/generateToken';
import { api } from 'settings/Api';

export const fetchEmployees = async (department) => {
  try {
    const token = generateToken();
    const url = `/api/sout/helpers/handbooks/engineers?department=${department}`;
    const headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
    
    const response = await api(url, headers);
    
    if (response.status !== 200) return response.status;
     
    const data = await response.json();

    if (data.isSuccess) return data.data;

    if (data.isFailure) throw new Error();
      
  }
  catch (e) {
    alert(`Произошла ошибка: ${e}`);
  }
}