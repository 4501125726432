import AgentContext from "context/ParticipantsOfProcessContext";
import { departmentTypeKey } from "../utils/BaseUrl";
import { getBaseUrl } from "../utils/BaseUrl";
import { useNavigate } from "react-router-dom";
import { api } from "settings/Api";
import { useContext } from "react";

function UnblockAgentModal({ closeModal, user, action }) {
  const navigate = useNavigate();
  const { isChangeState, setIsChangeState } = useContext(AgentContext);

  const unblockUser = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    const departmentType = localStorage.getItem(departmentTypeKey);

    api(`/api/sout/admin/organizations/unlock?id=${user.state.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Агент разблокирован!");
        setIsChangeState(!isChangeState);
        closeModal(false);
        navigate("/ListOfAgents");
      } else if (res.status === 500 || !res.ok) {
        alert("Агент не разблокирован!");
        closeModal(false);
      }
    });
  };

  return (
    <>
      <section className="modal" data-action="modal-exit">
        <div className="modal__content">
          <button
            onClick={() => closeModal(false)}
            className="modal__close-button close-button"
          >
            &#10005;
          </button>
          <h3 className="modal__description">
            Разблокировать агента
            <b>
              &nbsp;{user.state.name}&nbsp;{user.state.lastName}
            </b>
            . Вы уверены?
          </h3>
          <div className="modal__buttons-wrapper">
            <button onClick={unblockUser} className="button button--blue">
              Да
            </button>
            <button
              onClick={() => closeModal(false)}
              className="button close-button"
            >
              Нет
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default UnblockAgentModal;
