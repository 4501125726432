//*Define user area
const isSoutUserArea = () => localStorage.getItem('__user-area') === "sout"
export { isSoutUserArea }

const isMedUserArea = () => localStorage.getItem('__user-area') === "med"
export { isMedUserArea }

const canStop = () => localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'lab_chief' || localStorage.getItem('role') === 'lab_manager'
export { canStop }
//* Define user department only
const isTenderUser = () => localStorage.getItem('role') === 'tender_chief' || localStorage.getItem('role') === 'tender_emp'
export { isTenderUser }

const isCommercialUser = () => localStorage.getItem('role') === 'comercial_chief' || localStorage.getItem('role') === 'comercial_emp'
export { isCommercialUser }

const isLabUser = () => localStorage.getItem('__user-department') === 'lab'
export { isLabUser }

const isMedUser = () => localStorage.getItem('__user-department') === 'med'
export { isMedUser }

const isAccountantUser = () => localStorage.getItem('__user-department') === 'accountant'
export { isAccountantUser }


//* Define user position only
const isHead = () => localStorage.getItem('__user-position') === 'DepartmentHead'
export { isHead }

const isEmployee = () => localStorage.getItem('__user-position') === 'Employee'
export { isEmployee }

const isExpert = () => localStorage.getItem('__user-position') === 'Expert'
export { isExpert }

//* Define user department and position
const isTenderEmployee = () => isTenderUser() && isEmployee()
export { isTenderEmployee };

const isTenderHead = () => isTenderUser() && isHead()
export { isTenderHead };

const isCommercialEmployee = () => isCommercialUser() && isEmployee()
export { isCommercialEmployee };

const isComercialHead = () => isCommercialUser() && isHead()
export { isComercialHead };

const isLabEmployee = () => isLabUser() && isEmployee()
export { isLabEmployee }

const isLabHead = () => isLabUser() && isHead()
export { isLabHead }

const isMedEmployee = () => isMedUser() && isEmployee()
export { isMedEmployee }

const isMedHead = () => isMedUser() && isHead()
export { isMedHead }

const isAccountantEmployee = () => isAccountantUser() && isEmployee()
export { isAccountantEmployee }

const isСhieftain = () => isAccountantUser() && isHead()
export { isСhieftain }

const isLabExpert = () => isLabUser() && isExpert();
export { isLabExpert }

const isLabManager = () => isLabUser() && localStorage.getItem('__user-position') === 'Manager'
export { isLabManager as isLabHeadDeputy }

const isLabAssistant = () => isLabUser() && localStorage.getItem('__user-position') === 'Assistant'
export {isLabAssistant};

const isAdmin = () => localStorage.getItem('__erp_username') === 'Admin' || localStorage.getItem('__isAdmin') || localStorage.getItem('role') === 'admin'
export { isAdmin }

