import { api } from 'settings/Api';
import postTenderData from "./PostTenderData";
const moveToPrevStageTender = async (projectId, type) => {
	
	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
	let res = await api(`/api/sout/workflow/${type}/return/${projectId}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
			// 'Authorization':  'String(authTokens.access) ,
		},
	});
	let data = await res.json();

	//
	return res;
};
export default moveToPrevStageTender;
