export const sortLocation = (contractMB, potentialMB) => {
    const result = [];
    const includesRegion = [];
    
    for(let item of contractMB){
        if(!includesRegion.includes(item.regions[0].id)){
            if(potentialMB?.filter((i) => i?.regions[0]?.id == item?.regions[0]?.id).length !== 0){
                result.push(...contractMB?.filter((i) => i?.regions[0]?.id == item.regions[0]?.id));
                result.push(...potentialMB?.filter((i) => i?.regions[0]?.id == item?.regions[0]?.id));
                includesRegion.push(item?.regions[0]?.id)
            }
        }
    }

    return result;
}