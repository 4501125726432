const Input = ({ 
  className, 
  classNameLabel, 
  type, 
  onChange, 
  onPasteCapture, 
  value, 
  data, 
  disabled
}) => (
	<label className={classNameLabel}>
		{" "}
		{data.label}
		<input
			type={type}
			className={`input ${className}`}
			onChange={onChange}
			onPasteCapture={onPasteCapture}
			value={value}
			name={data.name}
      disabled={disabled}
		/>
	</label>
);

Input.defaultProps = {
	className: "",
};

export { Input };
