import spinner from '../assets/images/spinner.gif'

function Spinner({ className, style }) {
  return (
    <img
      className={className}
      src={spinner}
      style={style}
      alt='Loading...'
    />
  )
}

Spinner.defaultProps = {
  className: 'spinner'
}
export default Spinner
