import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TENDER_STAGES } from "../../../../utils/stages";
import Sidebar from "../../../../components/Sidebar";
import fetchProject from "../../../../utils/fetchProject";
import patchProject from "../../../../utils/patchProject";
import fetchCompetitors from "../../../../utils/fetchCompetitors";
import {
	TENDER_DEFAULT_USER_ID,
	COM_DEFAULT_USER_ID,
	LAB_DEFAULT_USER_ID,
} from "../../../../utils/DefaultUsersID";

import SelectRegions from "../../../../components/common/SelectRegions";
import {
	isAccountantEmployee,
	isAdmin,
	isLabEmployee,
	isLabExpert,
	isLabHead,
	isLabHeadDeputy,
	isMedUserArea,
	isSoutUserArea,
	isTenderUser,
} from "../../../../utils/defineUser";
import { api } from 'settings/Api';

const EditProjectTender = () => {
	const location = useLocation();
	const { data } = location.state;
	const projectId = data;

	const navigate = useNavigate();

	const [values, setUpdatdedValues] = useState("");

	const [tradingFloors, getTradingFloors] = useState("");
	const [competitors, setCompetitors] = useState("");
	const [tenderUsers, getTenderUsers] = useState("");
	const [commercialUsers, getCommercialUsers] = useState("");
	const [laboratoryUsers, getLaboratoryUsers] = useState("");
	const [workers, getWorkers] = useState("");

	const tradingFloorCommissionAmountRef = useRef(null);
	const contractSecurityAmountRef = useRef(null);
	const offerSecurityAmountRef = useRef(null);

	const resetTradingFloorCommissionAmount = () =>
		setUpdatdedValues({ ...values, tradingFloorCommissionAmount: 0 });
	const resetContractSecurityAmount = () =>
		setUpdatdedValues({ ...values, securityAmount: 0 });
	const resetOfferSecurityAmount = () =>
		setUpdatdedValues({ ...values, offerSecurityAmount: 0 });

	const handleFetchedValues = (values) => setUpdatdedValues(values);

	useEffect(() => {
		fetchProject(projectId, handleFetchedValues, "edit");
		fetchUsers("TenderDepartment");
		fetchUsers("CommercialDepartment");
		fetchUsers("Laboratory");
		fetchCompetitors(setCompetitors);
		fetchTradingSites();
		fetchWorkers();
	}, []);

	const inputChangeHandler = (e) => {
		const { name, value } = e.target;
		setUpdatdedValues({
			...values,
			[name]: value,
		});
	};

	const updateRegions = (updatedRegions) =>
		setUpdatdedValues({ ...values, regions: updatedRegions });

	let fetchUsers = async (departmentName) => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		let response = await api(
			`/api/sout/helpers/handbooks/engineers?department=${departmentName}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		let data = await response.json();

		switch (departmentName) {
			case "TenderDepartment":
				getTenderUsers(data.data);
				break;
			case "CommercialDepartment":
				getCommercialUsers(data.data);
				break;
			case "Laboratory":
				getLaboratoryUsers(data.data);
				break;
			default:
				throw new Error("Fetch error: department cannot found!");
		}
	};

	let fetchWorkers = async () => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		let response = await api(`/api/sout/helpers/handbooks/workers`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		let data = await response.json();
		getWorkers(data.data);
	};

	let fetchTradingSites = async () => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;

		let response = await api(`/api/sout/helpers/handbooks/tradingfloors`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		let data = await response.json();
		getTradingFloors(data.data);
	};

	const isOnStageOrPassed = (stageIdx) => {
		const stageName = values.projectStage;
		return TENDER_STAGES.indexOf(stageName) >= stageIdx;
	};

	return (
		<main className="workspace">
			<Sidebar />
			<div className="workspace__content">
				<section className="workspace__wrapper">
					<h3 className="title title workspace__page-title">
						<span style={{ marginRight: "10px" }}>&#x270E;</span>
						Редактировать | Тендерный проект
					</h3>
					<form className="edit-page__form">
						{
							isAdmin()
							&&
							<>
								{/* FIRST COLUMN */}
								<section className="form__column">
									<fieldset className="workspace__ensure-wrapper">
										<h4 className="subtitle">Заказчик</h4>
										<label className="workspace__input-title">
											Наименование заказчика:
										</label>
										<input
											className="input"
											type="text"
											disabled={isOnStageOrPassed(0)}
											name="customerName"
											value={values.customerName}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label className="workspace__input-title">
											Дополнительное наименование:
										</label>
										<input
											className="input"
											type="text"
											name="additionalCustomerName"
											value={values.additionalCustomerName}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label className="workspace__input-title">
											ИНН заказчика:
										</label>
										<input
											className="input"
											disabled={isOnStageOrPassed(0)}
											type="text"
											maxLength="12"
											name="inn"
											value={values.inn}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label className="workspace__input-title">
											Контактные данные:
										</label>
										<textarea
											className="input"
											name="contactData"
											value={values.contactData}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</fieldset>
									<fieldset className="workspace__ensure-wrapper">
										<h4 className="subtitle">Контракт</h4>
										<label className="workspace__input-title">
											Номер контракта:
										</label>
										<input
											className="input"
											type="text"
											name="contractNumber"
											value={values.contractNumber}
											onChange={(e) => inputChangeHandler(e)}
											disabled={isOnStageOrPassed(4) && !isAdmin()}
										/>

										<label className="workspace__input-title">
											Цена контракта:
										</label>
										<input
											className="input"
											type="number"
											name="price"
											value={values.price}
											onChange={(e) => inputChangeHandler(e)}
											onWheel={(e) => e.target.blur()}
										/>
										<label className="workspace__input-title">
											Сумма по акту:
										</label>
										<input
											className="input"
											type="number"
											name="actAmount"
											value={values.actAmount}
											onChange={(e) => inputChangeHandler(e)}
											onWheel={(e) => e.target.blur()}
											disabled={isOnStageOrPassed(13) && !isAdmin()}
										/>
										<label className="workspace__input-title">
											Ссылка от агрегатора:
										</label>
										<input
											className="input"
											type="text"
											name="aggregatorLink"
											value={values.aggregatorLink}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label className="workspace__input-title">
											Идентификационный номер:
										</label>
										<input
											className="input"
											type="text"
											name="alternateId"
											value={values.alternateId}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label className="workspace__input-title">
											Комментарий к исполнению:
										</label>
										<textarea
											className="input"
											name="executionDescription"
											value={values.executionDescription}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label className="workspace__input-title">
											КПП:
										</label>
										<input
											className="input"
											type="text"
											name="KPP"
											value={values.KPP}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</fieldset>
								</section>
								{/* SECOND COLUMN */}
								<section className="form__column workspace__ensure-wrapper">
									<h4 className="subtitle">Закупка</h4>
									<label className="workspace__input-title">
										ЕИС (ID на площадке):
									</label>
									<input
										className="input"
										type="text"
										name="tenderId"
										value={values.tenderId}
										onChange={(e) => inputChangeHandler(e)}
									/>
									<label className="workspace__input-title">Площадка</label>
									<select
										className="input search-area__select"
										name="tradingFloorId"
										value={values.tradingFloorId}
										onChange={(e) => inputChangeHandler(e)}
									>
										<option value="null">-</option>
										{tradingFloors &&
											tradingFloors.map(({ id, name }) => (
												<option key={id} value={id}>
													{" "}
													{name}{" "}
												</option>
											))}
									</select>
									<label className="workspace__input-title">
										Закон госзакупки:
										<select
											className="select"
											name="legalBasesId"
											value={values.legalBasesId}
											onChange={(e) => inputChangeHandler(e)}
										>
											<option value="None">Нет</option>
											<option value="Gov">44 ФЗ</option>
											<option value="PartialGov">223 ФЗ</option>
											<option value="Other">Другое</option>
										</select>
									</label>
									<label className="workspace__input-title">
										Вид процедуры
									</label>
									<select
										className="input search-area__select"
										name="operationTypeId"
										value={values.operationTypeId}
										onChange={(e) => inputChangeHandler(e)}
									>
										<option value="null">-</option>
										<option value="None">Нет</option>
										<option value="DigitalAuction">Электронный аукцион</option>
										<option value="RequestForQuotations">
											Запрос котировок или предложений
										</option>
										<option value="Other">Другое</option>
									</select>
									{values && values?.operationTypeId === "DigitalAuction" ? (
										<fieldset className="workspace__ensure-wrapper">
											<label className="workspace__input-ensure">
												Кто отыгрывает аукцион:
											</label>
											<input
												id="hasTradingRobot-true"
												className="radio"
												type="radio"
												name="hasTradingRobot"
												value="true"
												checked={values.hasTradingRobot === "true"}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label
												htmlFor="hasTradingRobot-true"
												className="radio__label"
											>
												Робот
											</label>

											<input
												id="hasTradingRobot-false"
												className="radio"
												type="radio"
												name="hasTradingRobot"
												value="false"
												checked={values.hasTradingRobot === "false"}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label
												htmlFor="hasTradingRobot-false"
												className="radio__label radio__label--ml"
											>
												Не робот
											</label>

											<label className="workspace__input-title">
												Дата аукциона:
											</label>
											<input
												className="input"
												type="datetime-local"
												name="auctionDate"
												value={values.auctionDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</fieldset>
									) : null}
									<label className="workspace__input-title">
										Дата публикации:
									</label>
									<input
										className="input"
										type="date"
										name="publicationDate"
										value={values.publicationDate}
										onChange={(e) => inputChangeHandler(e)}
									/>

									<label className="workspace__input-title">
										Дата окончания подачи заявки:
									</label>
									<input
										className="input"
										type="datetime-local"
										name="acceptingOffersDeadline"
										value={values.acceptingOffersDeadline}
										onChange={(e) => inputChangeHandler(e)}
									/>

									<label className="workspace__input-title">
										Дата итогового протокола:
										<input
											className="input"
											type="date"
											name="summaryReportDate"
											value={values.summaryReportDate}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</label>

									<label className="workspace__input-title">НМЦК:</label>
									<input
										className="input"
										type="number"
										name="startMaxPrice"
										value={values.startMaxPrice}
										onChange={(e) => inputChangeHandler(e)}
										onWheel={(e) => e.target.blur()}
									/>

									<label className="workspace__input-title">Наша цена:</label>
									<input
										className="input"
										type="number"
										disabled={isOnStageOrPassed(0) && !isAdmin()}
										name="offerAmount"
										value={values.offerAmount}
										onChange={(e) => inputChangeHandler(e)}
										onWheel={(e) => e.target.blur()}
									/>

									<label className="workspace__input-title">
										Комментарий к заявке:
									</label>
									<textarea
										className="input"
										cols="50"
										rows="5"
										name="description"
										value={values.description}
										onChange={(e) => inputChangeHandler(e)}
									/>

									<label className="workspace__input-title">
										Комментарий о причине неподачи:
									</label>
									<textarea
										className="input"
										cols="50"
										rows="5"
										name="failrueDescription"
										value={values.failrueDescription}
										onChange={(e) => inputChangeHandler(e)}
									/>

									<fieldset className="workspace__ensure-wrapper">
										<label className="workspace__input-ensure">
											Обеспечение заявки:
										</label>
										<input
											id="pp-request"
											className="radio"
											type="radio"
											name="offerSecuringMethodId"
											value="PaymentOrder"
											checked={values.offerSecuringMethodId === "PaymentOrder"}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label htmlFor="pp-request" className="radio__label">
											ПП
										</label>

										<input
											id="none-request"
											className="radio"
											type="radio"
											name="offerSecuringMethodId"
											value="None"
											checked={values.offerSecuringMethodId === "None"}
											onChange={(e) => inputChangeHandler(e)}
											onClick={resetOfferSecurityAmount}
										/>
										<label
											htmlFor="none-request"
											className="radio__label workspace__label"
											onClick={resetOfferSecurityAmount}
										>
											Нет
										</label>
										{values.offerSecuringMethodId !== "None" && (
											<>
												<label>Сумма обеспечения заявки:</label>
												<input
													className="input"
													type="number"
													name="offerSecurityAmount"
													value={
														values.offerSecuringMethodId === "None"
															? 0
															: values.offerSecurityAmount
													}
													onChange={(e) => inputChangeHandler(e)}
													onWheel={(e) => e.target.blur()}
													ref={offerSecurityAmountRef}
												/>
											</>
										)}
									</fieldset>

									<fieldset className="workspace__ensure-wrapper">
										<label className="workspace__input-ensure">
											Обеспечение контракта:
										</label>
										<input
											id="pp-contract"
											className="radio"
											type="radio"
											name="contractSecuringMethodId"
											value="PaymentOrder"
											checked={
												values.contractSecuringMethodId === "PaymentOrder"
											}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label htmlFor="pp-contract" className="radio__label">
											ПП
										</label>

										<input
											id="bg-contract"
											className="radio"
											type="radio"
											name="contractSecuringMethodId"
											value="BankGuarantee"
											checked={
												values.contractSecuringMethodId === "BankGuarantee"
											}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label
											htmlFor="bg-contract"
											className="radio__label workspace__label"
										>
											БГ
										</label>

										<input
											id="none-contract"
											className="radio"
											type="radio"
											name="contractSecuringMethodId"
											value="None"
											checked={values.contractSecuringMethodId === "None"}
											onChange={(e) => inputChangeHandler(e)}
											onClick={resetContractSecurityAmount}
										/>
										<label
											htmlFor="none-contract"
											className="radio__label workspace__label"
											onClick={resetContractSecurityAmount}
										>
											Нет
										</label>
										{values.contractSecuringMethodId !== "None" && (
											<>
												<label>Сумма обеспечения контракта:</label>
												<input
													className="input"
													type="number"
													name="securityAmount"
													value={
														values.contractSecuringMethodId === "None"
															? 0
															: values.securityAmount
													}
													onChange={(e) => inputChangeHandler(e)}
													onWheel={(e) => e.target.blur()}
													ref={contractSecurityAmountRef}
												/>
											</>
										)}
									</fieldset>

									<fieldset className="workspace__ensure-wrapper">
										<label className="workspace__input-ensure">
											Комиссия площадки:
										</label>
										<input
											id="pp-commision"
											className="radio"
											type="radio"
											name="tradingFloorCommisionId"
											value="PaymentOrder"
											checked={
												values.tradingFloorCommisionId === "PaymentOrder"
											}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label htmlFor="pp-commision" className="radio__label">
											ПП
										</label>

										<input
											id="tarif-commision"
											className="radio"
											type="radio"
											name="tradingFloorCommisionId"
											value="Rate"
											checked={values.tradingFloorCommisionId === "Rate"}
											onChange={(e) => inputChangeHandler(e)}
										/>
										<label
											htmlFor="tarif-commision"
											className="radio__label workspace__label"
										>
											Тариф
										</label>

										<input
											id="none-commision"
											className="radio"
											type="radio"
											name="tradingFloorCommisionId"
											value="None"
											checked={values.tradingFloorCommisionId === "None"}
											onChange={(e) => inputChangeHandler(e)}
											onClick={resetTradingFloorCommissionAmount}
										/>
										<label
											htmlFor="none-commision"
											className="radio__label workspace__label workspace__label--ml"
											onClick={resetTradingFloorCommissionAmount}
										>
											Нет
										</label>
										{values.tradingFloorCommisionId !== "None" && (
											<>
												<label>Сумма комиссии площадки:</label>
												<input
													className="input"
													type="number"
													name="tradingFloorCommissionAmount"
													value={
														values.tradingFloorCommisionId === "None"
															? 0
															: values.tradingFloorCommissionAmount
													}
													onChange={(e) => inputChangeHandler(e)}
													onWheel={(e) => e.target.blur()}
													ref={tradingFloorCommissionAmountRef}
												/>
											</>
										)}
									</fieldset>

									<label className="workspace__input-title">
										Цена победителя:
									</label>
									<input
										className="input"
										type="number"
										disabled={isOnStageOrPassed(2) && !isAdmin()}
										name="winnerPrice"
										value={values.winnerPrice}
										onChange={(e) => inputChangeHandler(e)}
										onWheel={(e) => e.target.blur()}
									/>

									<label className="workspace__input-title">Победитель:</label>
									<select
										className="input search-area__select"
										disabled={isOnStageOrPassed(2) && !isAdmin()}
										name="winnerId"
										value={values.winnerId}
										onChange={(e) => inputChangeHandler(e)}
									>
										<option value="null">-</option>
										{competitors &&
											competitors.map(({ id, name }) => (
												<option key={id} value={id}>
													{" "}
													{name}{" "}
												</option>
											))}
									</select>
								</section>
								{/* THIRD COLUMN */}
								<section className="form__column workspace__ensure-wrapper">
									{isSoutUserArea() && (
										<>
											<h4 className="subtitle">Даты</h4>
											<label className="workspace__input-title">
												Дата заключения договора:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="conclusionContractDate"
												value={values.conclusionContractDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Плановая дата исполнения:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="plannedExecutionDate"
												value={values.plannedExecutionDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Дата получения информации:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(6) && !isAdmin()}
													name="informationCollectionDate"
													value={values.informationCollectionDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата получения полной информации:
												<input
													className="input"
													type="date"
													name="informationCollectedAt"
													value={values.informationCollectedAt}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>

											<label className="workspace__input-title">
												Дата акта:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(13) && !isAdmin()}
													name="actDate"
													value={values.actDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">Дата оплаты:</label>
											<input
												className="input"
												type="date"
												name="paidDate"
												value={values.paidDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Дата приема в работу:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(8) && !isAdmin()}
													name="acceptedToWorkDate"
													value={values.acceptedToWorkDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки отчета:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(10) && !isAdmin()}
													name="sentDate"
													value={values.sentDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата выгрузки:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(12) && !isAdmin()}
													name="unloadingDate"
													value={values.unloadingDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата замеров:
												<input
													className="input"
													type="date"
													name="measurementsDate"
													value={values.measurementsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата назначения исполнителя:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(7) && !isAdmin()}
													name="performerAppointedDate"
													value={values.performerAppointedDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки на согласование:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(9) && !isAdmin()}
													name="approvalsDate"
													value={values.approvalsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата подписания титульного листа:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(11) && !isAdmin()}
													name="titleSheetSignDate"
													value={values.titleSheetSignDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-ensure">
													Акт получен?:
												</label>
												<input
													id="ActRecived"
													className="radio"
													type="radio"
													name="actHasReached"
													value="true"
													checked={values.actHasReached === "true"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label htmlFor="ActRecived" className="radio__label">
													Получен
												</label>

												<input
													id="ActDidNotRecived"
													className="radio"
													type="radio"
													name="actHasReached"
													value="false"
													checked={values.actHasReached === "false"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="ActDidNotRecived"
													className="radio__label workspace__label"
													style={{ width: "200px" }}
												>
													Не получен
												</label>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-title">
													Тип оценки:
												</label>
												<input
													id="sout"
													className="radio"
													type="radio"
													name="assesmentTypeId"
													value="WorkingConditionsSpecialAssesment"
													checked={
														values.assesmentTypeId ===
														"WorkingConditionsSpecialAssesment"
													}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(1) && !isAdmin()}
												/>
												<label
													htmlFor="sout"
													className={`radio__label ${isOnStageOrPassed(1) &&
														!isAdmin() &&
														"radio__label--grey"
														}`}
												>
													СОУТ
												</label>

												<input
													id="pc"
													className="radio"
													type="radio"
													name="assesmentTypeId"
													value="ProductionControl"
													checked={
														values.assesmentTypeId === "ProductionControl"
													}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(1) && !isAdmin()}
												/>
												<label
													htmlFor="pc"
													className={`radio__label workspace__label ${isOnStageOrPassed(1) &&
														!isAdmin() &&
														"radio__label--grey"
														}`}
												>
													ПК
												</label>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-ensure">
													Рабочая тетрадь:
												</label>
												<input
													id="workbook-true"
													className="radio"
													type="radio"
													name="haveWorkbook"
													value="true"
													checked={values.haveWorkbook === "true"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label htmlFor="workbook-true" className="radio__label">
													Есть
												</label>

												<input
													id="workbook-false"
													className="radio"
													type="radio"
													name="haveWorkbook"
													value="false"
													checked={values.haveWorkbook === "false"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="workbook-false"
													className="radio__label workspace__label"
												>
													Нет
												</label>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-ensure">
													Программа выполнения:
												</label>
												<input
													id="attr"
													className="radio"
													type="radio"
													name="executionProgramId"
													value="Attestation_5_1"
													checked={
														values.executionProgramId === "Attestation_5_1"
													}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="attr"
													className="radio__label radio__label--attestation"
												>
													Аттестация
												</label>

												<input
													id="labor"
													className="radio"
													type="radio"
													name="executionProgramId"
													value="LaborExpert_3_0"
													checked={
														values.executionProgramId === "LaborExpert_3_0"
													}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label htmlFor="labor" className="radio__label">
													ТрудЭксперт
												</label>

												<input
													id="no-programm"
													className="radio"
													type="radio"
													name="executionProgramId"
													value="None"
													checked={values.executionProgramId === "None"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="no-programm"
													className="radio__label workspace__label"
												>
													Нет
												</label>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-ensure">
													Обеспечение вернулось?
												</label>
												<input
													id="SecurityWasReturned"
													className="radio"
													type="radio"
													name="securityReturned"
													value="true"
													checked={values.securityReturned === "true"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="SecurityWasReturned"
													className="radio__label"
												>
													Да
												</label>

												<input
													id="SecurityDidNotReturned"
													className="radio"
													type="radio"
													name="securityReturned"
													value="false"
													checked={values.securityReturned === "false"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="SecurityDidNotReturned"
													className="radio__label workspace__label"
													style={{ width: "200px" }}
												>
													Нет
												</label>
											</fieldset>
											<h4 className="subtitle">Ответственные</h4>
											<label className="workspace__input-title">
												Тендерный отдел:
											</label>
											<select
												className="input search-area__select"
												name="tenderUserId"
												value={values.tenderUserId}
												onChange={(e) => inputChangeHandler(e)}
											>
												<option value={"null"}> - </option>
												{tenderUsers &&
													tenderUsers
														.filter(
															(item) => item.id !== TENDER_DEFAULT_USER_ID
														)
														.map(({ id, name }) => (
															<option key={id} value={id}>
																{name}
															</option>
														))}
											</select>
											<label className="workspace__input-title">
												Лаборатория:
											</label>
											<select
												className="input search-area__select"
												name="laboratoryUserId"
												value={values.laboratoryUserId}
												onChange={(e) => inputChangeHandler(e)}
												disabled={isOnStageOrPassed(7) && !isAdmin()}
											>
												<option value={LAB_DEFAULT_USER_ID}>
													Неизвестный сотрудник
												</option>
												{laboratoryUsers &&
													laboratoryUsers
														.filter((item) => item.id !== LAB_DEFAULT_USER_ID)
														.map(({ id, name }) => (
															<option key={id} value={id}>
																{" "}
																{name}{" "}
															</option>
														))}
											</select>
											<label className="workspace__input-title">
												Замерщик:
											</label>
											<select
												className="input search-area__select"
												name="workerId"
												value={values.workerId}
												onChange={(e) => inputChangeHandler(e)}
												disabled={isOnStageOrPassed(6) && !isAdmin()}
											>
												<option value="null">-</option>
												{workers &&
													workers.map(({ id, name }) => (
														<option key={id} value={id}>
															{" "}
															{name}{" "}
														</option>
													))}
											</select>
											<label className="workspace__input-title">
												Число подразделений:
											</label>
											<input
												className="input"
												type="number"
												name="unitCount"
												disabled={
													values.assesmentTypeId === "ProductionControl" ||
													(isOnStageOrPassed(5) && !isAdmin())
												}
												value={values.unitCount}
												onChange={(e) => inputChangeHandler(e)}
												onWheel={(e) => e.target.blur()}
											/>

											<label className="workspace__input-title">
												Число рабочих мест:
											</label>
											<input
												className="input"
												type="number"
												name="workspaceCount"
												value={values.workspaceCount}
												onChange={(e) => inputChangeHandler(e)}
												onWheel={(e) => e.target.blur()}
												disabled={isOnStageOrPassed(6) && !isAdmin()}
											/>
											<SelectRegions
												regions={values.regions}
												updateRegions={updateRegions}
											/>
										</>
									)}
									{isMedUserArea() && (
										<>
											<label className="workspace__input-title">
												Количество людей:
											</label>
											<input
												className="input"
												type="number"
												name="peopleForMedicalExamination"
												value={values.peopleForMedicalExamination}
												onChange={(e) => inputChangeHandler(e)}
												onWheel={(e) => e.target.blur()}
											/>

											<h4 className="subtitle">Ответственные</h4>
											<label className="workspace__input-title">
												Тендерный отдел:
											</label>
											<select
												className="input search-area__select"
												name="tenderUserId"
												value={values.tenderUserId}
												onChange={(e) => inputChangeHandler(e)}
											>
												<option value={"null"}> - </option>
												{tenderUsers &&
													tenderUsers
														.filter(
															(item) => item.id !== TENDER_DEFAULT_USER_ID
														)
														.map(({ id, name }) => (
															<option key={id} value={id}>
																{" "}
																{name}{" "}
															</option>
														))}
											</select>
										</>
									)}
									<button
										className="button staff__create-staff-button"
										onClick={async (e) => {
											await patchProject(e, values, projectId);
											navigate(-1);
										}}
									>
										Cохранить
									</button>
								</section>
							</>
						}
						{
							!isAdmin()
							&&
							<>
								{isTenderUser() && (
									<>
										{/* FIRST COLUMN */}
										<section className="form__column">
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Заказчик</h4>
												<label className="workspace__input-title">
													Наименование заказчика:
												</label>
												<input
													className="input"
													type="text"
													disabled={isOnStageOrPassed(0)}
													name="customerName"
													value={values.customerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Дополнительное наименование:
												</label>
												<input
													className="input"
													type="text"
													name="additionalCustomerName"
													value={values.additionalCustomerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													ИНН заказчика:
												</label>
												<input
													className="input"
													disabled={isOnStageOrPassed(0)}
													type="text"
													maxLength="12"
													name="inn"
													value={values.inn}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Контактные данные:
												</label>
												<textarea
													className="input"
													name="contactData"
													value={values.contactData}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Контракт</h4>
												<label className="workspace__input-title">
													Номер контракта:
												</label>
												<input
													className="input"
													type="text"
													name="contractNumber"
													value={values.contractNumber}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(4) && !isAdmin()}
												/>

												<label className="workspace__input-title">
													Цена контракта:
												</label>
												<input
													className="input"
													type="number"
													name="price"
													value={values.price}
													onChange={(e) => inputChangeHandler(e)}
													onWheel={(e) => e.target.blur()}
												/>
												<label className="workspace__input-title">
													Сумма по акту:
												</label>
												<input
													className="input"
													type="number"
													name="actAmount"
													value={values.actAmount}
													onChange={(e) => inputChangeHandler(e)}
													onWheel={(e) => e.target.blur()}
													disabled={isOnStageOrPassed(13) && !isAdmin()}
												/>
												<label className="workspace__input-title">
													Ссылка от агрегатора:
												</label>
												<input
													className="input"
													type="text"
													name="aggregatorLink"
													value={values.aggregatorLink}
													onChange={(e) => inputChangeHandler(e)}
												/>

												<label className="workspace__input-title">
													КПП:
												</label>
												<input
													className="input"
													type="text"
													name="KPP"
													value={values.KPP}
													onChange={(e) => inputChangeHandler(e)}
												/>

												{/* <label className="workspace__input-title">
													Замеры:
													<select
														className="select"
														name="measurementStatusId"
														value={values.measurementStatusId}
														onChange={(e) => inputChangeHandler(e)}
													>
														<option value="NotAssigned">Не назначены</option>
														<option value="NotRequired">Не требуются</option>
														<option value="Assigned">Назначены</option>
														<option value="Complited">Проведены</option>
													</select>
												</label> */}
											</fieldset>
										</section>
										{/* SECOND COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											<h4 className="subtitle">Закупка</h4>
											<label className="workspace__input-title">
												ЕИС (ID на площадке):
											</label>
											<input
												className="input"
												type="text"
												name="tenderId"
												value={values.tenderId}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">Площадка</label>
											<select
												className="input search-area__select"
												name="tradingFloorId"
												value={values.tradingFloorId}
												onChange={(e) => inputChangeHandler(e)}
											>
												<option value="null">-</option>
												{tradingFloors &&
													tradingFloors.map(({ id, name }) => (
														<option key={id} value={id}>
															{" "}
															{name}{" "}
														</option>
													))}
											</select>
											<label className="workspace__input-title">
												Закон госзакупки:
												<select
													className="select"
													name="legalBasesId"
													value={values.legalBasesId}
													onChange={(e) => inputChangeHandler(e)}
												>
													<option value="None">Нет</option>
													<option value="Gov">44 ФЗ</option>
													<option value="PartialGov">223 ФЗ</option>
													<option value="Other">Другое</option>
												</select>
											</label>
											<label className="workspace__input-title">
												Вид процедуры
											</label>
											<select
												className="input search-area__select"
												name="operationTypeId"
												value={values.operationTypeId}
												onChange={(e) => inputChangeHandler(e)}
											>
												<option value="null">-</option>
												<option value="None">Нет</option>
												<option value="DigitalAuction">Электронный аукцион</option>
												<option value="RequestForQuotations">
													Запрос котировок или предложений
												</option>
												<option value="Other">Другое</option>
											</select>
											{values && values?.operationTypeId === "DigitalAuction" ? (
												<fieldset className="workspace__ensure-wrapper">
													<label className="workspace__input-ensure">
														Кто отыгрывает аукцион:
													</label>
													<input
														id="hasTradingRobot-true"
														className="radio"
														type="radio"
														name="hasTradingRobot"
														value="true"
														checked={values.hasTradingRobot === "true"}
														onChange={(e) => inputChangeHandler(e)}
													/>
													<label
														htmlFor="hasTradingRobot-true"
														className="radio__label"
													>
														Робот
													</label>

													<input
														id="hasTradingRobot-false"
														className="radio"
														type="radio"
														name="hasTradingRobot"
														value="false"
														checked={values.hasTradingRobot === "false"}
														onChange={(e) => inputChangeHandler(e)}
													/>
													<label
														htmlFor="hasTradingRobot-false"
														className="radio__label radio__label--ml"
													>
														Не робот
													</label>

													<label className="workspace__input-title">
														Дата аукциона:
													</label>
													<input
														className="input"
														type="datetime-local"
														name="auctionDate"
														value={values.auctionDate}
														onChange={(e) => inputChangeHandler(e)}
													/>
												</fieldset>
											) : null}
											<label className="workspace__input-title">
												Дата публикации:
											</label>
											<input
												className="input"
												type="date"
												name="publicationDate"
												value={values.publicationDate}
												onChange={(e) => inputChangeHandler(e)}
											/>

											<label className="workspace__input-title">
												Дата окончания подачи заявки:
											</label>
											<input
												className="input"
												type="datetime-local"
												name="acceptingOffersDeadline"
												value={values.acceptingOffersDeadline}
												onChange={(e) => inputChangeHandler(e)}
											/>

											<label className="workspace__input-title">
												Дата итогового протокола:
												<input
													className="input"
													type="date"
													name="summaryReportDate"
													value={values.summaryReportDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>

											<label className="workspace__input-title">НМЦК:</label>
											<input
												className="input"
												type="number"
												name="startMaxPrice"
												value={values.startMaxPrice}
												onChange={(e) => inputChangeHandler(e)}
												onWheel={(e) => e.target.blur()}
											/>

											<label className="workspace__input-title">Наша цена:</label>
											<input
												className="input"
												type="number"
												disabled={isOnStageOrPassed(0) && !isAdmin()}
												name="offerAmount"
												value={values.offerAmount}
												onChange={(e) => inputChangeHandler(e)}
												onWheel={(e) => e.target.blur()}
											/>

											<label className="workspace__input-title">
												Комментарий к заявке:
											</label>
											<textarea
												className="input"
												cols="50"
												rows="5"
												name="description"
												value={values.description}
												onChange={(e) => inputChangeHandler(e)}
											/>

											<label className="workspace__input-title">
												Комментарий о причине неподачи:
											</label>
											<textarea
												className="input"
												cols="50"
												rows="5"
												name="failrueDescription"
												value={values.failrueDescription}
												onChange={(e) => inputChangeHandler(e)}
											/>

											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-ensure">
													Обеспечение заявки:
												</label>
												<input
													id="pp-request"
													className="radio"
													type="radio"
													name="offerSecuringMethodId"
													value="PaymentOrder"
													checked={values.offerSecuringMethodId === "PaymentOrder"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label htmlFor="pp-request" className="radio__label">
													ПП
												</label>

												<input
													id="none-request"
													className="radio"
													type="radio"
													name="offerSecuringMethodId"
													value="None"
													checked={values.offerSecuringMethodId === "None"}
													onChange={(e) => inputChangeHandler(e)}
													onClick={resetOfferSecurityAmount}
												/>
												<label
													htmlFor="none-request"
													className="radio__label workspace__label"
													onClick={resetOfferSecurityAmount}
												>
													Нет
												</label>
												{values.offerSecuringMethodId !== "None" && (
													<>
														<label>Сумма обеспечения заявки:</label>
														<input
															className="input"
															type="number"
															name="offerSecurityAmount"
															value={
																values.offerSecuringMethodId === "None"
																	? 0
																	: values.offerSecurityAmount
															}
															onChange={(e) => inputChangeHandler(e)}
															onWheel={(e) => e.target.blur()}
															ref={offerSecurityAmountRef}
														/>
													</>
												)}
											</fieldset>

											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-ensure">
													Обеспечение контракта:
												</label>
												<input
													id="pp-contract"
													className="radio"
													type="radio"
													name="contractSecuringMethodId"
													value="PaymentOrder"
													checked={
														values.contractSecuringMethodId === "PaymentOrder"
													}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label htmlFor="pp-contract" className="radio__label">
													ПП
												</label>

												<input
													id="bg-contract"
													className="radio"
													type="radio"
													name="contractSecuringMethodId"
													value="BankGuarantee"
													checked={
														values.contractSecuringMethodId === "BankGuarantee"
													}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="bg-contract"
													className="radio__label workspace__label"
												>
													БГ
												</label>

												<input
													id="none-contract"
													className="radio"
													type="radio"
													name="contractSecuringMethodId"
													value="None"
													checked={values.contractSecuringMethodId === "None"}
													onChange={(e) => inputChangeHandler(e)}
													onClick={resetContractSecurityAmount}
												/>
												<label
													htmlFor="none-contract"
													className="radio__label workspace__label"
													onClick={resetContractSecurityAmount}
												>
													Нет
												</label>
												{values.contractSecuringMethodId !== "None" && (
													<>
														<label>Сумма обеспечения контракта:</label>
														<input
															className="input"
															type="number"
															name="securityAmount"
															value={
																values.contractSecuringMethodId === "None"
																	? 0
																	: values.securityAmount
															}
															onChange={(e) => inputChangeHandler(e)}
															onWheel={(e) => e.target.blur()}
															ref={contractSecurityAmountRef}
														/>
													</>
												)}
											</fieldset>

											<fieldset className="workspace__ensure-wrapper">
												<label className="workspace__input-ensure">
													Комиссия площадки:
												</label>
												<input
													id="pp-commision"
													className="radio"
													type="radio"
													name="tradingFloorCommisionId"
													value="PaymentOrder"
													checked={
														values.tradingFloorCommisionId === "PaymentOrder"
													}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label htmlFor="pp-commision" className="radio__label">
													ПП
												</label>

												<input
													id="tarif-commision"
													className="radio"
													type="radio"
													name="tradingFloorCommisionId"
													value="Rate"
													checked={values.tradingFloorCommisionId === "Rate"}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label
													htmlFor="tarif-commision"
													className="radio__label workspace__label"
												>
													Тариф
												</label>

												<input
													id="none-commision"
													className="radio"
													type="radio"
													name="tradingFloorCommisionId"
													value="None"
													checked={values.tradingFloorCommisionId === "None"}
													onChange={(e) => inputChangeHandler(e)}
													onClick={resetTradingFloorCommissionAmount}
												/>
												<label
													htmlFor="none-commision"
													className="radio__label workspace__label workspace__label--ml"
													onClick={resetTradingFloorCommissionAmount}
												>
													Нет
												</label>
												{values.tradingFloorCommisionId !== "None" && (
													<>
														<label>Сумма комиссии площадки:</label>
														<input
															className="input"
															type="number"
															name="tradingFloorCommissionAmount"
															value={
																values.tradingFloorCommisionId === "None"
																	? 0
																	: values.tradingFloorCommissionAmount
															}
															onChange={(e) => inputChangeHandler(e)}
															onWheel={(e) => e.target.blur()}
															ref={tradingFloorCommissionAmountRef}
														/>
													</>
												)}
											</fieldset>

											<label className="workspace__input-title">
												Цена победителя:
											</label>
											<input
												className="input"
												type="number"
												disabled={isOnStageOrPassed(2) && !isAdmin()}
												name="winnerPrice"
												value={values.winnerPrice}
												onChange={(e) => inputChangeHandler(e)}
												onWheel={(e) => e.target.blur()}
											/>

											<label className="workspace__input-title">Победитель:</label>
											<select
												className="input search-area__select"
												disabled={isOnStageOrPassed(2) && !isAdmin()}
												name="winnerId"
												value={values.winnerId}
												onChange={(e) => inputChangeHandler(e)}
											>
												<option value="null">-</option>
												{competitors &&
													competitors.map(({ id, name }) => (
														<option key={id} value={id}>
															{" "}
															{name}{" "}
														</option>
													))}
											</select>
										</section>
										{/* THIRD COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											{isSoutUserArea() && (
												<>
													<h4 className="subtitle">Даты</h4>
													<label className="workspace__input-title">
														Дата заключения договора:
													</label>
													<input
														className="input"
														type="date"
														disabled={isOnStageOrPassed(4) && !isAdmin()}
														name="conclusionContractDate"
														value={values.conclusionContractDate}
														onChange={(e) => inputChangeHandler(e)}
													/>
													<label className="workspace__input-title">
														Плановая дата исполнения:
													</label>
													<input
														className="input"
														type="date"
														disabled={isOnStageOrPassed(4) && !isAdmin()}
														name="plannedExecutionDate"
														value={values.plannedExecutionDate}
														onChange={(e) => inputChangeHandler(e)}
													/>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Акт получен?:
														</label>
														<input
															id="ActRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="true"
															checked={values.actHasReached === "true"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="ActRecived" className="radio__label">
															Получен
														</label>

														<input
															id="ActDidNotRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="false"
															checked={values.actHasReached === "false"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="ActDidNotRecived"
															className="radio__label workspace__label"
															style={{ width: "200px" }}
														>
															Не получен
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-title">
															Тип оценки:
														</label>
														<input
															id="sout"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="WorkingConditionsSpecialAssesment"
															checked={
																values.assesmentTypeId ===
																"WorkingConditionsSpecialAssesment"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="sout"
															className={`radio__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															СОУТ
														</label>

														<input
															id="pc"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="ProductionControl"
															checked={
																values.assesmentTypeId === "ProductionControl"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="pc"
															className={`radio__label workspace__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															ПК
														</label>
													</fieldset>
													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Тендерный отдел:
													</label>
													<select
														className="input search-area__select"
														name="tenderUserId"
														value={values.tenderUserId}
														onChange={(e) => inputChangeHandler(e)}
													>
														<option value={"null"}> - </option>
														{tenderUsers &&
															tenderUsers
																.filter(
																	(item) => item.id !== TENDER_DEFAULT_USER_ID
																)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
													<label className="workspace__input-title">
														Число подразделений:
													</label>
													<input
														className="input"
														type="number"
														name="unitCount"
														disabled={
															values.assesmentTypeId === "ProductionControl" ||
															(isOnStageOrPassed(5) && !isAdmin())
														}
														value={values.unitCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>

													<label className="workspace__input-title">
														Число рабочих мест:
													</label>
													<input
														className="input"
														type="number"
														name="workspaceCount"
														value={values.workspaceCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
														disabled={isOnStageOrPassed(6) && !isAdmin()}
													/>
													<SelectRegions
														regions={values.regions}
														updateRegions={updateRegions}
													/>
												</>
											)}
											{isMedUserArea() && (
												<>
													<label className="workspace__input-title">
														Количество людей:
													</label>
													<input
														className="input"
														type="number"
														name="peopleForMedicalExamination"
														value={values.peopleForMedicalExamination}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>

													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Тендерный отдел:
													</label>
													<select
														className="input search-area__select"
														name="tenderUserId"
														value={values.tenderUserId}
														onChange={(e) => inputChangeHandler(e)}
													>
														<option value={"null"}> - </option>
														{tenderUsers &&
															tenderUsers
																.filter(
																	(item) => item.id !== TENDER_DEFAULT_USER_ID
																)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
												</>
											)}
											<button
												className="button staff__create-staff-button"
												onClick={async (e) => {
													await patchProject(e, values, projectId);
													navigate(-1);
												}}
											>
												Cохранить
											</button>
										</section>
									</>
								)}
								{(isLabHead() || isLabHeadDeputy()) && (
									<>
										{/* FIRST COLUMN */}
										<section className="form__column">
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Заказчик</h4>
												<label className="workspace__input-title">
													Наименование заказчика:
												</label>
												<input
													className="input"
													type="text"
													disabled={isOnStageOrPassed(0)}
													name="customerName"
													value={values.customerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Дополнительное наименование:
												</label>
												<input
													className="input"
													type="text"
													name="additionalCustomerName"
													value={values.additionalCustomerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													ИНН заказчика:
												</label>
												<input
													className="input"
													disabled={isOnStageOrPassed(0)}
													type="text"
													maxLength="12"
													name="inn"
													value={values.inn}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Контактные данные:
												</label>
												<textarea
													className="input"
													name="contactData"
													value={values.contactData}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Контракт</h4>
												<label className="workspace__input-title">
													Номер контракта:
												</label>
												<input
													className="input"
													type="text"
													name="contractNumber"
													value={values.contractNumber}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(4) && !isAdmin()}
												/>
												<label className="workspace__input-title">Номер акта:
												</label>
												<input
													className="input"
													type="text"
													name="actNumber"
													value={values.actNumber}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(13) && !isAdmin()}
												/>
												<label className="workspace__input-title">
													Цена контракта:
												</label>
												<input
													className="input"
													type="number"
													name="price"
													value={values.price}
													onChange={(e) => inputChangeHandler(e)}
													onWheel={(e) => e.target.blur()}
												/>
												<label className="workspace__input-title">
													Сумма по акту:
												</label>
												<input
													className="input"
													type="number"
													name="actAmount"
													value={values.actAmount}
													onChange={(e) => inputChangeHandler(e)}
													onWheel={(e) => e.target.blur()}
													disabled={isOnStageOrPassed(13) && !isAdmin()}
												/>
												<label className="workspace__input-title">
													Ссылка от агрегатора:
												</label>
												<input
													className="input"
													type="text"
													name="aggregatorLink"
													value={values.aggregatorLink}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Идентификационный номер:
												</label>
												<input
													className="input"
													type="text"
													name="alternateId"
													value={values.alternateId}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Комментарий к исполнению:
												</label>
												<textarea
													className="input"
													name="executionDescription"
													value={values.executionDescription}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Замеры:
													<select
														className="select"
														name="measurementStatusId"
														value={values.measurementStatusId}
														onChange={(e) => inputChangeHandler(e)}
													>
														<option value="NotAssigned">Не назначены</option>
														<option value="NotRequired">Не требуются</option>
														<option value="Assigned">Назначены</option>
														<option value="Complited">Проведены</option>
													</select>
												</label>
											</fieldset>
										</section>
										{/* SECOND COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											<h4 className="subtitle">Даты</h4>
											<label className="workspace__input-title">
												Дата заключения договора:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="conclusionContractDate"
												value={values.conclusionContractDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Плановая дата исполнения:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="plannedExecutionDate"
												value={values.plannedExecutionDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Дата получения информации:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(6) && !isAdmin()}
													name="informationCollectionDate"
													value={values.informationCollectionDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата получения полной информации:
												<input
													className="input"
													type="date"
													name="informationCollectedAt"
													value={values.informationCollectedAt}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>

											<label className="workspace__input-title">
												Дата акта:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(13) && !isAdmin()}
													name="actDate"
													value={values.actDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">Дата оплаты:</label>
											<input
												className="input"
												type="date"
												name="paidDate"
												value={values.paidDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Дата приема в работу:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(8) && !isAdmin()}
													name="acceptedToWorkDate"
													value={values.acceptedToWorkDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки отчета:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(10) && !isAdmin()}
													name="sentDate"
													value={values.sentDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата выгрузки:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(12) && !isAdmin()}
													name="unloadingDate"
													value={values.unloadingDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												{" "}
												Дата замеров:
												<input
													className="input"
													type="date"
													name="measurementsDate"
													value={values.measurementsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата назначения исполнителя:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(7) && !isAdmin()}
													name="performerAppointedDate"
													value={values.performerAppointedDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки на согласование:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(9) && !isAdmin()}
													name="approvalsDate"
													value={values.approvalsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата подписания титульного листа:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(11) && !isAdmin()}
													name="titleSheetSignDate"
													value={values.titleSheetSignDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
										</section>
										{/* THIRD COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											{isSoutUserArea() && (
												<>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-title">
															Тип оценки:
														</label>
														<input
															id="sout"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="WorkingConditionsSpecialAssesment"
															checked={
																values.assesmentTypeId ===
																"WorkingConditionsSpecialAssesment"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="sout"
															className={`radio__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															СОУТ
														</label>

														<input
															id="pc"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="ProductionControl"
															checked={
																values.assesmentTypeId === "ProductionControl"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="pc"
															className={`radio__label workspace__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															ПК
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Рабочая тетрадь:
														</label>
														<input
															id="workbook-true"
															className="radio"
															type="radio"
															name="haveWorkbook"
															value="true"
															checked={values.haveWorkbook === "true"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="workbook-true" className="radio__label">
															Есть
														</label>

														<input
															id="workbook-false"
															className="radio"
															type="radio"
															name="haveWorkbook"
															value="false"
															checked={values.haveWorkbook === "false"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="workbook-false"
															className="radio__label workspace__label"
														>
															Нет
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Программа выполнения:
														</label>
														<input
															id="attr"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="Attestation_5_1"
															checked={
																values.executionProgramId === "Attestation_5_1"
															}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="attr"
															className="radio__label radio__label--attestation"
														>
															Аттестация
														</label>

														<input
															id="labor"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="LaborExpert_3_0"
															checked={
																values.executionProgramId === "LaborExpert_3_0"
															}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="labor" className="radio__label">
															ТрудЭксперт
														</label>

														<input
															id="no-programm"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="None"
															checked={values.executionProgramId === "None"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="no-programm"
															className="radio__label workspace__label"
														>
															Нет
														</label>
													</fieldset>
													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Лаборатория:
													</label>
													<select
														className="input search-area__select"
														name="laboratoryUserId"
														value={values.laboratoryUserId}
														onChange={(e) => inputChangeHandler(e)}
														disabled={isOnStageOrPassed(7) && !isAdmin()}
													>
														<option value={LAB_DEFAULT_USER_ID}>
															Неизвестный сотрудник
														</option>
														{laboratoryUsers &&
															laboratoryUsers
																.filter((item) => item.id !== LAB_DEFAULT_USER_ID)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
													<label className="workspace__input-title">
														Замерщик:
													</label>
													<select
														className="input search-area__select"
														name="workerId"
														value={values.workerId}
														onChange={(e) => inputChangeHandler(e)}
														disabled={isOnStageOrPassed(6) && !isAdmin()}
													>
														<option value="null">-</option>
														{workers &&
															workers.map(({ id, name }) => (
																<option key={id} value={id}>
																	{" "}
																	{name}{" "}
																</option>
															))}
													</select>
													<label className="workspace__input-title">
														Число подразделений:
													</label>
													<input
														className="input"
														type="number"
														name="unitCount"
														disabled={
															values.assesmentTypeId === "ProductionControl" ||
															(isOnStageOrPassed(5) && !isAdmin())
														}
														value={values.unitCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>

													<label className="workspace__input-title">
														Число рабочих мест:
													</label>
													<input
														className="input"
														type="number"
														name="workspaceCount"
														value={values.workspaceCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
														disabled={isOnStageOrPassed(6) && !isAdmin()}
													/>
													<SelectRegions
														regions={values.regions}
														updateRegions={updateRegions}
													/>
												</>
											)}
											{isMedUserArea() && (
												<>
													<label className="workspace__input-title">
														Количество людей:
													</label>
													<input
														className="input"
														type="number"
														name="peopleForMedicalExamination"
														value={values.peopleForMedicalExamination}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>

													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Тендерный отдел:
													</label>
													<select
														className="input search-area__select"
														name="tenderUserId"
														value={values.tenderUserId}
														onChange={(e) => inputChangeHandler(e)}
													>
														<option value={"null"}> - </option>
														{tenderUsers &&
															tenderUsers
																.filter(
																	(item) => item.id !== TENDER_DEFAULT_USER_ID
																)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
												</>
											)}
											<button
												className="button staff__create-staff-button"
												onClick={async (e) => {
													await patchProject(e, values, projectId);
													navigate(-1);
												}}
											>
												Cохранить
											</button>
										</section>
									</>
								)}
								{isLabEmployee() && (
									<>
										{/* FIRST COLUMN */}
										<section className="form__column">
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Заказчик</h4>
												<label className="workspace__input-title">
													Наименование заказчика:
												</label>
												<input
													className="input"
													type="text"
													disabled={isOnStageOrPassed(0)}
													name="customerName"
													value={values.customerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Дополнительное наименование:
												</label>
												<input
													className="input"
													type="text"
													name="additionalCustomerName"
													value={values.additionalCustomerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													ИНН заказчика:
												</label>
												<input
													className="input"
													disabled={isOnStageOrPassed(0)}
													type="text"
													maxLength="12"
													name="inn"
													value={values.inn}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Контактные данные:
												</label>
												<textarea
													className="input"
													name="contactData"
													value={values.contactData}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Контракт</h4>
												<label className="workspace__input-title">
													Номер контракта:
												</label>
												<input
													className="input"
													type="text"
													name="contractNumber"
													value={values.contractNumber}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(4) && !isAdmin()}
												/>
												<label className="workspace__input-title">
													Идентификационный номер:
												</label>
												<input
													className="input"
													type="text"
													name="alternateId"
													value={values.alternateId}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Комментарий к исполнению:
												</label>
												<textarea
													className="input"
													name="executionDescription"
													value={values.executionDescription}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</fieldset>
										</section>
										{/* SECOND COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											<h4 className="subtitle">Даты</h4>
											<label className="workspace__input-title">
												Дата заключения договора:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="conclusionContractDate"
												value={values.conclusionContractDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Плановая дата исполнения:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="plannedExecutionDate"
												value={values.plannedExecutionDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Дата получения информации:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(6) && !isAdmin()}
													name="informationCollectionDate"
													value={values.informationCollectionDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата получения полной информации:
												<input
													className="input"
													type="date"
													name="informationCollectedAt"
													value={values.informationCollectedAt}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата приема в работу:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(8) && !isAdmin()}
													name="acceptedToWorkDate"
													value={values.acceptedToWorkDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки отчета:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(10) && !isAdmin()}
													name="sentDate"
													value={values.sentDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата выгрузки:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(12) && !isAdmin()}
													name="unloadingDate"
													value={values.unloadingDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												{" "}
												Дата замеров:
												<input
													className="input"
													type="date"
													name="measurementsDate"
													value={values.measurementsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата назначения исполнителя:
												<input
													className="input"
													type="date"
													name="performerAppointedDate"
													value={values.performerAppointedDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки на согласование:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(9) && !isAdmin()}
													name="approvalsDate"
													value={values.approvalsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата подписания титульного листа:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(11) && !isAdmin()}
													name="titleSheetSignDate"
													value={values.titleSheetSignDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
										</section>
										{/* THIRD COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											{isSoutUserArea() && (
												<>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Акт получен?:
														</label>
														<input
															id="ActRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="true"
															checked={values.actHasReached === "true"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="ActRecived" className="radio__label">
															Получен
														</label>

														<input
															id="ActDidNotRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="false"
															checked={values.actHasReached === "false"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="ActDidNotRecived"
															className="radio__label workspace__label"
															style={{ width: "200px" }}
														>
															Не получен
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-title">
															Тип оценки:
														</label>
														<input
															id="sout"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="WorkingConditionsSpecialAssesment"
															checked={
																values.assesmentTypeId ===
																"WorkingConditionsSpecialAssesment"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="sout"
															className={`radio__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															СОУТ
														</label>

														<input
															id="pc"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="ProductionControl"
															checked={
																values.assesmentTypeId === "ProductionControl"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="pc"
															className={`radio__label workspace__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															ПК
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Рабочая тетрадь:
														</label>
														<input
															id="workbook-true"
															className="radio"
															type="radio"
															name="haveWorkbook"
															value="true"
															checked={values.haveWorkbook === "true"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="workbook-true" className="radio__label">
															Есть
														</label>

														<input
															id="workbook-false"
															className="radio"
															type="radio"
															name="haveWorkbook"
															value="false"
															checked={values.haveWorkbook === "false"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="workbook-false"
															className="radio__label workspace__label"
														>
															Нет
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Программа выполнения:
														</label>
														<input
															id="attr"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="Attestation_5_1"
															checked={
																values.executionProgramId === "Attestation_5_1"
															}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="attr"
															className="radio__label radio__label--attestation"
														>
															Аттестация
														</label>

														<input
															id="labor"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="LaborExpert_3_0"
															checked={
																values.executionProgramId === "LaborExpert_3_0"
															}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="labor" className="radio__label">
															ТрудЭксперт
														</label>

														<input
															id="no-programm"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="None"
															checked={values.executionProgramId === "None"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="no-programm"
															className="radio__label workspace__label"
														>
															Нет
														</label>
													</fieldset>
													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Лаборатория:
													</label>
													<select
														className="input search-area__select"
														name="laboratoryUserId"
														value={values.laboratoryUserId}
														onChange={(e) => inputChangeHandler(e)}
														disabled={isOnStageOrPassed(7) && !isAdmin()}
													>
														<option value={LAB_DEFAULT_USER_ID}>
															Неизвестный сотрудник
														</option>
														{laboratoryUsers &&
															laboratoryUsers
																.filter((item) => item.id !== LAB_DEFAULT_USER_ID)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
													<label className="workspace__input-title">
														Замерщик:
													</label>
													<select
														className="input search-area__select"
														name="workerId"
														value={values.workerId}
														onChange={(e) => inputChangeHandler(e)}
														disabled={isOnStageOrPassed(6) && !isAdmin()}
													>
														<option value="null">-</option>
														{workers &&
															workers.map(({ id, name }) => (
																<option key={id} value={id}>
																	{" "}
																	{name}{" "}
																</option>
															))}
													</select>
													<label className="workspace__input-title">
														Число подразделений:
													</label>
													<input
														className="input"
														type="number"
														name="unitCount"
														disabled={
															values.assesmentTypeId === "ProductionControl" ||
															(isOnStageOrPassed(5) && !isAdmin())
														}
														value={values.unitCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>
													<label className="workspace__input-title">
														Число рабочих мест:
													</label>
													<input
														className="input"
														type="number"
														name="workspaceCount"
														value={values.workspaceCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
														disabled={isOnStageOrPassed(6) && !isAdmin()}
													/>
													<SelectRegions
														regions={values.regions}
														updateRegions={updateRegions}
													/>
												</>
											)}
											{isMedUserArea() && (
												<>
													<label className="workspace__input-title">
														Количество людей:
													</label>
													<input
														className="input"
														type="number"
														name="peopleForMedicalExamination"
														value={values.peopleForMedicalExamination}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>

													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Тендерный отдел:
													</label>
													<select
														className="input search-area__select"
														name="tenderUserId"
														value={values.tenderUserId}
														onChange={(e) => inputChangeHandler(e)}
													>
														<option value={"null"}> - </option>
														{tenderUsers &&
															tenderUsers
																.filter(
																	(item) => item.id !== TENDER_DEFAULT_USER_ID
																)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
												</>
											)}
											<button
												className="button staff__create-staff-button"
												onClick={async (e) => {
													await patchProject(e, values, projectId);
													navigate(-1);
												}}
											>
												Cохранить
											</button>
										</section>
									</>
								)}
								{isLabExpert() && (
									<>
										{/* FIRST COLUMN */}
										<section className="form__column">
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Заказчик</h4>
												<label className="workspace__input-title">
													Наименование заказчика:
												</label>
												<input
													className="input"
													type="text"
													disabled={isOnStageOrPassed(0)}
													name="customerName"
													value={values.customerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Дополнительное наименование:
												</label>
												<input
													className="input"
													type="text"
													name="additionalCustomerName"
													value={values.additionalCustomerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													ИНН заказчика:
												</label>
												<input
													className="input"
													disabled={isOnStageOrPassed(0)}
													type="text"
													maxLength="12"
													name="inn"
													value={values.inn}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Контактные данные:
												</label>
												<textarea
													className="input"
													name="contactData"
													value={values.contactData}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Контракт</h4>
												<label className="workspace__input-title">
													Номер контракта:
												</label>
												<input
													className="input"
													type="text"
													name="contractNumber"
													value={values.contractNumber}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(4) && !isAdmin()}
												/>
												<label className="workspace__input-title">
													Идентификационный номер:
												</label>
												<input
													className="input"
													type="text"
													name="alternateId"
													value={values.alternateId}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													Комментарий к исполнению:
												</label>
												<textarea
													className="input"
													name="executionDescription"
													value={values.executionDescription}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</fieldset>
										</section>
										{/* SECOND COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											<h4 className="subtitle">Даты</h4>
											<label className="workspace__input-title">
												Дата заключения договора:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="conclusionContractDate"
												value={values.conclusionContractDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Плановая дата исполнения:
											</label>
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="plannedExecutionDate"
												value={values.plannedExecutionDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
											<label className="workspace__input-title">
												Дата получения информации:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(6) && !isAdmin()}
													name="informationCollectionDate"
													value={values.informationCollectionDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата получения полной информации:
												<input
													className="input"
													type="date"
													name="informationCollectedAt"
													value={values.informationCollectedAt}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата приема в работу:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(8) && !isAdmin()}
													name="acceptedToWorkDate"
													value={values.acceptedToWorkDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки отчета:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(10) && !isAdmin()}
													name="sentDate"
													value={values.sentDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата выгрузки:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(12) && !isAdmin()}
													name="unloadingDate"
													value={values.unloadingDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата замеров:
												<input
													className="input"
													type="date"
													name="measurementsDate"
													value={values.measurementsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата назначения исполнителя:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(7) && !isAdmin()}
													name="performerAppointedDate"
													value={values.performerAppointedDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата отправки на согласование:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(9) && !isAdmin()}
													name="approvalsDate"
													value={values.approvalsDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
											<label className="workspace__input-title">
												Дата подписания титульного листа:
												<input
													className="input"
													type="date"
													disabled={isOnStageOrPassed(11) && !isAdmin()}
													name="titleSheetSignDate"
													value={values.titleSheetSignDate}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</label>
										</section>
										{/* THIRD COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											{isSoutUserArea() && (
												<>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Акт получен?:
														</label>
														<input
															id="ActRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="true"
															checked={values.actHasReached === "true"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="ActRecived" className="radio__label">
															Получен
														</label>

														<input
															id="ActDidNotRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="false"
															checked={values.actHasReached === "false"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="ActDidNotRecived"
															className="radio__label workspace__label"
															style={{ width: "200px" }}
														>
															Не получен
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-title">
															Тип оценки:
														</label>
														<input
															id="sout"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="WorkingConditionsSpecialAssesment"
															checked={
																values.assesmentTypeId ===
																"WorkingConditionsSpecialAssesment"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="sout"
															className={`radio__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															СОУТ
														</label>

														<input
															id="pc"
															className="radio"
															type="radio"
															name="assesmentTypeId"
															value="ProductionControl"
															checked={
																values.assesmentTypeId === "ProductionControl"
															}
															onChange={(e) => inputChangeHandler(e)}
															disabled={isOnStageOrPassed(1) && !isAdmin()}
														/>
														<label
															htmlFor="pc"
															className={`radio__label workspace__label ${isOnStageOrPassed(1) &&
																!isAdmin() &&
																"radio__label--grey"
																}`}
														>
															ПК
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Рабочая тетрадь:
														</label>
														<input
															id="workbook-true"
															className="radio"
															type="radio"
															name="haveWorkbook"
															value="true"
															checked={values.haveWorkbook === "true"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="workbook-true" className="radio__label">
															Есть
														</label>

														<input
															id="workbook-false"
															className="radio"
															type="radio"
															name="haveWorkbook"
															value="false"
															checked={values.haveWorkbook === "false"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="workbook-false"
															className="radio__label workspace__label"
														>
															Нет
														</label>
													</fieldset>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Программа выполнения:
														</label>
														<input
															id="attr"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="Attestation_5_1"
															checked={
																values.executionProgramId === "Attestation_5_1"
															}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="attr"
															className="radio__label radio__label--attestation"
														>
															Аттестация
														</label>

														<input
															id="labor"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="LaborExpert_3_0"
															checked={
																values.executionProgramId === "LaborExpert_3_0"
															}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="labor" className="radio__label">
															ТрудЭксперт
														</label>

														<input
															id="no-programm"
															className="radio"
															type="radio"
															name="executionProgramId"
															value="None"
															checked={values.executionProgramId === "None"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="no-programm"
															className="radio__label workspace__label"
														>
															Нет
														</label>
													</fieldset>
													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Лаборатория:
													</label>
													<select
														className="input search-area__select"
														name="laboratoryUserId"
														value={values.laboratoryUserId}
														onChange={(e) => inputChangeHandler(e)}
														disabled={isOnStageOrPassed(7) && !isAdmin()}
													>
														<option value={LAB_DEFAULT_USER_ID}>
															Неизвестный сотрудник
														</option>
														{laboratoryUsers &&
															laboratoryUsers
																.filter((item) => item.id !== LAB_DEFAULT_USER_ID)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
													<label className="workspace__input-title">
														Замерщик:
													</label>
													<select
														className="input search-area__select"
														name="workerId"
														value={values.workerId}
														onChange={(e) => inputChangeHandler(e)}
														disabled={isOnStageOrPassed(6) && !isAdmin()}
													>
														<option value="null">-</option>
														{workers &&
															workers.map(({ id, name }) => (
																<option key={id} value={id}>
																	{" "}
																	{name}{" "}
																</option>
															))}
													</select>
													<label className="workspace__input-title">
														Число подразделений:
													</label>
													<input
														className="input"
														type="number"
														name="unitCount"
														disabled={
															values.assesmentTypeId === "ProductionControl" ||
															(isOnStageOrPassed(5) && !isAdmin())
														}
														value={values.unitCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>

													<label className="workspace__input-title">
														Число рабочих мест:
													</label>
													<input
														className="input"
														type="number"
														name="workspaceCount"
														value={values.workspaceCount}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													// disabled={isOnStageOrPassed(5) && !isAdmin()}
													/>
													<SelectRegions
														regions={values.regions}
														updateRegions={updateRegions}
													/>
												</>
											)}
											{isMedUserArea() && (
												<>
													<label className="workspace__input-title">
														Количество людей:
													</label>
													<input
														className="input"
														type="number"
														name="peopleForMedicalExamination"
														value={values.peopleForMedicalExamination}
														onChange={(e) => inputChangeHandler(e)}
														onWheel={(e) => e.target.blur()}
													/>

													<h4 className="subtitle">Ответственные</h4>
													<label className="workspace__input-title">
														Тендерный отдел:
													</label>
													<select
														className="input search-area__select"
														name="tenderUserId"
														value={values.tenderUserId}
														onChange={(e) => inputChangeHandler(e)}
													>
														<option value={"null"}> - </option>
														{tenderUsers &&
															tenderUsers
																.filter(
																	(item) => item.id !== TENDER_DEFAULT_USER_ID
																)
																.map(({ id, name }) => (
																	<option key={id} value={id}>
																		{" "}
																		{name}{" "}
																	</option>
																))}
													</select>
												</>
											)}
											<button
												className="button staff__create-staff-button"
												onClick={async (e) => {
													await patchProject(e, values, projectId);
													navigate(-1);
												}}
											>
												Cохранить
											</button>
										</section>
									</>
								)}
								{isAccountantEmployee() && (
									<>
										{/* FIRST COLUMN */}
										<section className="form__column">
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Заказчик</h4>
												<label className="workspace__input-title">
													Наименование заказчика:
												</label>
												<input
													className="input"
													type="text"
													disabled={isOnStageOrPassed(0)}
													name="customerName"
													value={values.customerName}
													onChange={(e) => inputChangeHandler(e)}
												/>
												<label className="workspace__input-title">
													ИНН заказчика:
												</label>
												<input
													className="input"
													disabled={isOnStageOrPassed(0)}
													type="text"
													maxLength="12"
													name="inn"
													value={values.inn}
													onChange={(e) => inputChangeHandler(e)}
												/>
											</fieldset>
											<fieldset className="workspace__ensure-wrapper">
												<h4 className="subtitle">Контракт</h4>
												<label className="workspace__input-title">
													Номер контракта:
												</label>
												<input
													className="input"
													type="text"
													name="contractNumber"
													value={values.contractNumber}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(4) && !isAdmin()}
												/>
												<label className="workspace__input-title">Номер акта:
												</label>
												<input
													className="input"
													type="text"
													name="actNumber"
													value={values.actNumber}
													onChange={(e) => inputChangeHandler(e)}
													disabled={isOnStageOrPassed(13) && !isAdmin()}
												/>
											</fieldset>
										</section>
										{/* SECOND COLUMN */}
										<section className="form__column workspace__ensure-wrapper">
											{/* //? ONLY FOR EDIT TENDER PROJECT */}
											{isSoutUserArea() && (
												<>
													<fieldset className="workspace__ensure-wrapper">
														<label className="workspace__input-ensure">
															Акт получен?
														</label>
														<input
															id="ActRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="true"
															checked={values.actHasReached === "true"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label htmlFor="ActRecived" className="radio__label">
															Получен
														</label>

														<input
															id="ActDidNotRecived"
															className="radio"
															type="radio"
															name="actHasReached"
															value="false"
															checked={values.actHasReached === "false"}
															onChange={(e) => inputChangeHandler(e)}
														/>
														<label
															htmlFor="ActDidNotRecived"
															className="radio__label workspace__label"
															style={{ width: "200px" }}
														>
															Не получен
														</label>
													</fieldset>
													{(values.contractSecuringMethodId !== 'None' || values.offerSecuringMethodId !== 'None') && (
														<fieldset className="workspace__ensure-wrapper">
															<label className="workspace__input-ensure">
																Обеспечение вернулось?
															</label>
															<input
																id="SecurityWasReturned"
																className="radio"
																type="radio"
																name="securityReturned"
																value="true"
																checked={values.securityReturned === "true"}
																onChange={(e) => inputChangeHandler(e)}
															/>
															<label
																htmlFor="SecurityWasReturned"
																className="radio__label"
															>
																Да
															</label>

															<input
																id="SecurityDidNotReturned"
																className="radio"
																type="radio"
																name="securityReturned"
																value="false"
																checked={values.securityReturned === "false"}
																onChange={(e) => inputChangeHandler(e)}
															/>
															<label
																htmlFor="SecurityDidNotReturned"
																className="radio__label workspace__label"
																style={{ width: "200px" }}
															>
																Нет
															</label>
														</fieldset>
													)}
												</>
											)}
											<button
												className="button staff__create-staff-button"
												onClick={async (e) => {
													await patchProject(e, values, projectId);
													navigate(-1);
												}}
											>
												Cохранить
											</button>
										</section>
									</>
								)}
							</>
						}
					</form>
				</section>
			</div>
		</main>
	);
};
export default EditProjectTender;
