import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDeafultProjectsPageUrl } from '../utils/BaseUrl';
import AuthContext from '../context/AuthContext';
import Spinner from '../components/Spinner';
import switchColor from '../utils/switchColor';


const Home = () => {
  const { user, setUserArea } = useContext(AuthContext)
  const navigate = useNavigate();

  const defaultUrlHandler = (e) => {
    setUserArea(e.target.dataset.userarea)
    localStorage.setItem('__user-area', e.target.dataset.userarea)
    
    switchColor();

    const defaultPageUrl = getDeafultProjectsPageUrl(e.target.dataset.userarea, user.Department);
    localStorage.setItem('__default-url', defaultPageUrl)
    
    navigate(defaultPageUrl)
  }

  return (
    <>
      <main className="main-page">
        <div className="container main-page__container">
          <h3 className="title main-page__title">Выберите тип проектов</h3>
          <div className="modal__buttons-wrapper">
            <button className='button button--red' data-userarea="sout" onClick={e => defaultUrlHandler(e)}>ЛАБ</button>
            <button className='button button--blue' data-userarea="med" onClick={e => defaultUrlHandler(e)}>МЕД</button>
          </div>
        </div>
      </main>
      {
        false
          ? <div className="loading-wrapper">
            <Spinner className={'spinner loading-wrapper__spinner'} />
          </div>
          : null
      }
    </>
  )
};

export default Home; 
