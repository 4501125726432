import {createContext, useEffect, useState} from "react";
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {isLabHeadDeputy} from "../utils/defineUser";
import {AccessRights} from "settings/AccessRights";
import {StartPageData} from "settings/StartPageData";
import {api} from "settings/Api";
import {fetchAccount} from "../utils/fetchAccount";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    const [account, setAccount] = useState(null);
    const [role, setRole] = useState(localStorage.getItem("role") || "admin");

    if (role === "" && localStorage.getItem("authTokens")) {
        localStorage.removeItem("authTokens");
    }

    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? jwt_decode(
                JSON.parse(localStorage.getItem("authTokens")).data.accessToken
            )
            : null
    );
    const [userArea, setUserArea] = useState("sout");
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState();
    const [username, setUsername] = useState(
        localStorage.getItem("username") || ""
    );

    const accessRights = AccessRights;
    const startPageData = StartPageData;

    const navigate = useNavigate(null);

    const loginUser = async (e) => {
        e.preventDefault();
        setLoading(true);

        const response = await api("/api/account/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                login: e.target.username.value.trim(),
                password: e.target.password.value.trim(),
            }),
        });
        const data = await response.json();
        if (response.status === 200) {
            setAuthTokens(data);

            localStorage.setItem("authTokens", JSON.stringify(data));

            const userData = jwt_decode(data.data.accessToken);
            console.log("login", userData);
            setUsername(
                userData?.Department === "None"
                    ? "Admin"
                    : data?.data?.credentials?.fullName
            );
            localStorage.setItem(
                "username",
                userData?.Department === "None"
                    ? "Admin"
                    : data?.data?.credentials?.fullName
            );

            setUser(userData);
            const userType = defineUser(userData);
            redirectUser(userType);
            setLoading(false);
        } else if (response.status === 400) {
            updateToken();
        } else if (response.status === 500) {
            alert(`Error: ${data.Error}`);
            setLoading(false);
        } else {
            alert(`Error: ${data.Error}`);
            setLoading(false);
        }
    };

    const logoutUser = async () => {
        setAuthTokens(null);
        setUser(null);

        const token = JSON.parse(localStorage.getItem("authTokens")).data
            .accessToken;
        localStorage.removeItem("authTokens");
        navigate("/Login");

        let response = await api("/api/account/logout", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        let data = await response;
        localStorage.clear();

        let root = document.documentElement;
        root.style.setProperty("--green-light", "#05be90");
    };

    const updateToken = async () => {
        const token = JSON.parse(localStorage.getItem("authTokens")).data
            .accessToken;

        let response = await api("/api/account/RefreshToken", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            // body:JSON.stringify({'refresh':authTokens?.refresh})
        });
        let data = await response.json();
        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwt_decode(data.data.accessToken));
            localStorage.setItem("authTokens", JSON.stringify(data));
            navigate("/");
        } else if (response.status === 500) {
            if (data.error === "Refresh token не найден") {
                logoutUser();
            }
            return;
        } else {
            logoutUser();
        }
    };

    const redirectUser = (type) => {
        let root = document.documentElement;

        if (localStorage.getItem("__isAdmin")) {
            navigate("/AdminStartPage");
            root.style.setProperty("--green-light", "#05be90");
            return;
        }

        navigate("/AdminStartPage");
    };

    const defineUser = ({Department, Position, nameid}) => {
        //*Default entity
        localStorage.setItem("__entity-type", "tender");

        switch (Department) {
            case "TenderDepartment": {
                localStorage.setItem("__user-department", "tender");
                if (Position === "Employee") {
                    setRole("tender_emp");
                    localStorage.setItem("role", "tender_emp");
                    localStorage.setItem("__user-position", "Employee");
                } else if (Position === "Expert") {
                    localStorage.setItem("__user-position", "Expert");
                } else if (Position === "DepartmentHead") {
                    setRole("tender_chief");
                    localStorage.setItem("role", "tender_chief");
                    localStorage.setItem("__user-position", "DepartmentHead");
                } else if (Position === "DepartmentHead2") {
                    localStorage.setItem("__user-position", "DepartmentHead2");
                }
                break;
            }
            case "CommercialDepartment": {
                localStorage.setItem("__user-department", "commercial");
                if (Position === "Employee") {
                    setRole("comercial_emp");
                    localStorage.setItem("role", "comercial_emp");
                    localStorage.setItem("__user-position", "Employee");
                } else if (Position === "Expert") {
                    localStorage.setItem("__user-position", "Expert");
                } else if (Position === "DepartmentHead") {
                    setRole("comercial_chief");
                    localStorage.setItem("role", "comercial_chief");
                    localStorage.setItem("__user-position", "DepartmentHead");
                } else if (Position === "DepartmentHead2") {
                    localStorage.setItem("__user-position", "DepartmentHead2");
                }
                break;
            }
            case "AccountingDepartment": {
                localStorage.setItem("__user-department", "accountant");
                if (Position === "Employee") {
                    localStorage.setItem("__user-position", "Employee");
                } else if (Position === "Expert") {
                    localStorage.setItem("__user-position", "Expert");
                } else if (Position === "DepartmentHead") {
                    localStorage.setItem("__user-position", "DepartmentHead");
                } else if (Position === "DepartmentHead2") {
                    localStorage.setItem("__user-position", "DepartmentHead2");
                }
                break;
            }
            case "Laboratory": {
                localStorage.setItem("__user-department", "lab");
                if (Position === "Employee") {
                    setRole("lab_emp");
                    localStorage.setItem("role", "lab_emp");
                    localStorage.setItem("__user-position", "Employee");
                } else if (Position === "Expert") {
                    setRole("lab_expert");
                    localStorage.setItem("role", "lab_expert");
                    localStorage.setItem("__user-position", "Expert");
                } else if (Position === "DepartmentHead") {
                    setRole("lab_chief");
                    localStorage.setItem("role", "lab_chief");
                    localStorage.setItem("__user-position", "DepartmentHead");
                } else if (Position === "Assistant") {
                    setRole("lab_assist");
                    localStorage.setItem("role", "lab_assist");
                    localStorage.setItem("__user-position", "Assistant");
                } else if (Position === "Manager") {
                    setRole("lab_manager");
                    localStorage.setItem("role", "lab_manager");
                    localStorage.setItem("__user-position", "Manager");
                }
                break;
            }
            default: {
                setRole("admin");
                localStorage.setItem("role", "admin");
                break;
            }
        }

        localStorage.setItem("__user-id", nameid);

        if (isLabHeadDeputy()) {
            setUserArea("sout");
            localStorage.setItem("__user-area", "sout");
            return "lab-head-deputy";
        } else if (Department === "Laboratory" && Position !== "DepartmentHead") {
            setUserArea("sout");
            localStorage.setItem("__user-area", "sout");
            return "lab-assist";
        } else if (Department === "Laboratory" && Position === "DepartmentHead") {
            setUserArea("sout");
            localStorage.setItem("__user-area", "sout");
            return "lab-chief";
        } else {
            return "others";
        }
    };

    const contextData = {
        user,
        startPageData,
        accessRights,
        setUserArea,
        userArea,
        role,
        username,
        setUsername,
        setRole,
        employees,
        authTokens,
        updateToken,
        loginUser,
        logoutUser,
        loading,
        account
    };

    useEffect(() => {
        updateToken();
    }, []);

    return (
        <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
    );
};
export default AuthContext;
