import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";
import Login from "./pages/Login";
import Auth from './pages/Auth';

//Admin
import ListOfUsers from "./pages/sout/admin/ListOfUsers";
import AddEmployee from "./pages/sout/admin/AddEmployee";
import PrivateRoute from "./utils/PrivateRoute";
import Edit from "./pages/sout/admin/EditEmployee";
import ListOfProjects from "./pages/sout/admin/ListOfProjects";
import ListOfSites from "./pages/sout/admin/ListOfSites";
import AddSite from "./pages/sout/admin/AddSite";
import ListOfCompetitors from "./pages/sout/admin/ListOfCompetitors";
import AddCompetitor from "./pages/sout/admin/AddCompetitor";

//Add/Edit project for Tender/Commercial
import AddProjectCom from "./pages/sout/commercial/common/AddProjectCom";
import AddProjectTender from "./pages/sout/tender/common/AddProjectTender";
import EditProjectCom from "./pages/sout/commercial/common/EditProjectCom";
import EditProjectTender from "./pages/sout/tender/common/EditProjectTender";

import { getDeafultProjectsPageUrl } from "./utils/BaseUrl";
import Error404 from "./pages/Error404";

//New
import Home from "./pages/Home";
import Statistics from "./pages/Statistics";
import Expenses from "./components/common/Expenses";
import Workload from "./pages/Workload";
import IncomeByPaid from "./pages/IncomeByPaid";
import Workspace from "./components/Workspace";
import AgentReports from "./pages/AgentReports";
import AdminStartPage from './pages/AdminStartPage';
import { isAdmin } from './utils/defineUser';
import AddAgent from './pages/sout/admin/AddAgents';
import ListOfAgents from './pages/sout/admin/ListOfAgents';
import EditAgent from './pages/sout/admin/EditAgent';
import AddTenderPage from './pages/sout/tender/common/NewTenderPage';
import NewTenderPage from './pages/sout/tender/common/NewTenderPage';
import ChangeContracts from 'entities/contracts/pages/ChangeContracts/ChangeContracts';
import NewProjectPage from 'pages/sout/tender/common/NewProjectPage';
import ChangeLocations from 'entities/locations/pages/ChangeLocations/ChangeLocations';
import ChangePage from 'pages/ChangePage';
import TenderStatistics from "pages/TenderStatistics";
import SummaryOfProject from "pages/SummaryOfProject";
import SummaryOfLocation from "pages/SummaryOfLocation";
import EditSite from "pages/sout/admin/EditSite";
import EditCompetitor from "pages/sout/admin/EditCompetitor";
import Manual from "pages/Manual";
import ManualInfoPage from "pages/ManualInfoPage";


function AppRoutes() {
  let { user } = useContext(AuthContext);

  const defaultUrl = localStorage.getItem("__default-url");


  return (
    <Routes>
      <Route
        path=""
        element={(user && isAdmin) ? <Navigate to={'/AdminStartPage'} /> : <Login />}
      />

      <Route
        path="/"
        element={(user && isAdmin) ? <Navigate to={'/AdminStartPage'} /> : <Login />}
      />
      <Route
        path="/Login"
        element={(user && isAdmin) ? <Navigate to={'/AdminStartPage'} /> : <Login />}
      />


      <Route path="/Auth" element={<Auth />} />

      {/* If user logged in and defined an area:*/}
      <Route path="/Home" element={<Home />} />

      <Route path="*" element={<Error404 />} />

      <Route path="/AdminStartPage" element={<AdminStartPage />} />
      <Route path="/AddAgent" element={<AddAgent />} />
      <Route path="/ListOfAgents" element={<ListOfAgents />} />
      <Route path="/EditAgent" element={<EditAgent />} />


      <Route
        path="/:directionId/:departmentId/:stageId"
        element={
          <PrivateRoute>
            <Workspace />
          </PrivateRoute>
        }
      />

      {/*//TODO: Move 1)Add/Edit Tender/Com pages and 2)Admin pages - into a single file  */}
      <Route
        path="/:directionId/tender/edit-project"
        element={
          <PrivateRoute>
            <EditProjectTender />
          </PrivateRoute>
        }
      />
      <Route
        path="/:directionId/tender/tender"
        element={
          <PrivateRoute>
            <NewTenderPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/:directionId/projects/projects"
        element={
          <PrivateRoute>
            <NewProjectPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/:directionId/commercial/add-project"
        element={
          <PrivateRoute>
            <AddProjectCom />
          </PrivateRoute>
        }
      />
      <Route
        path="/:directionId/commercial/edit-project"
        element={
          <PrivateRoute>
            <EditProjectCom />
          </PrivateRoute>
        }
      />

      <Route
        path="/:directionId/:departmentId/:type/:entityID"
        element={
          <PrivateRoute>
            <ChangePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/:directionId/:departmentId/:type/new"
        element={
          <PrivateRoute>
            <ChangePage />
          </PrivateRoute>
        }
      />

      <Route
        path="/ListOfUsers"
        element={
          <PrivateRoute>
            <ListOfUsers />
          </PrivateRoute>
        }
      />
      <Route
        path="/AddEmployee"
        element={
          <PrivateRoute>
            <AddEmployee />
          </PrivateRoute>
        }
      />
      <Route
        path="/EditEmployee"
        element={
          <PrivateRoute>
            <Edit />
          </PrivateRoute>
        }
      />
      <Route
        path="/ListOfProjects"
        element={
          <PrivateRoute>
            <ListOfProjects />
          </PrivateRoute>
        }
      />
      <Route
        path="/ListOfSites"
        element={
          <PrivateRoute>
            <ListOfSites />
          </PrivateRoute>
        }
      />
      <Route
        path="/AddSite"
        element={
          <PrivateRoute>
            <AddSite />
          </PrivateRoute>
        }
      />
      <Route
        path="/EditSite/:id"
        element={
          <PrivateRoute>
            <EditSite />
          </PrivateRoute>
        }
      />

      <Route
        path="/Manual/:id"
        element={
          <PrivateRoute>
            <ManualInfoPage/>
          </PrivateRoute>
        }
      />

      <Route
        path="/Manual"
        element={
          <PrivateRoute>
            <Manual/>
          </PrivateRoute>
        }
      />

      <Route
        path="/ListOfCompetitors"
        element={
          <PrivateRoute>
            <ListOfCompetitors />
          </PrivateRoute>
        }
      />
      <Route
        path="/AddCompetitor"
        element={
          <PrivateRoute>
            <AddCompetitor />
          </PrivateRoute>
        }
      />
      <Route
        path="/EditCompetitor/:id"
        element={
          <PrivateRoute>
            <EditCompetitor />
          </PrivateRoute>
        }
      />
      <Route
        path="/Statistics"
        element={
          <PrivateRoute>
            <Statistics />
          </PrivateRoute>
        }
      />

      <Route
        path="/TenderStatistics"
        element={
          <PrivateRoute>
            <TenderStatistics />
          </PrivateRoute>
        }
      />

      <Route
        path="/projectSummary"
        element={
          <PrivateRoute>
            <SummaryOfProject />
          </PrivateRoute>
        }
      />

      <Route
        path="/locationSummary"
        element={
          <PrivateRoute>
            <SummaryOfLocation />
          </PrivateRoute>
        }
      />

      <Route
        path="/Expenses"
        element={
          <PrivateRoute>
            <Expenses />
          </PrivateRoute>
        }
      />

      <Route
        path="/Workload"
        element={
          <PrivateRoute>
            <Workload />
          </PrivateRoute>
        }
      />

      <Route
        path="/IncomeByPaid"
        element={
          <PrivateRoute>
            <IncomeByPaid />
          </PrivateRoute>
        }
      />

      <Route
        path="/AgentReports"
        element={
          <PrivateRoute>
            <AgentReports />
          </PrivateRoute>
        }
      />

      {/* <Route
        path="/AddTenderPage"
        element={
          <PrivateRoute>
            <AddTenderPage />
          </PrivateRoute>
        }
      /> */}
    </Routes>
  );
}
export default AppRoutes;
