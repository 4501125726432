import { useContext } from 'react';
import ProjectContext from '../../context/ProjectsContext';
import ModalWrapper from "./ModalWrapper"
import moveToPrevStage from '../../utils/moveToPrevStage.js'

function MovePrevStageModal({ setOpenModal, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const projectDataHandler = async (e) => {
    e.preventDefault();
    const res = await moveToPrevStage(project.id);
    if (res.status === '200' || res.ok) {
      setIsProjectMoved((prevState) => !prevState)
      setOpenModal(false);
    } else {
      alert('Error! Проект не был переведён на предыдущий этап');
      setOpenModal(false);
    }
  }

  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <h3 className="modal__description">Перевести проект
        <b>&nbsp;{project.customerName}&nbsp;</b>
        на предыдущий этап?</h3>
      <div className="modal__buttons-wrapper">
        <button className="button button--red"
          onClick={(e) => projectDataHandler(e)}>
          Да
        </button>
        <button className="button close-button"
          onClick={() => setOpenModal(false)}>
          Нет
        </button>
      </div>
    </ModalWrapper>
  )
}

export default MovePrevStageModal