import React, { useState, useContext, useEffect } from "react";
import AttachButton from "./AttachButton";
import DocumentContext from "context/DocumentsContext";
import { BsPlusLg } from "react-icons/bs";
import { getDocuments } from "utils/getDocuments";
import Spinner from "components/Spinner";
import { AiOutlineMinus } from "react-icons/ai";
import { ConfirmActionModal } from "components/modals/ConfirmActionModal";
import ModalWrapper from "components/modals/ModalWrapper";
import { Portal } from "components/Portal/Portal";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { DocActionModal } from "components/modals/DocActionModal";

const TenderModalFileInput = ({
  modelId,
  entity,
  setDoc,
  isOneDoc = true,
  doc,
  docType,
  title,
}) => {
  // const [files, setFiles] = useState([]);
  const [isOpenList, setIsOpenList] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);
  const [docIdForRemove, setDocIdForRemove] = useState(null);
  const color = "blue";
  const { uploadDocument, downloadDocument, removeDocument } =
    useContext(DocumentContext);
  const chooseFile = async (e, fileType) => {
    setDoc([
      ...doc,
      { file: e.target.files[0], fileType: fileType, id: doc.length },
    ]);
  };

  const updateDocuments = () => {
    getDocuments("contracts", modelId).then((res) => setDoc(res));
  };

  const toggleDocumentsList = async (listTitle) => {
    setIsOpenList({ ...isOpenList, [listTitle]: !isOpenList[listTitle] });
  };

  const handleModal = (id) => {
    setDocIdForRemove(id);
    setConfirmActionModalOpen(true);
  };

  const handleRemove = async (id) => {
    await removeDocument(id);
    setConfirmActionModalOpen(false);
    updateDocuments();
  };

  const cleanUploadedFile = (id) => {
    setDoc(doc.filter((item) => item.id !== id));
  };

  const handleUpload = async (type, file) => {
    setIsUploading(true);
    setIsSuccess(false);
    const formData = new FormData();
    formData.append("file", file);
    await uploadDocument(
      "contracts",
      modelId,
      type,
      formData,
      setIsUploading,
      setIsSuccess
    );
    setTimeout(() => {
      setIsSuccess(false);
      // setFiles([]);
      updateDocuments();
    }, []);
  };

  const handleDownload = async (e, name, extention, link, id) => {
    setIsDownloading(true);
    await downloadDocument(e, name, extention, link, id);
    setIsDownloading(false);
    setIsDownloaded(true);
    setTimeout(() => {
      setIsDownloaded(false);
    }, [2000]);
  };

  return (
    <div>
      <div className="doc-buttons">
        <label className="modal__input-title">{title}</label>
        {(doc.length !== 1 || !isOneDoc) && (
          <AttachButton
            docType={docType}
            isOpenList={isOpenList}
            chooseFile={chooseFile}
            color={color}
          />
        )}
        <button
          className={`button button--square button--${color} ${
            isOpenList[docType] && `button--${color}--active`
          }`}
          type="button"
          onClick={() => toggleDocumentsList(docType)}
        >
          {isOpenList[docType] ? (
            <AiOutlineMinus className="icon--medium" />
          ) : (
            <BsPlusLg className="icon--medium" />
          )}
        </button>
      </div>
      <div
        style={{ maxHeight: 80, overflowY: "scroll", scrollbarWidth: "thin" }}
      >
        {doc &&
          doc
            .filter((item) => item.fileType === docType)
            .map((item) => (
              <li className="modal__doc-item">
                {item.file.name.length > 27
                  ? item.file.name.slice(0, 27) + "..."
                  : item.file.name}
                <div className="doc-buttons">
                  {!isUploading && !isSuccess && (
                    <>
                      {/* <button
                    className={`button button--square button--${color}`}
                    onClick={() => handleUpload('Contract', item.file)}
                    >
                    <FaArrowUp className="icon--medium" />
                    </button> */}
                      <button
                        className={`button button--square button--${color}`}
                        onClick={() => cleanUploadedFile(item.id)}
                      >
                        <ImCross className="icon--medium" />
                      </button>
                    </>
                  )}
                  {isUploading && !isSuccess && (
                    <Spinner className="icon--spinner" />
                  )}
                  {!isUploading && isSuccess && (
                    <MdDone className="icon--done" />
                  )}
                </div>
              </li>
            ))}
      </div>
      {/* {
        doc
        &&
        doc.length > 0
        &&
        doc
        .filter((doc) => doc.url.includes('_Contract_'))
        .map((doc) => (
            <li key={doc.id} className="modal__doc-item">
            {doc.name}{doc.extention}
            <div className="doc-buttons">
                {isDownloading && !isDownloaded && (
                <Spinner className="icon--spinner" />
                )}
                {!isDownloading && isDownloaded && <MdDone className="icon--done" />}
                <button
                className={`button button--square button--${color}`}
                onClick={(e) =>
                    handleDownload(e, doc.name, doc.extention, 'contracts', doc.id)
                }
                >
                <FaArrowDown className="icon--medium" />
                </button>
                <button
                className={`button button--square button--${color}`}
                onClick={() => handleModal(doc.id)}
                >
                <ImCross className="icon--medium" />
                </button>
            </div>
            </li>
        ))

        } */}
      {!doc && doc.filter((doc) => doc.typeId === docType).length <= 0 && (
        <li className="modal__no-doc-message">
          <i>Нет документов</i>
        </li>
      )}
      {confirmActionModalOpen && (
        <Portal>
          <ModalWrapper>
            <DocActionModal
              title="Вы уверены, что хотите удалить документ"
              onYes={() => handleRemove(docIdForRemove)}
              onNo={() => setConfirmActionModalOpen(false)}
              colorThemeClass={`button--${color}`}
            />
          </ModalWrapper>
        </Portal>
      )}
    </div>
  );
};

export default TenderModalFileInput;
