import { useState, useContext, useEffect } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import moveToNextStage from '../../../../../utils/moveToNextStage';
import patchProject from '../../../../../utils/patchProject';
import fetchWorkers from '../../../../../utils/fetchWorkers';

import DocumentsListHandler from '../../../accountant/common/DocumentsListHandler';
import { LAB_CHIEF_INFO_COLLECTION_DOCS } from '../../../../../utils/documentType';

function InformationCollectionLabChiefModal({ setOpenModal, inputChangeHandler, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const [workers, setWorkers] = useState('');

  const [documentsQty, setDocumentsQty] = useState({
    'Information': 0
  })

  useEffect(() => {
    fetchWorkers(setWorkers);
  }, [])

  const projectDataHandler = async (e) => {
    e.preventDefault();
    await patchProject(e, project, project.id);
    const res = await moveToNextStage(project.id);
    if (res.status === '200' || res.ok) {
      setIsProjectMoved((prevState) => !prevState)
      setOpenModal(false);
    } else {
      alert('Error! Проект не был переведён на следующий этап');
      setOpenModal(false);
    }
  }

  const documentsQtyHandler = (updatedQty) =>
    (Object.keys(updatedQty).length === 0 && updatedQty.constructor === Object)
      ? setDocumentsQty(documentsQty)
      : setDocumentsQty({ ...documentsQty, ...updatedQty })

  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>
        <DocumentsListHandler
          projectId={project.id}
          docTypesList={LAB_CHIEF_INFO_COLLECTION_DOCS}
          documentsQtyHandler={documentsQtyHandler}
        />

        <label className="modal__input-title">Дата получения информации:
          <input className="modal__input input" type="date"
            name="informationCollectionDate"
            value={project.informationCollectionDate}
            onChange={(e) => inputChangeHandler(e)}
            max="9999-12-31"
          />
        </label>

        <label className="modal__input-title">Плановая дата исполнения:
          <input className="modal__input input" type="date"
            name="plannedExecutionDate"
            value={project.plannedExecutionDate}
            onChange={(e) => inputChangeHandler(e)}
          />
        </label>

        <label className="modal__input-title">Замерщик:
          <select className="modal__input input"
            name="workerId"
            value={project.workerId}
            onChange={(e) => inputChangeHandler(e)}
          >
            <option value='null'>-</option>
            {
              workers
              && workers.map(({ id, name }) => <option key={id} value={id}> {name} </option>)
            }
          </select>
        </label>

        <label className="modal__input-title">Число рабочих мест:
          <input className="modal__input input" type="number"
            name="workspaceCount"
            value={project.workspaceCount}
            onChange={(e) => inputChangeHandler(e)}
            onWheel={(e) => e.target.blur()}
          />
        </label>

        <label className="modal__input-title">Число подразделений:
          <input className="modal__input input" type="number"
            disabled={project.assesmentTypeId === 'ProductionControl'}
            name="unitCount"
            value={(project.assesmentTypeId === 'ProductionControl') ? 1 : project.unitCount}
            onChange={(e) => inputChangeHandler(e)}
            onWheel={(e) => e.target.blur()}
          />
        </label>

        <fieldset className="workspace__ensure-wrapper">
          <label className="workspace__input-ensure">Программа выполнения:</label>
          <input id="attr" className="radio" type="radio"
            name="executionProgramId"
            value="Attestation_5_1"
            checked={project.executionProgramId === 'Attestation_5_1'}
            onChange={(e) => inputChangeHandler(e)}
          />
          <label htmlFor="attr" className="radio__label" style={{ 'marginRight': '43px' }}>Аттестация</label>

          <input id="labor" className="radio" type="radio"
            name="executionProgramId"
            value='LaborExpert_3_0'
            checked={project.executionProgramId === 'LaborExpert_3_0'}
            onChange={(e) => inputChangeHandler(e)}
          />
          <label htmlFor="labor" className="radio__label">ТрудЭксперт</label>

          <input id="no-programm" className="radio" type="radio"
            name="executionProgramId"
            value='None'
            checked={project.executionProgramId === 'None'}
            onChange={(e) => inputChangeHandler(e)}
          />
          <label htmlFor="no-programm" className="radio__label radio__label--ml">Нет</label>
        </fieldset>

        <h3 className="modal__description">Перевести проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          на следующий этап?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            disabled={project.executionProgramId === 'None'
              || project.workerId === 'null'
              || project.workerId === undefined
              || project.unitCount <= 0
              || project.workspaceCount <= 0
              || project.plannedExecutionDate === '2010-01-01'
              || project.informationCollectionDate === undefined
              || project.informationCollectionDate === ''
              || documentsQty.Information <= 0
            }
            onClick={(e) => projectDataHandler(e)}>
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper>
  )
}
export default InformationCollectionLabChiefModal


