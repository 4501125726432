import { generateToken } from "../../../shared/generateToken";
import { buildQuery } from "shared/buildQuery";
import { api } from 'settings/Api';

export const fetchLocations = async (values, page, stage, locType, PageRowCount = 50, isCompare = false) => {
	const token = generateToken();
	const urls = {
		Contract: `contract-measuring-blocks${isCompare?'?WorkFlowStatuses=Unassigned&WorkFlowStatuses=Planned&PageRowCount=2500':''}`,
		Potential: `potential-measuring-blocks${isCompare?'?WorkFlowStatuses=Unassigned&PageRowCount=2500':''}`,
	};
	const baseURL = "/api/sout/admin/";
	try {
		const url = isCompare?baseURL + urls[locType] :buildQuery(
			baseURL + urls[locType],
			page,
			values,
			stage,
			PageRowCount
		);
		const response = await api(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.status !== 200) return response.status;

		const data = await response.json();

		if (data.isSuccess) {
			const { totalPagesCount, list, totalRowCount } = data.data;
			return { totalPagesCount, list, totalRowCount };
		}
		if (data.isFailure) throw new Error(data);
	} catch (e) {
		alert(`Произошла ошибка при загрузке замерных блоков: ${e}`);
	}
};
