import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const HandbookItem = ({item, isOpenHandbook, setIsOpenHandbook}) => {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false);
    }, [isOpenHandbook])

    return (
        <div className="">
            <li className={`user-panel__info-subitem `} onClick={() => setIsOpen(!isOpen)}>
                <p
                    style={{color: `${isOpen?'#05be90':''}`, textDecoration: `underline ${isOpen?'#05be90':''}`}}
                    className={`user-panel__info-sublink`}
                >
                    {item?.title}
                </p>
            </li>
            {
              isOpen &&
              item?.items?.map(elem => (
                <Link 
                    key={elem?.link}
                    to={elem?.link} 
                    element={elem?.element} 
                    className="user-panel__link"
                    onClick={() => setIsOpenHandbook(false)}
                >
                    {elem?.title}
                </Link>
              ))
            }
        </div>
    )
}

export default HandbookItem