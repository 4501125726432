import { createContext, useState } from "react";
import { buildQuery } from "shared/buildQuery";
import { generateToken } from "shared/generateToken";
import { api } from "settings/Api";

const DocumentContext = createContext();

export const DocumentProvider = ({ children }) => {
  const baseURL = "/api/sout/admin/";

  const entities = {
    tenders: {
      link: "tenders",
      docTypes: [
        {
          type: "Info",
          description: "Информация",
          typeId: 0,
        },
      ],
    },
    contracts: {
      link: "contracts",
      docTypes: [
        {
          type: "Contract",
          description: "Договор",
          typeId: 0,
        },
        {
          type: "Info",
          description: "Информация",
          typeId: 1,
        },
        {
          type: "ContractScanSigned",
          description: "Скан подписанного договора",
          typeId: 2,
        },
        {
          type: "Act",
          description: "Акт",
          typeId: 3,
        },
        {
          type: "ActScanSigned",
          description: "Скан подписанного акта",
          typeId: 4,
        },
      ],
    },
    projects: {
      link: "separated-projects",
      docTypes: [
        {
          type: "Information",
          description: "Информация",
          typeId: 0,
        },
        {
          type: "DB",
          description: "База",
          typeId: 1,
        },
        {
          type: "WordReport",
          description: "Отчет Word",
          typeId: 2,
        },
        {
          type: "TitleSheet",
          description: "Титульный",
          typeId: 3,
        },
        {
          type: "Unload",
          description: "Выгрузка",
          typeId: 4,
        },
        {
          type: "Statement",
          description: "Выписка",
          typeId: 5,
        },
        {
          type: "ErrorsScreenshot",
          description: "Скриншот ошибок",
          typeId: 6,
        },
      ],
    },
    locations: {
      link: "measuring-blocks",
      docTypes: [
        {
          type: "Info",
          description: "Информация",
          typeId: 0,
        },
      ],
    },
    potentialLocations: {
      link: "potential-measuring-blocks",
      docTypes: [
        {
          type: "Info",
          description: "Информация",
          typeId: 0,
        },
      ],
    },
    contractLocations: {
      link: "contract-measuring-blocks",
      docTypes: [
        {
          type: "Info",
          description: "Информация",
          typeId: 0,
        },
      ],
    },
  };

  const uploadDocument = async (
    entity,
    modelId,
    docType,
    file,
    setIsUploading,
    setIsSucсess,
    contractId
  ) => {
    const token = generateToken();
    let tempEntityLink = entity;
    if (
      entity === entities.contractLocations.link ||
      entity === entities.potentialLocations.link
    ) {
      tempEntityLink = "measuring-blocks";
    }
    // const link = buildQuery(baseURL + tempEntityLink + '/upload-document', null, { modelId, docType }, null);

    const link =
      docType !== "Id"
        ? buildQuery(
            baseURL + tempEntityLink + "/upload-document",
            null,
            { modelId, docType },
            null
          )
        : buildQuery(
            baseURL + tempEntityLink + "/upload-document",
            null,
            { modelId, docType, contractId: contractId },
            null
          );

    try {
      const response = await api(link, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: file,
      });

      if (response.status !== 200)
        throw new Error(
          `Ошибка при загрузке файла: ${response.status} \nОшибка получения ссылки`
        );

      const data = await response.json();

      const uploadLinkResponse = await fetch(data.link.href, {
        method: "PUT",
      });

      if (uploadLinkResponse.status !== 201)
        throw new Error(
          `Ошибка при загрузке файла: ${response.status} \nОшибка загрузки`
        );

      setIsUploading(false);
      setIsSucсess(true);
    } catch (e) {
      alert(e.message);
      setIsUploading(false);
      setIsSucсess(false);
    }
  };

  const downloadDocument = async (
    e,
    name,
    extention,
    entity,
    documentId,
    type = ""
  ) => {
    e.preventDefault();

    const token = generateToken();
    const link =
      baseURL +
      entity +
      `${type === "Id" ? "/departments" : ""}/download-document/${documentId}`;

    try {
      const response = await api(link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status !== 200)
        throw new Error(`Ошибка при скачивании файла: ${response.status}`);
      await response.blob().then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = name + extention;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const removeDocument = async (id) => {
    const token = generateToken();
    const link = `/api/documents?id=${id}`;

    try {
      const response = await api(link, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status !== 200)
        throw new Error(`Ошибка при удалении файла: ${response.status}`);
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <DocumentContext.Provider
      value={{
        entities,
        uploadDocument,
        downloadDocument,
        removeDocument,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export default DocumentContext;
