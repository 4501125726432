export const buildQuery = (url, page, values, stageQuery, PageRowCount) => {
  url += "?";

  Object.keys(values).forEach((key) => {
    if (values[key] !== null) {
      if (url[url.length - 1] !== "?") {
        url += "&";
      }
      url += `${key}=${values[key]}`;
    }
  });
  if (page === null && stageQuery === null) return url;
  url += url[url.length - 1] !== "?" ? "&" : "";
  url += `${stageQuery}&page=${page}&PageRowCount=${PageRowCount}`;
  return url;
};
