import { generateToken } from "shared/generateToken";
import { api } from "settings/Api";

export const fetchWorkloadData = async (year, setWorkloadData) => {
    const token = generateToken();
    let response = await api(`/api/stats/employees/${year}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    let data = await response.json();
    setWorkloadData(data);
};