import styled  from "styled-components";

export const Line = styled.div`
	border-bottom: 2px solid #000;
	margin: 15px 0 20px 0;
    grid-column: ${props => props.gridColumn};
    grid-row: ${props => props.gridRow || ''};
    flex-basis: 100%;
    width: 100%;
`;
