import React, { useState } from "react";
import { ReactComponent as Bookmark } from "../assets/icons/Bookmark.svg";
import { ReactComponent as Briefcase } from "../assets/icons/Briefcase.svg";
import { ReactComponent as Cube } from "../assets/icons/Cube.svg";
import { ReactComponent as Flag } from "../assets/icons/Flag.svg";
import { ReactComponent as LayoutFluid } from "../assets/icons/LayoutFluid.svg";
import { ReactComponent as LeafMaple } from "../assets/icons/LeafMaple.svg";
import { ReactComponent as Mountain } from "../assets/icons/Mountain.svg";
import { ReactComponent as Star } from "../assets/icons/Star.svg";
import { ReactComponent as Tags } from "../assets/icons/Tags.svg";
import { ReactComponent as Vector } from "../assets/icons/Vector.svg";

import { ReactComponent as ArrowDown } from "../assets/icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/ArrowUp.svg";

export const dataIcon = {
  Bookmark: <Bookmark />,
  Briefcase: <Briefcase />,
  Cube: <Cube />,
  Flag: <Flag />,
  LayoutFluid: <LayoutFluid />,
  LeafMaple: <LeafMaple />,
  Mountain: <Mountain />,
  Star: <Star />,
  Tags: <Tags />,
  Vector: <Vector />,
};

const IconSelect = ({ icons, activeIcon, setActiveIcon, isDisabled }) => {
  const [isOpenList, setIsOpenList] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <p>Значок агента</p>
      <div
        style={{
          padding: "5px 10px",
          border: "1px solid black",
          borderRadius: 10,
          position: "relative",
          marginTop: 5,
          width: 120,
        }}
        onClick={isDisabled ? null : () => setIsOpenList(!isOpenList)}
      >
        {isOpenList ? (
          <div style={{ position: "absolute", right: 5, top: 5 }}>
            <ArrowDown />
          </div>
        ) : (
          <div style={{ position: "absolute", right: 5, top: 5 }}>
            <ArrowUp />
          </div>
        )}
        {activeIcon ? (
          dataIcon[activeIcon]
        ) : (
          <span
            style={{
              fontSize: 20,
              marginLeft: 3,
              fontWeight: "bold",
            }}
          >
            -
          </span>
        )}
      </div>
      {isOpenList ? (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            zIndex: 10,
            width: 139,
            border: "1px solid black",
            borderTop: "none",
            backgroundColor: "white",
          }}
        >
          {icons?.map((item) => {
            return item?.name?.length > 0 ? (
              <div
                style={{
                  width: 120,
                  paddingLeft: 7,
                  marginTop: 3,
                  cursor: "pointer",
                }}
                onClick={() => setActiveIcon(item?.name)}
              >
                {dataIcon[item?.name]}
              </div>
            ) : null;
          })}
        </div>
      ) : null}
    </div>
  );
};

export default IconSelect;
