import Sidebar from "../Sidebar"

function WorkspaceWrapper({ children }) {
	return (
		<main className="workspace">
			<Sidebar />
			<div className="workspace__content">
				<section className="workspace__wrapper">
					{children}
				</section>
			</div>
		</main>
	)
}

export default WorkspaceWrapper