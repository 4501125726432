import ModalWrapper from "components/modals/ModalWrapper";

function RejectModal({ text, onYes, onNo, onClose }) {
  return (
    <ModalWrapper>
      <button className="modal__close-button close-button" onClick={onClose}>
        &#10005;
      </button>
      <h3 className="modal__description">{text}</h3>
      <div className="modal__buttons-wrapper">
        <button className="button button--red" onClick={onYes}>
          Да
        </button>
        <button className="button close-button" onClick={onNo}>
          Нет
        </button>
      </div>
    </ModalWrapper>
  );
}
export default RejectModal;
