import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";

//TODO: Rename to MenuRejected
import { isAdmin, isCommercialUser, isTenderUser } from "../utils/defineUser";
import ContractSidebar from "./Sidebars/ContractSidebar";
import TenderSidebar from "./Sidebars/TenderSidebar";
import ProjectSidebar from "./Sidebars/ProjectSidebar";
import LocationsSidebar from "./Sidebars/LocationsSidebar";

export const Sidebar = () => {
  const { user, role } = useContext(AuthContext);

  const { departmentId } = useParams();
  const [entity, setEntity] = useState();

  useEffect(() => {
    if (departmentId === undefined) {
      const currentURL = window.location.href;
      let arr = currentURL.split("/");
      setEntity(arr[arr.length - 1]);
      return;
    }
    setEntity(departmentId);
  }, [departmentId]);

  const [openedSidebarSections, setOpenedSidebarSections] = useState({
    commercial: isCommercialUser(),
    tender: isTenderUser(),
    lab_chief: role === "lab_chief",
    lab_manager: role === "lab_manager",
    lab_assist: role === "lab_assist",
    lab_expert: role === "lab_expert",
    lab_emp: role === "lab_emp",
    accountant: role === "accountant",
    admin: role === "admin",
  });

  useEffect(() => {
    localStorage.setItem(
      "__erp-sidebar",
      JSON.stringify(openedSidebarSections)
    );
  }, [openedSidebarSections, role, user]);

  const [isOpen, setIsOpen] = useState(() => {
    const openedSectionsFromLs =
      role === JSON.parse(localStorage.getItem("__erp-sidebar"))
        ? JSON.parse(localStorage.getItem("__erp-sidebar"))
        : false;
    return openedSectionsFromLs || openedSidebarSections;
  });

  const toggleSidebar = (department) => {
    setIsOpen({
      ...isOpen,
      [department]: !isOpen[department],
    });
  };

  return (
    <nav className="sidebar">
      <div className="sidebar__wrapper">
        {entity === "contracts" && (
          <ContractSidebar
            user={user}
            toggleSidebar={toggleSidebar}
            isOpen={isOpen}
          />
        )}
        {entity === "tender" && (
          <TenderSidebar toggleSidebar={toggleSidebar} isOpen={isOpen} />
        )}
        {entity === "projects" && (
          <ProjectSidebar toggleSidebar={toggleSidebar} isOpen={isOpen} />
        )}
        {entity === "locations" && (
          <LocationsSidebar toggleSidebar={toggleSidebar} isOpen={isOpen} />
        )}
      </div>
    </nav>
  );
};
export default Sidebar;
