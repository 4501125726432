import { useContext } from "react";
import ProjectContext from "../../../../../context/ProjectsContext";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import moveToNextStage from "../../../../../utils/moveToNextStage";
import patchProject from "../../../../../utils/patchProject";

function PaymentAccModal({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const projectDataHandler = async (e) => {
		e.preventDefault();
		await patchProject(e, project, project.id);
		const res = await moveToNextStage(project.id);
		if (res.status === "200" || res.ok) {
			setIsProjectMoved((prevState) => !prevState);
			setOpenModal(false);
		} else {
			alert("Error! Проект не был переведён на следующий этап");
			setOpenModal(false);
		}
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<label className="modal__input-title">Сумма по акту &nbsp;(₽)</label>
				<input
					className="input modal__input"
					type="number"
					disabled
					value={project?.actAmount}
				/>

				<label className="modal__input-title">
					Сумма всех платежей &nbsp;(₽)
				</label>
				<input
					className="input modal__input"
					type="number"
					disabled
					value={project?.paymentsSum}
				/>

				<label className="modal__input-title" style={{ marginTop: "15px" }}>
					Дата акта:
					<input
						className="modal__input input"
						type="date"
						name="actDate"
						disabled
						value={project?.actDate}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</label>

				<label className="modal__input-title">
					Дата оплаты:
					<input
						className="input modal__input"
						type="date"
						name="paidDate"
						disabled
						value={project?.paidDate}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</label>

				<fieldset
					className="workspace__ensure-wrapper"
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<label className="modal__input-title">Акт:</label>
					<input
						id="ActRecived"
						className="radio"
						type="radio"
						name="actHasReached"
						value="true"
						checked={project.actHasReached === "true"}
						onChange={(e) => inputChangeHandler(e)}
					/>
					<label
						htmlFor="ActRecived"
						className="modal__input-title radio__label"
					>
						Получен
					</label>

					<input
						id="ActDidNotRecived"
						className="radio"
						type="radio"
						name="actHasReached"
						value="false"
						checked={project.actHasReached === "false"}
						onChange={(e) => inputChangeHandler(e)}
					/>
					<label
						htmlFor="ActDidNotRecived"
						className="modal__input-title radio__label radio__label--ml"
						style={{ width: "200px" }}
					>
						Не получен
					</label>
				</fieldset>

				<h3 className="modal__description">
					Перевести проект
					<b>&nbsp;{project.customerName}&nbsp;</b>
					на следующий этап?
				</h3>
				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={
							project?.paymentsSum < project?.actAmount ||
							project?.actHasReached === "false" ||
							project?.paymentsSum < 1
						}
						onClick={(e) => projectDataHandler(e)}
					>
						Да
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default PaymentAccModal;
