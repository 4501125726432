import { dateToString } from "./formatDate";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

export const generateQueriesForSearchRequest = (params) => {
  const {
    e,
    openSort,
    values,
    setQueryStrings,
    setSortParam,
    setPaginationQuery,
    setSubmitButtonPressed,
  } = params;

  e.preventDefault();

  if (openSort) {
    if (!values.SortField) {
      alert("Выберите параметр сортировки");
      return;
    }
  }

  let queryString = "&";

  for (let key in values) {
    if (key === "SortDeadlines") {
      queryString = values[key];
      continue;
    }
    let value = key.includes("Date") || key.includes("AcceptingOffersDeadline")
      ? dateToString(values[key])
      : capitalizeFirstLetter(values[key]);
    queryString += `${key}=${value}&`;
  }

  queryString = queryString.slice(0, -1);

  setQueryStrings(queryString);
  setSortParam(capitalizeFirstLetter(values.SortField));
  setPaginationQuery(`&PageRowCount=20&Page=1`);
  setSubmitButtonPressed(true);
};
