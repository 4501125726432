export const formatDate = dateString => {
  if (dateString === null) return null;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${day}.${month}.${year}`;
}

export const formatDateWithTime = dateString => {
  if (!dateString) return null;
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth()+1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const min = (date.getMinutes()).toString().padStart(2, '0');
  return `${day}.${month}.${year} ${hours}:${min}`;
}

export const dateToString = date => {
  const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  if (regex.test(date)) return date;
  if (date === null) return null;
  if(typeof date !== 'string'){
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return [year, month, day].join("-");
  }
  else return date;
}

export const dateToStringWithTime = date => {
  const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  if (regex.test(date)) return date;
  if (date === null) return null;
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth()).toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  const hours = date.getHours();
  const min = (date.getMinutes()).toString();
  return new Date(year,month,day,hours+4,min)
}