import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "settings/Api";
import { fetchTradingFloors } from "utils/fetchTradingFloors";
import fetchCompetitors from "utils/fetchCompetitors";

const EditCompetitor = () => {
  const [competitor, setCompetitor] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    fetchCompetitors(getCompetitor);
  }, []);

  const getCompetitor = (competitors) => {
    setCompetitor(competitors.find((item) => item?.id === parseInt(id)));
  };

  console.log(competitor);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;

    setCompetitor({
      ...competitor,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const postData = () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    api("/api/sout/handbooks/competitors", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(competitor),
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Конкурент изменен!");
        navigate("/ListOfCompetitors");
      } else if (res.status === 500 || !res.ok) {
        alert("Ошибка. Площадка не создана!");
      }
    });
  };

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3 className="workspace__title workspace__page-title">
            Редактировать конкурента
          </h3>
          <form style={{ width: 400 }}>
            <p className="workspace__input-title">Наименование:</p>
            <input
              className="input"
              type="text"
              name="name"
              value={competitor?.name}
              onChange={inputChangeHandler}
            />
            <p className="workspace__input-title">Описание</p>
            <input
              className="input"
              type="text"
              value={competitor?.description}
              name="description"
              onChange={inputChangeHandler}
            />
            <div style={{ display: "flex" }}>
              <input
                type="button"
                disabled={competitor?.name?.length <= 0}
                onClick={postData}
                className="button staff__create-staff-button"
                value="Добавить"
              />
              <input
                type="button"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  navigate(-1);
                }}
                className="button staff__create-staff-button"
                value="Отмена"
              />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};
export default EditCompetitor;
