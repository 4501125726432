import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaInfo } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { MdTextSnippet } from "react-icons/md";

import InfoTableModalTender from "./modals/InfoTableModalTender";
import ChangeStageModal from "./modals/ChangeStageModal";
import DocumentContext from "context/DocumentsContext";
import DocumentsModal from "pages/sout/commercial/modals/documents/DocumentsModal";
import AuthContext from "context/AuthContext";

function ActionButton({
  onAction,
  modalType,
  iconType,
  title,
  isDisabled = false,
  styles,
}) {
  return (
    <button
      disabled={isDisabled}
      onClick={() => onAction(modalType)}
      className={`button button--square button--purple`}
      title={title}
      style={styles}
    >
      {iconType}
    </button>
  );
}

//TENDER BUTTONS
const ActionButtonsTenders = (props) => {
  const { entities } = useContext(DocumentContext);

  const { projectId, project } = props;
  const { role, startPageData, accessRights } = useContext(AuthContext);
  const workFlowId = project.workFlow.id;
  const stageId = project.workFlow.workFlowStatusId;

  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isNextModalOpen, setIsNextModalOpen] = useState(false);
  const [isPrevModalOpen, setIsPrevModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isTableInfoModalOpen, setIsTableInfoModalOpen] = useState(false);

  const openMoveNextModal = () => {
    setIsNextModalOpen(true);
  };

  const openMovePrevModal = () => {
    setIsPrevModalOpen(true);
  };

  const openRejectModal = () => {
    setIsRejectModalOpen(true);
  };

  const openDocumentModal = () => {
    setIsDocumentModalOpen(true);
  };

  const openInfoTable = () => setIsTableInfoModalOpen(true);
  return (
    <>
      <Link
        to="/sout/tender/tender"
        state={{ projectId, actionType: "edit", workFlowId }}
      >
        <ActionButton
          onAction={() => 5}
          iconType={<MdEdit className="icon-edit" />}
          title="Редактировать"
          isDisabled={accessRights[role].tender[stageId]?.edit}
        ></ActionButton>
      </Link>

      <ActionButton
        modalType="showInfo"
        iconType={<FaInfo className="icon-info" />}
        onAction={openInfoTable}
        title="Информация о тендере!"
        isDisabled={accessRights[role].tender[stageId]?.info}
      ></ActionButton>

      <ActionButton
        modalType="movePrev"
        iconType={<FaArrowLeft className="icon-move" />}
        title="Перевести на предыдущий этап"
        onAction={openMovePrevModal}
        isDisabled={accessRights[role].tender[stageId]?.prev}
      ></ActionButton>

      <ActionButton
        iconType={<FaArrowRight className="icon-move" />}
        title="Перевести на следующий этап"
        onAction={openMoveNextModal}
        modalType="next"
        isDisabled={accessRights[role].tender[stageId]?.next}
      ></ActionButton>

      <ActionButton
        iconType={<ImCross className="icon-delete" />}
        title="Удалить проект"
        onAction={openRejectModal}
        modalType="reject"
        isDisabled={accessRights[role].tender[stageId]?.reject}
      ></ActionButton>
      <ActionButton
        iconType={<MdTextSnippet className="icon-document" />}
        title="Документы"
        onAction={openDocumentModal}
        modalType="documents"
        isDisabled={false}
      ></ActionButton>
      {isNextModalOpen && (
        <ChangeStageModal
          project={project}
          projectId={project.workFlow.id}
          workFlowStatusId={stageId}
          setOpenModal={setIsNextModalOpen}
          projectName={project?.manager?.name}
          type="tender"
          move="next"
        />
      )}
      {isPrevModalOpen && (
        <ChangeStageModal
          project={project}
          projectId={project.workFlow.id}
          setOpenModal={setIsPrevModalOpen}
          workFlowStatusId={stageId}
          projectName={project?.manager?.name}
          type="tender"
          move="prev"
        />
      )}
      {isRejectModalOpen && (
        <ChangeStageModal
          project={project}
          projectId={project.workFlow.id}
          setOpenModal={setIsRejectModalOpen}
          projectName={project?.manager?.name}
          workFlowStatusId={stageId}
          type="tender"
          move="reject"
        />
      )}
      {isDocumentModalOpen && (
        <DocumentsModal
          modelId={project.id}
          entity={entities.tenders}
          setOpenModal={setIsDocumentModalOpen}
          documents={project.documents}
        />
      )}
      {isTableInfoModalOpen && (
        <InfoTableModalTender
          setOpenModal={setIsTableInfoModalOpen}
          project={project}
        />
      )}
    </>
  );
};
export default ActionButtonsTenders;
