import { TENDER_STAGES } from "./stages";
import { REJECT_STAGES } from "./stages";
import { COMMERCIAL_STAGES } from "./stages";

//**<-- ADMIN --> */
export const ADMIN_BUTTONS = {
	[TENDER_STAGES[0]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[1]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[2]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[3]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[4]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[12]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[13]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[14]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[15]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[16]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[17]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
};

//** <--- REJECTS ---> */
export const REJECT_STAGES_BUTTONS = {
	[REJECT_STAGES[0]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[REJECT_STAGES[1]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[REJECT_STAGES[2]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[REJECT_STAGES[3]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[REJECT_STAGES[4]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
};

//** <--- SOUT ---> */
export const TENDER_BUTTONS = {
	[TENDER_STAGES[0]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[1]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[2]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[3]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[4]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[12]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[13]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[14]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[17]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[18]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
};

export const TENDER_HEAD_BUTTONS = {
	[TENDER_STAGES[0]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[1]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[2]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[3]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[4]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[12]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[13]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[14]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[17]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[18]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
};

export const COMMERCIAL_BUTTONS = {
	[COMMERCIAL_STAGES[0]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[COMMERCIAL_STAGES[1]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[2]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[3]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[4]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[5]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[6]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[7]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[8]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[9]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[10]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[COMMERCIAL_STAGES[11]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[COMMERCIAL_STAGES[12]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[13]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[14]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
};

export const LAB_CHIEF_BUTTONS = {
	[TENDER_STAGES[0]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[1]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[2]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[3]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[4]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	//Ready To Work - TEN
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[12]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[13]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[14]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
};

export const LAB_ASSIST_BUTTONS = {
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[12]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[13]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[14]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
};

export const LAB_HEAD_DEPUTY_BUTTONS = {
	[TENDER_STAGES[0]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[1]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[2]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[3]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[4]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	//Ready To Work - TEN
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[12]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[13]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[14]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
};

export const ACCOUNTANT_BUTTONS = {
	[TENDER_STAGES[4]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[12]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[13]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[14]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
		addPayment: false,
		viewPayments: false,
	},
	[TENDER_STAGES[15]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
		addPayment: true,
		viewPayments: false,
	},
	[TENDER_STAGES[16]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: false,
		addPayment: true,
		viewPayments: false,
	},
};

export const LAB_EXPERT_BUTTONS = {
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[12]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[13]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[14]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
};

//** <--- MED ---> */
export const COMMERCIAL_BUTTONS_MED = {
	[COMMERCIAL_STAGES[0]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[COMMERCIAL_STAGES[1]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[COMMERCIAL_STAGES[2]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[3]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[4]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[5]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[6]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[7]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[8]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[9]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[COMMERCIAL_STAGES[10]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[11]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[12]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[13]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[14]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[COMMERCIAL_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
};

export const TENDER_BUTTONS_MED = {
	[TENDER_STAGES[0]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[1]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[2]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[3]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[4]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[5]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[6]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[7]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[8]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[9]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[10]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[11]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[12]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[13]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[14]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
};

export const MED_CHIEF_BUTTONS = {
	[TENDER_STAGES[4]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[5]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[6]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[7]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[8]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[9]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[10]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[11]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[12]]: {
		edit: false,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[13]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
	[TENDER_STAGES[14]]: {
		edit: true,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: false,
		info: false,
		movePrev: false,
		moveNext: false,
	},
};

export const ACCOUNTANT_BUTTONS_MED = {
	[TENDER_STAGES[4]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[5]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[6]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[7]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[8]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[9]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[10]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[11]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[12]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[13]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: false,
	},
	[TENDER_STAGES[14]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: false,
	},
	[TENDER_STAGES[15]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: true,
		moveNext: true,
	},
	[TENDER_STAGES[16]]: {
		edit: true,
		basket: true,
		info: false,
		movePrev: false,
		moveNext: true,
	},
};
