import {generateToken} from "../shared/generateToken";
import {api} from "../settings/Api";

export const fetchAccount = async () => {
    const token = generateToken();
    const response = await api("/api/account",
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }
        });
    return await response.json();
}