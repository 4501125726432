import Sidebar from "components/Sidebar";
import React from "react";

const SummaryOfProject = () => {
	return (
		<main className="workspace">
			<Sidebar />
			<div className="workspace__content">
				<section className="workspace__wrapper">
					<h3 className="workspace__title workspace__page-title">
						Сводка по проектам
					</h3>
				</section>
			</div>
		</main>
	);
};

export default SummaryOfProject;
