import { useContext } from "react";
import ProjectContext from "../../../../../context/ProjectsContext";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import moveToNextStage from "../../../../../utils/moveToNextStage";
import patchProject from "../../../../../utils/patchProject";

function ReturnOfAccSecurity({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const projectDataHandler = async (e) => {
		e.preventDefault();

		// if (project.contractSecuringMethodId === "PaymentOrder" && project.securityAmount > 0) {
		//   project.securityReturned = false;
		//
		// }

		await patchProject(e, project, project.id);
		const res = await moveToNextStage(project.id);
		if (res.status === "200" || res.ok) {
			setIsProjectMoved((prevState) => !prevState);
			setOpenModal(false);
		} else {
			alert("Error! Проект не был переведён на следующий этап");
			setOpenModal(false);
		}
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<fieldset className="workspace__ensure-wrapper">
					<label className="modal__input-title">Обеспечение вернулось?</label>
					<input
						id="SecurityWasReturned"
						className="radio"
						type="radio"
						name="securityReturned"
						value="true"
						checked={project.securityReturned === "true"}
						onChange={(e) => inputChangeHandler(e)}
					/>
					<label
						htmlFor="SecurityWasReturned"
						className="radio__label modal__input-title"
					>
						Да
					</label>

					<input
						id="SecurityDidNotReturned"
						className="radio"
						type="radio"
						name="securityReturned"
						value="false"
						checked={project.securityReturned === "false"}
						onChange={(e) => inputChangeHandler(e)}
					/>
					<label
						htmlFor="SecurityDidNotReturned"
						className="radio__label workspace__label modal__input-title"
						style={{ width: "200px" }}
					>
						Нет
					</label>
				</fieldset>

				<h3 className="modal__description">
					Перевести проект
					<b>&nbsp;{project.customerName}&nbsp;</b>
					на следующий этап?
				</h3>
				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={project.securityReturned === "false"}
						onClick={(e) => projectDataHandler(e)}
					>
						Да
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default ReturnOfAccSecurity;
