import Asterisk from 'components/Asterisk';
import { DatePicker } from 'components/DateInput/DateInput'
import FileInput from 'pages/sout/accountant/common/FileInput'
import React, { useEffect, useState } from 'react'

const DocWithDateModal = ({setIsDisabled, id, dataArr, date, setDate, titleDate, otherCheck = true}) => {
    const [isUpload1, setIsUpload1] = useState(true);
    const [isUpload2, setIsUpload2] = useState(true);

    console.log(dataArr)

    useEffect(() => {
        setIsDisabled(!(date && otherCheck && (!dataArr[0]?.require || isUpload1) && (!dataArr[1]?.require || isUpload2)))
    })

    return (
        <div className="modal__content-center">
            <div style={{width:320, marginTop: 15}}>
                <label className="modal__input-title">
                    {titleDate}
                    <Asterisk />
                </label>
                <DatePicker
                    className='input'
                    name="Date"
                    value={date || null}
                    onChange={date => setDate(date)}
                    required={true}
                    min={new Date('01.01.2000')}
                />
            </div>
            {dataArr.map(({link, title, require}, i) => {
                return <FileInput
                    key={link}
                    isUpload={i===0?isUpload1:isUpload2}
                    setIsUpload={i===0?setIsUpload1:setIsUpload2}
                    modelId={id}
                    entity={'separated-projects'}
                    link={link}
                    title={title}
                />
            })}
        </div>
    )
}

export default DocWithDateModal