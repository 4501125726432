import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import { api } from 'settings/Api';

function DeletePaymentModal({ setOpenModal, setOpen, paytId, paytAmount }) {
	const deletePayment = () => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		api(`/api/sout/admin/accounting/payments/${paytId}/delete`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				// 'Authorization':  'String(authTokens.access) ,
			},
		})
			.then((result) => result.json())
			.catch((error) => alert(`Ошибка при удалении платежа! ${error}`));
	};

	const handleDelete = async (e) => {
		e.preventDefault();
		await deletePayment();
		setOpen(false);
		setOpenModal(false);
	};

	return (
		<ModalWrapper>
			<h3 className="modal__description">
				Удалить платёж на сумму &nbsp;<b>{paytAmount}&nbsp;₽</b>?
			</h3>
			<div className="modal__buttons-wrapper">
				<button className="button button--red" onClick={(e) => handleDelete(e)}>
					Да
				</button>
				<button className="button close-button" onClick={() => setOpen(false)}>
					Нет
				</button>
			</div>
		</ModalWrapper>
	);
}
export default DeletePaymentModal;
