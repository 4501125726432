import jwtDecode from "jwt-decode";
import { api } from "settings/Api";
import { generateToken } from "shared/generateToken";
const fetchAccountInfo = async () => {
  const token = generateToken();
  console.log(jwtDecode(token));
  let response = await api(`/api/account`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  let data = await response.json();
  return data;
};
export { fetchAccountInfo };
