import { useContext, useEffect, useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import AuthContext from "context/AuthContext";
import SearchLocations from "entities/locations/components/SearchLocations";
import LocationContext from "context/LocationContext";

const StageLocations = memo(() => {
  const params = useParams();

  const navigate = useNavigate();

  const [stage, setStage] = useState({
    title: null,
    query: null,
  });

  const [isPotential, setIsPotential] = useState(false);
  const { isCompare, setIsCompare } = useContext(LocationContext);

  const WORKSPACE_OPTIONS = {
    sout: {
      locations: {
        Actual: [
          "Все актуальные",
          "WorkFlowStatuses=Unassigned&WorkFlowStatuses=Planned&WorkFlowStatuses=Finish",
        ],
        GlobalSearch: ["Общий поиск", "null=null"],
        Compare: ["Сравнение замерных блоков", ""],
        unassigned: ["Неназначенные", "WorkFlowStatuses=Unassigned"],
        planned: ["Планируемые", "WorkFlowStatuses=Planned"],
        inLeaving: ["На выезде", "WorkFlowStatuses=InLeaving"],
        finish: ["Завершённые", "WorkFlowStatuses=Finish"],
        archive: ["Архив", "WorkFlowStatuses=Archive"],
        suspended: ["Приостановленные", "WorkFlowStatuses=Suspended"],
        basket: ["Корзина", "WorkFlowStatuses=Basket"],
      },
    },
  };

  useEffect(() => {
    if (stage) {
      setIsCompare(false);
      if (params.directionId === "undefined") {
        navigate("/Home");
        return;
      }
      if (
        params.stageId === "potential" ||
        params.stageId === "potentialBasket"
      ) {
        setIsPotential(true);
        setStage({
          title:
            params.stageId === "potential"
              ? "Потенциальные замерные блоки"
              : "Корзина потенциальных замерных блоков",
          query:
            params.stageId === "potential"
              ? "WorkFlowStatuses=Unassigned"
              : "WorkFlowStatuses=Basket",
        });
        return;
      }
      if (params.stageId === "Compare") {
        setIsCompare(true);
      }
      setIsPotential(false);
      const title =
        WORKSPACE_OPTIONS[params.directionId][params.departmentId][
          params.stageId
        ][0];
      const query =
        WORKSPACE_OPTIONS[params.directionId][params.departmentId][
          params.stageId
        ][1];

      setStage({
        title,
        query,
      });
    }
  }, [params]);

  return (
    <>
      {stage && (
        <SearchLocations
          stage={stage}
          isCompare={isCompare}
          locType={isPotential ? "Potential" : "Contract"}
        />
      )}
    </>
  );
});
export default StageLocations;
