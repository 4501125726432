import { useContext, useEffect, useState } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import patchProject from '../../../../../utils/patchProject';
import moveToNextStage from '../../../../../utils/moveToNextStage';
import Asterisk from '../../../../../components/Asterisk';
import fetchCompetitors from '../../../../../utils/fetchCompetitors';

function FiledTenderModal({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);
	const [competitor, setCompetitor] = useState({})

	const competitorName =
		localStorage.getItem('__user-area') === 'sout'
			? 'ПРЦОТ'
			: 'СтандартМед'

	useEffect(() => {
		competitorHandler()
	}, [])

	const competitorHandler = async () => {
		const competitors = await fetchCompetitors()
		const competitor = competitors.filter(item => item.name === competitorName)
		setCompetitor(competitor[0])
	}


	const projectDataHandler = async (e) => {
		e.preventDefault();

		project.winnerId = competitor.id;
		project.price = project.winnerPrice; //Our Price is equal to winner price

		await patchProject(e, project, project.id);

		const res = await moveToNextStage(project.id);
		if (res.status === '200' || res.ok) {
			setIsProjectMoved((prevState) => !prevState)
			setOpenModal(false);
		} else {
			alert('Error! Проект не был переведён на следующий этап');
			setOpenModal(false);
		}
	}

	return (
		<ModalWrapper>
			<button className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}>&#10005;</button>
			<form>
				<label className="modal__input-title">Победитель:</label>
				<select className="modal__input input" disabled>
					<option>{competitor.name}</option>
				</select>
				<label className="modal__input-title">Цена победителя:<Asterisk /></label>
				<input className="modal__input input" type="number"
					name="winnerPrice"
					value={project.winnerPrice}
					onChange={(e) => inputChangeHandler(e)}
					onWheel={(e) => e.target.blur()}
				/>

				<h3 className="modal__description">Перевести проект
					<b>&nbsp;{project.customerName}&nbsp;</b>
					на следующий этап?</h3>
				<div className="modal__buttons-wrapper">
					<button className="button button--red"
						disabled={project.winnerPrice <= 0}
						onClick={(e) => projectDataHandler(e)}>
						Да
					</button>
					<button className="button close-button"
						onClick={() => setOpenModal(false)}>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	)
}
export default FiledTenderModal


