import { generateToken } from '../shared/generateToken';
import { api } from 'settings/Api';

export const getCustomerNameByINN = async (inn) => {
  const token = generateToken();
  const url = `/api/account/fetchinfobyinn?inn=${inn}`;

  try {
    const response = await api(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (data.success) {
      return data.organizationName;
    }
    return null;
  }
  catch (e) {
    alert(`Произошла ошибка: ${e}`)
  }

  return null;
}