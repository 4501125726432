import { api } from 'settings/Api';
export const fetchOrganizations = async () => {
  const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;

  try {
    const response = await api('/api/sout/admin/organizations', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })

    const data = await response.json();

    if (data.isSuccess) {
      return data.data;
    }
    if (data.isFailure) {
      throw new Error();
    }
  }
  catch(e) {
    alert(`Не удалось загрузить организации. Ошибка: ${e.message}`)
  }
}