import { api } from "settings/Api";
import { generateToken } from "shared/generateToken";

export const fetchTradingFloors = async (setTradingFloors) => {
    const token = generateToken();

    let response = await api(`/api/sout/handbooks/tradingfloors`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // 'Authorization':  'String(authTokens.access) ,
        },
    });
    let data = await response.json();
    setTradingFloors(data.data);
};