import Sidebar from "./Sidebar";
import StageWrapper from './StageWrapper';

const Workspace = () => {
	return (
		<main className="workspace">
			<Sidebar />
			<div className="workspace__content">
				<StageWrapper />
			</div>
		</main>
	);
};
export default Workspace;
