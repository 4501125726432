import { api, showValidateMessage } from 'settings/Api';
import { generateToken } from "shared/generateToken";

export const addOrEditLocation = async (values, cb, type, locationTypeId) => {
	const token = generateToken();
	const params = {
		add: {
			method: "POST",
			message: "Замерный блок успешно добавлен.",
			errorMessage: "Замерный блок не был создан.",
		},
		edit: {
			method: "PATCH",
			message: "Замерный блок успешно изменён.",
			errorMessage: "Замерный блок не был изменён.",
		},
	};
	const urls = {
		Contract: "contract-measuring-blocks",
		Potential: "potential-measuring-blocks",
	};
	const baseURL = "/api/sout/admin/";
	try {
		const response = await api(baseURL + urls[locationTypeId], {
			method: params[type].method,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(values),
		});
		if (response.status === 200) {
			// alert(params[type].message);
			cb();
		} else {
			// showValidateMessage()
			console.log(response)
			throw new Error(`${response.status}`);
		}
	} catch (e) {
		alert(`${params[type].errorMessage} Ошибка: ${e}`);
	}
};
