import { api } from "settings/Api";
import { generateToken } from "shared/generateToken";

export const getContractStatistic = async (type,fromDate, toDate) => {
    const token = generateToken();

    const url = type === "conclusionDate"?"/api/stats/contracts":"/api/stats/contracts-by-planned-date"

    let response = await api(`${url}?from=${fromDate}&to=${toDate}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            // 'Authorization':  'String(authTokens.access) ,
        },
    });
    let data = await response.json();

    return data;
};