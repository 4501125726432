import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);
setDefaultLocale("ru");

export const DatePicker = ({
  className,
  name,
  id,
  value,
  onChange,
  required,
  min,
  max,
  placeholder,
  disabled,
}) => {
  const handleChange = (date) => {
    onChange(date);
  };
  return value === null ? (
    <>
      <ReactDatePicker
        minDate={min}
        maxDate={max}
        className={className}
        selected={value}
        id={id}
        name={name}
        onSelect={(date) => handleChange(date)}
        onChange={(date) => handleChange(date)}
        dateFormat="DD:MM:YYYY"
        required={required}
        disabled={disabled}
        autoComplete="off"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale={ru}
      />
    </>
  ) : (
    <>
      <ReactDatePicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale={ru}
        minDate={min}
        maxDate={max}
        className={className}
        id={id}
        name={name}
        selected={new Date(value)}
        onChange={(date) => handleChange(date)}
        dateFormat="dd.MM.yyyy"
        required={required}
        placeholderText={placeholder ? placeholder : "дд.мм.гггг"}
        disabled={disabled}
        autoComplete="off"
      />
    </>
  );
};
