import { api } from "settings/Api";
const fetchBranchOffices = async (id) => {
  const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
  let response = await api(
    `/api/sout/helpers/handbooks/branch-offices?organizationId=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    }
  );
  let data = await response.json();

  if (data.isSuccess) {
    return data.data;
  }
  
};

export default fetchBranchOffices;
