import React from 'react'
import { RU_USER } from 'utils/stages'

const WelcomeBlock = ({name = 'User', post = 'user'}) => {
  console.log(RU_USER[post])
  return (
    <div className="welcomeBlock">
        <div className="helloMessage">
            {`Здравствуйте, ${name}`}
        </div>
        <div className="postMessage">
            {`Вы вошли как ${RU_USER[post]}`}
        </div>
  </div>
  )
}

export default WelcomeBlock