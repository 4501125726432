import React, { useState } from "react";

const SelectWithSearch = (props) => {
  const { 
    values,
    isMultipleChoice, 
    onSelect, 
    selectValue, 
    onDelete 
    } = props;

  const [searchStr, setSearchStr] = useState("");
  const [listItem, setListItem] = useState([]);

  const onChange = (e) => {
    setSearchStr(e.target.value);

    if (e.target.value.length >= 2) {
      setListItem(
        values.filter((item) =>
          item.name.toLowerCase().includes(e.target.value.trim().toLowerCase())
        )
      );
    }

    if (e.target.value.length === 0) {
      setListItem([]);
    }
  };

  const onClick = (e) => {
    onSelect(e);
    setSearchStr("");
    setListItem([]);
  };

  return (
    <div style={{ width: "100%" }}>
      {selectValue ? (
        <div style={{display: 'flex', fontSize: 18, textAlign: 'left', justifyContent: 'space-between'}}>
          <div>
            {values?.find((i) => i.id === selectValue)?.name}
          </div>
          <div onClick={onDelete} style={{cursor: 'pointer', marginLeft: 7, fontFamily: 'monospace'}}>x</div>
        </div>
      ) : (
        <input
          className="input"
          autoComplete='off'
          type="text"
          name="searchStr"
          id="searchStr"
          value={searchStr}
          onChange={onChange}
        />
      )}

      <ul onSelect={onSelect} className="SelectWithSearchList">
        {listItem?.map((item) => (
          <li 
          data-tooltip="Всплывающая подсказка"
            onClick={onClick} 
            id={item?.id} 
            key={item?.id} 
            name={item?.name} 
            className="SelectWithSearchItem"
           >
            {item?.name.replace(/общество с ограниченной ответственностью/gi, 'ООО').replace(/\\/g, '')}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectWithSearch;
