import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import Sidebar from "../../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineExternalLink } from "react-icons/hi";
import { api } from "settings/Api";
import { MdEdit } from "react-icons/md";
import { ImCross } from "react-icons/im";
import RejectModal from "./RejectModal";

const ListOfSites = () => {
  const [notes, setNotes] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const { authTokens, logoutUser, updateToken } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    getNotes();
  }, []);

  const rejetcSite = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(
      `/api/sout/handbooks/tradingfloors/${selectedSite}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // 'Authorization':  'String(authTokens.access) ,
        },
      }
    );

    if (response.status === 200) {
      alert("Площадка успешно удалена");
      setNotes([
        ...notes?.filter((item) => item.id !== parseInt(selectedSite)),
      ]);
    } else if (response.status === 401) {
      updateToken();
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    } else if (response.status === 500) {
      alert("Площадка не удалена. Есть тендеры, связанные с этой площадкой");
    }
    setIsShowModal(false);
  };

  const getNotes = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api("/api/sout/handbooks/tradingfloors", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });

    let data = await response.json();

    if (response.status === 200) {
      setNotes(data.data);
    } else if (response.status === 401) {
      updateToken();
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    }
    setNotes(data.data);
  };

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h2 className="workspace__title workspace__page-title">
            Список площадок
          </h2>
          <Link to="/AddSite" className="button workspace__button">
            Добавить площадку
          </Link>
          <section className="workspace__table-wrapper">
            <div className="table-wrapper">
              <table className="table workspace__table">
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Наименование</td>
                    <td>Описание</td>
                    <td>Ссылка</td>
                  </tr>
                </thead>
                <tbody className="table__row--h35px">
                  {notes &&
                    notes.map((note) => (
                      <tr key={note.id}>
                        <td>{note.id}</td>
                        <td>{note.name}</td>
                        <td>{note.description}</td>
                        <td>
                          {note.link ? (
                            <a
                              className="external-link"
                              href={note.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Перейти по ссылке
                              <HiOutlineExternalLink />
                            </a>
                          ) : (
                            " - "
                          )}
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              navigate(`/EditSite/${note?.id}`);
                            }}
                            className={`button button--square`}
                            title="Редактировать"
                          >
                            <MdEdit className="icon-edit" />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedSite(note?.id);
                              setIsShowModal(true);
                            }}
                            className={`button button--square`}
                            title="Удалить"
                          >
                            <ImCross className="icon-edit" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </div>
      {isShowModal && (
        <RejectModal
          onNo={() => {
            setIsShowModal(false);
          }}
          onYes={rejetcSite}
          onClose={() => {
            setIsShowModal(false);
          }}
          text={`Вы действительно хотите удалить площадку ${
            notes?.find((item) => item.id === parseInt(selectedSite))?.name
          }?`}
        />
      )}
    </main>
  );
};
export default ListOfSites;
