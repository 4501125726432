const isDayExists = (arrOfDates) => arrOfDates.length > 2 ? true : false;

const addFirstDay = (YYYYmm) => YYYYmm + '-01';

export const addDayToDateHandler = (date) => {
	if (!date) return;

	const arrOfDates = date.split('-');
	return isDayExists(arrOfDates)
		? date
		: addFirstDay(date);
}

export const removeDayFromDateHandler = (date) => {
	if (!date) return;

	const arrOfDates = date.split('-');
	const newDate = arrOfDates[0] + '-' + arrOfDates[1];
	return newDate;
}