import { useState, useContext, useEffect } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import rejectProject from '../../../../../utils/rejectProject';
import patchProject from '../../../../../utils/patchProject';
import fetchCompetitors from '../../../../../utils/fetchCompetitors';
import Asterisk from '../../../../../components/Asterisk';
import './RejectWithCommentModal';

function RejectSelectWinnerModal({ setOpenModal, inputChangeHandler, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const [competitors, setCompetitors] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isDropdownListShown, setIsDropdownListShown] = useState(false);
  const [matched, setMatched] = useState(false)

  const handleValue = (e) => {
    e.target.value.length >= 2
      ? setIsDropdownListShown(true)
      : setIsDropdownListShown(false)

    inputChangeHandler(e);


    const isMatched = competitors.filter((item) => (item.name.toLowerCase().includes(e.target.value.trim().toLowerCase())))


    if (isMatched && isMatched.length > 0) {
      setMatched(true)
    } else {
      setMatched(false)
    }

    setSearchValue(e.target.value);
  }

  useEffect(() => {
    fetchCompetitors(setCompetitors)
  }, [])

  const projectDataHandler = async (e) => {
    await patchProject(e, project, project.id);
    await rejectProject(project.id)
    setIsProjectMoved((prevState) => !prevState)
    setOpenModal(false)
  }

  const chooseWinnerHandler = (id, name) => {
    const e = {
      target: {
        name: 'winnerId',
        value: id,
      }
    };

    inputChangeHandler(e);
    setSearchValue(name);
    setIsDropdownListShown(false);
  }


  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>
        <label className="modal__input-title">Победитель:<Asterisk /></label>
        <div className="winner-id__wrapper">
          <input
            name="winnerId"
            type="textbox"
            autocomplete="off"
            className="input winner-id__input"
            value={searchValue}
            placeholder="Начните вводить название победителя..."
            onChange={(e) => handleValue(e)}
          />
          {
            isDropdownListShown
              ? <ul className={`winner-id__list ${!matched && 'invisible'}`}>
                {
                  competitors && searchValue.length >= 2
                  && competitors
                    .filter((item) => (
                      item.name
                        .toLowerCase()
                        .includes(searchValue.trim().toLowerCase())
                    ))
                    .map((item) => (
                      <li
                        key={item.id}
                        className='winner-id__list-item'
                        onClick={() => chooseWinnerHandler(item.id, item.name)}
                      >
                        {item.name}
                      </li>
                    ))
                }
              </ul>
              : null
          }
        </div>

        <label className="modal__input-title" style={{ marginTop: '30px' }}>Цена победителя:<Asterisk /></label>
        <input className="modal__input input" type="number"
          name="winnerPrice"
          value={project.winnerPrice}
          onChange={(e) => inputChangeHandler(e)}
          onWheel={(e) => e.target.blur()}
        />
        <h3 className="modal__description">Удалить проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          ?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            disabled={Number.isNaN(parseInt(project.winnerId)) || project.winnerPrice <= 0}
            onClick={(e) => projectDataHandler(e)}>
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper>
  )
}
export default RejectSelectWinnerModal


