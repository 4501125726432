import { useEffect, useState } from "react";
import ActionButton from "./ActionButton";
import ModalWrapper from "./modals/ModalWrapper";
import { MdEdit, MdTextSnippet } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaInfo, FaArrowLeft, FaArrowRight, FaPause } from "react-icons/fa";
import { ConfirmActionModal } from "./modals/ConfirmActionModal";
import DocumentsModal from "pages/sout/commercial/modals/documents/DocumentsModal";
import { canStop } from "utils/defineUser";
import { suspendProject } from "utils/suspendProject";

export const ActionButtons = (props) => {
  const {
    disabledButtons,
    onEdit,
    editURL,
    stage,
    contract,
    reject,
    moveToPrevStage,
    moveToNextStage,
    getInfoModal,
    documentsInfo,
    suspendContract,
    colorTheme,
    location,
    entityTypeName,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const [openDocsModal, setOpenDocsModal] = useState(false);
  const [modalType, setModalType] = useState();
  const [colorThemeClass, setColorThemeClass] = useState("");

  const modalHandler = async (modalType) => {
    if (!openModal) {
      setModalType(modalType);
      setOpenModal(true);
      return;
    }

    setModalType("");
    setOpenModal(false);
  };

  useEffect(() => {
    if (colorTheme) {
      setColorThemeClass(`button--${colorTheme}`);
    }
  }, [colorTheme]);

  return (
    <>
      <ActionButton
        isDisabled={disabledButtons["edit"].includes(
          contract?.workflow?.workFlowStatusId
        )}
        iconType={() => <MdEdit className="icon-edit" />}
        title="Редактировать"
        className={colorThemeClass}
        onAction={() => {
          onEdit();
        }}
      ></ActionButton>
      {/* {stage === "PreparationAndExecution" && canStop() && (
        <ActionButton
          isDisabled={!disabledButtons["suspend"]}
          modalType="suspend"
          iconType={() => <FaPause className="icon-edit" />}
          title="Приостановить"
          className={colorThemeClass}
          onAction={modalHandler}
        ></ActionButton>
      )} */}
      <ActionButton
        isDisabled={disabledButtons["trash"].includes(
          contract?.workflow?.workFlowStatusId
        )}
        onAction={modalHandler}
        modalType="reject"
        iconType={() => <ImCross className="icon-delete" />}
        title="Удалить проект"
        className={colorThemeClass}
      ></ActionButton>

      <ActionButton
        isDisabled={disabledButtons["info"].includes(
          contract?.workflow?.workFlowStatusId
        )}
        onAction={modalHandler}
        modalType="showInfo"
        iconType={() => <FaInfo className="icon-info" />}
        title="Информация о проекте"
        className={colorThemeClass}
      ></ActionButton>

      <ActionButton
        isDisabled={disabledButtons["movePrev"].includes(
          contract?.workflow?.workFlowStatusId
        )}
        onAction={modalHandler}
        iconType={() => <FaArrowLeft className="icon-move" />}
        title="Перевести на предыдущий этап"
        modalType="movePrev"
        className={colorThemeClass}
      ></ActionButton>

      <ActionButton
        isDisabled={disabledButtons["moveNext"].includes(
          contract?.workflow?.workFlowStatusId
        )}
        onAction={modalHandler}
        iconType={() => <FaArrowRight className="icon-move" />}
        title="Перевести на следующий этап"
        modalType="moveNext"
        className={colorThemeClass}
      ></ActionButton>

      <ActionButton
        isDisabled={disabledButtons["documents"].includes(
          contract?.workflow?.workFlowStatusId
        )}
        onAction={() => setOpenDocsModal(true)}
        iconType={() => <MdTextSnippet className="icon-document" />}
        title="Документы"
        modalType="showDocs"
        className={colorThemeClass}
      ></ActionButton>
      {openModal ? (
        <ModalWrapper>
          {modalType === "showInfo" && getInfoModal(modalHandler)}
          {modalType === "suspend" && (
            <ConfirmActionModal
              stage={stage}
              setOpenModal={setOpenModal}
              contractId={contract?.id}
              moveType={modalType}
              onYes={() => {
                suspendProject();
              }}
              onNo={modalHandler}
              colorThemeClass={colorThemeClass}
            />
          )}
          {modalType === "reject" && (
            <ConfirmActionModal
              stage={stage}
              setOpenModal={setOpenModal}
              contractId={contract?.id}
              moveType={modalType}
              onYes={() => {
                reject();
              }}
              onNo={modalHandler}
              colorThemeClass={colorThemeClass}
            />
          )}
          {modalType === "moveNext" && (
            <ConfirmActionModal
              stage={stage}
              moveType={modalType}
              setOpenModal={setOpenModal}
              contractId={contract?.id}
              onYes={() => {
                moveToNextStage();
              }}
              onNo={modalHandler}
              colorThemeClass={colorThemeClass}
            />
          )}
          {modalType === "movePrev" && (
            <ConfirmActionModal
              stage={stage}
              moveType={modalType}
              contractId={contract?.id}
              setOpenModal={setOpenModal}
              onYes={() => {
                moveToPrevStage();
              }}
              onNo={modalHandler}
              colorThemeClass={colorThemeClass}
            />
          )}
        </ModalWrapper>
      ) : null}
      {openDocsModal && (
        <DocumentsModal
          modelId={documentsInfo.id}
          entity={documentsInfo.entity}
          documents={documentsInfo.documents}
          setOpenModal={setOpenDocsModal}
        />
      )}
    </>
  );
};
