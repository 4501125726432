import { useContext } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import rejectProject from '../../../../../utils/rejectProject';
import patchProject from '../../../../../utils/patchProject';
import Asterisk from '../../../../../components/Asterisk';

function RejectWithCommentModal({ setOpenModal, inputChangeHandler, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const projectDataHandler = async (e) => {
    await patchProject(e, project, project.id);
    await rejectProject(project.id)
    setIsProjectMoved((prevState) => !prevState)
    setOpenModal(false)
  }

  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>
        <label className="modal__input-title">Комментарий о причине неподачи:<Asterisk /></label>
        <textarea className="input modal__input input" cols="50" rows="5"
          name="failrueDescription"
          value={project.failrueDescription}
          onChange={(e) => inputChangeHandler(e)}
        />
        <h3 className="modal__description">Удалить проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          ?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            disabled={project.failrueDescription === '' ? true : false}
            onClick={(e) => projectDataHandler(e)}>
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper>
  )
}
export default RejectWithCommentModal


