import { useState, useEffect } from "react";
import Asterisk from "components/Asterisk";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
import { api } from 'settings/Api';

function SearchSelectRegions({
	regions,
	color,
	updateRegions,
	isSearchBar,
	disabled = false,
	required = false,
}) {
	const [searchValue, setSearchValue] = useState("");
	const [toggler, setToggler] = useState(false);

	const [regionsList, setRegionsList] = useState(null);

	useEffect(() => {
		fetchRegions();
	}, []);

	const fetchRegions = async () => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		let response = await api("/api/sout/helpers/handbooks/regions", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		let data = await response.json();
		setRegionsList(data.data);
	};

	const toggleList = (e) => {
		e.preventDefault();
		setToggler(!toggler);
	};

	const inputHandler = (e) => setSearchValue(e.target.value);

	const addRegion = (region) => {
		let choosedNames = [];

		if (!isSearchBar) {
			if (regions) {
				choosedNames = regions.map((item) => item.name);
			}
			if (!choosedNames.includes(region.name)) {
				regions ? updateRegions([...regions, region]) : updateRegions([region]);

				setToggler(false);
				setSearchValue("");
				return true;
			} else {
				setSearchValue("");
				return false;
			}
		}

		if (isSearchBar && regions.length < 1) {
			if (regions) {
				choosedNames = regions.map((item) => item.name);
			}

			if (!choosedNames.includes(region.name)) {
				regions ? updateRegions([...regions, region]) : updateRegions(region);

				setToggler(false);
				setSearchValue("");
				return true;
			} else {
				setToggler(false);
				setSearchValue("");
				return false;
			}
		}

		if (isSearchBar && regions.length > 1) {
			setToggler(false);
			setSearchValue("");
		}
	};

	const removeRegion = (region) => {
		const deleteIdx = regions.indexOf(region);
		const updatedRegions = [...regions];
		updatedRegions.splice(deleteIdx, 1);
		updateRegions(updatedRegions);
	};

	return (
		<div
			className="regions"
			style={{
				marginTop: 5,
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			<button
				className={`button  button--${color}`}
				style={{
					width: 320,
					height: 40,
				}}
				disabled={
					(isSearchBar && regions && regions.length > 0 && true) || disabled
				}
				onClick={toggleList}
			>
				Выбрать регион
			</button>
			<div
				style={{
					width: 505,
					position: "relative",
					marginLeft: 5,
					top:'5px'
				}}
			>
				<input
					type="search"
					className="input regions__search"
					value={searchValue}
					placeholder="Введите название..."
					onChange={inputHandler}
					disabled={disabled}
				/>
				{regionsList && searchValue.length >= 2 && (
					<ul className="search_regions_list">
						{regionsList
							.filter((region) =>
								region.name
									.toLowerCase()
									.includes(searchValue.trim().toLowerCase())
							)
							.map((region) => (
								<li className="regions__item" onClick={() => addRegion(region)}>
									{region.name}
								</li>
							))}
					</ul>
				)}

				<div className="regions__keywords">
					{regions &&
						regions.length > 0 &&
						regions.map((region) => {
							return (
								<li
									key={region.id}
									className="keywords-list__item"
									style={{ marginBottom: 0 }}
								>
									<span>{region.name}</span>
									<span
										className="keywords-list__delete-item"
										onClick={() => removeRegion(region)}
										title="Удалить"
									>
										&#x2716;
									</span>
								</li>
							);
						})}
				</div>
			</div>
		</div>
	);
}

export default SearchSelectRegions;
