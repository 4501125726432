import { Portal } from "components/Portal/Portal";
import ModalWrapper from "components/modals/ModalWrapper";
import { Select } from "components/Select/Select";
import { Button } from "shared/ui/Button/Button";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContractContext from "context/ContractContext";
import styled from "styled-components";
import { isTenderUser, isCommercialUser, isAdmin } from "utils/defineUser";
import { api } from "settings/Api";
import Spinner from "components/Spinner";

export const ContractConclusionDepartmentChoiceModal = ({
  close,
  required,
}) => {
  const navigate = useNavigate();

  const { initTempContract, updateTempContract } = useContext(ContractContext);

  const [contractConclusionDepartmentId, setContractConclusionDepartmentId] =
    useState();
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [personInChargeId, setPersonInChargeId] = useState([]);

  const onClick = () => {
    const user = employees.find((user) => user.id == personInChargeId);
    initTempContract(contractConclusionDepartmentId, user);
    navigate(`/sout/contracts/add/new`);
  };

  useEffect(() => {
    if (isTenderUser()) {
      initTempContract("TenderDepartment");
    }
    if (isCommercialUser()) {
      initTempContract("CommercialDepartment");
    }
  }, []);

  useEffect(() => {
    if (isAdmin()) {
      const fetchEmployees = async () => {
        const token = JSON.parse(localStorage.getItem("authTokens")).data
          .accessToken;
        setIsLoading(true);
        api("/api/sout/admin/users", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .then((res) => {
            const data = res;
            setEmployees(
              data.data.filter(
                (user) => user.departmentId === contractConclusionDepartmentId
              )
            );
            setIsLoading(false);
          });
      };
      fetchEmployees();
    }
  }, [contractConclusionDepartmentId]);

  const Content = styled.div`
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 30vw;
  `;

  const Buttons = styled.div`
    display: flex;
    justify-content: ${(props) =>
      props.required ? "center" : "space-between"};
    width: 60%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 20px;
  `;

  return (
    <Portal>
      <ModalWrapper>
        <Content>
          {isCommercialUser() || isTenderUser() ? (
            <Spinner
              style={{
                margin: "10vh auto",
              }}
            />
          ) : (
            <>
              <Select
                className={`workspace__input-title selectInput`}
                label="Отдел заключения договоров"
                name="ContractConclusionDepartmentId"
                dataSrc="local"
                onChange={(e) =>
                  setContractConclusionDepartmentId(e.target.value)
                }
                value={contractConclusionDepartmentId}
                data={{
                  values: {
                    TenderDepartment: "Тендерный отдел",
                    CommercialDepartment: "Коммерческий отдел",
                  },
                }}
              />
              <br />
              {isAdmin() &&
                (isLoading ? (
                  <Spinner />
                ) : (
                  <Select
                    className="workspace__input-title selectInput"
                    required={true}
                    label="Ответственный сотрудник"
                    name={contractConclusionDepartmentId}
                    dataSrc="server"
                    data={employees}
                    value={personInChargeId}
                    onChange={(e) => setPersonInChargeId(e.target.value)}
                  />
                ))}
              <Buttons required={required}>
                <Button color="blue" onClick={onClick}>
                  Создать
                </Button>
                <Button color="blue" onClick={close}>
                  Отмена
                </Button>
              </Buttons>
            </>
          )}
        </Content>
      </ModalWrapper>
    </Portal>
  );
};
