import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COMMERCIAL_STAGES } from "../../../../utils/stages";
import Sidebar from "../../../../components/Sidebar";
import fetchProject from "../../../../utils/fetchProject";
import patchProject from "../../../../utils/patchProject";
import fetchCompetitors from "../../../../utils/fetchCompetitors";
import {
	TENDER_DEFAULT_USER_ID,
	COM_DEFAULT_USER_ID,
	LAB_DEFAULT_USER_ID,
} from "../../../../utils/DefaultUsersID";

import SelectRegions from "../../../../components/common/SelectRegions";
import {
	isAccountantEmployee,
	isAccountantUser,
	isAdmin,
	isCommercialUser,
	isLabEmployee,
	isLabExpert,
	isLabHead,
	isSoutUserArea,
} from "../../../../utils/defineUser";
import { api } from 'settings/Api';

const EditProjectCom = () => {
	const location = useLocation();
	const { data } = location.state;
	const projectId = data;

	const navigate = useNavigate();

	const [values, setUpdatdedValues] = useState("");
	const [tradingFloors, getTradingFloors] = useState("");
	const [competitors, setCompetitors] = useState("");
	const [tenderUsers, getTenderUsers] = useState("");
	const [commercialUsers, getCommercialUsers] = useState("");
	const [laboratoryUsers, getLaboratoryUsers] = useState("");
	const [workers, getWorkers] = useState("");
	const [isAssessmentTypeDisabled, setIsAssessmentTypeDisabled] = useState("");

	const handleFetchedValues = (values) => setUpdatdedValues(values);

	useEffect(() => {
		fetchProject(projectId, handleFetchedValues, "edit");
		fetchUsers("TenderDepartment");
		fetchUsers("CommercialDepartment");
		// fetchUsers('Laboratory');
		fetchCompetitors(setCompetitors);
		fetchTradingSites();
		// fetchWorkers();
	}, []);

	const inputChangeHandler = (e) => {
		const { name, value } = e.target;
		setUpdatdedValues({
			...values,
			[name]: value,
		});
	};

	const updateRegions = (updatedRegions) =>
		setUpdatdedValues({ ...values, regions: updatedRegions });

	let fetchUsers = async (departmentName) => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		let response = await api(
			`/api/sout/helpers/handbooks/engineers?department=${departmentName}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					// 'Authorization':  'String(authTokens.access) ,
				},
			}
		);
		let data = await response.json();

		switch (departmentName) {
			case "TenderDepartment":
				getTenderUsers(data.data);
				break;
			case "CommercialDepartment":
				getCommercialUsers(data.data);
				break;
			case "Laboratory":
				getLaboratoryUsers(data.data);
				break;
			default:
				throw new Error("Fetch error: department cannot found!");
		}
	};

	let fetchTradingSites = async () => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;

		let response = await api(`/api/sout/helpers/handbooks/tradingfloors`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				// 'Authorization':  'String(authTokens.access) ,
			},
		});
		let data = await response.json();
		getTradingFloors(data.data);
	};

	const isOnStageOrPassed = (stageId) => {
		return COMMERCIAL_STAGES.indexOf(values.projectStage) >= stageId;
	};

	return (
		<main className="workspace">
			<Sidebar />
			<div className="workspace__content">
				<section className="workspace__wrapper">
					<h3 className="title workspace__page-title">
						Редактировать проект | Коммерческий отдел
					</h3>
					<form className="edit-page__form">
						<section className="form__column">
							<fieldset className="workspace__ensure-wrapper">
								<h4 className="subtitle">Заказчик</h4>
								<label className="workspace__input-title">
									Наименование заказчика:
								</label>
								<input
									className="input"
									type="text"
									disabled={isOnStageOrPassed(0)}
									name="customerName"
									value={values.customerName}
									onChange={(e) => inputChangeHandler(e)}
								/>
								<label className="workspace__input-title">
									Дополнительное наименование:
								</label>
								<input
									className="input"
									type="text"
									name="additionalCustomerName"
									value={values.additionalCustomerName}
									onChange={(e) => inputChangeHandler(e)}
								/>
								<label className="workspace__input-title">ИНН заказчика:</label>
								<input
									className="input"
									disabled={isOnStageOrPassed(0)}
									type="text"
									maxLength="12"
									name="inn"
									value={values.inn}
									onChange={(e) => inputChangeHandler(e)}
								/>
								{
									!isAccountantEmployee() && (
										<>
											<label className="workspace__input-title">
												Контактные данные:
											</label>
											<textarea
												className="input"
												rows="7"
												name="contactData"
												value={values.contactData}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</>
									)
								}
							</fieldset>

							<fieldset className="workspace__ensure-wrapper">
								<h4 className="subtitle">Контракт</h4>
								<label className="workspace__input-title">
									Номер контракта:
								</label>
								<input
									className="input"
									type="text"
									name="contractNumber"
									value={values.contractNumber}
									onChange={(e) => inputChangeHandler(e)}
									disabled={isOnStageOrPassed(2) && !isAdmin()}
								/>
								{
									(isLabHead() || isAccountantUser() || isCommercialUser())
									&&
									<>
										<label className="workspace__input-title">Номер акта:
										</label>
										<input
											className="input"
											type="text"
											name="actNumber"
											value={values.actNumber}
											onChange={(e) => inputChangeHandler(e)}
											disabled={isOnStageOrPassed(10) && !isAdmin()}
										/>
									</>
								}
								{!isLabEmployee() && !isAccountantEmployee() && !isLabExpert() && (
									<>
										<label className="workspace__input-title">
											Цена контракта:
										</label>
										<input
											className="input"
											type="number"
											name="price"
											value={values.price}
											onChange={(e) => inputChangeHandler(e)}
											onWheel={(e) => e.target.blur()}
											disabled={isOnStageOrPassed(2) && !isAdmin()}
										/>

										<label className="workspace__input-title">
											Сумма по акту:
										</label>
										<input
											className="input"
											type="number"
											name="actAmount"
											value={values.actAmount}
											onChange={(e) => inputChangeHandler(e)}
											onWheel={(e) => e.target.blur()}
											disabled={isOnStageOrPassed(10) && !isAdmin()}
										/>
									</>
								)}
								{
									(isCommercialUser() || isAdmin())
									&&
									<>
										<label className="workspace__input-title">
											КПП:
										</label>
										<input
											className="input"
											type="text"
											name="KPP"
											value={values.KPP}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</>
								}
								{
									isLabHead() &&
									<label className="workspace__input-title">
										Замеры:
										<select
											className="select"
											name="measurementStatusId"
											value={values.measurementStatusId}
											onChange={(e) => inputChangeHandler(e)}
										>
											<option value="NotAssigned">Не назначены</option>
											<option value="NotRequired">Не требуются</option>
											<option value="Assigned">Назначены</option>
											<option value="Complited">Проведены</option>
										</select>
									</label>
								}
							</fieldset>
						</section>
						<section className="form__column">
							<fieldset className="workspace__ensure-wrapper">
								{!isAccountantEmployee() && (
									<>
										<h4 className="subtitle workspace__subtitle">Даты</h4>
										<label className="workspace__input-title">
											Дата заключения договора:
										</label>
										<input
											className="input"
											type="date"
											disabled={isOnStageOrPassed(2) && !isAdmin()}
											name="conclusionContractDate"
											value={values.conclusionContractDate}
											onChange={(e) => inputChangeHandler(e)}
										/>

										<label className="workspace__input-title">
											Плановая дата исполнения:
										</label>
										<input
											className="input"
											type="date"
											disabled={isOnStageOrPassed(2) && !isAdmin()}
											name="plannedExecutionDate"
											value={values.plannedExecutionDate}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</>
								)}

								{!isLabEmployee() && !isAccountantEmployee() && !isLabExpert() && (
									<>
										<label className="workspace__input-title">
											Дата акта:
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(10) && !isAdmin()}
												name="actDate"
												value={values.actDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата оплаты:
										</label>
										<input
											className="input"
											type="date"
											disabled={isOnStageOrPassed(11) && !isAdmin()}
											name="paidDate"
											value={values.paidDate}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</>
								)}

								{((!isCommercialUser() && !isAccountantEmployee()) || isAdmin()) && (
									<>
										<label className="workspace__input-title">
											Дата получения информации:
											<input
												className="input"
												type="date"
												name="informationCollectionDate"
												value={values.informationCollectionDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата получения полной информации:
											<input
												className="input"
												type="date"
												name="informationCollectedAt"
												value={values.informationCollectedAt}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата приема в работу:
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(5) && !isAdmin()}
												name="acceptedToWorkDate"
												value={values.acceptedToWorkDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата отправки отчета:
											<input
												className="input"
												type="date"
												name="sentDate"
												value={values.sentDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата выгрузки:
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(9) && !isAdmin()}
												name="unloadingDate"
												value={values.unloadingDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											{" "}
											Дата замеров:
											<input
												className="input"
												type="date"
												name="measurementsDate"
												value={values.measurementsDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата назначения исполнителя:
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(4) && !isAdmin()}
												name="performerAppointedDate"
												value={values.performerAppointedDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата отправки на согласование:
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(6) && !isAdmin()}
												name="approvalsDate"
												value={values.approvalsDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата отправки отчета:
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(7) && !isAdmin()}
												name="sentDate"
												value={values.sentDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
										<label className="workspace__input-title">
											Дата подписания титульного листа:
											<input
												className="input"
												type="date"
												disabled={isOnStageOrPassed(8) && !isAdmin()}
												name="titleSheetSignDate"
												value={values.titleSheetSignDate}
												onChange={(e) => inputChangeHandler(e)}
											/>
										</label>
									</>
								)}
							</fieldset>
							{!isLabHead() && (
								<fieldset className="workspace__ensure-wrapper">
									<label className="workspace__input-ensure">
										Акт получен?:
									</label>
									<input
										id="ActRecived"
										className="radio"
										type="radio"
										name="actHasReached"
										value="true"
										checked={values.actHasReached === "true"}
										onChange={(e) => inputChangeHandler(e)}
									/>
									<label htmlFor="ActRecived" className="radio__label">
										Получен
									</label>

									<input
										id="ActDidNotRecived"
										className="radio"
										type="radio"
										name="actHasReached"
										value="false"
										checked={values.actHasReached === "false"}
										onChange={(e) => inputChangeHandler(e)}
									/>
									<label
										htmlFor="ActDidNotRecived"
										className="radio__label radio__label--ml"
										style={{ width: "200px" }}
									>
										Не получен
									</label>
								</fieldset>
							)}
						</section>

						<section className="form__column">
							<div className="workspace__ensure-wrapper">
								{isSoutUserArea() && !isAccountantEmployee() && (
									<>
										<h4 className="subtitle workspace__subtitle">Прочее</h4>
										<label className="workspace__input-title">
											Идентификационный номер:
										</label>
										<input
											className="input"
											type="text"
											name="alternateId"
											value={values.alternateId}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</>
								)}
								{!isAccountantEmployee() && (
									<>
										<label className="workspace__input-title">
											Комментарий к исполнению:
										</label>
										<textarea
											className="input"
											rows="7"
											name="executionDescription"
											value={values.executionDescription}
											onChange={(e) => inputChangeHandler(e)}
										/>
									</>
								)}
								{isSoutUserArea() && !isAccountantEmployee() && (
									<>
										<fieldset className="workspace__ensure-wrapper">
											<label className="workspace__input-ensure">
												Тип оценки:
											</label>
											<input
												id="sout"
												className="radio"
												type="radio"
												name="assesmentTypeId"
												value="WorkingConditionsSpecialAssesment"
												checked={
													values.assesmentTypeId ===
													"WorkingConditionsSpecialAssesment"
												}
												onChange={(e) => inputChangeHandler(e)}
												disabled={isOnStageOrPassed(1) && !isAdmin()}
											/>
											<label
												htmlFor="sout"
												className={`radio__label ${isOnStageOrPassed(1) &&
													!isAdmin() &&
													"radio__label--grey"
													}`}
											>
												СОУТ
											</label>

											<input
												id="pc"
												className="radio"
												type="radio"
												name="assesmentTypeId"
												value="ProductionControl"
												checked={values.assesmentTypeId === "ProductionControl"}
												onChange={(e) => inputChangeHandler(e)}
												disabled={isOnStageOrPassed(1) && !isAdmin()}
											/>
											<label
												htmlFor="pc"
												className={`radio__label radio__label--ml ${isOnStageOrPassed(1) &&
													!isAdmin() &&
													"radio__label--grey"
													}`}
											>
												ПК
											</label>
										</fieldset>
										<h4 className="subtitle workspace__subtitle">
											Ответственные
										</h4>
										<label className="workspace__input-title">
											Коммерческий отдел:
										</label>
										<select
											className="input search-area__select"
											name="commercialUserId"
											value={values.commercialUserId}
											onChange={(e) => inputChangeHandler(e)}
											disabled={isAdmin() ? false : true}
										>
											<option value={COM_DEFAULT_USER_ID}>
												Неизвестный сотрудник
											</option>
											{commercialUsers &&
												commercialUsers
													.filter((item) => item.id !== COM_DEFAULT_USER_ID)
													.map(({ id, name }) => (
														<option key={id} value={id}>
															{" "}
															{name}{" "}
														</option>
													))}
										</select>

										<label className="workspace__input-title">
											Число рабочих мест:
										</label>
										<input
											className="input"
											type="number"
											name="workspaceCount"
											value={values.workspaceCount}
											onChange={(e) => inputChangeHandler(e)}
											onWheel={(e) => e.target.blur()}
										/>

										<SelectRegions
											regions={values.regions}
											updateRegions={updateRegions}
										/>
									</>
								)}
								{localStorage.getItem("__user-area") === "med" && (
									<>
										<fieldset className="workspace__ensure-wrapper evaluation-type">
											<label className="workspace__input-title">
												Количество людей:
											</label>
											<input
												className="input"
												type="number"
												name="peopleForMedicalExamination"
												value={values.peopleForMedicalExamination}
												onChange={(e) => inputChangeHandler(e)}
												onWheel={(e) => e.target.blur()}
											/>
										</fieldset>

										<h4 className="subtitle  ">Ответственные</h4>

										<label className="workspace__input-title">
											Коммерческий отдел:
										</label>
										<select
											className="input search-area__select"
											name="commercialUserId"
											value={values.commercialUserId}
											onChange={(e) => inputChangeHandler(e)}
											disabled={isAdmin() ? false : true}
										>
											<option value={COM_DEFAULT_USER_ID}>
												Неизвестный сотрудник
											</option>
											{commercialUsers &&
												commercialUsers
													.filter((item) => item.id !== COM_DEFAULT_USER_ID)
													.map(({ id, name }) => (
														<option key={id} value={id}>
															{" "}
															{name}{" "}
														</option>
													))}
										</select>
									</>
								)}
							</div>
							<input
								type="button"
								onClick={async (e) => {
									await patchProject(e, values, projectId);
									navigate(-1);
								}}
								className="button staff__create-staff-button"
								value="Сохранить"
							/>
						</section>
					</form>
				</section>
			</div>
		</main>
	);
};
export default EditProjectCom;
