import ModalWrapper from '../../../../components/modals/ModalWrapper'
import { useState } from 'react';
import { api } from 'settings/Api';

const AgentReportsModal = (props) => {
	const { modalToggler, toggleIsReportsUpdated, reportsIsUpdated } = props;
	const [queries, setQueries] = useState({});

	const inputQueriesHandler = (e) => {
		const { value, name } = e.target;
		setQueries({
			...queries,
			[name]: value,
		})
	};

	const generateReport = async () => {
		const url = `api/reports/monthly?reportDate=${queries.reportDate}-01&reportNumber=${queries.reportNumber}&date=${queries.date}`;

		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;

		try {
			let response = await api(url, {
				method: 'GET',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok) {
				const message = `An error has occured: ${response.status}`;
				alert('Отчет не сформирован!');
				console.error(message);
				return;
			}

			alert('Отчёт успешно сформирован!');
			toggleIsReportsUpdated(!reportsIsUpdated);
			modalToggler(false);
		} catch (err) {
			alert('Ошибка. Отчет не сформирован!');
			console.error(err);
			return;
		}
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => modalToggler(false)}
			>
				&#10005;
			</button>

			<label className="modal__input-title">Номер отчёта:</label>
			<input
				className="input modal__input"
				type="text"
				name='reportNumber'
				value={queries.reportNumber}
				onChange={(e) => inputQueriesHandler(e)}
			/>

			<label className="modal__input-title">Дата отчета:</label>
			<input
				className="input modal__input"
				type="month"
				name='reportDate'
				value={queries.reportDate}
				onChange={(e) => inputQueriesHandler(e)}
			/>

			<label className="modal__input-title">Отчёт от:</label>
			<input
				className="input modal__input"
				type="date"
				name='date'
				value={queries.date}
				onChange={(e) => inputQueriesHandler(e)}
			/>

			<div className="modal__buttons-wrapper">
				<button className="button button--red"
					onClick={generateReport}
					disabled={
						!queries.reportDate ||
						!queries.reportNumber ||
						!queries.date
					}
				>
					Сформировать отчёт
				</button>
				<button
					className="button close-button"
					onClick={() => modalToggler(false)}
				>
					Закрыть
				</button>
			</div>
		</ModalWrapper>
	);
}

export default AgentReportsModal