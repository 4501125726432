import { generateToken } from "shared/generateToken";
import { api } from 'settings/Api';

export const getDocuments = async (entity, modelId) => {
	const token = generateToken();

	const link = `/api/sout/admin/${entity}?id=${modelId}`;

	try {
		const response = await api(link, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.status !== 200) throw new Error(response.status);

		const data = await response.json();
		let depDoc = entity === 'contracts'
			?[...data.data.list[0].departments.map((x) => x?.document)].filter(x => x !== undefined)
			:[]
		
		let result = entity === 'contracts'
		?[...data.data.list[0].documents.map((x) => {
			return { ...x, status: "not-downloaded" };
		}), ...depDoc.map(x => {return{ ...x, status: "not-downloaded" }})]
		:data.data.list[0].documents.map((x) => {
			return { ...x, status: "not-downloaded" };
		});
		return result;
	} catch (e) {
		alert("Ошибка получения документов: " + e.message);
	}
};
