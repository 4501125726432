export const buildValidation = (params) => {
	const {
		type,
		stage,
		isNotariusChecked,
		assesmentTypesLength,
		customerINN,
		personInChargeId,
		customerNameViaINN,
		customer,
		departments,
		isOPRorPK
	} = params;
	let conditions = [
		{
			statement: () => assesmentTypesLength === 0,
			text: "Выберите хотя бы один тип оценки",
		},
		{
			statement: () => !personInChargeId,
			text: "Вы должны выбрать хотя бы одного ответственного работника",
		},
		{
			statement: () => customer?.inn === null || customer?.name === null,
			text: "ИНН и Наименование заказчика должны быть заполнены",
		},
		{
			statement: () => (type === 'edit' && departments?.length === 0 && stage !== 'Draft' && stage !== "IdAssigning" && isOPRorPK()),
			text: "Договор должен содержать хотя бы одно подразделение",
		},
	];
	if (!isNotariusChecked) {
		conditions.push({
			statement: () => customerNameViaINN === null,
			text: "Заказчика не существует",
		});
	}

  return conditions;
};
