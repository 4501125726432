import { getPatchBaseUrl } from "./BaseUrl";
import { addDayToDateHandler } from './addFirstDayToDate';
import { api } from 'settings/Api';

const patchProject = async (e, values, projectId) => {

	e.preventDefault();

	const fetchSingleProject = async () => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		let FETCH_URL = "";

		if (localStorage.getItem("__user-area") === "sout") {
			FETCH_URL = `/api/sout/admin/projects/${projectId}`;
		}

		if (localStorage.getItem("__user-area") === "med") {
			FETCH_URL = `/api/med/project/${projectId}`;
		}

		let response = await api(FETCH_URL, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		let data = await response.json();
		const workFlowId = data.data.workFlow.typeId;
		return workFlowId;
	};

	const workFlowId = await fetchSingleProject();

	const getSecurityReturnedValue = (value) => {
		if (value === undefined) return null;
		if (value === null) return null;
		if (value === "true" || value === true) return true;
		if (value === "false" || value === false) return false;
	};

	let body = {};

	if (workFlowId === "CommercialWorkFlow") {
		if (localStorage.getItem("__user-area") === "sout") {
			body = {
				id: values.id,
				archiveId: null,
				assesmentTypeId: values?.assesmentTypeId,
				haveWorkbook: values.haveWorkbook === "true",
				executionProgramId: values.executionProgramId,
				unitCount: parseInt(values.unitCount),
				workspaceCount: parseInt(values.workspaceCount),
				contract: {
					id: values.id,
					number: values.contractNumber,
					customer: {
						id: null,
						inn: values.inn === "" ? null : values.inn,
						name: values.customerName === "" ? null : values.customerName,
						contactData: values.contactData === "" ? null : values.contactData,
					},
					price: parseFloat(values.price),
					actAmount: values.actAmount,
					postage: 0,
					securityAmount: parseFloat(values.securityAmount),
					tradingFloorCommissionAmount: parseFloat(
						values.tradingFloorCommissionAmount
					),
					offerSecurityAmount: parseFloat(values.offerSecurityAmount),
					payments: null,
					conclusionContractDate: values.conclusionContractDate,
					plannedExecutionDate: values.plannedExecutionDate,
					description: null,
					additionalCustomerName: values.additionalCustomerName,
					actDate: values.actDate,
					paidDate: values.paidDate,
					paymentsSum: values.paymentsSum,
					paymentsStatusId: values.paymentsStatusId,
					actNumber: values.actNumber
				},
				tender: {
					id: values.id,
					tradingFloor: {
						id: parseInt(values.tradingFloorId),
					},
					tenderId: values.tenderId,
					publicationDate: values.publicationDate,
					acceptingOffersDeadline: values.acceptingOffersDeadline,
					startMaxPrice: parseFloat(values.startMaxPrice),
					offerAmount: parseFloat(values.offerAmount),
					description: values.description,
					auctionDate: values.auctionDate,
					failrueDescription: values.failrueDescription,
					offerSecuringMethodId: values.offerSecuringMethodId,
					contractSecuringMethodId: values.contractSecuringMethodId,
					tradingFloorCommisionId: values.tradingFloorCommisionId,
					winnerPrice: parseFloat(values.winnerPrice),
					winner: {
						id: parseInt(values.winnerId),
					},
				},
				documents: null,
				users: [],
				worker: {
					id: parseInt(values.workerId),
				},
				workFlow: {
					id: values.id,
					typeId: values.departmentType,
					currentWorkFlowStageId: "Draft",
					projectStatusId: "Draft",
					isActive: true,
				},
				informationCollectedAt: values.informationCollectedAt,
				alternateId: values.alternateId,
				executionDescription: values.executionDescription,
				regions: values.regions,
				informationCollectionDate: values.informationCollectionDate,
				acceptedToWorkDate: values.acceptedToWorkDate,
				sentDate: values.sentDate,
				unloadingDate: values.unloadingDate,
				measurementsDate: values.measurementsDate,
				performerAppointedDate: values.performerAppointedDate,
				approvalsDate: values.approvalsDate,
				titleSheetSignDate: values.titleSheetSignDate,
				actHasReached: values.actHasReached === "true",
				monthlyReportDate: addDayToDateHandler(values.monthlyReportDate),
				KPP: values.KPP,
				MeasurementStatusId: values.measurementStatusId
			};

			values.tenderUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.tenderUserId),
					departmentId: "TenderDepartment",
				});

			values.commercialUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.commercialUserId),
					departmentId: "CommercialDepartment",
				});

			values.laboratoryUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.laboratoryUserId),
					departmentId: "Laboratory",
				});
		}

		if (localStorage.getItem("__user-area") === "med") {
			body = {
				id: values.id,
				archiveId: null,
				unitCount: parseInt(values.unitCount),
				contract: {
					id: values.id,
					number: values.contractNumber,
					customer: {
						id: null,
						inn: values.inn === "" ? null : values.inn,
						name: values.customerName === "" ? null : values.customerName,
						contactData: values.contactData === "" ? null : values.contactData,
					},
					actAmount: values.actAmount,
					price: parseFloat(values.price),
					conclusionContractDate: values.conclusionContractDate,
					plannedExecutionDate: values.plannedExecutionDate,
					description: null,
					additionalCustomerName: values.additionalCustomerName,
				},
				tender: {
					id: null,
					tradingFloor: {
						id: null,
					},
				},
				documents: null,
				users: [],
				med: {
					peopleForMedicalExamination: parseInt(
						values.peopleForMedicalExamination
					),
				},
				workFlow: {
					id: values.id,
					typeId: values.departmentType,
					currentWorkFlowStageId: "Draft",
					projectStatusId: "Draft",
					isActive: true,
				},
				informationCollectedAt: values.informationCollectedAt,
				alternateId: values.alternateId,
				executionDescription: values.executionDescription,
				regions: values.regions,
				informationCollectionDate: values.informationCollectionDate,
			};

			values.tenderUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.tenderUserId),
					departmentId: "TenderDepartment",
				});

			values.commercialUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.commercialUserId),
					departmentId: "CommercialDepartment",
				});

			values.laboratoryUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.laboratoryUserId),
					departmentId: "Laboratory",
				});
		}
	}

	if (workFlowId === "TenderWorkFlow") {
		if (localStorage.getItem("__user-area") === "sout") {
			body = {
				id: values.id,
				archiveId: null,
				assesmentTypeId: values?.assesmentTypeId,
				haveWorkbook: values.haveWorkbook === "true",
				executionProgramId: values.executionProgramId,
				unitCount: parseInt(values.unitCount),
				workspaceCount: parseInt(values.workspaceCount),
				contract: {
					id: values.id,
					number: values.contractNumber,
					customer: {
						id: null,
						inn: values.inn === "" ? null : values.inn,
						name: values.customerName === "" ? null : values.customerName,
						contactData: values.contactData === "" ? null : values.contactData,
					},
					price: parseFloat(values.price),
					actAmount: values.actAmount,
					postage: 0,
					securityAmount: parseFloat(values.securityAmount),
					tradingFloorCommissionAmount: parseFloat(
						values.tradingFloorCommissionAmount
					),
					offerSecurityAmount: parseFloat(values.offerSecurityAmount),
					payments: null,
					conclusionContractDate: values.conclusionContractDate,
					plannedExecutionDate: values.plannedExecutionDate,
					description: null,
					additionalCustomerName: values.additionalCustomerName,
					actDate: values.actDate,
					paidDate: values.paidDate,
					paymentsSum: values.paymentsSum,
					paymentsStatusId: values.paymentsStatusId,
					actNumber: values.actNumber
				},
				tender: {
					id: values.id,
					tradingFloor: {
						id: parseInt(values.tradingFloorId),
					},
					tenderId: values.tenderId,
					publicationDate: values.publicationDate,
					acceptingOffersDeadline: values.acceptingOffersDeadline,
					startMaxPrice: parseFloat(values.startMaxPrice),
					offerAmount: parseFloat(values.offerAmount),
					description: values.description,
					auctionDate: values.auctionDate,
					failrueDescription: values.failrueDescription,
					offerSecuringMethodId: values.offerSecuringMethodId,
					contractSecuringMethodId: values.contractSecuringMethodId,
					tradingFloorCommisionId: values.tradingFloorCommisionId,
					winnerPrice: parseFloat(values.winnerPrice),
					winner: {
						id: parseInt(values.winnerId),
					},
					summaryReportDate: values.summaryReportDate,
					operationTypeId: values.operationTypeId,
					legalBasesId: values.legalBasesId,
					hasTradingRobot: values.hasTradingRobot === "true",
					securityReturned: getSecurityReturnedValue(values.securityReturned),
				},
				documents: null,
				users: [],
				worker: {
					id: parseInt(values.workerId),
				},
				workFlow: {
					id: values.id,
					typeId: values.departmentType,
					currentWorkFlowStageId: "Draft",
					projectStatusId: "Draft",
					isActive: true,
				},
				aggregatorLink: values.aggregatorLink,
				informationCollectedAt: values.informationCollectedAt,
				alternateId: values.alternateId,
				executionDescription: values.executionDescription,
				regions: values.regions,
				informationCollectionDate: values.informationCollectionDate,
				acceptedToWorkDate: values.acceptedToWorkDate,
				sentDate: values.sentDate,
				unloadingDate: values.unloadingDate,
				measurementsDate: values.measurementsDate,
				performerAppointedDate: values.performerAppointedDate,
				approvalsDate: values.approvalsDate,
				titleSheetSignDate: values.titleSheetSignDate,
				actHasReached: values.actHasReached === "true",
				monthlyReportDate: addDayToDateHandler(values.monthlyReportDate),
				KPP: values.KPP,
				MeasurementStatusId: values.measurementStatusId
			};

			values.tenderUserId === null || values.tenderUserId === "null"
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.tenderUserId),
					departmentId: "TenderDepartment",
				});

			values.commercialUserId === null || values.tenderUserId === "null"
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.commercialUserId),
					departmentId: "CommercialDepartment",
				});

			values.laboratoryUserId === null || values.laboratoryUserId === "null"
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.laboratoryUserId),
					departmentId: "Laboratory",
				});
		}

		if (localStorage.getItem("__user-area") === "med") {
			body = {
				id: values.id,
				archiveId: null,
				unitCount: parseInt(values.unitCount),
				workspaceCount: parseInt(values.workspaceCount),
				contract: {
					id: values.id,
					number: values.contractNumber,
					customer: {
						id: null,
						inn: values.inn === "" ? null : values.inn,
						name: values.customerName === "" ? null : values.customerName,
						contactData: values.contactData === "" ? null : values.contactData,
					},
					price: parseFloat(values.price),
					actAmount: values.actAmount,
					postage: 0,
					securityAmount: parseFloat(values.securityAmount),
					tradingFloorCommissionAmount: parseFloat(
						values.tradingFloorCommissionAmount
					),
					offerSecurityAmount: parseFloat(values.offerSecurityAmount),
					payments: null,
					conclusionContractDate: values.conclusionContractDate,
					plannedExecutionDate: values.plannedExecutionDate,
					description: null,
					additionalCustomerName: values.additionalCustomerName,
				},
				tender: {
					id: values.id,
					tradingFloor: {
						id: parseInt(values.tradingFloorId),
					},
					tenderId: values.tenderId,
					publicationDate: values.publicationDate,
					acceptingOffersDeadline: values.acceptingOffersDeadline,
					startMaxPrice: parseFloat(values.startMaxPrice),
					offerAmount: parseFloat(values.offerAmount),
					description: values.description,
					auctionDate: values.auctionDate,
					failrueDescription: values.failrueDescription,
					offerSecuringMethodId: values.offerSecuringMethodId,
					contractSecuringMethodId: values.contractSecuringMethodId,
					tradingFloorCommisionId: values.tradingFloorCommisionId,
					winnerPrice: parseFloat(values.winnerPrice),
					winner: {
						id: parseInt(values.winnerId),
					},
					summaryReportDate: values.summaryReportDate,
					operationTypeId: values.operationTypeId,
					legalBasesId: values.legalBasesId,
					hasTradingRobot: values.hasTradingRobot === "true",
					securityReturned: values.securityReturned === "true",
				},
				documents: null,
				users: [],
				med: {
					peopleForMedicalExamination: parseInt(
						values.peopleForMedicalExamination
					),
				},
				worker: {
					id: parseInt(values.workerId),
				},
				workFlow: {
					id: values.id,
					typeId: values.departmentType,
					currentWorkFlowStageId: "Draft",
					projectStatusId: "Draft",
					isActive: true,
				},
				aggregatorLink: values.aggregatorLink,
				informationCollectedAt: values.informationCollectedAt,
				alternateId: values.alternateId,
				executionDescription: values.executionDescription,
				regions: values.regions,
				informationCollectionDate: values.informationCollectionDate,
			};

			values.tenderUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.tenderUserId),
					departmentId: "TenderDepartment",
				});

			values.commercialUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.commercialUserId),
					departmentId: "CommercialDepartment",
				});

			values.laboratoryUserId === null
				? body.users.push(null)
				: body.users.push({
					id: parseInt(values.laboratoryUserId),
					departmentId: "Laboratory",
				});
		}
	}

	let str_body = JSON.stringify(body);
	str_body = JSON.parse(str_body);



	const getNull = (obj) => {
		if (!obj) return null;

		const keys = Object.keys(obj);
		const length = keys.length;

		let nullCounter = 0;
		for (let i = 0; i < length; i++) {
			const value = obj[keys[i]];
			if (value === null) {
				nullCounter++;
			}
		}

		if (nullCounter === length) {
			obj = null;
		}
		return obj;
	};

	const getArrNull = (arr) => {
		let filtered = arr.filter((elem) => elem !== null);
		if (filtered.length === 0) {
			filtered = null;
		}
		return filtered;
	};

	str_body.contract.customer = getNull(str_body.contract.customer);
	str_body.contract = getNull(str_body.contract);
	str_body.tender.tradingFloor = getNull(str_body.tender.tradingFloor);
	str_body.tender.winner = getNull(str_body.tender.winner);
	str_body.tender = getNull(str_body.tender);
	str_body.users = getArrNull(str_body.users);
	str_body.workFlow = getNull(str_body.workFlow);
	str_body.worker = getNull(str_body.worker);
	str_body = getNull(str_body);

	const new_body = Object.assign(str_body);

	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;

	let response = await api(
		getPatchBaseUrl(projectId, localStorage.getItem("__user-area")),
		{
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(new_body),
		}
	);
	let data = await response.json();



	if (response.status !== 200) {
		alert(`Ошибка ${response.status}. Проект не был изменён :(`);
	}
};
export default patchProject;
