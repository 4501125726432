import SelectWithSearch from "components/SelectWithSearch";
import React, { useEffect, useState } from "react";
import { api } from "settings/Api";

const FiledTenderStage = ({
  price,
  setPrice,
  setIsDisabled,
  move,
  winner,
  setWinner,
}) => {
  const [competitors, setCompetitors] = useState();
  const [haveWinner, setHaveWinner] = useState(!!winner?.id ? "true" : "false");
  const fetchCompetitors = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    let response = await api(`/api/sout/helpers/handbooks/competitors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();
    setCompetitors(data.data);
  };

  useEffect(() => {
    if (move === "prev") {
      setIsDisabled(false);
    }
    if (move === "next") {
      setIsDisabled(!price);
    }
    if (move === "reject") {
      setIsDisabled(
        haveWinner == "false" ? !price : !(price && Boolean(winner?.id))
      );
    }
  });

  useEffect(() => {
    if (move !== "next") {
      fetchCompetitors();
    }
  }, [move, setIsDisabled, price, haveWinner, winner]);

  const inputHandler = (e) => {
    setPrice(e.target.value);
  };

  return (
    <div className="modal__content-center">
      {move === "next" && (
        <>
          <label className="modal__input-title">Цена:</label>
          <input
            style={{ maxWidth: 300 }}
            className={"input"}
            type="number"
            name="price"
            value={price}
            onChange={inputHandler}
          />
        </>
      )}
      {move === "reject" && (
        <>
          <label className="modal__input-title">Цена победителя:</label>
          <input
            style={{ maxWidth: 300 }}
            className={!price ? "input modal__input-err" : "input "}
            name="price"
            autoFocus={!price ? true : false}
            value={price}
            type="number"
            onChange={inputHandler}
          />
          <label className="modal__input-title">Победитель известен</label>
          <div className="modal__radioGroupFlex">
            <div className="modal__radioGroup">
              <input
                type="radio"
                id="yes"
                name="winnerHave"
                style={{ display: "block" }}
                value="true"
                checked={haveWinner == "true"}
                onClick={(e) => setHaveWinner(e.target.value)}
              />
              <label for="yes">Да</label>
            </div>
            <div className="modal__radioGroup">
              <input
                type="radio"
                id="no"
                style={{ display: "block" }}
                name="winnerHave"
                checked={haveWinner == "false"}
                value="false"
                onClick={(e) => setHaveWinner(e.target.value)}
              />
              <label for="no">Нет</label>
            </div>
          </div>
          {haveWinner == "true" && (
            <>
              <label className="modal__input-title">Победитель:</label>
              <div style={{ width: "80%", maxWidth: 350 }}>
                <SelectWithSearch
                  values={competitors}
                  onSelect={(e) =>
                    setWinner(
                      competitors?.find((item) => item?.id == e.target.id)
                    )
                  }
                  onDelete={() => setWinner(null)}
                  selectValue={winner?.id}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FiledTenderStage;
