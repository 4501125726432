import { dataIcon } from "components/IconSelect";
import { getColor } from "utils/getColor";
import { useContext, useEffect, useState } from "react";
import { userIsAgent } from "../../utils/userIsAgent";
import authContext from "../../context/AuthContext";
import { fetchAccount } from "../../utils/fetchAccount";

export const RecordsShowTable = ({
  records,
  type,
  page,
  actionButtons,
  sortFields,
  additionalFields,
  selectedSortField,
  selectedAdditionalField,
  headers,
  getDataFns,
  isCompare,
  sortMap,
}) => {
  // const {account} = useContext(authContext);

  return (
    <>
      {records && (
        <div className="table-wrapper">
          <table className="table workspace__table contracts__table">
            <thead>
              <tr>
                <td></td>
                <td>#</td>
                {selectedSortField !== headers[0].value && (
                  <td>{headers[0].text}</td>
                )}
                {selectedSortField && <td>{sortFields[selectedSortField]}</td>}
                {selectedAdditionalField &&
                  selectedAdditionalField !== "null" && (
                    <td>{additionalFields[selectedAdditionalField]}</td>
                  )}
                {headers
                  .slice(1)
                  .map((header) =>
                    header.value !== selectedSortField &&
                    header.value !== selectedAdditionalField ? (
                      <td key={header.value}>{header.text}</td>
                    ) : null
                  )}
                <td style={!isCompare ? { width: 250 } : { width: 150 }}>
                  Действия
                </td>
              </tr>
            </thead>
            <tbody>
              {getDataFns &&
                records.map((record) => (
                  <tr
                    key={record.id}
                    style={{
                      fontStyle: `${
                        isCompare &&
                        !record?.hasOwnProperty("fromPlannedMeasurementsDate")
                          ? "italic"
                          : ""
                      }`,
                      fontWeight: `${
                        isCompare &&
                        !record?.hasOwnProperty("fromPlannedMeasurementsDate")
                          ? "600"
                          : ""
                      }`,
                    }}
                  >
                    <td>
                      {record?.personInCharge?.organization?.picture?.name
                        ? dataIcon[
                            record?.personInCharge?.organization?.picture?.name
                          ]
                        : null}
                    </td>
                    <td
                      style={{
                        fontStyle: "normal",
                        fontWeight: "normal",
                        background: `${getColor(
                          type,
                          type === "location"
                            ? record.hasOwnProperty("tender")
                              ? "Potential"
                              : record?.workFlow?.workFlowStatusId
                            : record?.workflow?.workFlowStatusId,
                          record?.isExpired,
                          record?.isWarning
                        )}`,
                      }}
                    >
                      {records.indexOf(record) +
                        (type === "location"
                          ? 50 * (parseInt(page) - 1)
                          : 20 * (parseInt(page) - 1)) +
                        1}
                    </td>
                    {selectedSortField !== "id" && (
                      <td
                        style={{
                          fontStyle: "normal",
                          fontWeight: "bold",
                          background: `${getColor(
                            type,
                            type === "location"
                              ? record.hasOwnProperty("tender")
                                ? "Potential"
                                : record?.workFlow?.workFlowStatusId
                              : record?.workflow?.workFlowStatusId,
                            record?.isExpired,
                            record?.isWarning
                          )}`,
                        }}
                      >
                        {getDataFns["id"](record)}
                      </td>
                    )}
                    {selectedSortField && (
                      <td className="table__sort-column">
                        {Object.keys(getDataFns).includes(selectedSortField)
                          ? getDataFns[selectedSortField](record)
                          : sortMap[selectedSortField](record) || "-"}
                      </td>
                    )}
                    {selectedAdditionalField &&
                      selectedAdditionalField !== "null" && (
                        <td className="table__additional-column">
                          {Object.keys(getDataFns).includes(
                            selectedAdditionalField
                          )
                            ? getDataFns[selectedAdditionalField](record)
                            : sortMap[selectedAdditionalField](record) || "-"}
                        </td>
                      )}
                    {Object.keys(getDataFns).map((key) =>
                      key !== "id" &&
                      key !== selectedAdditionalField &&
                      key !== selectedSortField ? (
                        type === "location" ? (
                          <td
                            style={{
                              fontSize: `${
                                getDataFns[key](record)?.length > 100
                                  ? "13px"
                                  : ""
                              }`,
                            }}
                          >
                            {getDataFns[key](record) || "-"}
                          </td>
                        ) : (
                          <td>{getDataFns[key](record) || "-"}</td>
                        )
                      ) : null
                    )}
                    <td
                      className="table__stage-buttons-wrapper"
                      style={{ fontStyle: "normal", fontWeight: "normal" }}
                    >
                      {actionButtons(record)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
