import { useState, useContext, useEffect } from "react";
import ProjectContext from "../context/ProjectsContext";
import { FaSearch } from "react-icons/fa";
import { FaSort } from "react-icons/fa";
import { Input } from "./Input/Input";
import { Select } from "./Select/Select";
import INPUTS from "../constants/inputsData";
import InputDiapason from "./InputDiapason/InputDiapason";
import fetchUsers from "utils/fetchUsers";
import fetchWorkers from "utils/fetchWorkers";
import { fetchTradingFloors } from "utils/fetchTradingFloors";
import {
  getAdditionalDataByUserDepartment,
  getAdditionalSortValuesByUserDepartment,
} from "../settings/additionalInfoColumn";
import SearchSelectRegions from "./common/SearchSelectRegions";
import { useParams } from "react-router-dom";
import AuthContext from "context/AuthContext";
import { Portal } from "./Portal/Portal";
import ModalWrapper from "./modals/ModalWrapper";
import { DocActionModal } from "./modals/DocActionModal";
import fetchCompetitors from "utils/fetchCompetitors";
import { generateQueriesForSearchRequest } from "shared/generateQueriesForSearchRequest";

const SearchSortTabs = () => {
  const {
    setQueryStrings,
    stageTitle,
    setSortParam,
    setPaginationQuery,
    setIsUpdateDraftTender,
    setAdditionalInfoValue,
    removeAllDraftTenders,
    paginationParams,
    setSubmitButtonPressed,
    additionalInfoValue,
    values,
    setValues,
    currentEntity,
    setCurrentEntity,
  } = useContext(ProjectContext);

  const { role, accessRights } = useContext(AuthContext);

  const params = useParams();

  const PAGE_ROW_COUNT = 20;

  // let additionalInfo = getAdditionalDataByUserDepartment();

  const [openSearch, setOpenSearch] = useState();
  const [openSort, setOpenSort] = useState(false);
  const [regions, setRegions] = useState([]);
  const [tradingFloors, setTradingFloors] = useState([]);
  const [tenderUsers, setTenderUsers] = useState("");
  const [commercialUsers, setCommercialUsers] = useState("");
  const [laboratoryUsers, setLaboratoryUsers] = useState("");
  const [competitors, setCompetitors] = useState([]);
  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);
  const [workers, setWorkers] = useState("");
  useEffect(() => {
    fetchUsers(
      "TenderDepartment",
      setTenderUsers,
      setCommercialUsers,
      setLaboratoryUsers
    );
    fetchUsers(
      "CommercialDepartment",
      setTenderUsers,
      setCommercialUsers,
      setLaboratoryUsers
    );
    fetchUsers(
      "Laboratory",
      setTenderUsers,
      setCommercialUsers,
      setLaboratoryUsers
    );
    fetchWorkers(setWorkers);
    fetchTradingFloors(setTradingFloors);
    fetchCompetitors(setCompetitors);
    // onReset();
  }, []);

  const updateRegions = (updatedRegions) => {
    if (updatedRegions.length >= 1) {
      const regionId = updatedRegions[0].id;
      setRegions(updatedRegions);
      setValues({ ...values, Regions: regionId });
    } else if (updatedRegions.length === 0) {
      setRegions([]);
      setValues({ ...values, Regions: null });
    }
  };

  const [isPasted, setIsPasted] = useState(false);

  const onChange = (e) => {
    if (!isPasted) {
      setValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
    setIsPasted(false);
  };

  const onPasteCaptureHandler = (e) => {
    setIsPasted(true);

    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.clipboardData.getData("Text"),
    }));
  };

  const onSubmit = (e) => {
    generateQueriesForSearchRequest({
      e,
      openSort,
      values,
      setQueryStrings,
      setSortParam,
      setPaginationQuery,
      setSubmitButtonPressed,
    });
  };

  const onReset = (e) => {
    setValues({});
    setQueryStrings("");
    setRegions([]);
    setAdditionalInfoValue("");
    setSortParam("");
    setSubmitButtonPressed(true);
  };

  useEffect(() => {
    if (currentEntity === "project") {
      setCurrentEntity("tender");
      onReset();
    }
    if (!currentEntity) {
      setCurrentEntity("tender");
    }
  }, []);

  const displayAdditionalInfo = (e) => {
    const value = e.target.value;
    setAdditionalInfoValue(value);
  };

  return (
    <section className="workspace__search-area">
      {confirmActionModalOpen && (
        <Portal>
          <ModalWrapper>
            <DocActionModal
              title="Вы уверены, что хотите удалить все тендеры на этом этапе?"
              onYes={() => {
                removeAllDraftTenders().then(() => {
                  setIsUpdateDraftTender(true);
                  setConfirmActionModalOpen(false);
                });
              }}
              onNo={() => setConfirmActionModalOpen(false)}
              colorThemeClass={`button--purple`}
            />
          </ModalWrapper>
        </Portal>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="search-area__buttons-wrapper"
          style={{
            marginLeft: `${
              accessRights[role].canChange.includes("tender") ? "" : "0"
            }`,
          }}
        >
          <button
            className={`button search-area__search-button button--purple ${
              openSearch ? "button--purple--active" : ""
            }`}
            onClick={() => {
              setOpenSearch(!openSearch);
              setOpenSort(false);
            }}
          >
            <FaSearch className="search-button__icon" />
            <span className="search-button__text">Поиск</span>
          </button>
          <button
            className={`button search-area__search-button button--purple ${
              openSort ? "button--purple--active" : ""
            }`}
            onClick={() => {
              setOpenSort(!openSort);
              setOpenSearch(false);
            }}
          >
            <FaSort className="search-button__icon icon-sort" />
            <span className="search-button__text">Сортировка</span>
          </button>
        </div>
        {stageTitle === "Черновик" && role === "admin" && (
          <button
            style={{ width: 220 }}
            className={`button search-area__search-button button--purple`}
            onClick={() => setConfirmActionModalOpen(true)}
          >
            Удалить все тендеры
          </button>
        )}
      </div>
      <form
        onReset={onReset}
        onSubmit={onSubmit}
        style={{ marginBottom: "70px", width: openSort ? "650px" : "auto" }}
      >
        <div className={`search-area__panel ${!openSearch && "collapse"}`}>
          <fieldset className="search-area__fieldset">
            <legend className="subtitle search-area__subtitle">Поиск</legend>
            <Input
              type="text"
              className={"id-tender-input"}
              onChange={onChange}
              value={values?.id}
              data={INPUTS.id}
            />
            <Input
              type="text"
              onChange={onChange}
              onPasteCapture={onPasteCaptureHandler}
              value={values?.SearchString}
              data={INPUTS.SearchString}
              className="w-800"
            />
          </fieldset>
          <fieldset className="search-area__fieldset">
            <Input
              type="number"
              onChange={onChange}
              value={values?.ManagerINN}
              data={{
                name: "ManagerINN",
                label: "ИНН организатора",
              }}
            />
            <InputDiapason
              title="Дата заключения договора"
              type="date"
              name={[
                INPUTS.FromConclusionContractDate.name,
                INPUTS.ToConclusionContractDate.name,
              ]}
              onChange={[
                (date) =>
                  setValues({ ...values, FromConclusionContractDate: date }),
                (date) =>
                  setValues({ ...values, ToConclusionContractDate: date }),
              ]}
              value={[
                values?.FromConclusionContractDate || null,
                values?.ToConclusionContractDate || null,
              ]}
              placeholder="дд.мм.гггг"
            />
            <InputDiapason
              title="Цена договора"
              type="number"
              className="input--w130"
              name={[
                INPUTS.FromContractPrice.name,
                INPUTS.ToContractPrice.name,
              ]}
              onChange={[
                (e) =>
                  setValues({ ...values, FromContractPrice: e.target.value }),
                (e) =>
                  setValues({ ...values, ToContractPrice: e.target.value }),
              ]}
              value={[
                values?.FromContractPrice || null,
                values?.ToContractPrice || null,
              ]}
              placeholder={["от", "до"]}
            />
          </fieldset>
          <fieldset className="search-area__fieldset">
            <Input
              type="number"
              onChange={onChange}
              value={values?.CustomerINN}
              data={{
                name: "CustomerINN",
                label: "ИНН заказчика",
              }}
            />
            <InputDiapason
              title="Плановая дата исполнения"
              type="date"
              name={[
                INPUTS.FromPlannedExecutionDate.name,
                INPUTS.ToPlannedExecutionDate.name,
              ]}
              onChange={[
                (date) =>
                  setValues({ ...values, FromPlannedExecutionDate: date }),
                (date) =>
                  setValues({ ...values, ToPlannedExecutionDate: date }),
              ]}
              value={[
                values?.FromPlannedExecutionDate || null,
                values?.ToPlannedExecutionDate || null,
              ]}
              placeholder="дд.мм.гггг"
              // disabled={true}
            />
            <div style={{ width: 150, marginRight: 173 }}>
              <Select
                onChange={onChange}
                dataSrc="local"
                data={INPUTS.AssessmentTypeId}
              />
            </div>
          </fieldset>
          <div
            className="grid-line"
            style={{
              gridTemplateColumns: "2fr 3fr 1fr",
              marginLeft: "3px",
            }}
          >
            <SearchSelectRegions
              isSearchBar
              regions={regions}
              updateRegions={updateRegions}
              color={"purple"}
            />
            {/* <button className='button  button--purple' >
                  Выбрать регион
                </button>
                <input type="text" className='input'  /> */}
          </div>
          <fieldset className="search-area__fieldset-diapason">
            <div
              className="line"
              style={{
                width: "1000px",
                borderBottom: "2px solid #000",
                margin: "15px 0 10px 0",
                flexBasis: "100%",
              }}
            ></div>
            {/* {isTenderUser() && ( */}
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Input
                  type="text"
                  onChange={onChange}
                  value={values?.EIS}
                  data={INPUTS.EIS}
                  classNameLabel="mb-5 w-318"
                />
                <Select
                  onChange={onChange}
                  dataSrc="local"
                  data={INPUTS.OperationTypeId}
                  classNameLabel="mb-5 w-318"
                />
                <Select
                  onChange={onChange}
                  label={INPUTS.TenderDepartmentEmployeeId.label}
                  name={"PersonInChargeId"}
                  dataSrc="server"
                  data={tenderUsers}
                  classNameLabel="mb-5 w-318"
                />
              </div>
              <Select
                onChange={onChange}
                dataSrc="server"
                data={tradingFloors}
                name={INPUTS.TradingFloorId.name}
                label={INPUTS.TradingFloorId.label}
                value={values?.TradingFloorId}
                classNameLabel="mb-5 w-318"
              />
              <Select
                className={"w-150"}
                onChange={onChange}
                label={INPUTS.hasTradingRobot.label}
                value={values?.hasTradingRobot}
                name={INPUTS.hasTradingRobot.name}
                dataSrc="local"
                data={{
                  values: {
                    true: "Робот",
                    false: "Не робот",
                  },
                }}
              />
              <label
                className="workspace__input-title"
                style={{ margin: "0 0 0 18px" }}
              >
                Закон закупки:
                <select
                  className="select w-150"
                  name="legalBasesId"
                  value={values?.legalBasesId}
                  onChange={onChange}
                >
                  <option value="None">Нет</option>
                  <option value="Gov">44 ФЗ</option>
                  <option value="PartialGov">223 ФЗ</option>
                  <option value="Other">Другое</option>
                </select>
              </label>

              <InputDiapason
                title="Дата публикации*"
                type="date"
                name={[
                  INPUTS.FromPublicationDate.name,
                  INPUTS.ToPublicationDate.name,
                ]}
                onChange={[
                  (date) => setValues({ ...values, FromPublicationDate: date }),
                  (date) => setValues({ ...values, ToPublicationDate: date }),
                ]}
                value={[
                  values?.FromPublicationDate || null,
                  values?.ToPublicationDate || null,
                ]}
                placeholder="дд.мм.гггг"
              />
              <InputDiapason
                title="НМЦК*"
                type="number"
                name={[
                  INPUTS.FromStartMaxPrice.name,
                  INPUTS.ToStartMaxPrice.name,
                ]}
                onChange={[
                  (e) =>
                    setValues({ ...values, FromStartMaxPrice: e.target.value }),
                  (e) =>
                    setValues({ ...values, ToStartMaxPrice: e.target.value }),
                ]}
                value={[
                  values?.FromStartMaxPrice || null,
                  values?.ToStartMaxPrice || null,
                ]}
                placeholder={["от", "до"]}
              />

              <InputDiapason
                title="Наша цена"
                type="number"
                name={["FromOurPrice", "ToOurPrice"]}
                onChange={[
                  (e) => setValues({ ...values, FromOurPrice: e.target.value }),
                  (e) => setValues({ ...values, ToOurPrice: e.target.value }),
                ]}
                value={[
                  values?.FromOurPrice || null,
                  values?.ToOurPrice || null,
                ]}
                placeholder={["от", "до"]}
              />

              <InputDiapason
                title="Дата окончания подачи заявок*"
                type="date"
                name={[
                  INPUTS.FromAcceptingOffersDeadline.name,
                  INPUTS.ToAcceptingOffersDeadline.name,
                ]}
                onChange={[
                  (date) =>
                    setValues({ ...values, FromAcceptingOffersDeadline: date }),
                  (date) =>
                    setValues({ ...values, ToAcceptingOffersDeadline: date }),
                ]}
                value={[
                  values?.FromAcceptingOffersDeadline || null,
                  values?.ToAcceptingOffersDeadline || null,
                ]}
                placeholder="дд.мм.гггг"
              />

              <InputDiapason
                title="Дата итогового протокола"
                type="date"
                name={["FromSummaryReportDate", "ToSummaryReportDate"]}
                onChange={[
                  (date) =>
                    setValues({ ...values, FromSummaryReportDate: date }),
                  (date) => setValues({ ...values, ToSummaryReportDate: date }),
                ]}
                value={[
                  values?.FromSummaryReportDate || null,
                  values?.ToSummaryReportDate || null,
                ]}
                placeholder="дд.мм.гггг"
              />

              <InputDiapason
                title="Цена победителя"
                type="number"
                name={["FromWinnerPrice", "ToWinnerPrice"]}
                onChange={[
                  (e) =>
                    setValues({ ...values, FromWinnerPrice: e.target.value }),
                  (e) =>
                    setValues({ ...values, ToWinnerPrice: e.target.value }),
                ]}
                value={[
                  values?.FromWinnerPrice || null,
                  values?.ToWinnerPrice || null,
                ]}
                placeholder={["от", "до"]}
              />

              <InputDiapason
                title="Дата аукциона"
                type="date"
                name={[INPUTS.FromAuctionDate.name, INPUTS.ToAuctionDate.name]}
                onChange={[
                  (date) => setValues({ ...values, FromAuctionDate: date }),
                  (date) => setValues({ ...values, ToAuctionDate: date }),
                ]}
                value={[
                  values?.FromAuctionDate || null,
                  values?.ToAuctionDate || null,
                ]}
                placeholder="дд.мм.гггг"
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Select
                  onChange={onChange}
                  label={"Победитель"}
                  name={INPUTS.WinnerId.name}
                  className={"w-318"}
                  data={competitors}
                  dataSrc="server"
                  value={values?.winner?.id}
                  classNameLabel="w-318"
                />
                <InputDiapason
                  title="Цена"
                  classNameLabel={"marginLeft20px"}
                  type="number"
                  name={[INPUTS.FromPrice.name, INPUTS.ToPrice.name]}
                  value={[
                    values[INPUTS.FromPrice.name],
                    values[INPUTS.ToPrice.name],
                  ]}
                  onChange={[(e) => onChange(e), (e) => onChange(e)]}
                  placeholder={["от", "до"]}
                />
              </div>
            </>
          </fieldset>
        </div>
        <div
          className={`search-area__fieldset ${openSort ? null : "collapse"}`}
        >
          <fieldset className="search-area__filters search-area__filters--select-area">
            <h4 className="workspace__title">Сортировка</h4>
            <Select
              onChange={onChange}
              dataSrc="local"
              data={INPUTS.SortMethod}
            />
            <Select
              onChange={onChange}
              dataSrc="local"
              data={INPUTS.SortFieldTender}
            />
          </fieldset>
          <fieldset
            className="search-area__filters search-area__filters--select-area"
            style={{ marginLeft: "40px" }}
          >
            <h4 className="workspace__title">Дополнительная информация</h4>
            <Select
              onChange={displayAdditionalInfo}
              dataSrc="local"
              data={INPUTS.AdditionalInfoFieldTender}
            />
            {/* <Select
              onChange={onChange}
              dataSrc="local"
              data={INPUTS.SortDeadlinesTender}
            /> */}
          </fieldset>
        </div>

        <fieldset
          className={`${openSearch || openSort ? null : "collapse"}`}
          style={{ marginTop: 40 }}
        >
          <button type="submit" className="button button--purple">
            {" "}
            {openSearch && "Найти"}
            {openSort && "Сортировать"}{" "}
          </button>
          <button
            type="reset"
            className="button button--purple"
            style={{ marginLeft: "15px" }}
          >
            {" "}
            Сбросить{" "}
          </button>
        </fieldset>
      </form>
    </section>
  );
};
export default SearchSortTabs;
