import React, { useState, useEffect } from "react";
import { isTenderUser } from "utils/defineUser";
import { Grommet, Grid, Box } from "grommet";
import { TextInput } from "components/TextInput/TextInput";
import { Select } from "components/Select/Select";
import { fetchTradingFloors } from "utils/fetchTradingFloors";
import { DatePicker } from "components/DateInput/DateInput";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
import fetchUsers from "utils/fetchUsers";
import Asterisk from "components/Asterisk";
import DateWithTimeInput from "components/DateInput/DateWithTimeInput";
import { dateToString, dateToStringWithTime } from "shared/formatDate";

const ApprovedTenderStage = ({
  emp,
  setIsDisabled,
  isDisabled,
  setEmp,
  move,
  project,
  data,
  setData,
  contactData,
  setContactData,
}) => {
  const [tradingFloors, setTradingFloors] = useState();
  const [tenderUsers, setTenderUsers] = useState();

  const convertDate = (date) => {
    return date ? dateToString(date) : null;
  };

  useEffect(() => {
    setData({
      eis: project?.eis,
      tradingFloor: project?.tradingFloor,
      operationTypeId: project?.operationTypeId,
      legalBasesId: project?.legalBasesId,
      startMaxPrice: project?.startMaxPrice,
      publicationDate: project?.publicationDate,
      auctionDate: project?.auctionDate,
      acceptingOffersDeadline: project?.acceptingOffersDeadline,
      summaryReportDate: project?.summaryReportDate,
      customerAdditionalName: project?.customerAdditionalName,
    });
    fetchTradingFloors(setTradingFloors);
    fetchUsers("TenderDepartment", setTenderUsers);
    if (isTenderUser() && move === "next") {
      setEmp(localStorage.getItem("__user-id"));
    }
  }, []);

  useEffect(() => {
    if (move === "next") {
      console.log("[eqe",data);
      setIsDisabled(
        // разворачиваем объект data и проверяем все поля, если хоть одно не заполнено, вернуть true
        [
          ...Object.keys(data)
            .filter((key) => key !== "startMaxPrice" && key !== "auctionDate" && key !== "customerAdditionalName")
            .map((key) => data[key]),
          emp,
          data?.operationTypeId === "DigitalAuction" ? data?.auctionDate : true,
        ].some((value) => !value)
      );
    }
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const selectTradingFloor = (e) => {
    if (tradingFloors) {
      const { value } = e.target;
      setData({
        ...data,
        tradingFloor: tradingFloors.find((el) => el.id == value),
      });
    }
  };

  return (
    <div className="modal__content-center">
      {move === "next" && (
        <form>
          <Grommet>
            <Grid
              columns={["1fr", "30px", "1fr"]}
              rows={["1fr", "1fr"]}
              style={{
                height: "400px",
                overflowY: "scroll",
              }}
            >
              <div style={{ marginBottom: 30 }}>
                <div>
                  <p style={{ textAlign: "left" }}>ИНН организатора</p>
                  <p style={{ fontWeight: "bold", textAlign: "left" }}>
                    {project.manager.inn}
                  </p>
                </div>
                <div>
                  <p style={{ textAlign: "left" }}>Наименование организатора</p>
                  <p style={{ fontWeight: "bold", textAlign: "left" }}>
                    {project.manager.name}
                  </p>
                </div>
                <div>
                  <p style={{ textAlign: "left" }}>Ссылка от агрегатора</p>
                  <p style={{ fontWeight: "bold", textAlign: "left" }}>
                    <a
                      href={project.aggregatorLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Перейти по ссылке
                    </a>
                  </p>
                </div>
              </div>
              <div></div>
              <div>
                <p style={{ textAlign: "left" }}>Регион закупки</p>
                <p style={{ fontWeight: "bold", textAlign: "left" }}>
                  {project?.regions[0]?.name}
                </p>
              </div>
              <div>
                <TextInput
                  value={data?.eis}
                  name="eis"
                  onChange={onChange}
                  label="ЕИС (ID на площадке)"
                  alignLabel="left"
                  required
                />
                <Select
                  alignLabel="left"
                  label="Вид процедуры"
                  name="operationTypeId"
                  value={data?.operationTypeId}
                  onChange={onChange}
                  dataSrc="local"
                  data={{
                    values: {
                      None: "Нет",
                      DigitalAuction: "Электронный аукцион",
                      RequestForQuotations: "Запрос котировок или предложений",
                      Other: "Другое",
                    },
                  }}
                  required
                />
                <TextInput
                  name="startMaxPrice"
                  onChange={onChange}
                  label="НМЦК"
                  value={data?.startMaxPrice}
                  alignLabel="left"
                  type="number"
                />
                <label>
                  <LabelTitle align="left">
                    Дата публикации
                    <Asterisk />
                  </LabelTitle>
                  <DatePicker
                    className="input"
                    name="publicationDate"
                    value={data?.publicationDate || null}
                    onChange={(date) =>
                      setData({ ...data, publicationDate: convertDate(date) })
                    }
                    min={new Date("01.01.2000")}
                    required
                  />
                </label>
                <label>
                  <LabelTitle align="left">
                    Дата аукциона
                    {data.operationTypeId === "DigitalAuction" && <Asterisk />}
                  </LabelTitle>
                  <DateWithTimeInput
                    className="input"
                    name="auctionDate"
                    date={
                      data?.auctionDate ? new Date(data?.auctionDate) : null
                    }
                    onChange={(date) => setData({ ...data, auctionDate: date })}
                    min={new Date("01.01.2000")}
                    required={data.operationTypeId === "DigitalAuction"}
                    disabled={data.operationTypeId !== "DigitalAuction"}
                  />
                </label>
                <Select
                  label="Сотрудник тендерного отдела"
                  alignLabel="left"
                  dataSrc="server"
                  value={emp}
                  onChange={(e) => setEmp(e.target.value)}
                  data={tenderUsers}
                  required
                />
                <TextInput
                  name="customerAdditionalName"
                  onChange={onChange}
                  label="Доп. наименование"
                  value={data?.customerAdditionalName}
                  alignLabel="left"
                  type="string"
                />
              </div>
              <div></div>
              <div>
                <Select
                  alignLabel="left"
                  label="Площадка"
                  dataSrc="server"
                  name="tradingFloor"
                  value={data?.tradingFloor?.id}
                  onChange={selectTradingFloor}
                  data={tradingFloors}
                  required
                />

                <Select
                  alignLabel="left"
                  label={"Закон закупки"}
                  name={"legalBasesId"}
                  value={data?.legalBasesId}
                  onChange={onChange}
                  dataSrc="local"
                  data={{
                    values: {
                      None: "Нет",
                      Gov: "44 ФЗ",
                      PartialGov: "223 ФЗ",
                      Other: "Другое",
                    },
                  }}
                  required
                />
                <div style={{ height: 55 }}></div>
                <label>
                  <LabelTitle align="left">
                    Дата окончания подачи заявки
                    <Asterisk />
                  </LabelTitle>
                  <DateWithTimeInput
                    className="input"
                    name="acceptingOffersDeadline"
                    date={
                      data?.acceptingOffersDeadline
                        ? new Date(data?.acceptingOffersDeadline)
                        : null
                    }
                    onChange={(date) =>
                      setData({
                        ...data,
                        acceptingOffersDeadline: date,
                      })
                    }
                    min={new Date("01.01.2000")}
                    required
                  />
                </label>
                <label>
                  <LabelTitle align="left">
                    Дата итогового протокола
                    <Asterisk />
                  </LabelTitle>
                  <DatePicker
                    className="input"
                    name="summaryReportDate"
                    value={data?.summaryReportDate || null}
                    onChange={(date) =>
                      setData({ ...data, summaryReportDate: convertDate(date) })
                    }
                    min={new Date("01.01.2000")}
                    required
                  />
                </label>
                <div>
                  <label
                    className="workspace__input-title"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Контактные данные
                  </label>
                  <textarea
                    className="textarea"
                    name="managerContactData"
                    value={contactData}
                    onChange={(e) => setContactData(e.target.value)}
                  />
                </div>
              </div>
            </Grid>
          </Grommet>
        </form>
      )}
    </div>
  );
};

export default ApprovedTenderStage;
