import { isAccountantUser, isAdmin, isCommercialUser, isLabEmployee, isLabExpert, isLabHead, isTenderUser } from '../utils/defineUser';

const ADDITIONAL_INFO_TENDER = {
  name: 'ADDITIONAL_INFO_TENDER',
  label: 'Отобразить дополнительную информацию:',
  values: {
    tenderId: 'ЕИС (ID на площадке)',
    plannedExecutionDate: 'Плановая дата исполнения',
    tradingFloorName: 'Название площадки',
    startMaxPrice: 'НМЦК',
    ourPrice: 'Наша цена',
    price: 'Цена',
    description: 'Комментарий к заявке',
    failrueDescription: 'Комментарий о причине неподачи',
    offerSecuringMethod: 'Обеспечение заявки',
    contractSecuringMethod: 'Тип обеспечения контракта',
    offerSecurityAmount: 'Сумма обеспечения контракта',
    tradingFloorCommision: 'Комиссия площадки',
    tradingFloorCommissionAmount: 'Сумма комиссии площадки',
    winnerPrice: 'Цена победителя',
    winnerId: 'Победитель',
    assesmentTypeId: 'Тип оценки',
    workspaceCount: 'Число рабочих мест',
    unitCount: 'Число подразделений',
    actAmount: 'Сумма по акту',
    projectStage: 'Этап проекта',
    aggregatorLink: 'Ссылка от агрегатора',
    executionDescription: 'Комментарий к исполнению',
    publicationDate: 'Дата публикации',
    conclusionContractDate: 'Дата заключения договора',
    acceptingOffersDeadline: 'Дата окончания подачи заявки',
    auctionDate: 'Дата аукциона',
    summaryReportDate: 'Дата итогового протокола',
    operationTypeId: 'Вид процедуры',
    legalBasesId: 'Закон госзакупки',
    hasTradingRobot: 'Отыгрывает робот?',
    actDate: 'Дата акта',
  }
}

const ADDITIONAL_INFO_COMMERCIAL = {
  name: 'ADDITIONAL_INFO_COMMERCIAL',
  label: 'Отобразить дополнительную информацию:',
  values: {
    plannedExecutionDate: 'Плановая дата исполнения',
    // tradingFloorName: 'Название площадки',
    assesmentTypeId: 'Тип оценки',
    workspaceCount: 'Число рабочих мест',
    actAmount: 'Сумма по акту',
    projectStage: 'Этап проекта',
    alternateId: 'Идентификационный номер',
    // operationTypeId: 'Вид процедуры',
    conclusionContractDate: 'Дата заключения договора',
    actDate: 'Дата акта',
    paidDate: 'Дата оплаты',
  }
}

const ADDITIONAL_INFO_LAB_HEAD = {
  name: 'ADDITIONAL_INFO_LAB_HEAD',
  label: 'Отобразить дополнительную информацию:',
  values: {
    tenderId: 'ЕИС (ID на площадке)',
    departmentType: 'Тип проекта',
    plannedExecutionDate: 'Плановая дата исполнения',
    tradingFloorName: 'Название площадки',
    startMaxPrice: 'НМЦК',
    offerAmount: 'Наша цена',
    description: 'Комментарий к заявке',
    failrueDescription: 'Комментарий о причине неподачи',
    assesmentTypeId: 'Тип оценки',
    haveWorkbook: 'Рабочая тетрадь',
    executionProgramId: 'Программа выполнения',
    workspaceCount: 'Число рабочих мест',
    actAmount: 'Сумма по акту',
    projectStage: 'Этап проекта',
    aggregatorLink: 'Ссылка от агрегатора',
    executionDescription: 'Комментарий к исполнению',
    operationTypeId: 'Вид процедуры',
    conclusionContractDate: 'Дата заключения договора',
    informationCollectedAt: 'Дата получения полной информации',
    informationCollectionDate: 'Дата получения информации',
    actDate: 'Дата акта',
    acceptedToWorkDate: 'Дата приема в работу',
    sentDate: 'Дата отправки',
    unloadingDate: 'Дата выгрузки',
    measurementsDate: 'Дата замеров',
    performerAppointedDate: 'Дата назначения исполнителя',
    approvalsDate: 'Дата согласования',
    titleSheetSignDate: 'Дата подписания титульного листа',
    paidDate: 'Дата оплаты',
    measurementStatusId: 'Замеры'
  }
}

const ADDITIONAL_INFO_LAB_USER = {
  name: 'ADDITIONAL_INFO_LAB_ASSIST',
  label: 'Отобразить дополнительную информацию:',
  values: {
    departmentType: 'Тип проекта',
    plannedExecutionDate: 'Плановая дата исполнения',
    assesmentTypeId: 'Тип оценки',
    haveWorkbook: 'Рабочая тетрадь',
    executionProgramId: 'Программа выполнения',
    workspaceCount: 'Число рабочих мест',
    projectStage: 'Этап проекта',
    alternateId: 'Идентификационный номер',
    informationCollectedAt: 'Дата получения полной информации',
    executionDescription: 'Комментарий к исполнению',
    conclusionContractDate: 'Дата заключения договора',
    informationCollectionDate: 'Дата получения информации',
    acceptedToWorkDate: 'Дата приема в работу',
    sentDate: 'Дата отправки',
    unloadingDate: 'Дата выгрузки',
    measurementsDate: 'Дата замеров',
    performerAppointedDate: 'Дата назначения исполнителя',
    approvalsDate: 'Дата согласования',
    titleSheetSignDate: 'Дата подписания титульного листа',
  }
}

const ADDITIONAL_INFO_ACCOUNTANT = {
  name: 'ADDITIONAL_INFO_LAB_EXPERT',
  label: 'Отобразить дополнительную информацию:',
  values: {
    offerSecuringMethod: 'Тип обеспечения заявки',
    securityAmount: 'Сумма обеспечения заявки',
    tradingFloorCommision: 'Комиссия площадки',
    assesmentTypeId: 'Тип оценки',
    workspaceCount: 'Число рабочих мест',
    projectStage: 'Этап проекта',
    executionDescription: 'Комментарий к исполнению',
    plannedExecutionDate: 'Плановая дата исполнения',
    conclusionContractDate: 'Дата заключения договора',
    offerSecurityAmount: 'Сумма обеспечения контракта',
  }
}

const ADDITIONAL_INFO_ADMIN = {
  name: 'ADDITIONAL_INFO_ADMIN',
  label: 'Отобразить дополнительную информацию:',
  values: {
    ...ADDITIONAL_INFO_TENDER.values,
    ...ADDITIONAL_INFO_COMMERCIAL.values,
    ...ADDITIONAL_INFO_LAB_HEAD.values,
    ...ADDITIONAL_INFO_LAB_USER.values,
    ...ADDITIONAL_INFO_ACCOUNTANT.values
  }
}

const ADDITIONAL_INFO_NAMINGS = {
  tenderDepartment: { tenderDepartment: 'Сотрудник тендерного отдела' },
  commercialDepartment: { commercialDepartment: 'Сотрудник коммерческого отдела' },
  laboratory: { laboratory: 'Сотрудник лаборатории' },
  workerId: { workerId: 'Замерщик' },
  regions: { regions: 'Регионы' },
}

const ADDITIONAL_SORT_PARAMS = {
  tenderId: { TenderId: 'ЕИС (ID на площадке)' },
}

const ADDITIONAL_INFO_TENDERS = {
  name: 'ADDITIONAL_INFO_TENDERS',
  label: 'Отобразить дополнительную информацию:',
  values: {
    // id: 'ID Тендера',
    publicationDate: 'Дата публикации',
    acceptingOffersDeadline: 'Дата окончания подачи заявки',
    auctionDate: 'Дата аукциона',
    summaryReportDate: 'Дата итогового протокола',
    winnerPrice: 'Цена победителя', 
    tradingFloorName: 'Площадка',
    personInCharge: 'Ответственный',
    legalBasesId: 'Закон госзакупки',
    hasTradingRobot: 'Кто отыгрывает',
    contractPrice: 'Цена',
    winner: 'Победитель',
    eis: 'ЕИС (ID)',
    assessmentTypeList: 'Тип оценки',
    regions: 'Регион',
    startMaxPrice: 'НМЦК',
    ourPrice: 'Наша цена',
    operationTypeId: 'Вид процедуры',
    contractsCount: 'Количество договоров',
  }
}

let updatedData;

//? Blue column data
export const getAdditionalDataByUserDepartment = () => {
  if (isAdmin() && localStorage.getItem('__entity-type') === 'tender') return ADDITIONAL_INFO_TENDERS;

  if (isAdmin()) return ADDITIONAL_INFO_ADMIN;

  if (isTenderUser()) {
    updatedData = Object.assign({}, ADDITIONAL_INFO_TENDER);
    updatedData.values = {
      ...ADDITIONAL_INFO_TENDER.values,
      ...ADDITIONAL_INFO_NAMINGS.tenderDepartment,
      ...ADDITIONAL_INFO_NAMINGS.regions
    };
    return updatedData;
  };

  if (isCommercialUser()) {
    updatedData = Object.assign({}, ADDITIONAL_INFO_COMMERCIAL);
    updatedData.values = {
      ...ADDITIONAL_INFO_COMMERCIAL.values,
      ...ADDITIONAL_INFO_NAMINGS.commercialDepartment,
      ...ADDITIONAL_INFO_NAMINGS.regions
    };
    return updatedData;
  }

  if (isLabHead()) {
    updatedData = Object.assign({}, ADDITIONAL_INFO_LAB_HEAD);
    updatedData.values = {
      ...ADDITIONAL_INFO_LAB_HEAD.values,
      ...ADDITIONAL_INFO_NAMINGS.tenderDepartment,
      ...ADDITIONAL_INFO_NAMINGS.commercialDepartment,
      ...ADDITIONAL_INFO_NAMINGS.laboratory,
      ...ADDITIONAL_INFO_NAMINGS.workerId,
      ...ADDITIONAL_INFO_NAMINGS.regions
    };
    return updatedData;
  };

  if (isLabEmployee() || isLabExpert()) {
    updatedData = Object.assign({}, ADDITIONAL_INFO_LAB_USER);
    updatedData.values = {
      ...ADDITIONAL_INFO_LAB_USER.values,
      ...ADDITIONAL_INFO_NAMINGS.tenderDepartment,
      ...ADDITIONAL_INFO_NAMINGS.commercialDepartment,
      ...ADDITIONAL_INFO_NAMINGS.laboratory,
      ...ADDITIONAL_INFO_NAMINGS.workerId,
      ...ADDITIONAL_INFO_NAMINGS.regions
    };
    return updatedData;
  };

  if (isAccountantUser()) {
    updatedData = Object.assign({}, ADDITIONAL_INFO_ACCOUNTANT);
    updatedData.values = {
      ...ADDITIONAL_INFO_ACCOUNTANT.values,
      ...ADDITIONAL_INFO_NAMINGS.regions
    };
    return updatedData;
  };
}

//*Green column data
export const getAdditionalSortValuesByUserDepartment = () => {
  if (isAdmin()) return ADDITIONAL_INFO_ADMIN.values;
  if (isTenderUser()) return ADDITIONAL_INFO_TENDER.values;
  if (isCommercialUser()) return ADDITIONAL_INFO_COMMERCIAL.values;
  if (isLabHead()) return ADDITIONAL_INFO_LAB_HEAD.values;
  if (isLabEmployee()) return ADDITIONAL_INFO_LAB_USER.values;
  if (isLabExpert()) return ADDITIONAL_INFO_LAB_USER.values;
  if (isAccountantUser()) return ADDITIONAL_INFO_ACCOUNTANT.values;
}
