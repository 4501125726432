import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar";
import postData from "../../../../utils/PostData";
import {
  TENDER_DEFAULT_USER_ID,
  COM_DEFAULT_USER_ID,
  LAB_DEFAULT_USER_ID,
} from "../../../../utils/DefaultUsersID";
import Asterisk from "../../../../components/Asterisk";
import SelectRegions from "../../../../components/common/SelectRegions";
import {
  isAdmin,
  isCommercialUser,
  isLabEmployee,
  isSoutUserArea,
} from "../../../../utils/defineUser";
import { api } from 'settings/Api';

const AddProjectCom = () => {
  let { user } = useContext(AuthContext);
  //TODO: Rename to AUTHORIZED_USER_ID
  const CURRENT_USER_ID = user.nameid;

  const navigate = useNavigate();

  const defaultValues = {
    actAmount: null,
    departmentType: "CommercialWorkFlow",
    inn: null,
    customerName: null,
    contactData: null,
    price: null,
    contractNumber: null,
    conclusionContractDate: null,
    plannedExecutionDate: null,
    tenderId: null,
    tradingFloorId: null,
    publicationDate: null,
    acceptingOffersDeadline: null,
    startMaxPrice: null,
    offerAmount: null,
    description: null,
    auctionDate: null,
    failrueDescription: null,
    offerSecuringMethod: "None",
    securityAmount: null,
    contractSecuringMethod: "None",
    offerSecurityAmount: null,
    tradingFloorCommision: "None",
    tradingFloorCommissionAmount: null,
    winnerPrice: null,
    winnerId: null,
    assesmentTypeId: "None",
    haveWorkbook: false,
    executionProgramId: "None",
    tenderUserId: null,
    commercialUserId: isAdmin() ? COM_DEFAULT_USER_ID : CURRENT_USER_ID,
    laboratoryUserId: null,
    workspaceCount: 0,
    unitCount: 1,
    workerId: null,
    peopleForMedicalExamination: null,
    aggregatorLink: null,
    informationCollectedAt: null,
    alternateId: null,
    executionDescription: null,
    regions: [],
    KPP: null,
    measurementStatusId: 'NotAssigned',
    additionalCustomerName: null,
  };

  const [values, setValues] = useState(defaultValues);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const [tradingFloors, getTradingFloors] = useState("");
  const [competitors, getCompetitors] = useState("");
  const [tenderUsers, getTenderUsers] = useState("");
  const [commercialUsers, getCommercialUsers] = useState("");
  const [laboratoryUsers, getLaboratoryUsers] = useState("");
  const [workers, getWorkers] = useState("");

  const updateRegions = (updatedRegions) =>
    setValues({ ...values, regions: updatedRegions });

  let request_body = {};

  if (localStorage.getItem("__user-area") === "sout") {
    request_body = {
      id: null,
      archiveId: null,
      assesmentTypeId: values.assesmentTypeId,
      haveWorkbook: values.haveWorkbook === "true",
      executionProgramId: values.executionProgramId,
      unitCount: parseInt(values.unitCount),
      workspaceCount: parseInt(values.workspaceCount),
      contract: {
        id: null,
        number: values.contractNumber,
        customer: {
          id: null,
          inn: values.inn === "" ? null : values.inn,
          name: values.customerName === "" ? null : values.customerName,
          contactData: values.contactData === "" ? null : values.contactData,
        },
        price: parseFloat(values.price),
        actAmount: values.actAmount,
        postage: null,
        securityAmount: parseFloat(values.securityAmount),
        tradingFloorCommissionAmount: parseFloat(
          values.tradingFloorCommissionAmount
        ),
        offerSecurityAmount: parseFloat(values.offerSecurityAmount),
        payments: null,
        conclusionContractDate: values.conclusionContractDate,
        plannedExecutionDate: values.plannedExecutionDate,
        description: null,
        additionalCustomerName: values.additionalCustomerName,
        actDate: values.actDate,
        paidDate: values.paidDate,
        actNumber: values.actNumber,
      },
      tender: {
        id: null,
        tradingFloor: {
          id: parseInt(values.tradingFloorId),
        },
        tenderId: values.tenderId,
        publicationDate: values.publicationDate,
        acceptingOffersDeadline: values.acceptingOffersDeadline,
        startMaxPrice: parseFloat(values.startMaxPrice),
        offerAmount: parseFloat(values.offerAmount),
        description: values.description,
        auctionDate: values.auctionDate,
        failrueDescription: values.failrueDescription,
        offerSecuringMethodId: values.offerSecuringMethod,
        contractSecuringMethodId: values.contractSecuringMethod,
        tradingFloorCommisionId: values.tradingFloorCommision,
        winnerPrice: parseFloat(values.winnerPrice),
        winner: {
          id: parseInt(values.winnerId),
        },
      },
      documents: null,
      users: [
        values.tenderUserId && values.tenderUserId !== "null"
          ? { id: values.tenderUserId, departmentId: "TenderDepartment" }
          : { id: TENDER_DEFAULT_USER_ID, departmentId: "TenderDepartment" },

        values.commercialUserId && values.commercialUserId !== "null"
          ? {
            id: values.commercialUserId,
            departmentId: "CommercialDepartment",
          }
          : { id: COM_DEFAULT_USER_ID, departmentId: "CommercialDepartment" },

        values.laboratoryUserId && values.laboratoryUserId !== "null"
          ? { id: values.laboratoryUserId, departmentId: "Laboratory" }
          : { id: LAB_DEFAULT_USER_ID, departmentId: "Laboratory" },
      ],
      worker: {
        id: parseInt(values.workerId),
      },
      workFlow: {
        id: null,
        typeId: values.departmentType,
        currentWorkFlowStageId: "Draft",
        projectStatusId: "Draft",
        isActive: true,
      },
      informationCollectedAt: values.informationCollectedAt,
      alternateId: values.alternateId,
      executionDescription: values.executionDescription,
      regions: values.regions,
      informationCollectionDate: values.informationCollectionDate,
      acceptedToWorkDate: values.acceptedToWorkDate,
      sentDate: values.sentDate,
      unloadingDate: values.unloadingDate,
      measurementsDate: values.measurementsDate,
      performerAppointedDate: values.performerAppointedDate,
      approvalsDate: values.approvalsDate,
      titleSheetSignDate: values.titleSheetSignDate,
      monthlyReportDate: values.monthlyReportDate,
      KPP: values.KPP,
      measurementStatusId: values.measurementStatusId,
    };
  }

  if (localStorage.getItem("__user-area") === "med") {
    request_body = {
      id: null,
      archiveId: null,
      assesmentTypeId: null,
      executionProgramId: null,
      unitCount: parseInt(values.unitCount),
      contract: {
        id: null,
        number: values.contractNumber,
        customer: {
          id: null,
          inn: values.inn === "" ? null : values.inn,
          name: values.customerName === "" ? null : values.customerName,
          contactData: values.contactData === "" ? null : values.contactData,
        },
        price: parseFloat(values.price),
        actAmount: values.actAmount,
        conclusionContractDate: values.conclusionContractDate,
        plannedExecutionDate: values.plannedExecutionDate,
        description: null,
        additionalCustomerName: values.additionalCustomerName,
      },
      tender: {
        id: null,
        tradingFloor: {
          id: null,
        },
      },
      documents: null,
      users: [
        values.tenderUserId && values.tenderUserId !== "null"
          ? { id: values.tenderUserId, departmentId: "TenderDepartment" }
          : { id: TENDER_DEFAULT_USER_ID, departmentId: "TenderDepartment" },

        values.commercialUserId && values.commercialUserId !== "null"
          ? {
            id: values.commercialUserId,
            departmentId: "CommercialDepartment",
          }
          : { id: COM_DEFAULT_USER_ID, departmentId: "CommercialDepartment" },

        values.laboratoryUserId && values.laboratoryUserId !== "null"
          ? { id: values.laboratoryUserId, departmentId: "Laboratory" }
          : { id: LAB_DEFAULT_USER_ID, departmentId: "Laboratory" },
      ],
      med: {
        peopleForMedicalExamination:
          parseInt(values.peopleForMedicalExamination) ?? 0,
      },
      workFlow: {
        id: null,
        typeId: values.departmentType,
        currentWorkFlowStageId: "Draft",
        projectStatusId: "Draft",
        isActive: true,
      },
      aggregatorLink: values.aggregatorLink,
      informationCollectedAt: values.informationCollectedAt,
      alternateId: values.alternateId,
      executionDescription: values.executionDescription,
      regions: values.regions,
    };
  }

  useEffect(() => {
    fetchUsers("TenderDepartment");
    fetchUsers("CommercialDepartment");
    fetchUsers("Laboratory");
    fetchWorkers();
    fetchCompetitors();
    fetchTradingFloors();
  }, []);

  const fetchUsers = async (departmentName) => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(
      `/api/sout/helpers/handbooks/engineers?department=${departmentName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // 'Authorization':  'String(authTokens.access) ,
        },
      }
    );
    let data = await response.json();

    switch (departmentName) {
      case "TenderDepartment":
        getTenderUsers(data.data);
        break;
      case "CommercialDepartment":
        getCommercialUsers(data.data);
        break;
      case "Laboratory":
        getLaboratoryUsers(data.data);
        break;
      default:
        throw new Error("Fetch error: department cannot found!");
    }
  };

  const fetchWorkers = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(`/api/sout/helpers/handbooks/workers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();
    getWorkers(data.data);
  };

  const fetchCompetitors = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    let response = await api(`/api/sout/helpers/handbooks/competitors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();
    getCompetitors(data.data);
  };

  const fetchTradingFloors = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    let response = await api(`/api/sout/helpers/handbooks/tradingfloors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();
    getTradingFloors(data.data);
  };

  const [focused, setFocused] = useState({
    customerName: false,
    inn: false,
    assesmentTypeIdY: false,
    assesmentTypeIdX: false,
    peopleForMedicalExamination: false,
  });

  const [displayErrors, setDisplayErrors] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const status = await postData(
      request_body,
      user,
      localStorage.getItem("__user-area")
    );
    if (status !== 200) {
      return false;
    } else {
      navigate(`/${localStorage.getItem("__user-area")}/commercial/draft`);
    }
  };

  const [customerNameViaINN, setCustomerNameViaINN] = useState('Введите ИНН для поиска заказчика...');


  const customerNameHandler = (name, inn) => {
    setCustomerNameViaINN(name);
    setValues({
      ...values,
      customerName: name,
      inn: inn,
    });
  }

  const [isSearching, setIsSearching] = useState(false);


  const getCustomerNameByINN = async (e) => {
    const inn = e.target.value;
    inputChangeHandler({ target: { name: e.target.name, value: inn } });

    if (inn.length === 10 || inn.length === 12) {
      setIsSearching(true)

      const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;

      api(`/api/account/fetchinfobyinn?inn=${inn}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setTimeout(() => {
              customerNameHandler(data.organizationName, inn)
              setIsSearching(false)
            });

          } else {
            setCustomerNameViaINN(false);
            setIsSearching(false);
            return;
          }
        });
    } else {
      setCustomerNameViaINN(false);
      setIsSearching(false)
    }
  };

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3 className="title workspace__page-title">
            Добавить проект | Коммерческий отдел{" "}
          </h3>
          <form
            onSubmit={handleSubmit}
            className={`form ${displayErrors ? "form--isValid" : ""}`}
          >
            <section className="form__column">
              <fieldset className="workspace__ensure-wrapper">
                <h4 className="subtitle">Заказчик</h4>
                <label className="workspace__input-title">
                  ИНН заказчика:
                  <Asterisk />
                  <input
                    className="input"
                    type="text"
                    maxLength="12"
                    name="inn"
                    value={values.inn}
                    onChange={(e) => getCustomerNameByINN(e)}
                    onPaste={(e) => getCustomerNameByINN(e)}
                    onBlur={() => setFocused({ ...focused, inn: true })}
                    focused={focused.inn.toString()}
                    required={true}
                    pattern="^(\d{10}|\d{12})$"
                  />
                  <span className="error-message">
                    Введите 10 или 12 цифр
                  </span>
                </label>
                <label className="workspace__input-title" style={{ maxWidth: '380px' }}>
                  Наименование заказчика:
                  {
                    isSearching
                      ? <p>Поиск по базе данных...</p>
                      : customerNameViaINN
                        ? <p>{customerNameViaINN}</p>
                        : <span className="error-message visible">Заказчик не найден!</span>
                  }
                  {/* customerName ?AcceptedToWorkCom
												<p>{customerNameViaINN}</p> */}
                  {/* <Asterisk /> */}
                  {/* <input
													className="input"
													type="text"
													name="customerName"
													value={values.customerName}
													onChange={(e) => inputChangeHandler(e)}
													onBlur={() =>
														setFocused({ ...focused, customerName: true })
													}
													focused={focused.customerName.toString()}
													required={true}
													pattern="^[A-Za-z0-9]{\d+}$"
												/>
												<span className="error-message">
													Введите наименование заказчика
												</span> */}
                </label>


                <label className="workspace__input-title">
                  Дополнительное наименование:
                </label>
                <input
                  className="input"
                  type="text"
                  name="additionalCustomerName"
                  value={values.additionalCustomerName}
                  onChange={(e) => inputChangeHandler(e)}
                />

                <label className="workspace__input-title">
                  Контактные данные:
                </label>
                <textarea
                  className="input"
                  rows="7"
                  name="contactData"
                  value={values.contactData}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </fieldset>

              <fieldset className="workspace__ensure-wrapper">
                <h4 className="subtitle  ">Контракт</h4>
                <label className="workspace__input-title">
                  Номер контракта:
                </label>
                <input
                  className="input"
                  type="text"
                  name="contractNumber"
                  value={values.contractNumber}
                  onChange={(e) => inputChangeHandler(e)}
                />
                {!isLabEmployee() && (
                  <>
                    <label className="workspace__input-title">
                      Цена контракта:
                    </label>
                    <input
                      className="input"
                      type="number"
                      name="price"
                      value={values.price}
                      onChange={(e) => inputChangeHandler(e)}
                      onWheel={(e) => e.target.blur()}
                    />
                    <label className="workspace__input-title">
                      Сумма по акту:
                    </label>
                    <input
                      className="input"
                      type="number"
                      name="actAmount"
                      value={values.actAmount}
                      onChange={(e) => inputChangeHandler(e)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </>
                )}

                <label className="workspace__input-title">
                  КПП:
                </label>
                <input
                  className="input"
                  type="text"
                  name="KPP"
                  value={values.KPP}
                  onChange={(e) => inputChangeHandler(e)}
                />

                {/* <label className="workspace__input-title">
									Замеры:
									<select
										className="select"
										name="measurementStatusId"
										value={values.measurementStatusId}
										onChange={(e) => inputChangeHandler(e)}
									>
										<option value="NotAssigned">Не назначены</option>
										<option value="NotRequired">Не требуются</option>
										<option value="Assigned">Назначены</option>
										<option value="Complited">Проведены</option>
									</select>
								</label> */}
              </fieldset>
            </section>
            <section className="form__column">
              <fieldset className="workspace__ensure-wrapper">
                <h4 className="subtitle workspace__subtitle">Даты</h4>
                <label className="workspace__input-title">
                  Дата заключения договора:
                </label>
                <input
                  className="input"
                  type="date"
                  name="conclusionContractDate"
                  value={values.conclusionContractDate}
                  onChange={(e) => inputChangeHandler(e)}
                />

                <label className="workspace__input-title">
                  Плановая дата исполнения:
                </label>
                <input
                  className="input"
                  type="date"
                  name="plannedExecutionDate"
                  value={values.plannedExecutionDate}
                  onChange={(e) => inputChangeHandler(e)}
                />
                {!isLabEmployee() && (
                  <>
                    <label className="workspace__input-title">
                      Дата акта:
                      <input
                        className="input"
                        type="date"
                        name="actDate"
                        value={values.actDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата оплаты:
                    </label>
                    <input
                      className="input"
                      type="date"
                      name="paidDate"
                      value={values.paidDate}
                      onChange={(e) => inputChangeHandler(e)}
                    />
                  </>
                )}
                {(!isCommercialUser() || isAdmin()) && (
                  <>
                    <label className="workspace__input-title">
                      Дата получения информации:
                      <input
                        className="input"
                        type="date"
                        name="informationCollectionDate"
                        value={values.informationCollectionDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата получения полной информации:
                      <input
                        className="input"
                        type="date"
                        name="informationCollectedAt"
                        value={values.informationCollectedAt}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата приема в работу:
                      <input
                        className="input"
                        type="date"
                        name="acceptedToWorkDate"
                        value={values.acceptedToWorkDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата отправки:
                      <input
                        className="input"
                        type="date"
                        name="sentDate"
                        value={values.sentDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата выгрузки:
                      <input
                        className="input"
                        type="date"
                        name="unloadingDate"
                        value={values.unloadingDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      {" "}
                      Дата замеров:
                      <input
                        className="input"
                        type="date"
                        name="measurementsDate"
                        value={values.measurementsDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата назначения исполнителя:
                      <input
                        className="input"
                        type="date"
                        name="performerAppointedDate"
                        value={values.performerAppointedDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата согласования:
                      <input
                        className="input"
                        type="date"
                        name="approvalsDate"
                        value={values.approvalsDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата отправки:
                      <input
                        className="input"
                        type="date"
                        name="sentDate"
                        value={values.sentDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                    <label className="workspace__input-title">
                      Дата подписания титульного листа:
                      <input
                        className="input"
                        type="date"
                        name="titleSheetSignDate"
                        value={values.titleSheetSignDate}
                        onChange={(e) => inputChangeHandler(e)}
                      />
                    </label>
                  </>
                )}
              </fieldset>
            </section>

            <div className="form__column">
              <div className="workspace__ensure-wrapper">
                <h4 className="subtitle workspace__subtitle">Прочее</h4>
                {isSoutUserArea() && (
                  <>
                    <label className="workspace__input-title">
                      Идентификационный номер:
                    </label>
                    <input
                      className="input"
                      type="text"
                      name="alternateId"
                      value={values.alternateId}
                      onChange={(e) => inputChangeHandler(e)}
                    />
                  </>
                )}
                <label className="workspace__input-title">
                  Комментарий к исполнению:
                </label>
                <textarea
                  className="input"
                  rows="7"
                  name="executionDescription"
                  value={values.executionDescription}
                  onChange={(e) => inputChangeHandler(e)}
                />
                {isSoutUserArea() && (
                  <>
                    <fieldset className="workspace__ensure-wrapper evaluation-type">
                      <label className="workspace__input-ensure">
                        Тип оценки:
                        <Asterisk />
                      </label>
                      <input
                        id="sout"
                        className="radio"
                        type="radio"
                        name="assesmentTypeId"
                        value="WorkingConditionsSpecialAssesment"
                        checked={
                          values.assesmentTypeId ===
                          "WorkingConditionsSpecialAssesment"
                        }
                        onChange={(e) => inputChangeHandler(e)}
                      />
                      <label htmlFor="sout" className="radio__label">
                        СОУТ
                      </label>

                      <input
                        id="pc"
                        className="radio"
                        type="radio"
                        name="assesmentTypeId"
                        value="ProductionControl"
                        checked={values.assesmentTypeId === "ProductionControl"}
                        onChange={(e) => inputChangeHandler(e)}
                        required={true}
                      />
                      <label
                        htmlFor="pc"
                        className="radio__label radio__label--ml"
                      >
                        ПК
                      </label>
                      <span className="error-message">
                        Выберите СОУТ или ПК
                      </span>
                    </fieldset>
                    <h4 className="subtitle  ">Ответственные</h4>
                    <label className="workspace__input-title">
                      Коммерческий отдел:
                    </label>
                    <select
                      className="select"
                      name="commercialUserId"
                      value={values.commercialUserId}
                      onChange={(e) => inputChangeHandler(e)}
                      disabled={isAdmin() ? false : true}
                    >
                      {commercialUsers &&
                        commercialUsers.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {" "}
                            {name}{" "}
                          </option>
                        ))}
                    </select>

                    <label className="workspace__input-title">
                      Число рабочих мест:
                    </label>
                    <input
                      className="input"
                      type="number"
                      name="workspaceCount"
                      value={values.workspaceCount}
                      onChange={(e) => inputChangeHandler(e)}
                      onWheel={(e) => e.target.blur()}
                    />

                    <SelectRegions
                      regions={values.regions}
                      updateRegions={updateRegions}
                    />
                  </>
                )}
                {localStorage.getItem("__user-area") === "med" && (
                  <>
                    <fieldset className="workspace__ensure-wrapper evaluation-type">
                      <label className="workspace__input-title">
                        Количество людей:
                        <Asterisk />
                      </label>
                      <input
                        className="input"
                        type="number"
                        name="peopleForMedicalExamination"
                        value={values.peopleForMedicalExamination}
                        onChange={(e) => inputChangeHandler(e)}
                        onWheel={(e) => e.target.blur()}
                        onBlur={() =>
                          setFocused({
                            ...focused,
                            peopleForMedicalExamination: true,
                          })
                        }
                        focused={focused.peopleForMedicalExamination}
                        required={true}
                        pattern="^[0-9]{\d+}$"
                      />
                    </fieldset>
                    <h4 className="subtitle  ">Ответственные</h4>
                    <label className="workspace__input-title">
                      Коммерческий отдел:
                    </label>
                    <select
                      className="select"
                      name="commercialUserId"
                      value={values.commercialUserId}
                      onChange={(e) => inputChangeHandler(e)}
                      disabled={isAdmin() ? false : true}
                    >
                      {commercialUsers &&
                        commercialUsers.map(({ id, name }) => (
                          <option key={id} value={id}>
                            {" "}
                            {name}{" "}
                          </option>
                        ))}
                    </select>
                  </>
                )}
              </div>
              <button
                type="submit"
                className="button staff__create-staff-button"
                onClick={() => setDisplayErrors(true)}
              >
                Добавить
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};
export default AddProjectCom;
