import { DatePicker } from "components/DateInput/DateInput";
import FileInput from "pages/sout/accountant/common/FileInput";
import React, { useEffect, useState } from "react";

const ActsContractStage = ({ contract, act, setAct, setIsDisabled }) => {
  const [isUpload, setIsUpload] = useState(true);
  const validateNumber = (value) => {
    return value.replace(/[^0-9]/g, "");
  };

  useEffect(() => {
    setIsDisabled(!(act?.date && act?.sum));
  }, [act?.date, act?.number, act?.sum, setIsDisabled, isUpload]);

  return (
    <div className="modal_align_center">
      <div style={{ width: 250 }}>
        <label className="modal_display_start">Дата акта:</label>
        <DatePicker
          className="input"
          name="conclusionContractDate"
          value={act?.date || null}
          onChange={(date) => setAct({ ...act, date: date })}
          required={true}
          min={new Date("01.01.2000")}
        />
      </div>
      {/* <div style={{ width: 250 }}>
        <label htmlFor="" className="modal_display_start">
          Номер акта
        </label>
        <input
          type="text"
          className="input"
          value={act?.number}
          onChange={(e) =>
            setAct({ ...act, number: validateNumber(e.target.value) })
          }
        />
      </div> */}
      <div style={{ width: 250 }}>
        <label htmlFor="" className="modal_display_start">
          Сумма по акту
        </label>
        <input
          type="number"
          className="input"
          value={act?.sum}
          onChange={(e) => setAct({ ...act, sum: e.target.value })}
        />
      </div>
      <div style={{ width: 250, marginTop: 10 }}>
        <FileInput
          modelId={contract?.id}
          entity={"contracts"}
          link={"Act"}
          title={"Загрузить акт"}
          setIsUpload={setIsUpload}
        />
      </div>
    </div>
  );
};

export default ActsContractStage;
