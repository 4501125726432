export const DocActionModal = ({ title, onYes, onNo, colorThemeClass }) => {
    return (
      <>
        <h3 className='modal__description'>
          {title}
        </h3>
        <div
          className="buttons"
          style={
            {
              width: '60%',
              display: 'flex',
              justifyContent: 'space-between',
              margin: 'auto'
            }
          }
        >
          <button className={`button ${colorThemeClass}`} onClick={onYes}>Да</button>
          <button className={`button ${colorThemeClass}`} onClick={onNo}>Нет</button>
        </div>
      </>
    );
  } 