export const Button = ({ className, children, color, onClick, ...props }) => {
    return (
        <button
            className={`button button--${color} ${className || ''}`}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
}