export const manualList = {
    Entities: [
        {title: 'Тендеры', link: 'TendersInfo'},
        {title: 'Договоры', link: 'ContractsInfo'},
        {title: 'Проекты', link: 'ProjectsInfo'},
        {title: 'Замерные блоки', link: 'LocationsInfo'},
    ],
    Users: [
        {title: 'Начальник тендерного отдела', link: 'TenderChief'},
        {title: 'Сотрудник тендерного отдела', link: 'TenderEmp'},
        {title: 'Начальник коммерческого отдела', link: 'ComercialChief'},
        {title: 'Сотрудник коммерческого отдела', link: 'ComercialEmp'},
        {title: 'Начальник лаборатории', link: 'LabChief'},
        {title: 'Сотрудник лаборатории', link: 'LabEmp'},
        {title: 'Заведующий лабораторией', link: 'LabManager'},
        {title: 'Эксперт лаборатории', link: 'LabExpert'},
        {title: 'Помощник заведующего лабораторией', link: 'LabAssist'},
    ]
}

export const manualData = {
    TendersInfo: {title: 'Тендеры', text: '111111111'} ,
    ContractsInfo: {title: 'Договоры', text: '22222222222222222'},
    ProjectsInfo: {title: 'Проекты', text: '33333333333'},
    LocationsInfo: {title: 'Замерные блоки', text: '44444444444444444'} ,
    TenderChief : {title: 'Начальник тендерного отдела', text: '55555555555555'} ,
    TenderEmp: {title: 'Сотрудник тендерного отдела', text: '666666666666'},
    ComercialChief: {title: 'Начальник коммерческого отдела', text: '7777777777777777'},
    ComercialEmp: {title: 'Сотрудник коммерческого отдела', text: '8888888888888888'},
    LabChief: {title: 'Начальник лаборатории', text: '99999999999999999'},
    LabEmp: {title: 'Сотрудник лаборатории', text: '132123123'},
    LabManager: {title: 'Заведующий лабораторией', text: '43535345345'},
    LabExpert: {title: 'Эксперт лаборатории', text: '756756756756'},
    LabAssist: {title: 'Помощник заведующего лабораторией', text: '234324324'},        
}