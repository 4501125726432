import { generateToken } from 'shared/generateToken';
import { api, showValidateMessage } from 'settings/Api';

export const editContract = async (values, cb) => {
  const token = generateToken();
  try {
    const response = await api('/api/sout/admin/contracts', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
      ,
      body: JSON.stringify(values)
    }).then((res) => {
      res?.json().then((r) => {
        if(res.status === 400) {
          showValidateMessage(r?.data?.errors)
      }
      })
      if (res?.status === 200) {
        alert("Договор успешно изменён.")
        cb();
      }
    })
  }
  catch (e) {
    alert(`Произошла ошибка: ${e}`);
  }
}