import { fetchAgents } from "./fetchAgents";

export const getAgents = async (setAgents) => {
  try {
    const agents = await fetchAgents();
    setAgents(agents);
  } catch (error) {
    console.error(error);
    return null;
  }
};
