import { Portal } from "components/Portal/Portal";
import ModalWrapper from "components/modals/ModalWrapper";
import { Select } from "components/Select/Select";
import { Button } from "shared/ui/Button/Button";
import { useContext } from "react";
import styled from "styled-components";
import AgentContext from "context/ParticipantsOfProcessContext";
import { useNavigate } from "react-router-dom";

export const ChoiceAgentType = ({ close, required }) => {
  const { agentType, setAgentType } = useContext(AgentContext);

  const onClick = () => {
    if (agentType) {
      close();
    }
  };

  const navigate = useNavigate();

  const Content = styled.div`
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 30vw;
  `;

  const Buttons = styled.div`
    display: flex;
    justify-content: ${(props) =>
      props.required ? "center" : "space-between"};
    width: 60%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 20px;
  `;

  return (
    <Portal>
      <ModalWrapper>
        <Content>
          <Select
            className={`workspace__input-title selectInput`}
            label="Добавить агента"
            name="ContractConclusionDepartmentId"
            dataSrc="local"
            onChange={(e) => setAgentType(e.target.value)}
            value={agentType}
            data={{
              values: {
                Organization: "Организация",
                Individual: "Физическое лицо",
              },
            }}
          />
          <Buttons required={required}>
            <Button color="green" onClick={onClick}>
              Создать
            </Button>
            <Button color="green" onClick={() => navigate("/ListOfAgents")}>
              Отмена
            </Button>
          </Buttons>
        </Content>
      </ModalWrapper>
    </Portal>
  );
};
