import { generateToken } from 'shared/generateToken';
import { api } from 'settings/Api';

export const fetchLocationByID = async (locationId, locType) => {
  const token = generateToken();
  
  const urls = {
		Contract: "contract-measuring-blocks",
		Potential: "potential-measuring-blocks",
	};
	const baseURL = "/api/sout/admin";
  const url = `${baseURL}/${urls[locType]}/${locationId}`;
  try {
    const response = await api(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    const data = await response.json();
    if (response.status !== 200) return response.status;
    
    if (data.isSuccess) return { ...data.data };

    if (data.isFailure) throw new Error(data);

  } catch (e) {
    alert(`Произошла ошибка: ${e}`);
  }
}