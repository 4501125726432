import { useCallback, useEffect, useState } from "react";
import cls from "./ShowDeadlines.module.scss";
import { workspaceCountView } from "components/common/WorkspaceCountView";

const valueHandler = (value) => (isNaN(value) ? 0 : value);

const Deadline = ({ date, values, assessmentTypeKey }) => {
  console.log(date);
  console.log(values);

  const getDate = (arr) => {
    const months = [
      "Янв.",
      "Фев.",
      "Мар.",
      "Апр.",
      "Май.",
      "Июн.",
      "Июл.",
      "Авг.",
      "Сен.",
      "Окт.",
      "Ноя.",
      "Дек.",
    ];

    return `${months[parseInt(arr[0]) - 1]}${arr[1]}`;
  };

  const [projectsCount, setProjectsCount] = useState();
  const [workspaceCount, setWorkspaceCount] = useState();

  useEffect(() => {
    setProjectsCount(values[0][assessmentTypeKey]?.projectsCount);
    setWorkspaceCount(values[0][assessmentTypeKey]?.workspaceCount);
  }, []);

  return (
    <p
      style={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <span>{getDate(date.split("_"))}</span>
      <span>
        {projectsCount} /{" "}
        {workspaceCountView(workspaceCount, values[0][assessmentTypeKey].withX)}
      </span>
    </p>
  );
};

const ShowDeadlines = ({ data, assessmentType }) => {
  const [isShowed, setIsShowed] = useState(false);
  console.log(data);
  const [groupedProjectDates, setGroupedProjectDates] = useState([]);

  const addZero = (number) => {
    if (number >= 1 && number <= 9) {
      return `0${number}`;
    }
    return number;
  };

  const sortDates = (obj) => {
    const arr = Object.entries(obj);
    return arr.sort((a, b) => {
      var dateA = new Date(...a[0].split("_")); // Преобразуем строку в объект Date
      var dateB = new Date(...b[0].split("_"));
      return dateA - dateB; // Сравниваем даты
    });
  };

  const getCurrentDate = () => {
    // Получение текущей даты
    const currentDate = new Date();
    // Получение месяца и года из текущей даты
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Месяцы начинаются с 0
    const year = currentDate.getFullYear().toString();
    // Форматирование даты в "мм_гггг"
    const formattedDate = `${month}_${year}`;
    // Вывод результата
    return formattedDate;
  };

  const compareDates = (date1Str, date2Str) => {
    // Функция для преобразования строки в объект Date
    function parseDate(dateStr) {
      const [monthStr, yearStr] = dateStr.split("_");
      const month = parseInt(monthStr, 10);
      const year = parseInt(yearStr, 10);
      // Создание объекта Date (месяцы начинаются с 0, поэтому вычитаем 1)
      return new Date(year, month - 1);
    }
    // Преобразование строк в объекты Date
    const date1 = parseDate(date1Str);
    const date2 = parseDate(date2Str);
    // Сравнение дат
    return date1 <= date2;
  };

  const groupByDate = useCallback((arr) => {
    const currentDate = getCurrentDate();
    return arr.reduce((acc, item) => {
      const key = `${addZero(item.month)}_${item.year}`;
      if (compareDates(key, currentDate)) {
        if (!acc[currentDate]) {
          acc[currentDate] = [];
        }
        acc[currentDate].push(item);
      } else {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
      }
      return acc;
    }, {});
  }, []);

  const generateDeadline = () => {
    if (data) {
      const projectDates = sortDates(
        groupByDate(data.filter((item) => item[assessmentType]))
      );

      setGroupedProjectDates(projectDates);
    }
  };

  console.log(groupedProjectDates);

  useEffect(() => {
    generateDeadline();
  }, []);

  return data ? (
    <>
      <div
        className={cls.ShowDeadlines}
        onClick={() => {
          isShowed ? setIsShowed(false) : setIsShowed(true);
          generateDeadline();
        }}
      >
        <span
          className={cls.triangle}
          style={{
            transform: !isShowed ? "rotate(90deg)" : "rotate(0)",
          }}
        >
          &#9660;
        </span>
      </div>

      {isShowed && (
        <div className={cls.list}>
          <ul>
            {groupedProjectDates && groupedProjectDates.length > 0 ? (
              <>
                <li>
                  {groupedProjectDates.map(([date, values]) => (
                    <Deadline
                      date={date}
                      values={values}
                      assessmentTypeKey={assessmentType}
                    />
                  ))}
                </li>
              </>
            ) : (
              <p>Сроков нет</p>
            )}
          </ul>
        </div>
      )}
    </>
  ) : null;
};

export default ShowDeadlines;
