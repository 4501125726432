import React, { useContext, useEffect, useState } from "react";
import { GiBookCover } from "react-icons/gi";
import AuthContext from "context/AuthContext";
import HandbookItem from "./HandbookItem";
import { Link } from "react-router-dom";

export const Handbook = () => {
  const { user, startPageData, role } = useContext(AuthContext);
  const [isOpenHandbook, setIsOpenHandbook] = useState(false);
  const [timeoutId, setTimeoutId] = useState("");

  useEffect(() => {
    clearTimeout(timeoutId);
  }, []);

  const handbookList = (
    <ul className="user-panel__info-sublist">
      <>
        {startPageData[role]
          .filter((item) => item.title !== "Для работы вам доступны")
          .map((item, id) =>
            item?.hasOwnProperty("title") ? (
              <HandbookItem
                item={item}
                key={id}
                setIsOpenHandbook={setIsOpenHandbook}
                isOpenHandbook={isOpenHandbook}
              />
            ) : null
          )}
      </>
      <li
        className={`user-panel__info-subitem `}
        onClick={() => setIsOpenHandbook(!isOpenHandbook)}
      >
        <Link to={"/Manual"} className={`user-panel__info-sublink`}>
          Руководство пользователя
        </Link>
      </li>
    </ul>
  );

  const onLeaveFunc = () => {
    setTimeoutId(
      setTimeout(() => {
        setIsOpenHandbook(false);
      }, 5000)
    );
  };

  return (
    <li
      className="user-panel__info-item"
      title={"Справочник"}
      onMouseLeave={onLeaveFunc}
      onMouseEnter={() => clearTimeout(timeoutId)}
    >
      <GiBookCover
        className="user-panel__icon icon-handbook"
        onClick={() => setIsOpenHandbook(!isOpenHandbook)}
      />

      {isOpenHandbook && handbookList}
    </li>
  );
};
export default Handbook;
