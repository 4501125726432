import { formatDate } from "shared/formatDate";
import { translateText } from "settings/stages";
import { formatDateWithTime } from "shared/formatDate";
import { toDateWithoutTime } from "utils/formatDate";

const buildUserName = (user) => {
  if (user) {
    return [user.name, user.lastName].join(" ");
  }
  return "-";
};

const getDate = (value) => {
  return value ? formatDate(value) : "-";
};

const getWorkspaceCount = (project) => {
  if (project?.workspaceCount || project?.workspaceCount === 0) {
    return project.workspaceCount;
  }

  return "x";
};

export const getSortMap = (departmentId, project) => {
  return {
    tender: {
      Eis: project?.eis,
      PublicationDate: getDate(project?.publicationDate),
      AcceptingOffersDeadline: formatDateWithTime(project?.acceptingOffersDeadline) || "-",
      ManagerINN: project?.manager?.inn,
      ManagerName: project?.manager?.name,
      AuctionDate: formatDateWithTime(project?.auctionDate) || "-",
      Winner: project?.winner,
      WinnerId: project?.winner?.name ? project?.winner?.name : "-",
      PersonInCharge: buildUserName(project?.personInCharge),
      Description: project?.description,
      ContactData: project?.manager?.contactData,
      StartMaxPrice: project?.startMaxPrice,
      SummaryReportDate: getDate(project?.summaryReportDate),
      WinnerPrice: project?.winnerPrice,
      TradingFloorName: project?.tradingFloor?.name,
      ContractPrice: project?.contractPrice,
      Price: project?.price,
      LegalBasesId: project?.legalBasesId,
      HasTradingRobot: project.hasTradingRobot ? "Робот" : "Не робот",
      AssessmentTypeList: project?.assessmentTypeList,
      ContractsCount: project?.contractsCount,
      OurPrice: project?.ourPrice,
      OperationTypeId: translateText(project?.operationTypeId),
      Regions: project?.regions.map(el => el.name).join(", ")
    },
    projects: {
      AssessmentTypeId: project?.assessmentTypeId,
      CustomerINN: project?.contract?.customer?.inn,
      Region: project?.regions.map((item) => item.name).join(" "),
      HaveWorkbook: project?.haveWorkbook ? "Да" : "Нет",
      KPP: project?.kpp,
      ExecutionProgramId: project?.executionProgramId,
      ContractNumber: project?.contract?.contractNumber,
      DepartmentId: project?.departmentId,
      Name: project?.name,
      ContractDepartmentNumber: project?.contractDepartmentNumber,
      PlannedExecutionDate: toDateWithoutTime(project?.plannedExecutionDate),
      ContactData: project?.contract?.customer?.contactData,
      PersonInChargeLastName: buildUserName(project?.personInCharge),
      WorkspaceCount: getWorkspaceCount(project),
      ExecutionDescription: project?.executionDescription,
      InformationReceiptDate: toDateWithoutTime(project?.informationReceiptDate),
      PerformerAppointedDate:  toDateWithoutTime(project?.performerAppointedDate),
      AcceptedToWorkDate: toDateWithoutTime(project?.acceptedToWorkDate),
      SendingForApprovalDate: toDateWithoutTime(project?.sendingForApprovalDate),
      ReportSendingDate: toDateWithoutTime(project?.reportSendingDate),
      TitleSheetSignDate: toDateWithoutTime(project?.titleSheetSignDate),
      UnloadingDate: toDateWithoutTime(project?.unloadingDate),
    },
  }[departmentId];
};
