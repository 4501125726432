/**
 ** Formates date 
 * @param {string} date - some text
 * @returns - formated date
 */
export const isDate = (text) => {
  if (text === undefined) return false
  if (text && typeof text === 'string') return text.indexOf('T') !== -1 || text.indexOf('-') !== -1
  if (text && typeof text !== 'string') return text.toString().indexOf('T') !== -1 && text.toString().indexOf('-') !== -1
}

const getIdxOfCharT = (date) => date.toString().indexOf('T')

export const formatDate = (date) =>
  (date)
    ? date.toString().slice(0, date.toString().indexOf('T'))
    : date

export const toDateWithTime = (date) => {
  if (date) {
    const ddMMyyyy = date.toString().slice(0, getIdxOfCharT(date)).split('-').reverse().join('.');
    const hhMM = date.toString().slice(getIdxOfCharT(date) + 1, date.length - 3);
    if (ddMMyyyy === '01.01.0001' && hhMM === '00:00') return ' - '
    return ddMMyyyy + ' ' + hhMM;
  }
}

export const toDateWithoutTime = (date) => {
  if (date) {
    const arrayOfDayMounthYear = date.toString().slice(0, getIdxOfCharT(date) === -1 ? date.length : getIdxOfCharT(date));
    const ddMMYYYY = arrayOfDayMounthYear.split('-').reverse().join('.');
    if (ddMMYYYY === '01.01.0001') return ' - '
    return ddMMYYYY;
  }
}

export function removeTimeZone(date) {
  // Получаем компоненты даты и времени в формате UTC
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  const milliseconds = date.getUTCMilliseconds();

  // Создаем новую дату без часового пояса
  const dateWithoutTimeZone = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));

  return dateWithoutTimeZone;
}