import { useContext } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import patchProject from '../../../../../utils/patchProject';
import moveToNextStage from '../../../../../utils/moveToNextStage';

function InfoCollectionAndSigningComModalMed({ setOpenModal, inputChangeHandler, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const projectDataHandler = async (e) => {
    e.preventDefault();
    await patchProject(e, project, project.id);
    const res = await moveToNextStage(project.id);
    if (res.status === '200' || res.ok) {
      setIsProjectMoved((prevState) => !prevState)
      setOpenModal(false);
    } else {
      alert('Error! Проект не был переведён на следующий этап');
      setOpenModal(false);
    }
  }

  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>
        <label className="modal__input-title">Контактные данные:</label>
        <input className="modal__input input" type="text"
          name="contactData"
          value={project.contactData}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Номер контракта:</label>
        <input className="modal__input input" type="text"
          name="contractNumber"
          value={project.contractNumber}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Дата заключения договора:</label>
        <input className="modal__input input" type="date"
          name="conclusionContractDate"
          value={project.conclusionContractDate}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Плановая дата исполнения:</label>
        <input className="modal__input input" type="date"
          name="plannedExecutionDate"
          value={project.plannedExecutionDate}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Количество людей:</label>
        <input className="modal__input input" type="number"
          name="peopleForMedicalExamination"
          value={project.peopleForMedicalExamination}
          onChange={(e) => inputChangeHandler(e)}
          onWheel={(e) => e.target.blur()}
        />

        <label className="modal__input-title">Цена контракта:</label>
        <input className="modal__input input" type="text"
          name="price"
          value={project.price}
          onChange={(e) => inputChangeHandler(e)}
        />

        <h3 className="modal__description">Перевести проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          на следующий этап?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            disabled={
              project.contactData === '' ||
              project.contractNumber === '' ||
              project.conclusionContractDate === '2010-01-01' ||
              project.plannedExecutionDate === '2010-01-01' ||
              project.peopleForMedicalExamination <= 0 ||
              project.price <= 0
            }
            onClick={(e) => projectDataHandler(e)}>
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper >
  )
}
export default InfoCollectionAndSigningComModalMed


