import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { departmentTypeKey } from "../../../utils/BaseUrl";
import { getBaseUrl } from "../../../utils/BaseUrl";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Select } from "../../../components/Select/Select";
import { api } from "settings/Api";

const Create = () => {
  const navigate = useNavigate();

  const [name, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [departmentId, setDepartment] = useState("");
  const [positionId, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [employess, setEmployees] = useState(null);
  const [filial, setFilial] = useState(null);
  const [currentOrganizationId, setCurrentOrganizationId] = useState(null);
  const [currentFilialId, setCurrentFilialId] = useState(null);

  const postData = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    const departmentType = localStorage.getItem(departmentTypeKey);
    const url = getBaseUrl(departmentType, `users/registration`);

    api("/api/sout/admin/users/registration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        lastName: lastName,
        userName: login,
        password: password,
        positionId: positionId,
        departmentId: departmentId,
        branchOfficeId: currentFilialId,
        organizationId: currentOrganizationId,
        email: email,
      }),
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Пользователь создан!");
        navigate("/ListOfUsers");
      } else if (res.status === 500 || !res.ok) {
        console.error(res);
        alert("Ошибка. Пользователь не создан!");
      }
    });
  };

  useEffect(() => {
    const fetchOrganizations = async (setEmployees) => {
      const token = JSON.parse(localStorage.getItem("authTokens")).data
        .accessToken;

      api("/api/sout/admin/organizations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = res;
          setEmployees(data.data);
        });
    };
    fetchOrganizations(setEmployees);
  }, []);

  const onChange = (e) => {
    const value = e.target.value;
    setCurrentOrganizationId(value);
  };

  useEffect(() => {
    setFilial(
      employess?.find((i) => i.id === parseInt(currentOrganizationId))
        ?.branchOffices
    );
  }, [currentOrganizationId]);

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3 className="workspace__title workspace__page-title">
            Добавить сотрудника
          </h3>
          <form
            style={{
              display: "flex",
              width: "900px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "32%" }}>
              <p className="workspace__input-title">Имя:</p>
              <input
                className="input"
                type="text"
                name="name"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <p className="workspace__input-title">Фамилия</p>
              <input
                className="input"
                type="text"
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
              />
              <p className="workspace__input-title">Отдел:</p>
              <select
                className="input"
                data-val="true"
                name="departmentId"
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option defaultValue="selected">-</option>
                <option value="None">Нет</option>
                <option value="CommercialDepartment">Коммерческий отдел</option>
                <option value="TenderDepartment">Тендерный отдел</option>
                <option value="Laboratory">Лаборатория</option>
                <option value="AccountingDepartment">Бухгалтерия</option>
                {/* <option value="MedicalCenter">МЕД</option> */}
              </select>
            </div>

            <div style={{ width: "32%" }}>
              <p className="workspace__input-title">Должность:</p>
              <select
                className="input"
                name="positionId"
                onChange={(e) => setPosition(e.target.value)}
              >
                <option defaultValue="selected">-</option>
                <option value="None">Нет</option>
                <option value="Employee">Сотрудник</option>
                <option value="DepartmentHead">Начальник отдела</option>
                <option value="Expert">Эксперт</option>
                {departmentId === "Laboratory" && (
                  <>
                    <option value="Manager">Заведующий лабораторией</option>
                    <option value="Assistant">
                      Помощник заведующего лабораторией
                    </option>
                  </>
                )}
              </select>
              <Select
                classNameLabel={"workspace__input-title"}
                onChange={onChange}
                label="Организация:"
                dataSrc="server"
                data={employess}
              />
              <Select
                classNameLabel={"workspace__input-title"}
                onChange={(e) => setCurrentFilialId(e.target.value)}
                label="Филиал:"
                dataSrc="server"
                data={filial}
              />
            </div>
            <div style={{ width: "32%" }}>
              <p className="workspace__input-title">E-mail:</p>
              <input
                className="input"
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Введите логин"
              />
              <p className="workspace__input-title">Логин:</p>
              <input
                className="input"
                type="text"
                name="login"
                onChange={(e) => setLogin(e.target.value)}
                placeholder="Введите логин"
              />
              <p className="workspace__input-title">Пароль:</p>
              <input
                className="input"
                type="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Введите пароль"
              />
            </div>
          </form>

          <div style={{ display: "flex" }}>
            <input
              type="button"
              onClick={postData}
              className="button staff__create-staff-button"
              value="Добавить"
            />
            <button
              style={{ marginLeft: 20 }}
              type="button"
              onClick={() => navigate("/ListOfUsers")}
              className="button staff__create-staff-button"
            >
              Отмена
            </button>
          </div>
        </section>
      </div>
    </main>
  );
};
export default Create;
