import { fetchAgents } from "./fetchAgents";

export const getOrganizationNameById = async (id) => {
  try {
    const organizations = await fetchAgents();
    const organization = organizations.find((elem) => elem.id == id);
    console.log(organizations);
    console.log(
      organizations.find((elem) => elem.id == id),
      id
    );
    return organization.name;
  } catch (error) {
    console.error(error);
    return null;
  }
};
