import { dataIcon } from "components/IconSelect";

const AgentInfoModal = ({ setOpenModal, organization }) => {
  return (
    <section className="flex-wrapper__modal__tables" style={{ width: 700 }}>
      <div
        className="modal_content modal__table-content"
        style={{ width: 630 }}
      >
        <button
          onClick={() => setOpenModal(false)}
          className="modal__close-button close-button"
        >
          &#10005;
        </button>
        <h2 className="modal__title">Информация об агенте</h2>
        <table className="table__table-view-modal">
          <tbody
            className="flex-wrapper__modal-table"
            style={{ display: "block", width: 600 }}
          >
            <tr>
              <td style={{ width: 150 }}>
                {organization?.organizationType === "Organization"
                  ? "Наименование агента"
                  : "ФИО"}
              </td>
              <td style={{ width: 500 }}>{organization?.name || "-"}</td>
            </tr>
            {organization?.organizationType === "Organization" && (
              <>
                <tr>
                  <td>ИНН агента</td>
                  <td className="table__id">{organization?.inn || "-"}</td>
                </tr>
                <tr>
                  <td>Регионы</td>
                  <td className="table__id">
                    {organization?.region?.name || "-"}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>Значок агента</td>
              <td className="table__id">
                {dataIcon[organization?.picture?.name] || "-"}
              </td>
            </tr>
            <tr>
              <td>Буквы агента</td>
              <td className="table__id">{organization?.code || "-"}</td>
            </tr>
          </tbody>
        </table>
        <h2 className="modal__title">Филиалы</h2>
        <table className="table__table-view-modal">
          <tbody
            className="flex-wrapper__modal-table"
            style={{ display: "block", width: 600 }}
          >
            <tr>
              <td style={{ width: 150 }}>#</td>
              <td style={{ width: 500 }}>Наименование филиала</td>
            </tr>
            {organization?.branchOffices?.length ? (
              organization?.branchOffices.map((item, index) => (
                <tr key={item?.name}>
                  <td>{index + 1}</td>
                  <td className="table__id">{item.name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>-</td>
                <td className="table__id">{"-"}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default AgentInfoModal;
