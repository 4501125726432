import { DatePicker } from "components/DateInput/DateInput";
import React, { useEffect } from "react";

const PlannedStage = ({ date, setDate, setIsDisabled }) => {
  useEffect(() => {
    setIsDisabled(!date);
  });

  return (
    <div
      style={{
        display: "flex",
        height: 100,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 220,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label className="modal__input-title" style={{ marginBottom: 10 }}>
          Дата замеров
        </label>
        <DatePicker
          className="input"
          name="conclusionContractDate"
          value={date}
          onChange={(date) => setDate(date)}
          required={true}
          min={new Date("01.01.2000")}
        />
      </div>
    </div>
  );
};

export default PlannedStage;
