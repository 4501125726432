import { useContext } from "react";
import ProjectContext from "../../../../../context/ProjectsContext";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import moveToNextStage from "../../../../../utils/moveToNextStage";
import patchProject from "../../../../../utils/patchProject";

function PaymentComModal({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const projectDataHandler = async (e) => {
		e.preventDefault();
		await patchProject(e, project, project.id);
		const res = await moveToNextStage(project.id);
		if (res.status === "200" || res.ok) {
			setIsProjectMoved((prevState) => !prevState);
			setOpenModal(false);
		} else {
			alert("Error! Проект не был переведён на следующий этап");
			setOpenModal(false);
		}
	};

	const actAmount = project?.actAmount;

	let paymentsAmount = 0;

	if (project?.payments && project.payments.length > 0) {
		paymentsAmount = project.payments
			.map((payt) => payt.amount)
			.reduce((acc, cur) => acc + cur);
	}

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<label className="modal__input-title">Сумма по акту&nbsp;(₽)</label>
				<input
					className="input modal__input"
					type="number"
					disabled
					value={actAmount}
					onWheel={(e) => e.target.blur()}
				/>

				<label className="modal__input-title">
					Сумма всех платежей&nbsp;(₽)
				</label>
				<input
					className="input modal__input"
					type="number"
					disabled
					value={paymentsAmount}
					onWheel={(e) => e.target.blur()}
				/>

				<label className="modal__input-title">
					Дата оплаты:
					<input
						className="input modal__input"
						type="date"
						name="paidDate"
						value={project.paidDate}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</label>

				<fieldset
					className="workspace__ensure-wrapper"
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<label className="modal__input-title">Акт:</label>
					<input
						id="ActRecived"
						className="radio"
						type="radio"
						name="actHasReached"
						value="true"
						checked={project.actHasReached === "true"}
						onChange={(e) => inputChangeHandler(e)}
					/>
					<label
						htmlFor="ActRecived"
						className="modal__input-title radio__label"
					>
						Получен
					</label>

					<input
						id="ActDidNotRecived"
						className="radio"
						type="radio"
						name="actHasReached"
						value="false"
						checked={project.actHasReached === "false"}
						onChange={(e) => inputChangeHandler(e)}
					/>
					<label
						htmlFor="ActDidNotRecived"
						className="modal__input-title radio__label radio__label--ml"
						style={{ width: "200px" }}
					>
						Не получен
					</label>
				</fieldset>

				<h3 className="modal__description">
					Перевести проект
					<b>&nbsp;{project.customerName}&nbsp;</b>
					на следующий этап?
				</h3>
				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={
							paymentsAmount < actAmount ||
							paymentsAmount < 1 ||
							actAmount < 1 ||
							project.paidDate === undefined ||
							project.paidDate === "" ||
							project.paidDate === null ||
							project.actHasReached === "false"
						}
						onClick={(e) => projectDataHandler(e)}
					>
						Да
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default PaymentComModal;
