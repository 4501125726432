export const checkObject = (obj) => {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        if (checkObject(obj[key])) {
          return true;
        }
      } else {
        if (obj[key] === true) {
          return true;
        }
      }
    }
    return false;
  };