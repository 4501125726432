import { createContext, useState } from "react";
import { api } from "settings/Api";

const AgentContext = createContext();

export const AgentProvider = ({ children }) => {
  const [agents, setAgents] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isChangeState, setIsChangeState] = useState(false);
  const [isOpenChoiceModal, setIsOpenChoiceModal] = useState(true);
  const [agentType, setAgentType] = useState("none");

  const fetchAgents = async (setIsLoading, setAgents) => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    api("/api/sout/admin/organizations", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const data = res;

        setIsLoading(false);
        setAgents(data.data);
        setAllAgents(data.data);
      });
  };

  const getBlokedAgents = () => {
    setAgents([...allAgents?.filter((agent) => agent?.isBlocked === true)]);
  };

  const getActiveAgents = () => {
    setAgents([...allAgents?.filter((agent) => agent?.isBlocked === false)]);
  };

  const getAllAgents = () => {
    setAgents([...allAgents]);
  };
  console.log(agentType);

  const nullAgentState = () => {
    setAgentType(null);
    setIsOpenChoiceModal(true);
  };

  const contextData = {
    fetchAgents,
    agents,
    nullAgentState,
    setAgents,
    isLoading,
    setIsLoading,
    getBlokedAgents,
    getActiveAgents,
    getAllAgents,
    isChangeState,
    setIsChangeState,
    isOpenChoiceModal,
    setIsOpenChoiceModal,
    agentType,
    setAgentType,
  };
  return (
    <AgentContext.Provider value={contextData}>
      {children}
    </AgentContext.Provider>
  );
};

export default AgentContext;
