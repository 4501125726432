import { classNames } from "shared/classNames";
import Asterisk from "components/Asterisk";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";

export const TextInput = ({
	className,
	type,
	required,
	disabled,
	value,
	onChange,
	label,
	alignLabel,
    labelHeight,
	errorMessage,
	...props
}) => {
	return (
		<label
			className={classNames(
				"workspace__input-title contracts__input-title",
				{ required },
				[className]
			)}
		>
			<LabelTitle align={alignLabel} height={labelHeight}>
				{label}
				{required && <Asterisk />}
			</LabelTitle>
			<input
				className="input"
				type={type}
				value={value}
				onChange={onChange}
				onPaste={onChange}
				disabled={disabled}
				required={required}
				{...props}
			/>
			<span className="error-message">{errorMessage}</span>
		</label>
	);
};
