import { useContext, useState } from "react";
import ProjectContext from "../../../../context/ProjectsContext";
import ModalWrapper from "../../../../components/modals/ModalWrapper";
import { departmentTypeKey } from "../../../../utils/BaseUrl";
import { getBaseUrl } from "../../../../utils/BaseUrl";
import { api } from 'settings/Api';

function AddPaymentModal({ setOpenModal, handler, project, isAddPaymentStat }) {
	// const { fetchProjects } = useContext(ProjectContext);

	const [values, setValues] = useState("");

	const inputChangeHandler = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const addPaymentHandler = async (e) => {
		await postPayment(e);
		// await fetchProjects()
		setOpenModal(false);
	};

	const body = {
		contractId: project?.id,
		isDeleted: true,
		inn: project?.contract?.customer?.inn,
		paymentDate: values.paymentDate,
		amount: parseFloat(values.amount),
		description: values.description || "No description",
	};

	const postPayment = async (e) => {
		e.preventDefault();
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;

		let url = "/api/sout/admin/accounting/payments";

		if (isAddPaymentStat) {
			url = "/api/sout/admin/accounting/smpayments";
		}

		let response = await api(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		});
		let data = await response.json();
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<h3 className="modal__description">
					Внести оплату &nbsp;<b>{project?.contract?.customer?.name}</b>&nbsp;
				</h3>
				<form action="" style={{ marginBottom: "20px" }}>
					{!isAddPaymentStat && (
						<>
							<label className="modal__input-title">ИНН:</label>
							<input
								className="input modal__input"
								type="number"
								disabled
								value={project?.contract?.customer?.inn}
								onWheel={(e) => e.target.blur()}
							/>
						</>
					)}

					<label className="modal__input-title">Сумма платежа:</label>
					<input
						className="input modal__input"
						type="number"
						name="amount"
						value={values.amount}
						onChange={(e) => inputChangeHandler(e)}
						onWheel={(e) => e.target.blur()}
					/>

					<label className="modal__input-title">Описание:</label>
					<textarea
						className="input modal__input"
						id=""
						cols="30"
						rows="10"
						name="description"
						value={values.description}
						onChange={(e) => inputChangeHandler(e)}
					></textarea>

					<label className="modal__input-title">Дата:</label>
					<input
						className="input modal__input"
						type="date"
						name="paymentDate"
						value={values.paymentDate}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</form>

				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={
							values.amount <= 0 ||
							values.amount === undefined ||
							values.paymentDate === "" ||
							values.paymentDate === undefined
						}
						onClick={(e) => addPaymentHandler(e)}
					>
						Оплатить
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Отмена
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default AddPaymentModal;
