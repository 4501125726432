import { isLabEmployee } from "./defineUser";

const DOC_TYPES_COMMON = {
	'Contract': 'Договор',
	'ContractScanSigned': 'Скан подписанного договора',
	'Information': 'Информация',
	'CoherenceLetter': 'Письмо о согласовании',
	'DB': 'База',
	'WordReport': 'Отчет Word',
	'TitleSheet': 'Титульный',
	'Unload': 'Файл выгрузки',
	'Notification': 'Выписка',
	'Act': 'Акт',
	'ActScanSigned': 'Скан подписанного акта',
};
export { DOC_TYPES_COMMON }

const DOC_TYPES_LAB = {
	'Contract': 'Договор',
	'ContractScanSigned': 'Скан подписанного договора',
	'Information': 'Информация',
	'CoherenceLetter': 'Письмо о согласовании',
	'DB': 'База',
	'WordReport': 'Отчет Word',
	'TitleSheet': 'Титульный',
	'Unload': 'Файл выгрузки',
	'Notification': 'Выписка',
	'Act': 'Акт',
	'ActScanSigned': 'Скан подписанного акта',
};
export { DOC_TYPES_LAB }

const COM_DRAFT_DOCS = {
	'Contract': 'Договор',
}
export { COM_DRAFT_DOCS }

const LAB_AGREEMENT_DOCS = {
	'CoherenceLetter': 'Письмо о согласовании',
	'DB': 'База',
	'WordReport': 'Отчет Word',
}
export { LAB_AGREEMENT_DOCS }

const LAB_PRINTED_SENT_DOCS = {
	'TitleSheet': 'Титульный',
	'Unload': 'Файл выгрузки',
}
export { LAB_PRINTED_SENT_DOCS }

const LAB_UNLOADING_DOCS = {
	'Notification': 'Выписка',
}
export { LAB_UNLOADING_DOCS }

const COM_ACTS_DOCS = {
	'Act': 'Акт',
}
export { COM_ACTS_DOCS }

const TEN_WON_SIGNING_DOCS = {
	'Contract': 'Договор',
}
export { TEN_WON_SIGNING_DOCS }

const LAB_CHIEF_INFO_COLLECTION_DOCS = {
	'Information': 'Информация',
}
export { LAB_CHIEF_INFO_COLLECTION_DOCS }

const LAB_CHIEF_ACTS_DOCS = {
	'Act': 'Акт',
}
export { LAB_CHIEF_ACTS_DOCS }

const DOC_TYPES = isLabEmployee() ? DOC_TYPES_LAB : DOC_TYPES_COMMON;
export { DOC_TYPES }

const DOC_TYPE_KEYS = Object.keys(DOC_TYPES);
export { DOC_TYPE_KEYS }

const getTypesKeys = (list) => Object.keys(list);
export { getTypesKeys }






