import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { FaInfo, FaPause } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { MdTextSnippet } from "react-icons/md";

import InfoTableModalProjects from "./modals/InfoTableModalProjects";
import ChangeStageModal from "./modals/ChangeStageModal";
import DocumentsModal from "pages/sout/commercial/modals/documents/DocumentsModal";
import DocumentContext from "context/DocumentsContext";
import AuthContext from "context/AuthContext";
import { suspendProject } from "utils/suspendProject";

const suspendStages = [
  "InformationRequest",
  "ReadyToWork",
  "AcceptedToWork",
  "Agreement",
];

function ActionButton({
  onAction,
  modalType,
  iconType,
  title,
  isDisabled = false,
  styles,
}) {
  return (
    <button
      disabled={isDisabled}
      onClick={() => onAction(modalType)}
      className={`button button--square button--orange`}
      title={title}
      style={styles}
    >
      {iconType}
    </button>
  );
}

//TENDER BUTTONS
const ActionButtonsProject = (props) => {
  const { entities } = useContext(DocumentContext);
  const { role, startPageData, accessRights } = useContext(AuthContext);

  const { projectId, project, departmentId } = props;
  const workFlowId = project.workFlow.id;
  const stageId = project.workFlow.workFlowStatusId;

  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isNextModalOpen, setIsNextModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isPrevModalOpen, setIsPrevModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [isTableInfoModalOpen, setIsTableInfoModalOpen] = useState(false);

  const openMoveNextModal = () => {
    setIsNextModalOpen(true);
  };

  const openMovePrevModal = () => {
    setIsPrevModalOpen(true);
  };

  const openRejectModal = () => {
    setIsRejectModalOpen(true);
  };

  const openDocumentModal = () => {
    setIsDocumentModalOpen(true);
  };

  const openInfoTable = () => setIsTableInfoModalOpen(true);

  const STAGES = {
    ReadyToWork: {
      prev: true,
    },
    PerformerAppointed: {},
    AcceptedToWork: {},
    Agreement: {},
    PrintedAndSent: {},
    UnloadingDocuments: {},
    Finish: {
      next: false,
      reject: false,
    },
    Archive: {
      next: true,
      reject: true,
    },
    Terminated: {
      next: true,
      reject: true,
    },
    Lost: {
      next: true,
      reject: true,
    },
    NotSigned: {
      next: true,
      reject: true,
    },
    Basket: {
      next: true,
      reject: true,
    },
  };

  return (
    <>
      <Link
        to="/sout/projects/projects"
        state={{ projectId, actionType: "edit", workFlowId }}
      >
        <ActionButton
          onAction={() => 5}
          iconType={<MdEdit className="icon-edit" />}
          title="Редактировать"
          isDisabled={accessRights[role].project[stageId]?.edit}
        ></ActionButton>
      </Link>

      <ActionButton
        modalType="showInfo"
        iconType={<FaInfo className="icon-info" />}
        onAction={openInfoTable}
        title="Информация о проекте"
        isDisabled={accessRights[role].project[stageId]?.info}
      ></ActionButton>

      <ActionButton
        modalType="movePrev"
        iconType={<FaArrowLeft className="icon-move" />}
        title="Перевести на предыдущий этап"
        onAction={openMovePrevModal}
        isDisabled={accessRights[role].project[stageId]?.prev}
      ></ActionButton>
      {suspendStages?.includes(stageId) && (
        <ActionButton
          isDisabled={accessRights[role].project[stageId]?.suspend}
          modalType="suspend"
          iconType={<FaPause className="icon-move" />}
          title="Приостановить"
          onAction={() => {
            setIsSuspendModalOpen(true);
          }}
        ></ActionButton>
      )}
      <ActionButton
        iconType={<FaArrowRight className="icon-move" />}
        title="Перевести на следующий этап"
        onAction={openMoveNextModal}
        modalType="next"
        isDisabled={accessRights[role].project[stageId]?.next}
      ></ActionButton>

      <ActionButton
        iconType={<ImCross className="icon-delete" />}
        title="Удалить проект"
        onAction={openRejectModal}
        modalType="reject"
        isDisabled={accessRights[role].project[stageId]?.reject}
      ></ActionButton>
      <ActionButton
        iconType={<MdTextSnippet className="icon-document" />}
        title="Документы"
        onAction={openDocumentModal}
        modalType="documents"
        isDisabled={accessRights[role].project[stageId]?.doc}
      ></ActionButton>
      {isNextModalOpen && (
        <ChangeStageModal
          projectId={project.workFlow.id}
          workFlowStatusId={stageId}
          project={project}
          setOpenModal={setIsNextModalOpen}
          projectName={project?.contract?.customer?.name}
          type="separated-project"
          move="next"
        />
      )}
      {isPrevModalOpen && (
        <ChangeStageModal
          workFlowStatusId={stageId}
          projectId={project.workFlow.id}
          setOpenModal={setIsPrevModalOpen}
          project={project}
          projectName={project?.contract?.customer?.name}
          type="separated-project"
          move="prev"
        />
      )}
      {isDocumentModalOpen && (
        <DocumentsModal
          modelId={project.id}
          entity={entities.projects}
          secondEntity={{
            link: "contracts",
            docTypes: [
              {
                type: "Contract",
                description: "Договор",
                typeId: 0,
              },
              {
                type: "Info",
                description: "Информация",
                typeId: 0,
              },
            ],
          }}
          setOpenModal={setIsDocumentModalOpen}
          documents={project.documents}
          secondModelId={project.contract.id}
        />
      )}
      {isRejectModalOpen && (
        <ChangeStageModal
          projectId={project.workFlow.id}
          setOpenModal={setIsRejectModalOpen}
          project={project}
          workFlowStatusId={stageId}
          projectName={project?.contract?.customer?.name}
          type="separated-project"
          move="reject"
        />
      )}

      {isSuspendModalOpen && (
        <ChangeStageModal
          projectId={project.workFlow.id}
          setOpenModal={() => {
            setIsSuspendModalOpen(!isSuspendModalOpen);
          }}
          project={project}
          workFlowStatusId={stageId}
          projectName={project?.contract?.customer?.name}
          type="separated-project"
          move="suspend"
        />
      )}

      {isTableInfoModalOpen && (
        <InfoTableModalProjects
          setOpenModal={setIsTableInfoModalOpen}
          project={project}
        />
      )}
    </>
  );
};
export default ActionButtonsProject;
