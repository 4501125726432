import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Spinner from '../components/Spinner';

const Auth = () => {
	let { loginUser, loading } = useContext(AuthContext);

	return (
		<>
			<main className="main-page">
				<div className="container main-page__container">
					<h3 className="title main-page__title">Войти</h3>
					<form onSubmit={loginUser} className="main-page__sign-in">
						<input name="username" className="input sign-in__input"
							placeholder="Логин"
							type="text" />
						<input name="password" className="input sign-in__input"
							placeholder="Пароль"
							type="password" />
						<button className="button button--green" type="submit">Войти</button>
					</form>
				</div>
			</main>
			{
				loading
					? <div className="loading-wrapper">
						<Spinner className={'spinner loading-wrapper__spinner'} />
					</div>
					: null
			}
		</>
	)
};

export default Auth; 
