import { useContext } from "react";
import {
  isDate,
  toDateWithoutTime,
  toDateWithTime,
} from "../../utils/formatDate";
import { translateText } from "../../settings/stages";
import ProjectContext from "../../context/ProjectsContext";
import { isСhieftain } from "../../utils/defineUser";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import ActionButtonsTenders from "../../pages/sout/tender/common/ActionButtonsTender";
import ActionButtonsProject from "pages/sout/tender/common/ActionButtonsProject";
import { getSortMap } from "./tenderProjectSortMap";
import { getColor } from "utils/getColor";
import { dataIcon } from "components/IconSelect";

function ProjectRowTender({ project, projectNumber, departmentId }) {
  const { sortParam, additionalInfoValue, additionalInfoValue2 } =
    useContext(ProjectContext);

  const color = getColor(
    departmentId === "tender" ? "tender" : "project",
    project?.workFlow?.workFlowStatusId,
    project?.isExpired,
    project?.isWarning
  );

  const getFormatedDate = (text) => {
    if (
      sortParam === "AuctionDate" ||
      sortParam === "AcceptingOffersDeadline"
    ) {
      return toDateWithTime(text);
    } else {
      return toDateWithoutTime(text);
    }
  };

  const displaySortValue = (value) => {
    if (value == "0") return 0;
    if (value instanceof String) {
      if (!value || value.trim().length === 0) return "-";
    }

    const text = translateText(value);

    if (isDate(text)) {
      return getFormatedDate(text);
    } else {
      return text ? text : "-";
    }
  };

  const showedTenderTitles = ["ManagerINN", "Eis", "Id"];
  const showedProjectTitles = ["CustomerINN", "Id"];

  const returnTdTitle = (sortParam) => {
    return {
      ManagerINN: (
        <td className="table__sort-column">{project?.manager?.inn}</td>
      ),
      Eis: <td className="table__sort-column">{project?.eis}</td>,
      CustomerINN: (
        <td className="table__sort-column">
          {project?.contract?.customer?.inn}
        </td>
      ),
    }[sortParam];
  };

  const filterTitles = () =>
    sortParam === "Id" || sortParam === "null" ? " " : sortParam;

  const buildProjectName = (project) => {
    let name = project?.contract?.customer?.name;
    if (project?.name) {
      name += ` (${project?.name})`;
    }
    return name;
  };

  const sortMap = getSortMap(departmentId, project);

  return (
    <>
      {project && (
        <tr>
          <td>
            {departmentId !== "tender" && project?.organization?.picture?.name
              ? dataIcon[project?.organization?.picture?.name]
              : null}
          </td>
          <td
            style={{
              background: color,
            }}
          >
            {projectNumber}
          </td>
          <td
            style={{
              background: color,
            }}
            className={sortParam === "Id" ? "table__sort-column" : null}
          >
            <b>{project.id}</b>
          </td>
          {showedTenderTitles?.includes(sortParam) ? (
            returnTdTitle(sortParam)
          ) : filterTitles()?.length > 1 ? (
            <td className={sortParam ? "table__sort-column" : null}>
              {sortParam && displaySortValue(sortMap[sortParam])}
            </td>
          ) : null}

          {additionalInfoValue && additionalInfoValue !== "null" && (
            <td className="table__additional-column">
              {translateText(
                sortMap[capitalizeFirstLetter(additionalInfoValue)]
              )}
            </td>
          )}
          {additionalInfoValue2 && additionalInfoValue2 !== "null" && (
            <td className="table__additional-column2">
              {translateText(
                sortMap[capitalizeFirstLetter(additionalInfoValue2)]
              )}
            </td>
          )}
          {departmentId === "tender" && (
            <>
              <td className="table__column-2">
                {`${project?.manager?.name} ${
                  project?.customerAdditionalName?.length > 0
                    ? `(${project?.customerAdditionalName})`
                    : ""
                }` || " - "}
                {project?.name?.length > 0 &&
                  ["Draft", "Estimated"]?.includes(
                    project?.workFlow?.workFlowStatusId
                  ) && (
                    <span
                      style={{ color: "#0c0099", fontSize: 13, marginLeft: 5 }}
                    >
                      [{project?.name}]
                    </span>
                  )}
              </td>
              {showedTenderTitles.map((title) => {
                if (sortParam !== title) {
                  return (
                    <td>
                      {
                        {
                          ManagerINN: project?.manager?.inn || " - ",
                          Eis: project?.eis,
                        }[title]
                      }
                    </td>
                  );
                }
                return null;
              })}
            </>
          )}
          {departmentId === "projects" && (
            <>
              <td className="table__column-2">{buildProjectName(project)}</td>
              <td className="table__column-2">
                {project?.contract?.contractNumber || " - "}
              </td>
              {showedProjectTitles.map((title) => {
                if (sortParam !== title) {
                  return (
                    <td>
                      {
                        {
                          CustomerINN:
                            project?.contract?.customer?.inn || " - ",
                        }[title]
                      }
                    </td>
                  );
                }
                return null;
              })}
            </>
          )}
          <td
            className={
              localStorage.getItem("__user-department") === "accountant"
                ? "table__action-buttons-wrapper--accountant"
                : "table__action-buttons-wrapper"
            }
          >
            <div
              className={`table__action-buttons ${
                isСhieftain() && "table__action-buttons--center"
              }`}
            >
              {departmentId === "tender" ? (
                <ActionButtonsTenders
                  projectId={project.id}
                  project={project}
                />
              ) : (
                <ActionButtonsProject
                  projectId={project.id}
                  project={project}
                />
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
export default ProjectRowTender;
