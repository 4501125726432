import React, { useContext, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { api } from "settings/Api";
import AgentContext from "context/ParticipantsOfProcessContext";
import { ChoiceAgentType } from "../commercial/modals/ChoiceAgentType";
import IconSelect from "components/IconSelect";
import { getPictures } from "utils/getPictures";

const AddAgent = () => {
  const [name, setName] = useState("");
  const [inn, setInn] = useState("");
  const [icons, setIcons] = useState([]);
  const [activeIcon, setActiveIcon] = useState(null);

  const [code, setCode] = useState("");
  const [withLaboratory, setWithLaboratory] = useState(false);

  const [regionId, setRegionId] = useState("");
  const [regions, setRegions] = useState(null);
  const [regionName, setRegionName] = useState("");
  const [showDropDown, setShowDropdown] = useState(true);
  const navigate = useNavigate();

  const { isOpenChoiceModal, setIsOpenChoiceModal, nullAgentState, agentType } =
    useContext(AgentContext);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(
    agentType === "Organization" ? true : false
  );

  const postData = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    api("/api/sout/admin/organizations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(
        agentType === "Organization"
          ? {
              inn: inn,
              name: name,
              code: code,
              picture: { id: icons?.find((i) => i.name == activeIcon)?.id },
              withLaboratory: withLaboratory,
              organizationType: "Organization",
              region: regions?.find((i) => i.id === regionId),
            }
          : {
              name: name,
              code: code,
              picture: { id: icons?.find((i) => i.name == activeIcon)?.id },
              withLaboratory: withLaboratory,
              organizationType: "Individual",
            }
      ),
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Агент создан!");
        navigate("/ListOfAgents");
      } else if (res.status === 500 || !res.ok) {
        alert("Ошибка. Агент не создан!");
      }
    });
  };

  useEffect(() => {
    fetchRegions();
    getPictures().then((data) => {
      setIcons(data.data);
    });
  }, []);

  const fetchRegions = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api("/api/sout/helpers/handbooks/regions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();
    setRegions(data.data);
  };

  const handleRegionName = (e) => {
    setShowDropdown(true);
    setRegionName(e.target.value);
  };

  const handleINN = (e) => {
    const inn = e.target.value;

    if (inn.length === 10 || inn.length === 12) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }

    setInn(e.target.value);
  };

  const isRegionNameString = () => {
    if (typeof regionName === "string" && Number.isNaN(parseInt(regionName))) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    return nullAgentState();
  }, []);

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        {!isOpenChoiceModal && agentType === "Organization" && (
          <section className="workspace__wrapper">
            <h3 className="workspace__title workspace__page-title">
              Добавить агента организации
            </h3>
            <form style={{ width: "500px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: 650,
                }}
              >
                <div style={{ width: 500 }}>
                  <p className="workspace__input-title">ИНН:</p>
                  <input
                    className="input"
                    type="text"
                    maxLength="12"
                    name="inn"
                    onChange={(e) => handleINN(e)}
                    required={true}
                    pattern="^(\d{10}|\d{12})$"
                  />
                </div>
                <div style={{ width: 100 }}>
                  <p className="workspace__input-title">Буквы агента:</p>
                  <input
                    className="input"
                    type="text"
                    maxLength="3"
                    name="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required={true}
                  />
                </div>
              </div>

              <p className="workspace__input-title">Наименование</p>
              <input
                className="input"
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
              />
              <p className="workspace__input-title">Регион</p>
              <input
                className="input"
                type="text"
                name="regionId"
                value={regionName}
                onChange={(e) => handleRegionName(e)}
              />
              <ul className="regions__list">
                {regions &&
                  regionName.length >= 2 &&
                  regions
                    .filter((region) => {
                      return region.name
                        .toLowerCase()
                        .includes(regionName.trim().toLowerCase());
                    })
                    .map(
                      (region) =>
                        showDropDown && (
                          <li
                            className="regions__item"
                            onClick={() => {
                              setRegionId(region.id);
                              setRegionName(region.name);
                              setShowDropdown(false);
                            }}
                          >
                            {region.name}
                          </li>
                        )
                    )}
              </ul>

              <div
                style={{
                  width: 500,
                  display: "flex",
                  marginTop: 20,
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ marginRight: 10 }}>С лабораторией</p>
                  <input
                    type="checkbox"
                    name="withLab"
                    id="withLab"
                    checked={withLaboratory}
                    onChange={() => setWithLaboratory(!withLaboratory)}
                  />
                </div>
                <IconSelect
                  icons={icons}
                  setActiveIcon={setActiveIcon}
                  activeIcon={activeIcon}
                  isDisabled={!withLaboratory}
                />
              </div>

              <div style={{ display: "flex" }}>
                <input
                  type="button"
                  disabled={isSubmitButtonDisabled}
                  onClick={postData}
                  className="button staff__create-staff-button"
                  value="Добавить"
                />
                <button
                  type="button"
                  style={{ marginLeft: 20 }}
                  onClick={() => navigate("/ListOfAgents")}
                  className="button staff__create-staff-button"
                >
                  Отмена
                </button>
              </div>
            </form>
          </section>
        )}
        {!isOpenChoiceModal && agentType === "Individual" && (
          <section className="workspace__wrapper">
            <h3 className="workspace__title workspace__page-title">
              Добавить агента физ.лицо
            </h3>
            <form style={{ width: "500px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: 650,
                }}
              >
                <div style={{ width: 500 }}>
                  <p className="workspace__input-title">ФИО</p>
                  <input
                    className="input"
                    type="text"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div style={{ width: 100 }}>
                  <p className="workspace__input-title">Буквы агента:</p>
                  <input
                    className="input"
                    type="text"
                    maxLength="3"
                    name="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required={true}
                  />
                </div>
              </div>
              <div
                style={{
                  width: 500,
                  display: "flex",
                  marginTop: 20,
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ marginRight: 10 }}>С лабораторией</p>
                  <input
                    type="checkbox"
                    name="withLab"
                    id="withLab"
                    checked={withLaboratory}
                    onChange={() => setWithLaboratory(!withLaboratory)}
                  />
                </div>
                <IconSelect
                  icons={icons}
                  setActiveIcon={setActiveIcon}
                  isDisabled={!withLaboratory}
                  activeIcon={activeIcon}
                />
              </div>
              <div style={{ display: "flex" }}>
                <input
                  type="button"
                  disabled={isSubmitButtonDisabled}
                  onClick={postData}
                  className="button staff__create-staff-button"
                  value="Добавить"
                />
                <button
                  style={{ marginLeft: 20 }}
                  type="button"
                  onClick={() => navigate("/ListOfAgents")}
                  className="button staff__create-staff-button"
                >
                  Отмена
                </button>
              </div>
            </form>
          </section>
        )}
      </div>

      {isOpenChoiceModal && (
        <ChoiceAgentType close={() => setIsOpenChoiceModal(false)} />
      )}
    </main>
  );
};
export default AddAgent;
