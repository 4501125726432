import { generateToken } from 'shared/generateToken';
import { api } from 'settings/Api';

export const fetchContractByID = async contractId => {
  const token = generateToken();
  const url = `/api/sout/admin/contracts/${contractId}`;

  try {
    const response = await api(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    const data = await response.json();
    if (response.status !== 200) return response.status;
    if (data.isSuccess) return { ...data.data, id: contractId };
    
    if (data.isFailure) throw new Error(data);
    
  } catch (e) {
    console.log(`Произошла ошибка: ${e}`);
  }
}