import { useContext, useState } from "react";
import ProjectContext from "../../../../context/ProjectsContext";
import ModalWrapper from "../../../../components/modals/ModalWrapper";
import { departmentTypeKey } from "../../../../utils/BaseUrl";
import { getBaseUrl } from "../../../../utils/BaseUrl";
import { api } from 'settings/Api';

function AddPercentageModal({ setOpenModal, handler, project }) {
	// const { fetchProjects } = useContext(ProjectContext);

	const [values, setValues] = useState("");

	const inputChangeHandler = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const addPaymentHandler = async (e) => {
		await postPayment(e);
		// await fetchProjects()
		setOpenModal(false);
	};

	const body = {
		value: values.value,
		date: values.date,
	};

	const postPayment = async (e) => {
		e.preventDefault();
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;

		let response = await api("/api/sout/admin/accounting/profitpercent", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		});
		let data = await response.json();
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<h3 className="modal__description">
					Внести процент &nbsp;<b>{project?.contract?.customer?.name}</b>&nbsp;
				</h3>

				<form action="" style={{ marginBottom: "20px" }}>
					<label className="modal__input-title">Процент (1-100):</label>
					<input
						className="input modal__input"
						type="number"
						name="value"
						value={values.value}
						onChange={(e) => inputChangeHandler(e)}
						onWheel={(e) => e.target.blur()}
					/>

					<label className="modal__input-title">Дата:</label>
					<input
						className="input modal__input"
						type="date"
						name="date"
						value={values.date}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</form>

				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={
							values.value <= 0 ||
							values.value > 100 ||
							values.value === "" ||
							values.value === null ||
							values.value === undefined
						}
						onClick={(e) => addPaymentHandler(e)}
					>
						Оплатить
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Отмена
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default AddPercentageModal;
