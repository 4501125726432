import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import Sidebar from "../../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { api } from "settings/Api";
import { MdEdit } from "react-icons/md";
import { ImCross } from "react-icons/im";
import RejectModal from "./RejetcModal";

const ListOfCompetitors = () => {
  const [notes, setNotes] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedCompetitors, setSelectedCompetitors] = useState(null);
  const { authTokens, logoutUser, updateToken } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    getNotes();
  }, []);

  const rejetcCompetitors = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(
      `/api/sout/handbooks/competitors/${selectedCompetitors}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // 'Authorization':  'String(authTokens.access) ,
        },
      }
    );

    if (response.status === 200) {
      alert("Конкурент успешно удален");
      setNotes([
        ...notes?.filter((item) => item.id !== parseInt(selectedCompetitors)),
      ]);
    } else if (response.status === 401) {
      updateToken();
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    } else if (response.status === 500) {
      alert("Конкурент не удален");
    }
    setIsShowModal(false);
  };

  let getNotes = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    let response = await api("/api/sout/handbooks/competitors", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // 'Authorization':  'String(authTokens.access) ,
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setNotes(data.data);
    } else if (response.status === 401) {
      updateToken();
    } else if (response.statusText === "Unauthorized") {
      logoutUser();
    }

    setNotes(data.data);
  };

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h2 className="workspace__title workspace__page-title">
            Список конкурентов
          </h2>
          <Link to="/AddCompetitor" className="button workspace__button">
            Добавить конкурента
          </Link>
          <section className="workspace__table-wrapper">
            <div className="table-wrapper">
              <table className="table workspace__table">
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Наименование</td>
                    <td>Описание</td>
                  </tr>
                </thead>
                <tbody className="table__row--h35px">
                  {notes &&
                    notes.map((note) => (
                      <tr key={note.id}>
                        <td>{note.id}</td>
                        <td>{note.name}</td>
                        <td>{note.description}</td>
                        <td>
                          <button
                            onClick={() => {
                              navigate(`/EditCompetitor/${note?.id}`);
                            }}
                            className={`button button--square`}
                            title="Редактировать"
                          >
                            <MdEdit className="icon-edit" />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedCompetitors(note?.id);
                              setIsShowModal(true);
                            }}
                            className={`button button--square`}
                            title="Удалить"
                          >
                            <ImCross className="icon-edit" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </div>
      {isShowModal && (
        <RejectModal
          onNo={() => {
            setIsShowModal(false);
          }}
          onYes={rejetcCompetitors}
          onClose={() => {
            setIsShowModal(false);
          }}
          text={`Вы действительно хотите удалить площадку ${
            notes?.find((item) => item.id === parseInt(selectedCompetitors))
              ?.name
          }?`}
        />
      )}
    </main>
  );
};
export default ListOfCompetitors;
