const DEPARTMENTS_STAGE = {
  None: 'tender',
  CommercialDepartment: 'commercial',
  TenderDepartment: 'tender',
  Laboratory: 'lab-assist',
  AccountingDepartment: 'accountant'
}

const DEPARTMENTS_URL = {
  None: 'admin',
  CommercialDepartment: 'admin',
  TenderDepartment: 'admin',
  Laboratory: 'admin',
  AccountingDepartment: 'admin'
}

export const departmentTypeKey = '_departmentType';

export const positionTypeKey = '_positionType';

// /api/sout/admin/projects/${id}
// /api/med/project/6


// /api/sout/admin/projects
// /api/med/project/6

export const getPatchBaseUrl = (id, userArea) =>
  userArea === 'sout'
    ? `/api/sout/admin/projects/`
    : `/api/med/project/${id}`

export const getEditBaseUrl = (id, userArea) =>
  userArea === 'sout'
    ? `/api/sout/admin/projects/${id}`
    : `/api/med/project/${id}`

export const getBaseUrl = (id, dataType, userArea) =>
  userArea === 'sout'
    ? `/api/${userArea}/${DEPARTMENTS_URL[id]}/${dataType}?`
    : `/api/${userArea}/${dataType}?`

export const getDeafultProjectsPageUrl = (group, id) => `/${group}/${DEPARTMENTS_STAGE[id]}/all-actual`
