import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ListOfUsers from "./sout/admin/ListOfUsers";
import ListOfSites from "./sout/admin/ListOfSites";
import ListOfCompetitors from "./sout/admin/ListOfCompetitors";
import Statistics from "./Statistics";
import Expenses from "../components/common/Expenses";
import Workload from "./Workload";
import ListOfAgents from "./sout/admin/ListOfAgents";
import Sidebar from "components/Sidebar";
import { resetEntityType } from "utils/defineEntity";
import WelcomeBlock from "components/WelcomeBlock/WelcomeBlock";
import AuthContext from "context/AuthContext";
import { fetchAccount } from "utils/fetchAccount";
import ContractContext from "context/ContractContext";

const AdminStartPage = () => {
  useEffect(() => {
    resetEntityType();
  }, []);
  const { user, username, startPageData, role, setRole } =
    useContext(AuthContext);
  const { setAccount } = useContext(ContractContext);

  console.log(user);

  useEffect(() => {
    console.log("change account");
    fetchAccount().then((res) => {
      setAccount(res);
    });
  }, []);

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content" style={{ paddingLeft: "20px" }}>
        <WelcomeBlock name={username} post={role} />
        {startPageData[role]?.map((item, index) =>
          item?.hasOwnProperty("title") ? (
            <div
              key={item?.title}
              style={{ marginTop: `${index !== 0 ? "30px" : "10px"}` }}
            >
              <h4 style={{ fontSize: 20, fontWeight: "normal" }}>
                {item.title}
              </h4>
              <div className="admin-start-page__button-wrap">
                {item.items.map((elm) => (
                  <Link
                    to={elm.link}
                    element={elm.element}
                    style={{ marginRight: 20 }}
                  >
                    <button className="button admin-start-page__button">
                      {elm.title}
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          ) : null
        )}
      </div>
    </main>
  );
};

export default AdminStartPage;
