import { Portal } from "components/Portal/Portal";
import ModalWrapper from "components/modals/ModalWrapper";
import { Select } from "components/Select/Select";
import { Button } from "shared/ui/Button/Button";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LocationContext from "context/LocationContext";

export const LocationTypeChoiceModal = ({
	close,
	required,
}) => {
	const navigate = useNavigate();

	const { initLocation } = useContext(LocationContext);

	const [typeId, setTypeId] = useState();

	const onClick = () => {
		initLocation(typeId);
		navigate(`/sout/locations/add/new`);
	};

	const Content = styled.div`
		margin: auto;
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 30vw;
	`;

	const Buttons = styled.div`
		display: flex;
		justify-content: ${(props) =>
			props.required ? "center" : "space-between"};
		width: 60%;
		margin: auto;
		margin-top: 50px;
		margin-bottom: 20px;
	`;

	return (
		<Portal>
			<ModalWrapper>
				<Content>
					<Select
						className={`workspace__input-title selectInput`}
						label="Тип замерного блока"
						name="LocationTypeId"
						dataSrc="local"
						onChange={(e) => setTypeId(e.target.value)}
						value={typeId}
						data={{
							values: {
								Contract: "Договорный",
								Potential: "Потенциальный",
							},
						}}
					/>
					<Buttons required={required}>
						<Button color="olive" onClick={onClick}>
							Создать
						</Button>
						<Button color="olive" onClick={close}>
							Отмена
						</Button>
					</Buttons>
				</Content>
			</ModalWrapper>
		</Portal>
	);
};
