import { createContext, useEffect, useState } from "react";
import { getBaseUrl } from "../utils/BaseUrl";
import { generateToken } from "shared/generateToken";
import { api } from "settings/Api";

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const departmentId = localStorage.getItem("_departmentType");
  const BASE_URL = getBaseUrl(departmentId, "projects");
  const PAGE_ROW_COUNT = 20;
  const [account, setAccount] = useState(null);

  const [projects, setProjects] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [currentEntity, setCurrentEntity] = useState();

  const [isUdpateDraftTender, setIsUpdateDraftTender] = useState(null);

  //New flag
  const [isProjectMoved, setIsProjectMoved] = useState(false);
  const [queryStrings, setQueryStrings] = useState("");

  const [values, setValues] = useState({});

  const [paginationParams, setPaginationParams] = useState("");

  const [paginationQuery, setPaginationQuery] = useState(
    `&PageRowCount=${account?.organizationId === 1?20:2500}&Page=${1}`
  );

  const [sortParam, setSortParam] = useState("");

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [additionalInfoValue, setAdditionalInfoValue] = useState("");
  const [additionalInfoValue2, setAdditionalInfoValue2] = useState("");


  const [ecoStarDebt, setEcoStarDebt] = useState("");

  const [isProjectsLoaded, setIsProjectsLoaded] = useState(false);

	const [stageTitle, setStageTitle] = useState("");
	useEffect(() => {
		setPaginationQuery(`&PageRowCount=${account?.organizationId === 1?20:2500}&Page=${currentPageNumber}`)
	}, [currentPageNumber])

  const removeAllDraftTenders = async (stage) => {
    const token = generateToken();
    try {
      await api(`/api/sout/workflow/tenders/reject`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      alert(`Ошибка: ${e.message}`);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,

        isProjectMoved,
        setIsProjectMoved,
        queryStrings,
        setQueryStrings,
        paginationQuery,
        setPaginationQuery,
        removeAllDraftTenders,
        setPaginationParams,
        paginationParams,

        setSortParam,
        sortParam,

        values,
        setValues,

        account,
        setAccount,

        setCurrentPageNumber,
        currentPageNumber,

        additionalInfoValue,
        setAdditionalInfoValue,

        additionalInfoValue2,
        setAdditionalInfoValue2,

        ecoStarDebt,
        setEcoStarDebt,

        isProjectsLoaded,
        setIsProjectsLoaded,

        isUdpateDraftTender,
        setIsUpdateDraftTender,

        stageTitle,
        setStageTitle,

        submitButtonPressed,
        setSubmitButtonPressed,

        currentEntity,
        setCurrentEntity,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
