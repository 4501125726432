import { Link } from "react-router-dom";
import { isAdmin, isCommercialUser, isLabHeadDeputy, isTenderUser } from "../utils/defineUser";
import { useParams } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import AuthContext from "context/AuthContext";
import { useContext } from "react";

const StageAddProjectButton = () => {
  const params = useParams();
  const { directionId, departmentId } = params;

  const {role, accessRights } = useContext(AuthContext);

  let type = 'сущность';
  if (departmentId === 'tender') type = 'тендер';
  if (departmentId === 'projects') type = 'проект';

  const currentEntities = {
    "тендер": "tender",
    "проект": "project"
  }



  if (isAdmin()) {
    const currentSidebarDepartment = localStorage.setItem("__user-department", params.departmentId);

    if (params.departmentId && params.departmentId !== currentSidebarDepartment) {

      localStorage.setItem("__user-department", params.departmentId);
    }
  }

  const setButtonColorClass = () => {
    switch (departmentId) {
      case "projects":
        return 'button--orange';
      case "tender":
        return 'button--purple';
      default:
        return '';
    }
  }

  return (
    <div className="workspace__add-project-wrapper">
      {(accessRights[role].canChange.includes(currentEntities[type])) && (
        <Link
          to={`/${directionId}/${departmentId}/${departmentId}`}
          className={`button ${setButtonColorClass()}`}
          state={{ projectId: null, workFlowId: null, actionType: 'add' }}
        >
          <GoPlus />
          &nbsp; Добавить {type}
        </Link>
      )}
    </div>
  );
};

export default StageAddProjectButton;
