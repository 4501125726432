import { isLabHead } from "../../utils/defineUser";
import ProjectTableHeaders from "./ProjectHeaders";
import ProjectRow from "./ProjectRow";
import { useContext } from "react";
import ProjectContext from "../../context/ProjectsContext";
import { useState } from "react";

function ProjectsTable({ projects, departmentId }) {
  const {
    paginationParams,
    currentPageNumber,
    additionalInfoValue,
    additionalInfoValue2,
  } = useContext(ProjectContext);
  const PAGE_ROW_COUNT = 20;

  console.log("TENDERS");

  const [additionalInfoTitle, additionalInfoKey] = additionalInfoValue;

  const generateProjectNumber = (idx) => {
    const lastNumber = PAGE_ROW_COUNT * currentPageNumber;
    const firstNumber = lastNumber - PAGE_ROW_COUNT + 1;

    const arr = [];
    for (let i = firstNumber; i <= lastNumber; i++) {
      arr.push(i);
    }
    return arr[idx];
  };

  return (
    <section className="workspace__table-wrapper">
      <div className="table-wrapper">
        <table
          className={`table workspace__table ${
            additionalInfoValue && additionalInfoValue2 ? "padding_0" : ""
          }`}
        >
          <ProjectTableHeaders additionalInfoTitle={additionalInfoTitle} />
          <tbody>
            {projects &&
              projects.map((project, idx) => (
                <ProjectRow
                  key={project.id}
                  project={project}
                  departmentId={departmentId}
                  projectNumber={generateProjectNumber(idx)}
                  additionalInfoKey={additionalInfoKey}
                />
              ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
export default ProjectsTable;
