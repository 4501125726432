import { Link } from 'react-router-dom'

function Error404() {
  return (
    <section className='error-page'>
      <div className="error-page__container">
        <span className='error-page__icon'>&#9785;</span>
        <h1 className='error-page__title'>Page 404</h1>
        <p className='error-page__description'>Страница не найдена</p>
        <Link className='error-page__link' to="/">
          <button className='button button--white error-page__button'>Вернуться на главную</button>
        </Link>
      </div>
    </section>
  )
}
export default Error404