import React, {useEffect} from 'react'

const StageBackWithComment = ({comment, setComment,setIsDisabled}) => {
    useEffect(() => {
        setIsDisabled(!comment)
    })

    return (
        <div className="modal__content-center">
        <label className="modal__input-title">
            Комментарий:
        </label>
        <input
            style={{maxWidth: 300}}
            className={!comment?'input modal__input-err':'input '}
            type='text'
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
        />
    </div>
    )
}

export default StageBackWithComment