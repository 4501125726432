import { useState, useContext, useEffect } from "react";
import ProjectContext from "../../../../../context/ProjectsContext";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import moveToNextStage from "../../../../../utils/moveToNextStage";
import patchProject from "../../../../../utils/patchProject";
import fetchUsers from "../../../../../utils/fetchUsers";
import { LAB_DEFAULT_USER_ID } from "../../../../../utils/DefaultUsersID";

function MeasurementsLabChiefModal({
	setOpenModal,
	inputChangeHandler,
	project,
}) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const [tenderUsers, setTenderUsers] = useState("");
	const [commercialUsers, setCommercialUsers] = useState("");
	const [laboratoryUsers, setLaboratoryUsers] = useState("");

	// const [workers, setWorkers] = useState('');

	useEffect(() => {
		fetchUsers(
			"Laboratory",
			setTenderUsers,
			setCommercialUsers,
			setLaboratoryUsers
		);
	}, []);

	//

	const projectDataHandler = async (e) => {
		e.preventDefault();
		await patchProject(e, project, project.id);
		const res = await moveToNextStage(project.id);
		if (res.status === "200" || res.ok) {
			setIsProjectMoved((prevState) => !prevState);
			setOpenModal(false);
		} else {
			alert("Error! Проект не был переведён на следующий этап");
			setOpenModal(false);
		}
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<label className="modal__input-title">
					Лаборатория:
					<select
						className="modal__input input search-area__select"
						name="laboratoryUserId"
						value={project.laboratoryUserId}
						onChange={(e) => inputChangeHandler(e)}
					>
						<option value={LAB_DEFAULT_USER_ID}>Неизвестный сотрудник</option>
						{laboratoryUsers &&
							laboratoryUsers
								.filter((item) => item.id !== LAB_DEFAULT_USER_ID)
								.map(({ id, name }) => (
									<option key={id} value={id}>
										{" "}
										{name}{" "}
									</option>
								))}
					</select>
				</label>

				<label className="modal__input-title">
					Дата назначения исполнителя:
					<input
						className="modal__input input"
						type="date"
						name="performerAppointedDate"
						value={project.performerAppointedDate}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</label>

				<h3 className="modal__description">
					Перевести проект <b>&nbsp;{project.customerName}&nbsp;</b> на
					следующий этап?
				</h3>
				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={
							project.laboratoryUserId == LAB_DEFAULT_USER_ID ||
							project.performerAppointedDate === undefined ||
							project.performerAppointedDate === "" ||
							project.performerAppointedDate === null
						}
						onClick={(e) => projectDataHandler(e)}
					>
						Да
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default MeasurementsLabChiefModal;
