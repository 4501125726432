import {useContext} from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import moveToNextStage from '../../../../../utils/moveToNextStage';

function DraftLabAssistModal({ setOpenModal, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const projectDataHandler = async (e) => {
    e.preventDefault();
    const res = await moveToNextStage(project.id);
    if (res.status === '200' || res.ok) {
      setIsProjectMoved((prevState) => !prevState)
      setOpenModal(false);
    } else {
      alert('Error! Проект не был переведён на следующий этап');
      setOpenModal(false);
    }
  }

  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>
        <h3 className="modal__description">Перевести проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          на следующий этап?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            onClick={(e) => projectDataHandler(e)}>
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper>
  )
}
export default DraftLabAssistModal


