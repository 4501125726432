import { formatDate } from "./formatDate";
import { departmentTypeKey } from "./BaseUrl";
import { getBaseUrl } from "./BaseUrl";
import { getEditBaseUrl } from "./BaseUrl";
import { api } from 'settings/Api';

/**
 **Fetch single project data by id
 * @param {*} projectId - identificator of the fetched project
 * @param {*} handleFetchedValues - changes values state to futher displaying them in inputs, selects, radio buttons
 */

const fetchProject = (projectId, handleFetchedValues, action) => {
	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
	const departmentType = localStorage.getItem(departmentTypeKey);
	const url = getEditBaseUrl(projectId, localStorage.getItem("__user-area"));


	api(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
			// 'Authorization':  'String(authTokens.access) ,
		},
	})
		.then((result) => result.json())
		.then((result) => {
			const project = result.data;

			if (action === "edit") {
				let tenderUserId = null;
				let commercialUserId = null;
				let laboratoryUserId = null;

				const users = project?.users;
				users.forEach((user) => {
					if (user.departmentId === "TenderDepartment") {
						tenderUserId = user.id;
					} else if (user.departmentId === "CommercialDepartment") {
						commercialUserId = user.id;
					} else if (user.departmentId === "Laboratory") {
						laboratoryUserId = user.id;
					}
				});

				const valuess = {
					id: project?.id,
					departmentType: project?.workFlow?.typeId,
					inn: project?.contract?.customer?.inn,
					customerName: project?.contract?.customer?.name,
					contactData: project?.contract?.customer?.contactData,
					price: project?.contract?.price,
					contractNumber: project?.contract?.number,
					conclusionContractDate: formatDate(
						project?.contract?.conclusionContractDate
					),
					plannedExecutionDate: formatDate(
						project?.contract?.plannedExecutionDate
					),
					tenderId: project?.tender?.tenderId,
					tradingFloorId: project?.tender?.tradingFloor?.id,
					publicationDate: formatDate(project?.tender?.publicationDate),
					acceptingOffersDeadline: project?.tender?.acceptingOffersDeadline,
					startMaxPrice: project?.tender?.startMaxPrice,
					offerAmount: project?.tender?.offerAmount,
					description: project?.tender?.description,
					auctionDate: project?.tender?.auctionDate,
					failrueDescription: project?.tender?.failrueDescription,
					securityAmount: project?.contract?.securityAmount,
					offerSecuringMethodId: project?.tender?.offerSecuringMethodId,
					contractSecuringMethodId: project?.tender?.contractSecuringMethodId,
					tradingFloorCommisionId: project?.tender?.tradingFloorCommisionId,
					offerSecurityAmount: project?.contract?.offerSecurityAmount,
					tradingFloorCommissionAmount:
						project?.contract?.tradingFloorCommissionAmount,
					winnerPrice: project?.tender?.winnerPrice,
					winnerId: project?.tender?.winner?.id,
					assesmentTypeId: project?.assesmentTypeId,
					haveWorkbook: (project?.haveWorkbook).toString(),
					executionProgramId: project?.executionProgramId,
					tenderUserId: tenderUserId,
					commercialUserId: commercialUserId,
					laboratoryUserId: laboratoryUserId,
					workspaceCount: project?.workspaceCount,
					unitCount: project?.unitCount,
					workerId: project?.worker?.id,
					actAmount: project?.contract?.actAmount,
					projectStage: project?.workFlow?.projectStatusId,
					peopleForMedicalExamination:
						project?.med?.peopleForMedicalExamination,
					aggregatorLink: project?.aggregatorLink,
					informationCollectedAt: formatDate(project?.informationCollectedAt),
					alternateId: project?.alternateId,
					executionDescription: project?.executionDescription,
					additionalCustomerName: project?.contract?.additionalCustomerName,
					summaryReportDate: formatDate(project?.tender?.summaryReportDate),
					operationTypeId: project?.tender?.operationTypeId,
					regions: project?.regions,
					legalBasesId: project?.tender?.legalBasesId,
					hasTradingRobot: project?.tender?.hasTradingRobot.toString(),
					documents: project?.documents,

					informationCollectionDate: formatDate(
						project?.informationCollectionDate
					),
					actDate: formatDate(project?.contract?.actDate),
					acceptedToWorkDate: formatDate(project?.acceptedToWorkDate),
					sentDate: formatDate(project?.sentDate),
					unloadingDate: formatDate(project?.unloadingDate),

					measurementsDate: formatDate(project?.measurementsDate),
					performerAppointedDate: formatDate(project?.performerAppointedDate),
					approvalsDate: formatDate(project?.approvalsDate),
					titleSheetSignDate: formatDate(project?.titleSheetSignDate),
					paidDate: formatDate(project?.contract?.paidDate),

					payments: project?.contract?.payments,
					actHasReached: (project?.actHasReached).toString(),

					paymentsSum: project?.contract?.paymentsSum,
					paymentsStatusId: project?.contract?.paymentsStatusId,

					securityReturned:
						project?.tender?.securityReturned !== undefined
							? project.tender.securityReturned.toString()
							: null,
					monthlyReportDate: formatDate(project?.monthlyReportDate),
					actNumber: project?.contract?.actNumber,
					KPP: project?.kpp,
					measurementStatusId: project?.measurementStatusId
				};

				handleFetchedValues(valuess);
			}

			if (action === "view") {
				handleFetchedValues(result.data);
			}

			if (action === "docs") {
				handleFetchedValues(result.data.documents);
			}
		});


};
export default fetchProject;
