import { useEffect, useState, useContext } from "react";
import DraftContractStage from "./stage/DraftContractStage";
import IdAssigningContractStage from "./stage/IdAssigningContractStage";
import ProjectsCreationContractStage from "./stage/ProjectsCreationContractStage";
import LocationsCreationContractStage from "./stage/LocationsCreationContractStage";
import ActsContractStage from "./stage/ActsContractStage";
import DocumentContext from "context/DocumentsContext";
import WaitingForPaymentContractStage from "./stage/WaitingForPaymentContractStage";
import MoveStageWithRB from "./stage/MoveStageWithRB";
import patchContractData from "utils/patchContract";
import getContract from "utils/getContractById";

import { dateToString } from "shared/formatDate";
import FileInput from "pages/sout/accountant/common/FileInput";
import { showValidateMessage } from "settings/Api";

const contractArray = [
  "InformationRequest",
  "PreparationAndExecution",
  "WaitingForUnloadingDocuments",
  "Finish",
];

const terminatedStageArray = [
  "ProjectsCreation",
  "PreparationAndExecution",
  "Acts",
  "WaitingForUnloadingDocuments",
  "Finish",
];

export const ConfirmActionModal = ({
  moveType,
  onYes,
  onNo,
  contractId,
  setOpenModal,
  colorThemeClass,
  stage,
}) => {
  const [isVisibleBtn, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { uploadDocument } = useContext(DocumentContext);
  const [updateContract, setUpdateContract] = useState({});
  const [secReturned, setSecReturned] = useState(
    updateContract?.securityReturned || false
  );
  const [isUploadInfo, setIsUploadInfo] = useState([]);
  const [actsReturned, setActsReturned] = useState(
    updateContract?.act?.isReturned || false
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [projects, setProjects] = useState([]);
  const [arr, setArr] = useState(updateContract?.assesmentTypes || []);
  const [dep, setDep] = useState(updateContract?.departments || []);
  const [mesBlocks, setMesBlocks] = useState([]);
  const [act, setAct] = useState(updateContract?.act || {});
  const [docs, setDocs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (stage === "SigningAndCollectingInformation")
      setIsDisabled(!isUploadInfo);
  }, [isUploadInfo]);

  useEffect(() => {
    if (moveType !== "moveNext") setIsDisabled(false);
    getContract(contractId).then((res) => {
      setUpdateContract(res.data);
      setArr(res?.data?.assesmentTypes);
      setDep(res?.data?.departments);
      setAct(res?.data?.act);
      res?.data?.departments?.map((item) => {
        if (item.hasOwnProperty("document")) setDocs([...docs, item?.document]);
      });
    });
    if (contractArray.includes(stage)) setIsDisabled(false);
  }, [contractId, moveType, stage]);

  const moveNextHandler = (flag = true) => {
    patchContractData(
      {
        ...updateContract,
        personInCharge: null,
        // workers: null,
        projects: projects.length === 0 ? null : projects,
        measuringBlocks: mesBlocks.length === 0 ? null : mesBlocks,
        departments: dep.length === 0 ? [] : dep,
        customer: updateContract?.customer,
        assesmentTypes: arr,
        price: updateContract?.price,
        workspaceCount: {
          sout: updateContract?.workspaceCount?.sout
            ? parseInt(updateContract?.workspaceCount?.sout)
            : null,
          pk: updateContract?.workspaceCount?.pk
            ? parseInt(updateContract?.workspaceCount?.pk)
            : null,
          opr: updateContract?.workspaceCount?.opr
            ? parseInt(updateContract?.workspaceCount?.opr)
            : null,
        },
        paymentDate: updateContract?.paymentDate,
        contractNumber: updateContract?.contractNumber,
        conclusionContractDate: updateContract?.conclusionContractDate
          ? dateToString(updateContract?.conclusionContractDate)
          : null,
        plannedExecutionDate: updateContract?.plannedExecutionDate
          ? dateToString(updateContract?.plannedExecutionDate)
          : null,
        additionalName: updateContract?.additionalName,
        contractConclusionDepartmentId:
          updateContract?.contractConclusionDepartmentId,
        workFlow: updateContract?.workFlow,
        regions: updateContract?.regions,
        id: updateContract?.id,
        personInChargeId: updateContract?.personInChargeId,
        // workers: updateContract?.workers,
        isDeleted: false,
        organisationId: updateContract?.personInCharge?.organizationId,
        branchOfficeId: updateContract?.personInCharge?.branchOfficeId,
        act: { ...act, isReturned: actsReturned },
        securityReturned: secReturned,
        innerNumber: updateContract?.innerNumber,
      },
      "edit",
      contractId
    ).then((res) => {
      if (res?.status === 400) {
        showValidateMessage(res?.data?.errors);
      }

      if (flag) {
        setIsLoading(true);
        onYes();
        setTimeout(() => {
          setIsLoading(false);
        }, 12000);
      }
    });
  };

  const modalContent = (stage) => {
    switch (stage) {
      case "Draft":
        return (
          <DraftContractStage
            arr={arr}
            setArr={setArr}
            contract={updateContract}
            setContract={setUpdateContract}
            setIsDisabled={setIsDisabled}
          />
        );
      case "IdAssigning":
        return (
          <IdAssigningContractStage
            dep={dep}
            setDep={setDep}
            docs={docs}
            contractId={updateContract?.id}
            setDocs={setDocs}
            setIsDisabled={setIsDisabled}
            udpateDep={moveNextHandler}
          />
        );
      case "SigningAndCollectingInformation":
        return (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <FileInput
              modelId={updateContract?.id}
              entity="contracts"
              link="Info"
              title="Информация"
              setIsUpload={setIsUploadInfo}
            />
          </div>
        );

      case "LocationsCreation":
        return (
          <LocationsCreationContractStage
            contract={updateContract}
            mesBlocks={mesBlocks}
            setMesBlocks={setMesBlocks}
            setIsDisabled={setIsDisabled}
            setContract={setUpdateContract}
            initCount={updateContract?.measuringBlocksCount}
            isVisibleBtn={isVisibleBtn}
            setIsVisible={setIsVisible}
          />
        );
      case "ProjectsCreation":
        return (
          <ProjectsCreationContractStage
            contract={updateContract}
            projects={projects}
            initCount={updateContract?.projectsCount}
            contractAsses={updateContract?.assesmentTypes?.map(
              (item) => item?.id
            )}
            setProjects={setProjects}
            setIsDisabled={setIsDisabled}
            setContract={setUpdateContract}
            isVisibleBtn={isVisibleBtn}
            setIsVisible={setIsVisible}
          />
        );
      case "Acts":
        return (
          <ActsContractStage
            act={act}
            setAct={setAct}
            contract={updateContract}
            setIsDisabled={setIsDisabled}
          />
        );
      // case 'WaitingForPayment':
      //   return <WaitingForPaymentContractStage
      //           contract={updateContract}
      //           setIsDisabled={setIsDisabled}
      //           setContract={setUpdateContract}
      //         />
      // case 'ReturnOfSecurity':
      //   return <MoveStageWithRB
      //           setReturned={setSecReturned}
      //           returned={secReturned}
      //           contract={updateContract}
      //           title={'Обеспечение вернулось?'}
      //           setIsDisabled={setIsDisabled}
      //           setContract={setUpdateContract}
      //         />
      // case 'ReturnOfActs':
      //   return <MoveStageWithRB
      //           setReturned={setActsReturned}
      //           returned={actsReturned}
      //           contract={updateContract}
      //           title={'Акт вернулся?'}
      //           setIsDisabled={setIsDisabled}
      //           setContract={setUpdateContract}
      //         />
      default:
    }
  };

  const writeMessage = (stage) => {
    switch (moveType) {
      case "moveNext":
        return (
          <p>
            Перевести договор{" "}
            <b style={{ fontSize: 14 }}>{updateContract?.customer?.name}</b>{" "}
            {stage == "Finish" ? "в архив" : "на следующий этап?"}{" "}
          </p>
        );
      case "movePrev":
        return (
          <p>
            Перевести договор{" "}
            <b style={{ fontSize: 14 }}>{updateContract?.customer?.name}</b> на
            предыдущий этап?
          </p>
        );
      case "reject":
        return (
          <p>
            {`Вы уверены, что хотите ${
              terminatedStageArray.includes(stage) ? "расторгнуть" : "удалить"
            } договор`}{" "}
            <b style={{ fontSize: 14 }}>{updateContract?.customer?.name}</b>?
          </p>
        );
      case "suspend":
        return (
          <p>
            Вы уверены, что хотите приостановить договор{" "}
            <b style={{ fontSize: 14 }}>{updateContract?.customer?.name}</b>?
          </p>
        );
      default:
        break;
    }
  };

  return (
    <>
      <button
        className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}
      >
        &#10005;
      </button>
      {moveType === "moveNext" && modalContent(stage)}
      <h3 className="modal__description">
        {isVisibleBtn && writeMessage(stage)}
      </h3>
      <div
        className="buttons"
        style={{
          width: "60%",
          display: "flex",
          justifyContent: "space-between",
          margin: "auto",
        }}
      >
        {isVisibleBtn && (
          <>
            <button
              className={`button ${colorThemeClass}`}
              onClick={moveNextHandler}
              disabled={isDisabled || isLoading}
            >
              Да
            </button>
            <button className={`button ${colorThemeClass}`} onClick={onNo}>
              Нет
            </button>
          </>
        )}
      </div>
    </>
  );
};
