import { api } from 'settings/Api';
const postTenderData = async (body, actionType, projectId) => {
  const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;

  let url = '/api/sout/admin/tenders'
  let requestBody = body;

  if (actionType === 'edit') {
    requestBody.id = projectId;
  }

  let response = await api(url, {
    method: actionType === 'edit' ? 'PATCH' : 'POST',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  });
  let data = await response.json();
  return {...data , status: response.status};
};
export default postTenderData;
