import React, { useEffect } from "react";

const DraftTenderStage = ({
  price,
  setPrice,
  move,
  setIsDisabled,
  comment,
  setComment,
}) => {
  useEffect(() => {
    setIsDisabled(!price && move === "next");
  });

  const inputHandler = (e) => {
    setPrice(e.target.value);
  };

  return (
    <div className="modal__content-center">
      {move === "next" && (
        <>
          <label className="modal__input-title">Наша цена:</label>
          <input
            style={{ maxWidth: 300 }}
            className={!price ? "input modal__input-err" : "input "}
            type="number"
            name="price"
            autoFocus={!price ? true : false}
            value={price}
            onChange={inputHandler}
          />
          <label className="modal__input-title" style={{ textAlign: "start" }}>
            Комментарий к заявке:
          </label>
          <input
            style={{ maxWidth: 300, marginBottom: 20 }}
            className={"input "}
            type="text"
            name="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </>
      )}
    </div>
  );
};

export default DraftTenderStage;
