import { useContext, useState } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import moveToNextStage from '../../../../../utils/moveToNextStage';
import patchProject from '../../../../../utils/patchProject';

import { LAB_PRINTED_SENT_DOCS } from '../../../../../utils/documentType';
import DocumentsListHandler from '../../../accountant/common/DocumentsListHandler';

function PrintedAndSentLabAssistModal({ setOpenModal, inputChangeHandler, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const [documentsQty, setDocumentsQty] = useState({
    'TitleSheet': 0,
    'Unload': 0,
  })

  const projectDataHandler = async (e) => {
    e.preventDefault();
    await patchProject(e, project, project.id);
    const res = await moveToNextStage(project.id);
    if (res.status === '200' || res.ok) {
      setIsProjectMoved((prevState) => !prevState)
      setOpenModal(false);
    } else {
      alert('Error! Проект не был переведён на следующий этап');
      setOpenModal(false);
    }
  }

  const documentsQtyHandler = (updatedQty) =>
    (Object.keys(updatedQty).length === 0 && updatedQty.constructor === Object)
      ? setDocumentsQty(documentsQty)
      : setDocumentsQty({ ...documentsQty, ...updatedQty })

  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>

        <DocumentsListHandler
          projectId={project.id}
          docTypesList={LAB_PRINTED_SENT_DOCS}
          documentsQtyHandler={documentsQtyHandler}
        />

        <label className="modal__input-title" style={{ marginTop: '15px' }}>Дата подписания титульного листа:
          <input className="modal__input input" type="date"
            name="titleSheetSignDate"
            value={project.titleSheetSignDate}
            onChange={(e) => inputChangeHandler(e)}
          />
        </label>

        <h3 className="modal__description">Перевести проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          на следующий этап?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            onClick={(e) => projectDataHandler(e)}
            disabled={
              documentsQty.TitleSheet <= 0 ||
              documentsQty.Unload <= 0 || 
              project.titleSheetSignDate === undefined
            }
          >
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper>
  )
}
export default PrintedAndSentLabAssistModal


