import Stage from "./common/StageContracts";
import SearchSortTabs from "./SearchSortTabs";
import StageAddProjectButton from "./StageAddProjectButton";
import { useParams } from "react-router-dom";
import StageContracts from "./common/StageContracts";
import SearchSortTabsProject from "./SearchSortTabsProject";
import EntityStage from "./common/StageTender";
import StageLocations from "./common/StageLocations";
import { Pagination } from "./Pagination/Pagination";
import { useContext } from "react";
import ProjectContext from "context/ProjectsContext";

const StageWrapper = () => {
  const { departmentId } = useParams();

  const getStageByDepartmentId = () => {
    switch (departmentId) {
      case "tender":
        return EntityStage;
      case "projects":
        return EntityStage;
      case "contracts":
        return StageContracts;
      case "locations":
        return StageLocations;
      default:
        return null;
    }
  };

  const StageComponent = getStageByDepartmentId();

  const { paginationParams, setCurrentPageNumber, currentPageNumber } =
    useContext(ProjectContext);

  return (
    <section className="workspace__wrapper">
      {departmentId === "tender" ? (
        <section className="workspace__action-panel">
          <>
            <div className="action-panel__buttons">
              <StageAddProjectButton />
              <SearchSortTabs />
            </div>
            {paginationParams.totalPagesCount > 1 && (
              <Pagination
                currentPage={currentPageNumber}
                totalPagesCount={paginationParams.totalPagesCount}
                toStart={() => setCurrentPageNumber(1)}
                toEnd={() =>
                  setCurrentPageNumber(paginationParams.totalPagesCount)
                }
                forward={() => setCurrentPageNumber(currentPageNumber + 1)}
                back={() => setCurrentPageNumber(currentPageNumber - 1)}
                change={(to) => setCurrentPageNumber(to)}
                totalRowCount={paginationParams.totalRowCount}
                color={"purple"}
                type={"tender"}
              />
            )}
          </>
        </section>
      ) : null}
      {departmentId === "projects" ? (
        <section className="workspace__action-panel">
          <>
            <div className="action-panel__buttons">
              <StageAddProjectButton />
              <SearchSortTabsProject />
            </div>
            {paginationParams.totalPagesCount > 1 && (
              <Pagination
                currentPage={currentPageNumber}
                totalPagesCount={paginationParams.totalPagesCount}
                toStart={() => setCurrentPageNumber(1)}
                toEnd={() =>
                  setCurrentPageNumber(paginationParams.totalPagesCount)
                }
                forward={() => setCurrentPageNumber(currentPageNumber + 1)}
                back={() => setCurrentPageNumber(currentPageNumber - 1)}
                change={(to) => setCurrentPageNumber(to)}
                totalRowCount={paginationParams.totalRowCount}
                color={"orange"}
                type={"project"}
              />
            )}
          </>
        </section>
      ) : null}
      <StageComponent />
    </section>
  );
};
export default StageWrapper;
