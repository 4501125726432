import { useContext, useState } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import patchProject from '../../../../../utils/patchProject';
import moveToNextStage from '../../../../../utils/moveToNextStage';
import DocumentsListHandler from '../../../accountant/common/DocumentsListHandler';
import { COM_DRAFT_DOCS } from '../../../../../utils/documentType';

function InfoCollectionAndSigningComModal({ setOpenModal, inputChangeHandler, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const [documentsQty, setDocumentsQty] = useState({
    'Contract': 0,
  })

  const projectDataHandler = async (e) => {
    e.preventDefault();
    await patchProject(e, project, project.id);
    const res = await moveToNextStage(project.id);
    if (res.status === '200' || res.ok) {
      setIsProjectMoved((prevState) => !prevState)
      setOpenModal(false);
    } else {
      alert('Error! Проект не был переведён на следующий этап');
      setOpenModal(false);
    }
  }

  const documentsQtyHandler = (updatedQty) =>
    (Object.keys(updatedQty).length === 0 && updatedQty.constructor === Object)
      ? setDocumentsQty(documentsQty)
      : setDocumentsQty({ ...documentsQty, ...updatedQty })


  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>
        <label className="modal__input-title">Контактные данные:</label>
        <input className="modal__input input" type="text"
          name="contactData"
          value={project.contactData}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Номер контракта:</label>
        <input className="modal__input input" type="text"
          name="contractNumber"
          value={project.contractNumber}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Дата заключения договора:</label>
        <input className="modal__input input" type="date"
          name="conclusionContractDate"
          value={project.conclusionContractDate}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Плановая дата исполнения:</label>
        <input className="modal__input input" type="date"
          name="plannedExecutionDate"
          value={project.plannedExecutionDate}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Число рабочих мест:</label>
        <input className="modal__input input" type="number"
          name="workspaceCount"
          value={project.workspaceCount}
          onChange={(e) => inputChangeHandler(e)}
          onWheel={(e) => e.target.blur()}
        />

        <label className="modal__input-title">Цена контракта:</label>
        <input className="modal__input input" type="text"
          name="price"
          value={project.price}
          onChange={(e) => inputChangeHandler(e)}
        />

        <DocumentsListHandler
          projectId={project.id}
          docTypesList={COM_DRAFT_DOCS}
          documentsQtyHandler={documentsQtyHandler}
        />

        <h3 className="modal__description">Перевести проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          на следующий этап?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            disabled={
              project.contactData === '' ||
              project.contractNumber === '' ||
              project.conclusionContractDate === '0001-01-01' ||
              project.conclusionContractDate === '' ||
              project.plannedExecutionDate === '0001-01-01' ||
              project.plannedExecutionDate === '' ||
              project.workspaceCount <= 0 ||
              project.price <= 0 ||
              documentsQty.Contract <= 0
            }
            onClick={(e) => projectDataHandler(e)}>
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper >
  )
}
export default InfoCollectionAndSigningComModal


