import React, { useState, useContext, useEffect } from "react";
import AttachButton from "./AttachButton";
import DocumentContext from "context/DocumentsContext";
import { BsPlusLg } from "react-icons/bs";
import Spinner from "components/Spinner";
import { AiOutlineMinus } from "react-icons/ai";
import { ConfirmActionModal } from "components/modals/ConfirmActionModal";
import ModalWrapper from "components/modals/ModalWrapper";
import { Portal } from "components/Portal/Portal";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { DocActionModal } from "components/modals/DocActionModal";
import { generateToken } from "shared/generateToken";
import { api } from "settings/Api";

const Colors = {
  tenders: "purple",
  contracts: "blue",
  "separated-projects": "orange",
  "measuring-blocks": "olive",
};

const ContracModalFileInput = ({
  modelId,
  entity,
  link,
  isShowDonwload = true,
  title,
  isUpload,
  width,
  setIsUpload,
  contractId,
}) => {
  const [doc, setDoc] = useState([]);
  const [files, setFiles] = useState([]);
  const [isOpenList, setIsOpenList] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);
  const [docIdForRemove, setDocIdForRemove] = useState(null);
  const color = Colors[entity];
  const { uploadDocument, downloadDocument, removeDocument } =
    useContext(DocumentContext);

  const chooseFile = async (e, fileType) => {
    setFiles([
      ...doc,
      { file: e.target.files[0], fileType: fileType, id: doc.length },
    ]);
  };

  const updateDocuments = () => {
    getDocuments(entity, contractId, modelId).then((res) => setDoc(res));
  };

  useEffect(() => {
    setIsUpload(
      isUpload?.find((item) => item.id === modelId)
        ? isUpload?.map((item) =>
            item?.id === modelId ? { ...item, haveDoc: doc.length === 1 } : item
          )
        : [...isUpload, { id: modelId, haveDoc: doc.length === 1 }]
    );
  }, [doc]);

  useEffect(() => {
    if (!doc.length) updateDocuments();
  }, []);
  console.log("файлы", files);
  console.log("жопа", doc);
  const getDocuments = async (entity, modelId, id) => {
    const token = generateToken();

    const link = `/api/sout/admin/${entity}?id=${modelId}`;

    try {
      const response = await api(link, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) throw new Error(response.status);

      const data = await response.json();
      let depDoc = data.data.list[0].departments.find(
        (item) => item?.departmentNumber === id
      );
      let result = depDoc?.document
        ? [{ ...depDoc?.document, status: "not-downloaded" }]
        : [];

      return result;
    } catch (e) {
      alert("Ошибка получения документов: " + e.message);
    }
  };

  const toggleDocumentsList = async (listTitle) => {
    setIsOpenList({ ...isOpenList, [listTitle]: !isOpenList[listTitle] });
  };

  const handleModal = (id) => {
    setDocIdForRemove(id);
    setConfirmActionModalOpen(true);
  };

  const handleRemove = async (id) => {
    await removeDocument(id);
    setConfirmActionModalOpen(false);
    updateDocuments();
  };

  const cleanUploadedFile = (id) => {
    setFiles(doc.filter((item) => item.id !== id));
  };

  const handleUpload = async (type, file) => {
    setIsUploading(true);
    setIsSuccess(false);
    const formData = new FormData();
    formData.append("file", file);
    await uploadDocument(
      entity,
      modelId,
      type,
      formData,
      setIsUploading,
      setIsSuccess,
      contractId
    );
    setTimeout(() => {
      setIsSuccess(false);
      setFiles([]);
      updateDocuments();
    }, []);
  };

  const handleDownload = async (e, name, extention, link, id) => {
    setIsDownloading(true);
    await downloadDocument(e, name, extention, link, id, "Id");
    setIsDownloading(false);
    setIsDownloaded(true);
    setTimeout(() => {
      setIsDownloaded(false);
    }, [2000]);
  };

  return (
    <div style={{ marginTop: 5 }}>
      <div className="doc-buttons">
        <label className="modal__input-title" style={{ width: width }}>
          {title}
        </label>
        {files.length === 0 && doc.length === 0 && (
          <AttachButton
            docType={link}
            isOpenList={isOpenList}
            chooseFile={chooseFile}
            color={color}
          />
        )}
        <button
          className={`button button--square button--${color} ${
            isOpenList[link] && `button--${color}--active`
          }`}
          type="button"
          onClick={() => toggleDocumentsList(link)}
        >
          {isOpenList[link] ? (
            <AiOutlineMinus className="icon--medium" />
          ) : (
            <BsPlusLg className="icon--medium" />
          )}
        </button>
      </div>
      {files &&
        files
          .filter((item) => item.fileType === link)
          .map((item) => (
            <li className="modal__doc-item">
              {item.file.name.length > 40
                ? item.file.name.slice(0, 40) + "..."
                : item.file.name}
              <div className="doc-buttons">
                {!isUploading && !isSuccess && (
                  <>
                    <button
                      className={`button button--square button--${color}`}
                      onClick={() => handleUpload(link, item.file)}
                    >
                      <FaArrowUp className="icon--medium" />
                    </button>
                    <button
                      className={`button button--square button--${color}`}
                      onClick={() => cleanUploadedFile(item.id)}
                    >
                      <ImCross className="icon--medium" />
                    </button>
                  </>
                )}
                {isUploading && !isSuccess && (
                  <Spinner className="icon--spinner" />
                )}
                {!isUploading && isSuccess && <MdDone className="icon--done" />}
              </div>
            </li>
          ))}
      {doc &&
        doc.length > 0 &&
        doc
          .filter((doc) => doc?.url?.includes("_" + link + "_"))
          .map((doc) => (
            <li key={doc.id} className="modal__doc-item">
              {doc.name}
              {doc.extention}
              <div className="doc-buttons">
                {isDownloading && !isDownloaded && (
                  <Spinner className="icon--spinner" />
                )}
                {!isDownloading && isDownloaded && (
                  <MdDone className="icon--done" />
                )}
                <button
                  className={`button button--square button--${color}`}
                  onClick={(e) =>
                    handleDownload(e, doc.name, doc.extention, entity, doc.id)
                  }
                >
                  <FaArrowDown className="icon--medium" />
                </button>
                <button
                  className={`button button--square button--${color}`}
                  onClick={() => handleModal(doc.id)}
                >
                  <ImCross className="icon--medium" />
                </button>
              </div>
            </li>
          ))}
      {!doc && doc.filter((doc) => doc.typeId === link).length <= 0 && (
        <li className="modal__no-doc-message">
          <i>Нет документов</i>
        </li>
      )}
      {confirmActionModalOpen && (
        <Portal>
          <ModalWrapper>
            <DocActionModal
              title="Вы уверены, что хотите удалить документ"
              onYes={() => handleRemove(docIdForRemove)}
              onNo={() => setConfirmActionModalOpen(false)}
              colorThemeClass={`button--${color}`}
            />
          </ModalWrapper>
        </Portal>
      )}
    </div>
  );
};

export default ContracModalFileInput;
