import { toDateWithoutTime } from "utils/formatDate";

const assessmentTypes = {
  ProductionControl: "ПК",
  WorkingConditionsSpecialAssesment: "СОУТ",
  ProfessionalRisks: "ОПР",
};

export const LocationSortMap = {
  customerINN: (location) =>
    location?.contract?.customer?.inn || location?.tender?.manager?.inn,
  fromPlannedMeasurementsDate: (location) =>
    !location?.fromPlannedMeasurementsDate
      ? "-"
      : `${toDateWithoutTime(
          location?.fromPlannedMeasurementsDate
        )} - ${toDateWithoutTime(location?.toPlannedMeasurementsDate)}`,
  workerName: (location) =>
    location?.worker
      ? `${location?.worker?.name} ${location?.worker?.lastName}`
      : "-",
  assessmentTypeList: (location) =>
    location?.assessmentTypeList
      .map((assessmentType) => assessmentTypes[assessmentType.id])
      .join(", "),
  additionalName: (location) => location?.additionalName,
  contactData: location => location?.contract?.customer?.contactData,
  workspaceCount: location => location?.workspaceCount,
  measurementsDate: location => location?.measurementsDate,
};
