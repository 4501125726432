import { getBaseUrl } from "./BaseUrl";
import { api } from 'settings/Api';

const postData = async (body, user, userArea) => {
	let str_body = JSON.stringify(body);
	str_body = JSON.parse(str_body);

	const getNull = (obj) => {
		if (!obj) return null;

		const keys = Object.keys(obj);
		const length = keys.length;

		let nullCounter = 0;
		for (let i = 0; i < length; i++) {
			const value = obj[keys[i]];
			if (value === null) {
				nullCounter++;
			}
		}

		if (nullCounter === length) {
			obj = null;
		}
		return obj;
	};

	const getArrNull = (arr) => {
		let filtered = arr.filter((elem) => elem !== null);
		if (filtered.length === 0) {
			filtered = null;
		}
		return filtered;
	};

	str_body.contract.customer = getNull(str_body.contract.customer);
	str_body.contract = getNull(str_body.contract);
	str_body.tender.tradingFloor = getNull(str_body.tender.tradingFloor);
	str_body.tender.winner = getNull(str_body.tender.winner);
	str_body.tender = getNull(str_body.tender);
	str_body.users = getArrNull(str_body.users);
	str_body.med = getNull(str_body.med);
	str_body.workFlow = getNull(str_body.workFlow);
	str_body.worker = getNull(str_body.worker);
	str_body = getNull(str_body);

	const new_body = Object.assign(str_body);

	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;

	const URL =
		userArea === "sout"
			? getBaseUrl(
					user.Department,
					"projects",
					localStorage.getItem("__user-area")
			  )
			: getBaseUrl(null, "project", localStorage.getItem("__user-area"));

	let response = await api(URL, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(new_body),
	});
	let data = await response.json();

	if (response.status !== 200) {
		alert(`Ошибка ${response.status}. Проект не создан :(`);

		return response.status;
	}

	return response.status;
};
export default postData;
