import { api } from 'settings/Api';
const fetchWorkers = async (setWorkers) => {
  const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;
  let response = await api(`/api/sout/helpers/handbooks/workers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
  let data = await response.json();
  setWorkers(data.data);
}
export default fetchWorkers