import { StatisticTableRow } from "./StatisticTableRow";
import StatisticTableHeaders from "./StatisticTableHeaders";
import { getHeaders } from "./tableHeaders";
import StatisticTableTotalRow from "./StatisticTableTotalRow";
import { valueHandler } from "shared/valueHandler";

function StatisticTable({
  tableType,
  projects,
  incomeTab,
  type,
  setEcoStarTotalDebt,
}) {
  const sortProjectsByDate = (projects) => {
    function parseDate(dateStr) {
      const [month, year] = dateStr.split("/");
      return new Date(year, month - 1);
    }
    return projects.sort((a, b) => parseDate(a.title) - parseDate(b.title));
  };
  const sortProjectsByWC = (projects) => {
    console.log("projects" ,projects);
    const getArray = (obj) => {
      return [
        obj?.acceptedToWork?.all?.sout?.workspaceCount,
        obj?.acceptedToWork?.all?.pk?.workspaceCount,
        obj?.acceptedToWork?.all?.opr?.workspaceCount,
        obj?.informationCollection?.all?.sout?.workspaceCount,
        obj?.informationCollection?.all?.pk?.workspaceCount,
        obj?.informationCollection?.all?.opr?.workspaceCount,
        obj?.readyToWork?.all?.sout?.workspaceCount,
        obj?.readyToWork?.all?.pk?.workspaceCount,
        obj?.readyToWork?.all?.opr?.workspaceCount
      ];
    };

    return projects.sort((a, b) => {
      const workSpaceCount1 = getArray(a).reduce(
        (sum, num) => (sum += valueHandler(num)),
        0
      );
      const workSpaceCount2 = getArray(b).reduce(
        (sum, num) => (sum += valueHandler(num)),
        0
      );
      return workSpaceCount2 - workSpaceCount1;
    });
  };

  const sort = (projects) => {
    switch (tableType) {
      case "workload":
        return sortProjectsByWC(projects);
      case "income":
        return sortProjectsByDate(projects);
      default:
        return projects;
    }
  };

  return (
    <section style={{ marginBottom: "50px" }}>
      <div className="table-wrapper">
        <table className="table workspace__table">
          <StatisticTableHeaders
            headers={getHeaders(tableType, type)}
            tableType={tableType}
            incomeTab={incomeTab}
          />
          <tbody>
            {projects && projects.length > 0 ? (
              sort(projects)
                .filter((project) => project.title !== "Неназначенное")
                .map((project, index) => (
                  <StatisticTableRow
                    key={project.title || project.id}
                    headers={getHeaders(tableType)}
                    project={project}
                    type={type}
                    tableType={tableType}
                    incomeTab={incomeTab}
                    index={index + 1}
                  />
                ))
            ) : (
              <tr>
                <td colSpan={9}>Нет договоров</td>
              </tr>
            )}
            {projects?.length !== 0 && (
              <StatisticTableTotalRow
                type={type}
                tableType={tableType}
                projects={projects}
              />
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
}
export default StatisticTable;
