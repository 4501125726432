import { editContract } from "entities/contracts/service/editContract";
import { api } from "settings/Api";

const postProjectData = async (body, actionType, projectId) => {
	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;

	let url = "/api/sout/admin/separated-projects";
	let requestBody = body;

	if (actionType === "edit") {
		requestBody.id = projectId;
	}

	let response = await api(url, {
		method: actionType === "edit" ? "PATCH" : "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(requestBody),
	});
	let data = await response.json();

	// await editContract(
	// 	{
	// 		...data?.contract,
	// 		customer: {
	// 			...data?.contract?.customer,
	// 			contactData: data.managerContactData,
	// 		},
	// 		projects: [data],
	// 		additionalName: data.managerName,
	// 	},
	// 	() => {}
	// );

	return {status: response.status, ...data};
};
export default postProjectData;
