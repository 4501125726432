import { api } from 'settings/Api';

export const moveToNextStage = async (contractID) => {
	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;

	let res = await api(`/api/sout/workflow/contract/continue/${contractID}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	});
	let data = await res.json();
	return res;
};