

import { ImAttachment } from 'react-icons/im'

function AttachButton({ docType, isOpenList, chooseFile, color }) {
  return (
    <label className={`add-file ${!isOpenList[docType] && 'collapse'}`}>
      <input className="add-file__input"
        type="file"
        title="Загрузить документ"
        onChange={(e) => chooseFile(e, docType)}
      />
      <div className={`button button--square button--${color}`}>
        <ImAttachment className="icon--medium" />
      </div>
    </label>
  )
}

export default AttachButton