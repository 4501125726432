import { useContext } from "react";
import ProjectContext from "../../context/ProjectsContext";
import ModalWrapper from "./ModalWrapper";
import rejectProject from "../../utils/rejectProject";

function RejectModal({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const projectDataHandler = async (e) => {
		e.preventDefault();
		const res = await rejectProject(project.id);
		setOpenModal(false);
		if (res.status === "200" || res.ok) {
			setIsProjectMoved((prevState) => !prevState);
			setOpenModal(false);
		} else {
			alert("Ошибка! Проект не был удалён");
			setOpenModal(false);
		}
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<h3 className="modal__description">
				Удалить проект
				<b>&nbsp;{project.customerName}&nbsp;</b>?
			</h3>
			<div className="modal__buttons-wrapper">
				<button
					className="button button--red"
					onClick={(e) => projectDataHandler(e)}
				>
					Да
				</button>
				<button
					className="button close-button"
					onClick={() => setOpenModal(false)}
				>
					Нет
				</button>
			</div>
		</ModalWrapper>
	);
}
export default RejectModal;
