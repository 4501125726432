import { useEffect } from "react";
import Asterisk from "../../components/Asterisk";
import { DatePicker } from "components/DateInput/DateInput";
import { classNames } from "shared/classNames";

const InputDiapason = (props) => {
	const {
		title,
		type,
		name,
		placeholder,
		onChange,
		className,
		classNameLabel,
		value,
		width,
		isRequired,
		disabled = false,
	} = props;
	const valuesIsEmpty = () => {
		if (!value) return true;
		return false;
	};

	const valueisUndef = (value) => {
		if (value === undefined) {
			return null;
		}
		return value;
	};
	return (
		<fieldset
			className={classNames("search-area__inputs-diapason", {}, [
				classNameLabel,
			])}
			style={{
				marginTop: isRequired ? "-10px" : "0",
				width: width,
			}}
		>
			<legend>
				{title}
				{isRequired && <Asterisk />}
			</legend>
			<div className="inputs-diapason__wrapper">
				{type !== "date" ? (
					<>
						<input
							className={`input ${className}`}
							type={type}
							name={name[0]}
							onChange={onChange ? onChange[0] : () => {}}
							placeholder={placeholder[0]}
							value={value ? value[0] : ""}
							style={{
								width: "45%",
								padding: "6px 5px",
							}}
							disabled={disabled}
							required={isRequired}
						/>
						<span className="inputs-diapason__to">-</span>
						<input
							className={`input ${className}`}
							type={type}
							name={name[1]}
							onChange={onChange ? onChange[1] : () => {}}
							placeholder={placeholder[1]}
							value={value ? value[1] : ""}
							style={{
								width: "45%",
								padding: "6px 5px",
							}}
							disabled={disabled}
							required={isRequired}
						/>
					</>
				) : (
					<>
						<DatePicker
							className={`input ${className}`}
							name={name[0]}
							value={valuesIsEmpty(value) ? null : valueisUndef(value[0])}
							onChange={onChange ? onChange[0] : () => {}}
							disabled={disabled}
							required={isRequired}
							placeholder={placeholder}
							min={new Date(1, 1, 2000)}
						/>
						<span className="inputs-diapason__to">-</span>
						<DatePicker
							className={`input ${className}`}
							name={name[1]}
							value={valuesIsEmpty(value) ? null : valueisUndef(value[1])}
							onChange={onChange ? onChange[1] : () => {}}
							disabled={disabled}
							required={isRequired}
							placeholder={placeholder}
							min={new Date(1, 1, 2000)}
						/>
					</>
				)}
			</div>
		</fieldset>
	);
};

InputDiapason.defaultProps = {
	className: "",
	placeholder: ["", ""],
};
export default InputDiapason;
