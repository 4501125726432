import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import Spinner from "../../../components/Spinner";
import AdminAgentActionButtons from "../../../components/Select/AdminAgentActionButtons";
import AgentContext from "context/ParticipantsOfProcessContext";

const ListOfAgents = () => {
  const {
    fetchAgents,
    setAgents,
    setIsLoading,
    agents,
    isLoading,
    getActiveAgents,
    getBlokedAgents,
    getAllAgents,
    isChangeState,
  } = useContext(AgentContext);

  useEffect(() => {
    fetchAgents(setIsLoading, setAgents);
  }, [isChangeState]);

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <header className="">
                <h2 className="workspace__title workspace__page-title">
                  Список агентов
                </h2>
                <div style={{ marginBottom: 10 }}>
                  <button
                    className="button workspace__button agentBtn"
                    onClick={getAllAgents}
                  >
                    Все
                  </button>
                  <button
                    className="button workspace__button agentBtn"
                    onClick={getActiveAgents}
                  >
                    Активные
                  </button>
                  <button
                    className="button workspace__button agentBtn"
                    onClick={getBlokedAgents}
                  >
                    Заблокированные
                  </button>
                </div>
                <Link to="/AddAgent" className="button workspace__button">
                  Добавить агента
                </Link>
              </header>

              <section className="workspace__table-wrapper">
                <div className="table-wrapper">
                  <table className="table workspace__table">
                    <thead>
                      <tr>
                        <td>ИНН</td>
                        <td>Наименование</td>
                        <td>Регион</td>
                        <td>Действия</td>
                      </tr>
                    </thead>
                    <tbody>
                      {agents &&
                        agents.map((employee) => (
                          <tr key={employee.id}>
                            <td>{employee.inn}</td>
                            <td className="table__name">
                              {employee.name === 'ООО "ПРЦОТ"' ? (
                                <b>{employee.name}</b>
                              ) : (
                                employee.name
                              )}
                            </td>
                            <td>{employee.region?.name}</td>
                            {/* <td>{employee.departmentId}</td>
                              <td>{employee.positionId}</td> */}
                            <td
                              className="table__admin-action-buttons"
                              style={{
                                width: "auto",
                                justifyContent: "center",
                              }}
                            >
                              <AdminAgentActionButtons
                                note={{
                                  state: employee,
                                  link: "/EditAgent",
                                }}
                                isPRCOT={
                                  employee.name === 'ООО "ПРЦОТ"' ? true : false
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </>
          )}
        </section>
      </div>
    </main>
  );
};
export default ListOfAgents;
