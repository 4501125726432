import WorkspaceWrapper from "./WorkspaceWrapper";
import StatisticTable from "./StatisticTable";
import { FaPlus } from "react-icons/fa";
import ExpensesModal from "../modals/ExpensesModal";
import { useState, useEffect } from "react";
import { api } from 'settings/Api';

function Expenses() {
	useEffect(() => {
		getExpenses();
	}, []);

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [expenses, setExpenses] = useState([]);

	//

	const getExpenses = async () => {
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;
		let response = await api("/api/sout/admin/accounting/expenses", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		let data = await response.json();

		setExpenses(data.data.list);
	};

	const modalToggler = (bool) => setIsOpenModal(bool);

	const expensesHandler = (value) => setExpenses(value);

	return (
		<WorkspaceWrapper>
			<h3 className="workspace__title workspace__page-title">Статистика расходов</h3>
			<button
				className="button button--iconic"
				onClick={() => modalToggler(true)}
			>
				<FaPlus className="icon--mr" />
				Добавить расход
			</button>
			<StatisticTable tableType="expenses" projects={expenses} />
			{isOpenModal && (
				<ExpensesModal modalToggler={modalToggler} getExpenses={getExpenses} />
			)}
		</WorkspaceWrapper>
	);
}

export default Expenses;
