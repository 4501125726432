import Sidebar from '../components/Sidebar';
import StatisticTableHeaders from '../components/common/StatisticTableHeaders';
import { useEffect, useState } from 'react';
import { getHeaders } from '../components/common/tableHeaders';
import ActionButton from '../components/ActionButton';
import { Link } from 'react-router-dom';
import AgentReportsModal from './sout/commercial/modals/AgentReportsModal';
import { dateToTextDashed } from '../utils/stages';
import { FaArrowDown } from 'react-icons/fa';
import { api } from 'settings/Api';

const AgentReports = () => {
	const [agentReports, setAgentReports] = useState([]);
	const [reportsIsUpdated, setReportsIsUpdated] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		fetchReports()
	}, [reportsIsUpdated]);

	const TABLE_TYPE = 'agent_reports';

	const toggleIsReportsUpdated = () => setReportsIsUpdated(!reportsIsUpdated);

	const fetchReports = async () => {
		const url = `api/documents/monthly`; //Вывести список документов
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;

		let response = await api(url, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});


		let data = await response.json();

		setAgentReports(data.data);
	}

	const downloadReport = async (e, id) => {
		e.preventDefault();
		const url = `api/documents/${id}`;
		const token = JSON.parse(localStorage.getItem("authTokens")).data
			.accessToken;

		api(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((response) => {

				return response.blob()
			})
			.then((blob) => {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = 'report.pdf';
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
	}

	const getDateFromFilename = (name) => name.split('_')[0]

	const modalToggler = (bool) => setIsModalOpen(bool);

	return (
		<main className="workspace">
			<Sidebar />
			<div className="workspace__content">
				<section className="workspace__wrapper">
					<h3 className="workspace__title workspace__page-title">Отчеты агента</h3>
					<div>
						<button className="button button--blue" onClick={() => modalToggler(true)}>
							Формирование отчёта
						</button>
					</div>
					<section style={{ marginBottom: "50px" }}>
						<div className="table-wrapper">
							<table className="table workspace__table">
								<StatisticTableHeaders
									headers={getHeaders(TABLE_TYPE)}
									tableType={TABLE_TYPE}
								/>
								<tbody>
									{agentReports && agentReports.length > 0 ? (
										agentReports.map((report) => (
											<tr key={report.id}>
												<td>
													{report?.id}
												</td>
												<td>{dateToTextDashed((getDateFromFilename(report?.name)))}</td>
												<td>
													<Link to="#" onClick={(e, id) => downloadReport(e, report.id)}>
														<button className='button button--square button--blue'>
															<FaArrowDown className='icon-document' />
														</button>
													</Link>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan={9}>Нет проектов</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</section>
				</section>
			</div>
			{
				isModalOpen
				&&
				<AgentReportsModal modalToggler={modalToggler} toggleIsReportsUpdated={toggleIsReportsUpdated} reportsIsUpdated={reportsIsUpdated} />
			}
		</main>
	)
}

export default AgentReports