import React, { useEffect, useState } from "react";
import { DatePicker } from "components/DateInput/DateInput";
import SelectRegions from "components/common/SelectRegions";
import GroupButton from "components/common/GroupButton";
import ContracModalFileInput from "pages/sout/accountant/common/ContractModalFileInput";
import FileInput from "pages/sout/accountant/common/FileInput";

const assesmentTypeArray = [
  { value: "WorkingConditionsSpecialAssesment", label: "СОУТ" },
  { value: "ProfessionalRisks", label: "ОПР" },
  { value: "ProductionControl", label: "ПК" },
];

const DraftContractStage = ({
  contract,
  setContract,
  setArr,
  arr,
  setIsDisabled,
}) => {
  const [isUpload, setIsUpload] = useState([]);
  const [isUploadInfo, setIsUploadInfo] = useState([]);
  const [isNullPK, setIsNullPK] = useState(false);
  useEffect(() => {
    setIsDisabled(
      !(
        arr.length !== 0 &&
        contract?.contractNumber &&
        contract?.price &&
        contract?.conclusionContractDate &&
        contract?.plannedExecutionDate &&
        contract?.regions &&
        contract?.regions.length !== 0 &&
        checkWorkspaceCount() &&
        isUpload
      )
    );
  });
  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      const newItem = {
        id: value,
      };
      setArr(
        arr.includes(value)
          ? arr.filter((item) => item != value)
          : [...arr, newItem]
      );
    } else {
      const newItems = arr.filter((item) => item.id != value);
      setArr(newItems);
    }
  };
  const updateRegions = (updatedRegions) =>
    setContract({ ...contract, regions: updatedRegions });

  const checkWorkspaceCount = () => {
    if (
      arr.find((item) => item?.id === "ProfessionalRisks") &&
      !contract?.workspaceCount?.opr
    )
      return false;
    if (
      arr.find((item) => item?.id === "WorkingConditionsSpecialAssesment") &&
      !contract?.workspaceCount?.sout
    )
      return false;
    if (
      arr.find((item) => item?.id === "ProductionControl") &&
      !isNullPK &&
      !contract?.workspaceCount?.pk
    )
      return false;
    return true;
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        columnGap: "2rem",
      }}
    >
      <div style={{}} className="modal_display_start">
        <p style={{ marginBottom: 10 }}>
          ИНН заказчика:{" "}
          <span style={{ fontStyle: "italic", fontWeight: 600 }}>
            {contract?.customer?.inn}
          </span>
        </p>
        <p style={{ textAlign: "start" }}>
          Наименование:{" "}
          <span style={{ fontStyle: "italic", fontWeight: 600 }}>
            {contract?.customer?.name}
          </span>
        </p>
      </div>
      <div style={{ marginTop: 20 }}>
        <label htmlFor="" className="modal_display_start">
          Доп наименование
        </label>
        <input
          type="text"
          className="input"
          value={contract?.additionalName}
          onChange={(e) =>
            setContract({ ...contract, additionalName: e.target.value })
          }
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <GroupButton
          onChangeHandler={handleCheckboxChange}
          type={"checkbox"}
          title={"Тип оценки"}
          values={arr}
          info={assesmentTypeArray}
          isDraftContract={true}
        />
        <div style={{ width: 150, paddingTop: 12 }}>
          <div style={{ position: "inherit", zIndex: 2, width: 100 }}>
            <label htmlFor="" className="modal_display_start">
              Кол-во РМ
            </label>
            <input
              type="text"
              className="input"
              value={contract?.workspaceCount?.sout}
              disabled={
                !arr.find(
                  (item) => item?.id === "WorkingConditionsSpecialAssesment"
                )
              }
              onChange={(e) =>
                setContract({
                  ...contract,
                  workspaceCount: {
                    ...contract?.workspaceCount,
                    sout: e.target.value,
                  },
                })
              }
            />
          </div>
          <div style={{ position: "inherit", zIndex: 2, width: 100 }}>
            <label htmlFor="" className="modal_display_start">
              Кол-во РМ
            </label>
            <input
              type="text"
              className="input"
              value={contract?.workspaceCount?.opr}
              disabled={!arr.find((item) => item?.id === "ProfessionalRisks")}
              onChange={(e) =>
                setContract({
                  ...contract,
                  workspaceCount: {
                    ...contract?.workspaceCount,
                    opr: e.target.value,
                  },
                })
              }
            />
          </div>
          <div style={{ position: "inherit", zIndex: 2, display: "flex" }}>
            <div style={{ position: "inherit", zIndex: 2, width: 100 }}>
              <label htmlFor="" className="modal_display_start">
                Кол-во РМ
              </label>
              <input
                type="text"
                disabled={!arr.find((item) => item?.id === "ProductionControl")}
                className="input"
                value={contract?.workspaceCount?.pk}
                onChange={(e) =>
                  setContract({
                    ...contract,
                    workspaceCount: {
                      ...contract?.workspaceCount,
                      pk: e.target.value,
                    },
                  })
                }
              />
            </div>
            <label style={{ margin: "30px 0 0 5px" }}>
              <input type="checkbox" onClick={() => setIsNullPK(!isNullPK)} />
              <span
                style={{ fontFamily: "monospace", marginLeft: 5, fontSize: 16 }}
              >
                X
              </span>
            </label>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "45%" }}>
          <label className="modal_display_start">Дата заключения:</label>
          <DatePicker
            className="input"
            name="conclusionContractDate"
            value={contract?.conclusionContractDate || null}
            onChange={(date) =>
              setContract({ ...contract, conclusionContractDate: date })
            }
            required={true}
            min={new Date("01.01.2000")}
          />
        </div>
        <div style={{ width: "45%" }}>
          <label className="modal_display_start">Дата исполнения:</label>
          <DatePicker
            className="input"
            name="plannedExecutionDate"
            value={contract?.plannedExecutionDate || null}
            onChange={(date) =>
              setContract({ ...contract, plannedExecutionDate: date })
            }
            required={true}
            min={new Date("01.01.2000")}
          />
        </div>
      </div>
      <div style={{ position: "inherit", zIndex: 2 }}>
        <label htmlFor="" className="modal_display_start">
          Номер договора:
        </label>
        <input
          type="text"
          className="input"
          value={contract?.contractNumber}
          onChange={(e) =>
            setContract({ ...contract, contractNumber: e.target.value })
          }
        />
      </div>
      <div style={{ position: "inherit", zIndex: 2 }}>
        <label htmlFor="" className="modal_display_start">
          Цена договора
        </label>
        <input
          type="text"
          className="input"
          value={contract?.price}
          onChange={(e) => setContract({ ...contract, price: e.target.value })}
        />
      </div>
      {/* <div style={{width: 400, position: 'inherit', zIndex: 2}}>
                <label htmlFor="" className='modal_display_start'>Внутренний номер</label>
                <input 
                    type="text"
                    className='input'
                    value={contract?.innerNumber}
                    onChange={(e) => setContract({...contract, innerNumber: e.target.value})}
                    />
            </div> */}
      <div style={{ marginTop: -30, position: "inherit", zIndex: 1 }}>
        <SelectRegions
          regions={contract?.regions || []}
          updateRegions={updateRegions}
        />
      </div>
      <div style={{}}>
        <FileInput
          modelId={contract?.id}
          entity="contracts"
          link="Contract"
          title="Договор"
          setIsUpload={setIsUpload}
        />
      </div>
    </div>
  );
};

export default DraftContractStage;
