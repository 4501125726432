import { isCommercialUser, isTenderUser } from "./defineUser"

export const getDepContract = () => {
    if(isCommercialUser()){
      if(localStorage.getItem('role') === 'comercial_chief')
        return '&ContractConclusionDepartmentId=CommercialDepartment'
      else
        return `&CommercialDepartmentUserId=${localStorage.getItem('__user-id')}`
    }
    if(isTenderUser()){
      return '&ContractConclusionDepartmentId=TenderDepartment'
    }
  }