import React from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);
setDefaultLocale("ru");

const DateWithTimeInput = ({
  date,
  onChange,
  required,
  min,
  max,
  className,
  placeholder,
  name,
  disabled,
}) => {
  return (
    <DatePicker
      locale={ru}
      timeCaption="Время"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={date}
      name={name}
      className={className}
      onChange={onChange}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={1}
      dateFormat="dd.MM.yyyy HH:mm"
      maxDate={max}
      minDate={min}
      placeholderText={placeholder}
      disabled={disabled}
      required={required}
      autoComplete="off"
    />
  );
};

export default DateWithTimeInput;
