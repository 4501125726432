import React, { useEffect, useState } from "react";
import FileInput from "pages/sout/accountant/common/FileInput";
import ContracModalFileInput from "pages/sout/accountant/common/ContractModalFileInput";

const IdAssigningContractStage = ({
  docs,
  setDocs,
  setDep,
  dep,
  setIsDisabled,
  udpateDep,
  contractId,
}) => {
  const [kpp, setKpp] = useState("");
  const [isUpload, setIsUpload] = useState([]);
  const [isFileModal, setIsFileModal] = useState(false);
  const [idNum, setIdNum] = useState("");

  const inputValidate = (str) => {
    return str.replace(/[^0-9]/g, "");
  };

  const changeModal = () => {
    if (!isFileModal) {
      udpateDep(false);
    }
    setIsFileModal(!isFileModal);
  };

  const createDep = () => {
    setDep([...dep, { kpp: Number(kpp), departmentNumber: Number(idNum) }]);
    setIdNum("");
    setKpp("");
  };

  useEffect(() => {
    setIsDisabled(
      !(
        isFileModal &&
        isUpload.filter((item) => item?.haveDoc === true).length ===
          dep?.length &&
        dep.length !== 0
      )
    );
  }, [isUpload, isFileModal]);

  const deleteDep = (id) => {
    setDep(dep.filter((item) => item.departmentNumber !== id));
  };

  return (
    <div className="modal_align_center">
      <div style={{ width: 750, marginTop: 10, marginBottom: 10 }}>
        <div className="modal_display_start">
          <h3 className="blueColorText">
            Количество подразделений <span>{dep.length}</span>
          </h3>
        </div>
        <div
          style={{
            width: 450,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label style={{ width: 220 }} className="modal_display_start">
            КПП
          </label>
          <label style={{ width: 220 }} className="modal_display_start">
            Идентификационный номер
          </label>
        </div>
        <div
          style={{
            width: 650,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: 450,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <input
              type="text"
              value={kpp}
              style={{ width: 220 }}
              onChange={(e) => setKpp(inputValidate(e.target.value))}
              className="input"
            />
            <input
              type="text"
              value={idNum}
              style={{ width: 220 }}
              className="input"
              onChange={(e) => setIdNum(inputValidate(e.target.value))}
            />
          </div>
          {!isFileModal && (
            <button
              className="button--blue button"
              disabled={!idNum || !kpp}
              onClick={createDep}
            >
              сохранить
            </button>
          )}
        </div>
      </div>
      <div
        className="modal_display_start"
        style={{ width: 750, maxHeight: 300, overflowY: "scroll" }}
      >
        {dep?.map((depItem, index) => {
          return (
            <div style={{ display: "flex", alignItems: "start" }}>
              <div
                key={depItem.departmentNumber + index}
                style={{
                  display: "flex",
                  width: 450,
                  marginTop: 5,
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    background: "lightgray",
                    borderRadius: 7,
                    padding: 2,
                    width: 215,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{`${dep.indexOf(depItem) + 1})`}</span>
                  {depItem.kpp}
                </p>
                <p
                  style={{
                    fontSize: 16,
                    background: "lightgray",
                    borderRadius: 7,
                    padding: 2,
                    width: 215,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {depItem.departmentNumber}
                  <span
                    style={{ cursor: "pointer", fontSize: 18 }}
                    onClick={() => deleteDep(depItem.departmentNumber)}
                  >
                    &#11198;
                  </span>
                </p>
              </div>
              <div
                style={{
                  width: 190,
                  marginLeft: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {isFileModal && (
                  <ContracModalFileInput
                    contractId={contractId}
                    entity={"contracts"}
                    isUpload={isUpload}
                    width={190}
                    setIsUpload={setIsUpload}
                    modelId={depItem?.departmentNumber}
                    link={"Id"}
                    title={"Файл"}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <button
        style={{ marginTop: 10 }}
        className="button button--blue"
        onClick={changeModal}
      >
        {isFileModal
          ? "Перейти к созданию подразделений"
          : "Перейти к загрузке документов"}
      </button>
    </div>
  );
};

export default IdAssigningContractStage;
