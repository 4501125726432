import React from 'react'

const MovingProjectsModal = ({ move, moveType, data, setOpenModal }) => {

  
  return (
    <section className="modal">
      <div className="modal__content">
        <button className="modal__close-button close-button"
          onClick={() => setOpenModal(false)}
          >&#10005;</button>

        <h3 className="modal__description">Перевести проект
        &nbsp;<b>{data.state.contract.customer.name}</b>&nbsp;
          на &nbsp;
          <i>{moveType === 'next' ? 'следующий' : 'предыдущий'}</i>&nbsp;
          этап. &nbsp;
          Вы уверены?
        </h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            onClick={ move }
          >Да</button>
          <button className="button close-button"
            onClick={() => {setOpenModal(false)}}
          >Нет</button>
        </div>
      </div>
    </section>
  )
}

export default MovingProjectsModal;