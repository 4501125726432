import { generateToken } from 'shared/generateToken';
import { buildQuery } from 'shared/buildQuery';
import { isCommercialUser, isTenderUser } from 'utils/defineUser';
import { getComercialContract, getDepContract } from 'utils/getComercialContract';
import { api } from 'settings/Api';

export const fetchContracts = async (values, page, stage, rowCount = 20) => {
  try {
    const token = generateToken();
    const url = buildQuery('/api/sout/admin/contracts', page, values, stage, rowCount);

    const response = await api(`${url}${(isCommercialUser() || isTenderUser())?getDepContract():''}` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    })

    if (response.status !== 200) return response.status;

    const data = await response.json();

    if (data.isSuccess) {
      const { totalPagesCount, list, totalRowCount } = data.data;
      return { totalPagesCount, list, totalRowCount };
    }
    if (data.isFailure) throw new Error(data);
  }
  catch (e) {
    alert(`Произошла ошибка: ${e}`);
  }
}