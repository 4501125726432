import { useContext, useState, useEffect } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from "../../../../../components/modals/ModalWrapper"
import patchProject from '../../../../../utils/patchProject';
import moveToNextStage from "../../../../../utils/moveToNextStage"
import AuthContext from '../../../../../context/AuthContext';
import { api } from 'settings/Api';

function AcceptedToWorkTenderModal({ setOpenModal, inputChangeHandler, project }) {
  const { setIsProjectMoved } = useContext(ProjectContext);
  let { user } = useContext(AuthContext);
  //TODO: Rename to AUTHORIZED_USER_ID
  const CURRENT_USER_ID = user.nameid;

  const [tenderUsers, setTenderUsers] = useState('');

  useEffect(() => {
    fetchUsers('TenderDepartment');
  })

  const fetchUsers = async (departmentName) => {
    const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;
    let response = await api(`/api/sout/helpers/handbooks/engineers?department=${departmentName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        // 'Authorization':  'String(authTokens.access) ,
      },
    })
    let data = await response.json();
    setTenderUsers(data.data);
  }

  const projectDataHandler = async (e) => {
    e.preventDefault();

    if (project.tenderUserId === null || project.tenderUserId === 'null') {
      project.tenderUserId = CURRENT_USER_ID;
    }

    await patchProject(e, project, project.id);

    const res = await moveToNextStage(project.id);
    if (res.status === '200' || res.ok) {
      setIsProjectMoved((prevState) => !prevState)
      setOpenModal(false);
    } else {
      alert('Error! Проект не был переведён на следующий этап');
      setOpenModal(false);
    }
  }

  return (
    <ModalWrapper>
      <button className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}>&#10005;</button>
      <form>
        {//TODO: Change classes for modal inputs

          project.operationTypeId === 'DigitalAuction'
          &&
          <fieldset className="workspace__ensure-wrapper">
            <label className="modal__input-title">Кто отыгрывает аукцион:</label>
            <input id="hasTradingRobot-true" className="radio" type="radio"
              name="hasTradingRobot"
              value="true"
              checked={project.hasTradingRobot === 'true'}
              onChange={(e) => inputChangeHandler(e)}
            />
            <label htmlFor="hasTradingRobot-true" className="radio__label">Робот</label>

            <input id="hasTradingRobot-false" className="radio" type="radio"
              name="hasTradingRobot"
              value="false"
              checked={project.hasTradingRobot === 'false'}
              onChange={(e) => inputChangeHandler(e)}
            />
            <label htmlFor="hasTradingRobot-false" className="radio__label radio__label--ml">Не робот</label>
          </fieldset>
        }

        <label className="modal__input-title">Комментарий к заявке:</label>
        <textarea className="modal__input input" cols="50" rows="5"
          name="description"
          value={project.description}
          onChange={(e) => inputChangeHandler(e)}
        />
        {
          (!project.tenderUserId)
          && (
            <>
              <label className="modal__input-title">Сотрудник тендерного отдела:</label>
              <select className="select"
                name="tenderUserId"
                value={project.tenderUserId}
                onChange={(e) => inputChangeHandler(e)}
                disabled={localStorage.getItem('__erp_username') !== 'Admin'}
              >
                {
                  tenderUsers
                  && tenderUsers
                    .filter(item => parseInt(CURRENT_USER_ID) === item.id)
                    .map(item => {
                      return <option key={item.id} value={item.id}> {item.name} </option>
                    })
                }
              </select>
            </>
          )
        }
        <h3 className="modal__description">Перевести проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          на следующий этап?</h3>
        <div className="modal__buttons-wrapper">
          <button className="button button--red"
            disabled={project.offerAmount <= 0}
            onClick={(e) => projectDataHandler(e)}>
            Да
          </button>
          <button className="button close-button"
            onClick={() => setOpenModal(false)}>
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper>
  )
}
export default AcceptedToWorkTenderModal


