import React from "react";

const GroupButton = ({
  onChangeHandler,
  type,
  values,
  info,
  title,
  defValue = null,
  entity = "",
  isDraftContract = false
}) => {
  let name = "";

  if (type === "radio") name = "radioBtnsGroupName";

  const checkedValidate = (val) => {
    if (typeof values === "string") return values === val;
    else return values?.find((i) => i?.id === val);
  };

  return (
    <div  style={isDraftContract?{width: 100}:{}}>
      <label htmlFor="" className="modal_display_start">
        {title}
      </label>
      <div
        className={`${
          entity === "locations"
            ? "form_radio_group locations_rb"
            : "form_radio_group"
        }`}
        style={isDraftContract?{display: 'flex', flexDirection: 'column', width: 100}:{}}
      >
        {info.map((item, index) => {
          return (
            <div class={isDraftContract?"":"form_radio_group-item"} style={isDraftContract?{marginTop: 15, width: 70}:{}} >
              <input
                id={`radio-${index + 1}`}
                onChange={!defValue ? onChangeHandler : null}
                type={type}
                name={name || item?.value}
                value={item?.value}
                checked={
                  defValue
                    ? item?.value == defValue
                    : checkedValidate(item?.value)
                }
              />
              <label style={isDraftContract?{width: 70, border: '1px solid gray'}:{}} for={`radio-${index + 1}`}>{item?.label}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupButton;
