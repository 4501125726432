import { generateToken } from "shared/generateToken";
import { api } from 'settings/Api';

export const fetchAgents = async () => {
    const token = generateToken();
  
    try {
      const response = await api('/api/sout/helpers/handbooks/agents', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
  
      const data = await response.json();
  
      if (data.isSuccess) {
        return data.data;
      }
      if (data.isFailure) {
        throw new Error();
      }
    }
    catch(e) {
      alert(`Не удалось загрузить организации. Ошибка: ${e.message}`)
    }
  }