import React, { useEffect, useState } from "react";
import { fetchAgents } from "utils/fetchAgents";
import { fetchOrganizations } from "utils/fetchOrganizations";
import fetchBranchOffices from "utils/fetchBranchOffices";

const ReadyToWorkProjectStage = ({
  org,
  setOrg,
  move,
  setIsDisabled,
  filial,
  setFilial,
}) => {
  const [organizations, setOrganizations] = useState([]);
  const [filials, setFilials] = useState([]);

  useEffect(() => {
    setIsDisabled(!(org && filial && filial !== "null"));
    console.log(filial);
    if (organizations.length === 0) {
      fetchAgents().then((res) => {
        setOrganizations(res);
      });
      // fetchOrganizations().then(res => setOrganizations(res))
    }
  });

  useEffect(() => {
    if (org)
      fetchBranchOffices(org).then((res) => {
        setFilials(res);
      });
  }, [org]);

  return (
    <div className="modal__content-center">
      <label className="modal__input-title">Выбор организации</label>
      <select
        className="input search-area__select"
        name="winner"
        value={org}
        onChange={(e) => setOrg(e.target.value)}
        style={{ width: 300, margin: "0 auto" }}
      >
        <option value="null">-</option>
        {organizations &&
          organizations?.map(({ id, name }) => (
            <option key={id} value={id}>
              {" "}
              {name}{" "}
            </option>
          ))}
      </select>

      <label style={{ marginTop: 10 }} className="modal__input-title">
        Выбор филиала
      </label>
      <select
        className="input search-area__select"
        name="winner"
        value={filial}
        onChange={(e) => setFilial(e.target.value)}
        style={{ width: 300, margin: "0 auto" }}
      >
        <option value="null">-</option>
        {filials &&
          filials?.map(({ id, name }) => (
            <option key={id} value={id}>
              {" "}
              {name}{" "}
            </option>
          ))}
      </select>
    </div>
  );
};

export default ReadyToWorkProjectStage;
