import React from 'react'
import QUERIES from '../utils/SearchQueries';
import PaymentInfoTableButtons from './PaymentInfoTableButtons';

const TablePaymentsView = ({ setOpenTablePaymentsModal, user }) => {


  const currentStatus = user.state.workFlow.projectStatusId;


  const allStages = QUERIES.ProjectStatusId.stages; //[]

  let stageKeys = [];

  for (let i = 0; i < allStages.length; i++) {
    stageKeys.push(...Object.keys(allStages[i]));
  }

  let idxStage = 0;

  for (let i = 0; i < stageKeys.length; i++) {
    if (stageKeys[i] === currentStatus) {
      idxStage = i;
      break;
    }
  }

  const currentObj = allStages[idxStage];

  const fullNameStatus = currentObj[currentStatus];

  const payments = user?.state?.contract?.payments;
  let totalAmount = null;

  if (payments.length > 0) {
    totalAmount = payments.map(payt => payt.amount).reduce((acc, cur) => acc + cur);

  }

  const formatDate = (date) => (date && date.indexOf('T') !== -1) ? date.slice(0, date.indexOf('T')) : date;




  return (
    <section className="modal">
      <div className="modal__content">
        <button
          onClick={() => setOpenTablePaymentsModal(false)}
          className="modal__close-button close-button">&#10005;</button>
        <h3 className="modal__title">Список платежей по проекту {user.state.contract.customer.name}</h3>
        <div style={{ display: 'flex' }}>
          <div className="table-wrapper table-wrapper--flex-row">

            <table className="table table__table-view-modal">
              <thead>
                <tr>
                  <td><b>Общая сумма платежей: </b></td>
                  <td className="table__id" colspan="2"><b>{totalAmount || '0'}&nbsp;₽</b></td>
                </tr>
              </thead>
              <tbody>
                {
                  payments.length > 0
                    ? <tr>
                      <td>Сумма платежа*</td>
                      <td>Дата платежа</td>
                      <td>Действия</td>
                    </tr>
                    : <tr>
                      <td colspan="2">Нет платежей</td>
                    </tr>
                }
                {
                  payments &&
                  payments.map(payt =>
                    <tr>
                      <td>{payt?.amount}&nbsp;₽</td>
                      <td>{formatDate(payt?.paymentDate)}</td>
                      <td><PaymentInfoTableButtons paytId={payt.id}/></td>
                    </tr>
                  )
                }
              </tbody>
            </table>

          </div>
        </div>
        <button
          onClick={() => setOpenTablePaymentsModal(false)}
          className="button close-button modal__close-button--bottom">Закрыть окно</button>
      </div>
    </section>
  )
}

export default TablePaymentsView