import { api } from "settings/Api";

export const getPictures = async () => {
    const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;
    let response = await api(`/api/sout/admin/organizations/pics`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        // 'Authorization':  'String(authTokens.access) ,
      },
    })
    let data = await response.json();
    
    return data
};
