import { useContext, useEffect, useState } from "react";
import ActionButton from "./ActionButton";
import ModalWrapper from "./modals/ModalWrapper";
import { MdEdit, MdTextSnippet } from "react-icons/md";
import { ImCross } from "react-icons/im";
import {
  FaInfo,
  FaArrowLeft,
  FaArrowRight,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { ConfirmActionModal } from "./modals/ConfirmActionModal";
import DocumentsModal from "pages/sout/commercial/modals/documents/DocumentsModal";
import { ConfirmMesBlockActionModal } from "./modals/ConfirmMesBlockActionModal";
import { Link, useNavigate } from "react-router-dom";
import ProjectContext from "context/ProjectsContext";
import { generateQueriesForSearchRequestWithId } from "shared/generateQueriesForSearchRequestWithId";
import { setIsCompareData } from "shared/setIsCompareData";

const LocationActionButtons = (props) => {
  const {
    disabledButtons,
    onEdit,
    isCompare,
    reject,
    locType,
    moveToPrevStage,
    moveToNextStage,
    getInfoModal,
    documentsInfo,
    colorTheme,
    location,
    entityTypeName,
  } = props;

  const {
    setValues,
    values,
    setSortParam,
    setQueryStrings,
    setPaginationQuery,
  } = useContext(ProjectContext);

  const [openModal, setOpenModal] = useState(false);
  const [openDocsModal, setOpenDocsModal] = useState(false);
  const [modalType, setModalType] = useState();
  const [colorThemeClass, setColorThemeClass] = useState("");
  const navigate = useNavigate();

  const modalHandler = async (modalType) => {
    if (!openModal) {
      setModalType(modalType);
      setOpenModal(true);
      return;
    }

    setModalType("");
    setOpenModal(false);
  };

  useEffect(() => {
    if (colorTheme) {
      setColorThemeClass(`button--${colorTheme}`);
    }
  }, [colorTheme]);

  return (
    <div style={{ margin: `${isCompare ? "0 auto" : ""}`, display: "flex" }}>
      {!isCompare && (
        <>
          <ActionButton
            isDisabled={disabledButtons?.edit(location, locType)}
            iconType={() => <MdEdit className="icon-edit" />}
            title="Редактировать"
            className={colorThemeClass}
            onAction={() => {
              onEdit();
            }}
          ></ActionButton>
          <ActionButton
            isDisabled={disabledButtons?.trash(location, locType)}
            onAction={modalHandler}
            modalType="reject"
            iconType={() => <ImCross className="icon-delete" />}
            title="Удалить проект"
            className={colorThemeClass}
          ></ActionButton>
        </>
      )}

      <ActionButton
        isDisabled={disabledButtons?.info(location, locType)}
        onAction={modalHandler}
        modalType="showInfo"
        iconType={() => <FaInfo className="icon-info" />}
        title="Информация о проекте"
        className={colorThemeClass}
      ></ActionButton>
      {isCompare && location.hasOwnProperty("tender") && (
        <Link
          to={"/sout/tender/draft"}
          target="_blank"
        >
          <ActionButton
            onAction={() => {
              setIsCompareData({
                id:  location?.tender?.id
              });
            }}
            iconType={() => <FaAngleDoubleRight className="icon-move" />}
            title="Перейти к связному тендеру"
            className={colorThemeClass}
          ></ActionButton>
        </Link>
      )}

      {!isCompare && (
        <>
          <ActionButton
            isDisabled={disabledButtons?.movePrev(location, locType)}
            onAction={modalHandler}
            iconType={() => <FaArrowLeft className="icon-move" />}
            title="Перевести на предыдущий этап"
            modalType="movePrev"
            className={colorThemeClass}
          ></ActionButton>

          <ActionButton
            isDisabled={disabledButtons?.moveNext(location, locType)}
            onAction={modalHandler}
            iconType={() => <FaArrowRight className="icon-move" />}
            title="Перевести на следующий этап"
            modalType="moveNext"
            className={colorThemeClass}
          ></ActionButton>

          <ActionButton
            isDisabled={disabledButtons?.documents(location, locType)}
            onAction={() => setOpenDocsModal(true)}
            iconType={() => <MdTextSnippet className="icon-document" />}
            title="Документы"
            modalType="showDocs"
            className={colorThemeClass}
          ></ActionButton>
        </>
      )}

      {openModal ? (
        <ModalWrapper>
          {modalType === "showInfo" && getInfoModal(modalHandler)}
          {modalType === "reject" && (
            <ConfirmMesBlockActionModal
              location={location}
              locType={locType}
              setOpenModal={setOpenModal}
              stage={location?.workFlow?.workFlowStatusId}
              moveType={modalType}
              title={`Вы уверены, что хотите удалить ${entityTypeName}?`}
              onYes={() => {
                reject();
              }}
              onNo={modalHandler}
              colorThemeClass={colorThemeClass}
            />
          )}
          {modalType === "moveNext" && (
            <ConfirmMesBlockActionModal
              location={location}
              locType={locType}
              setOpenModal={setOpenModal}
              stage={location?.workFlow?.workFlowStatusId}
              moveType={modalType}
              title={"Вы уверены, что хотите перевести на следующий этап?"}
              onYes={() => {
                moveToNextStage();
              }}
              onNo={modalHandler}
              colorThemeClass={colorThemeClass}
            />
          )}
          {modalType === "movePrev" && (
            <ConfirmMesBlockActionModal
              location={location}
              locType={locType}
              setOpenModal={setOpenModal}
              stage={location?.workFlow?.workFlowStatusId}
              moveType={modalType}
              title={"Вы уверены, что хотите перевести на предыдущий этап?"}
              onYes={() => {
                moveToPrevStage();
              }}
              onNo={modalHandler}
              colorThemeClass={colorThemeClass}
            />
          )}
        </ModalWrapper>
      ) : null}
      {openDocsModal && (
        <DocumentsModal
          modelId={documentsInfo.id}
          entity={documentsInfo.entity}
          documents={documentsInfo.documents}
          setOpenModal={setOpenDocsModal}
        />
      )}
    </div>
  );
};

export { LocationActionButtons };
