const INPUTS = {
  id: {
    name: "id",
    label: "ID тендера*",
  },
  ArchiveId: "ArchiveId",
  AssessmentTypeId: {
    name: "AssessmentTypeId",
    label: "Тип оценки",
    values: {
      ProfessionalRisks: "ОПР",
      WorkingConditionsSpecialAssesment: "СОУТ",
      ProductionControl: "ПК",
    },
  },
  ExecutionProgramId: {
    name: "ExecutionProgramId",
    label: "Программа выполнения",
    values: {
      None: "Нет",
      LaborExpert_3_0: "ТрудЭксперт",
      Attestation_5_1: "Аттестация",
    },
  },
  INN: {
    name: "INN",
    label: "ИНН организатора",
  },
  managerINN: {
    name: "managerINN",
    label: "ИНН заказчика*",
  },
  HaveWorkbook: {
    name: "HaveWorkbook",
    label: "Рабочая тетрадь",
    values: {
      false: "Нет",
      true: "Да",
    },
  },
  TypeId: {
    name: "TypeId",
    label: "Тип проекта",
    values: {
      TenderWorkFlow: "Тендерный отдел",
      CommercialWorkFlow: "Коммерческий отдел",
    },
  },
  ExpenseTypeId: {
    name: "TypeId",
    label: "Тип расхода:",
    values: {
      None: "Нет",
      RefillPlatformAccount: "Пополнение счета площадки",
      PenaltyPayment: "Оплата пени",
      FotPayment: "Оплата ФОТ за сотрудников",
      SubcontractPayment: "Оплата субподряда",
      OtherPayment: "Другое",
    },
  },

  //Diapasons input[data/number]:

  FromPlannedExecutionDate: {
    name: "FromPlannedExecutionDate",
  },
  ToPlannedExecutionDate: {
    name: "ToPlannedExecutionDate",
  },
  FromWorkspaceCount: {
    name: "FromWorkspaceCount",
  },
  ToWorkspaceCount: {
    name: "ToWorkspaceCount",
  },
  FromConclusionContractDate: {
    name: "FromConclusionContractDate",
  },
  ToPrice: {
    name: "ToPrice",
  },
  FromPrice: {
    name: "FromPrice",
  },
  ToConclusionContractDate: {
    name: "ToConclusionContractDate",
  },
  FromContractPrice: {
    name: "FromContractPrice",
  },
  ToContractPrice: {
    name: "ToContractPrice",
  },
  FromAuctionDate: {
    name: "FromAuctionDate",
  },
  ToAuctionDate: {
    name: "ToAuctionDate",
  },
  //Users:
  TenderDepartmentEmployeeId: {
    name: "TenderDepartmentEmployeeId",
    label: "Тендерный отдел",
  },
  CommercialDepartmentEmployeeId: {
    name: "CommercialDepartmentEmployeeId",
    label: "Коммерческий отдел",
  },
  LaboratoryDepartmentEmployeeId: {
    name: "LaboratoryDepartmentEmployeeId",
    label: "Лаборатория",
  },
  WorkerId: {
    name: "WorkerId",
    label: "Замерщики",
  },
  //Sort
  SortMethod: {
    name: "SortMethod",
    label: "Сортировать по:",
    values: {
      Asc: "Возрастанию",
      Desc: "Убыванию",
    },
  },
  SortField: {
    name: "SortField",
    label: "Параметры сортировки:",
    values: {
      managerINN: "ИНН организатора",
      publicationDate: "Дата публикации",
      acceptingOffersDeadline: "Дата окончания подачи заявки",
      startMaxPrice: "НМЦК",
      eis: "ЕИС (ID)",
    },
  },
  SortFieldTender: {
    name: "SortField",
    label: "Параметры сортировки:",
    values: {
      Id: "ID тендера",
      Eis: "ЕИС (ID)",
      managerINN: "ИНН организатора",
      PublicationDate: "Дата публикации",
      acceptingOffersDeadline: "Дата окончания подачи заявки",
      auctionDate: "Дата аукциона",
      summaryReportDate: "Дата итогового протокола",
      tradingFloorName: "Площадка",
      personInCharge: "Ответственный", //!
      legalBasesId: "Закон закупки",
      hasTradingRobot: "Кто отыгрывает",
      winnerId: "Победитель",
      startMaxPrice: "НМЦК",
      price: "Цена"
    },
  },
  AdditionalInfoFieldTender: {
    name: "AdditionalInfoField",
    label: "Дополнительная информация:",
    values: {
      PublicationDate: "Дата публикации",
      acceptingOffersDeadline: "Дата окончания подачи заявки",
      auctionDate: "Дата аукциона",
      summaryReportDate: "Дата итогового протокола",
      tradingFloorName: "Площадка",
      personInCharge: "Ответственный",
      legalBasesId: "Закон закупки",
      hasTradingRobot: "Кто отыгрывает",
      winnerId: "Победитель",
      startMaxPrice: "НМЦК",
      ourPrice: "Наша цена",
      price: "Цена",
      operationTypeId: "Вид процедуры",
      description: "Комментарий к заявке",
      failureDescription: "Комментарий о причине неподачи",
      contactData: "Контактные данные",
      regions: "Регионы"
    },
  },
  SortFieldProject: {
    name: "SortField",
    label: "Параметры сортировки:",
    values: {
      Id: "ID",
      CustomerINN: "ИНН заказчика",
      ContractNumber: "Номер договора",
      InformationRequestDate: "Дата запроса информации",
      InformationReceiptDate: "Дата получения информации",
      PerformerAppointedDate: "Дата назначения исполнителя",
      AcceptedToWorkDate: "Дата приема в работу",
      SendingForApprovalDate: "Дата отправки на согласование",
      ReportSendingDate: "Дата отправки отчета",
      PlannedExecutionDate: "Плановая дата исполнения",
      TitleSheetSignDate: "Дата подписания титульного листа",
      UnloadingDate: "Дата выгрузки",
      ContractDepartmentNumber: "Идентификационный номер",
      ExecutionProgramId: "Программа исполнения",
      AssessmentTypeId: "Тип оценки",
      WorkspaceCount: "Число РМ",
      HaveWorkbook: "Рабочая тетрадь",
      PersonInChargeLastName: "Ответственный",
    },
  },
  AdditionalInfoFieldProject: {
    name: "AdditionalInfoField",
    label: "Дополнительная информация:",
    values: {
      InformationReceiptDate: "Дата получения информации",
      InformationRequestDate: "Дата запроса информации",
      PerformerAppointedDate: "Дата назначения исполнителя",
      AcceptedToWorkDate: "Дата приема в работу",
      SendingForApprovalDate: "Дата отправки на согласование",
      ReportSendingDate: "Дата отправки отчета",
      PlannedExecutionDate: "Плановая дата исполнения",
      TitleSheetSignDate: "Дата подписания титульного листа",
      UnloadingDate: "Дата выгрузки",
      ContractDepartmentNumber: "Идентификационный номер",
      ExecutionProgramId: "Программа исполнения",
      AssessmentTypeId: "Тип оценки",
      WorkspaceCount: "Число РМ",
      PersonInChargeLastName: "Ответственный",
      ExecutionDescription: "Комментарий к исполнению",
      ContactData: "Контактные данные",
      Region: "Регион"
    },
  },
  SortDeadlines: {
    name: "SortDeadlines",
    label: "Статус проекта:",
    values: {
      "&Expired=null&Warning=null&": "Все проекты",
      "&Expired=true&Warning=null&": "Только просроченные",
      "&Expired=null&Warning=true&": "Только предупреждения",
      "&Expired=true&Warning=true&": "Просроченные и предупреждения",
      "&Expired=false&Warning=null&": "Без просроченных",
      "&Expired=null&Warning=false&": "Без предупреждений",
      "&Expired=false&Warning=false&": "Без просроченных и предупреждений",
    },
  },

  //Common search field
  SearchString: {
    name: "SearchString",
    label: "Поиск по номеру договора и заказчику*",
  },
  OperationTypeId: {
    name: "OperationTypeId",
    label: "Вид процедуры",
    values: {
      Other: "Другое",
      None: "Нет",
      DigitalAuction: "Электронный аукцион",
      RequestForQuotations: "Запрос котировок или предложений",
    },
  },
  ContractSecuringMethodId: {
    name: "ContractSecuringMethodId",
    label: "Обеспечение контракта",
    values: {
      PaymentOrder: "ПП",
      BankGuarantee: "БГ",
      None: "Нет",
    },
  },
  OfferSecuringMethodId: {
    name: "OfferSecuringMethodId",
    label: "Обеспечение заявки",
    values: {
      PaymentOrder: "ПП",
      None: "Нет",
    },
  },
  actHasReached: {
    name: "actHasReached",
    label: "Акт получен?:",
    values: {
      true: "Получен",
      false: "Не получен",
    },
  },
  ContractPaymentsStatuses: {
    name: "ContractPaymentsStatuses",
    label: "Статус оплаты:",
    values: {
      NotPaid: "Не оплачено",
      PartialPaid: "Частично оплачено",
      Paid: "Полностью оплачено",
      OverPaid: "Переплачено",
    },
  },
  SecurityReturned: {
    name: "SecurityReturned",
    label: "Обеспечение вернулось?",
    values: {
      true: "Да",
      false: "Нет",
    },
  },
  MeasurementStatusId: {
    name: "MeasurementStatusId",
    label: "Замеры",
    values: {
      NotAssigned: "Не назначены",
      NotRequired: "Не требуются",
      Assigned: "Назначены",
      Complited: "Проведены",
    },
  },
  SortDeadlinesTender: {
    name: "SortDeadlinesTender",
    label: "Статус тендера:",
    values: {
      "&Expired=null&Warning=null&": "Все",
      "&Expired=true&Warning=null&": "Только просроченные",
      "&Expired=null&Warning=true&": "Только предупреждения",
      "&Expired=true&Warning=true&": "Просроченные и предупреждения",
      "&Expired=false&Warning=null&": "Без просроченных",
      "&Expired=null&Warning=false&": "Без предупреждений",
      "&Expired=false&Warning=false&": "Без просроченных и предупреждений",
    },
  },

  TradingFloorId: {
    name: "TradingFloorId",
    label: "Площадка",
  },
  hasTradingRobot: {
    name: "hasTradingRobot",
    label: "Кто отыгрывает?",
    values: {
      true: "Робот",
      false: "Не робот",
    },
  },
  publicationDate: {
    name: "publicationDate",
    label: "Дата публикации",
  },
  // 'startMaxPrice': {
  //   name: 'startMaxPrice',
  // 	label: 'НМЦК'
  // },
  ourPrice: {
    name: "ourPrice",
    label: "Наша цена",
  },
  workspaceCount: {
    name: "workspaceCount",
    label: "Число рабочих мест",
  },
  winnerPrice: {
    name: "winnerPrice",
    label: "Цена победителя",
  },
  WinnerId: {
    name: "WinnerId",
    label: "ID победителя",
  },
  EIS: {
    name: "EIS",
    label: "ЕИС (ID)*",
  },
  FromStartMaxPrice: {
    name: "FromStartMaxPrice",
  },
  ToStartMaxPrice: {
    name: "ToStartMaxPrice",
  },
  FromPublicationDate: {
    name: "FromPublicationDate",
  },
  ToPublicationDate: {
    name: "ToPublicationDate",
  },
  FromAcceptingOffersDeadline: {
    name: "FromAcceptingOffersDeadline",
  },
  ToAcceptingOffersDeadline: {
    name: "ToAcceptingOffersDeadline",
  },
  FromInformationReceiptDate: {
    name: "FromInformationReceiptDate",
  },
  ToInformationReceiptDate: {
    name: "ToInformationReceiptDate",
  },
  FromPerformerAppointedDate: {
    name: "FromPerformerAppointedDate",
  },
  FromAcceptedToWorkDate: {
    name: "FromAcceptedToWorkDate",
  },
  FromSendingForApprovalDate: {
    name: "FromSendingForApprovalDate",
  },
  FromReportSendingDate: {
    name: "FromReportSendingDate",
  },
  FromTitleSheetSignDate: {
    name: "FromTitleSheetSignDate",
  },
  FromUnloadingDate: {
    name: "FromUnloadingDate",
  },
  FromMeasurementsDate: {
    name: "FromMeasurementsDate",
  },
  ToMeasurementsDate: {
    name: "ToMeasurementsDate",
  },
  ToPerformerAppointedDate: {
    name: "ToPerformerAppointedDate",
  },
  ToAcceptedToWorkDate: {
    name: "ToAcceptedToWorkDate",
  },
  ToSendingForApprovalDate: {
    name: "ToSendingForApprovalDate",
  },
  ToReportSendingDate: {
    name: "ToReportSendingDate",
  },
  ToTitleSheetSignDate: {
    name: "ToTitleSheetSignDate",
  },
  ToUnloadingDate: {
    name: "ToUnloadingDate",
  },
};

export default INPUTS;
