import { useContext, useState } from "react";
import ProjectContext from "../../../../../context/ProjectsContext";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import patchProject from "../../../../../utils/patchProject";
import moveToNextStage from "../../../../../utils/moveToNextStage";
import Asterisk from "../../../../../components/Asterisk";

import DocumentsListHandler from "../../../accountant/common/DocumentsListHandler";
import { TEN_WON_SIGNING_DOCS } from "../../../../../utils/documentType";
import { translateText } from "../../../../../utils/stages";

function WonAndSigningTenderModal({
  setOpenModal,
  inputChangeHandler,
  project,
}) {
  const { setIsProjectMoved } = useContext(ProjectContext);

  const [documentsQty, setDocumentsQty] = useState({
    Contract: 0,
  });

  const projectDataHandler = async (e) => {
    e.preventDefault();

    if (
      project.contractSecuringMethodId === "PaymentOrder" &&
      project.securityAmount > 0
    ) {
      project.securityReturned = false;
    } else {
      project.securityReturned = null;
    }

    await patchProject(e, project, project.id);

    const res = await moveToNextStage(project.id);
    if (res.status === "200" || res.ok) {
      setIsProjectMoved((prevState) => !prevState);
      setOpenModal(false);
    } else {
      alert("Error! Проект не был переведён на следующий этап");
      setOpenModal(false);
    }
  };

  const documentsQtyHandler = (updatedQty) =>
    Object.keys(updatedQty).length === 0 && updatedQty.constructor === Object
      ? setDocumentsQty(documentsQty)
      : setDocumentsQty({ ...documentsQty, ...updatedQty });

  return (
    <ModalWrapper>
      <button
        className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}
      >
        &#10005;
      </button>
      <form>
        <DocumentsListHandler
          projectId={project.id}
          docTypesList={TEN_WON_SIGNING_DOCS}
          documentsQtyHandler={documentsQtyHandler}
        />

        <label className="modal__input-title">
          Сумма обеспечения контракта:
        </label>
        <input
          className="modal__input input"
          type="text"
          style={{ cursor: "default" }}
          disabled
          value={project.securityAmount}
        />

        <label className="modal__input-title">Тип обеспечения:</label>
        <input
          className="modal__input input"
          type="text"
          style={{ cursor: "default" }}
          disabled
          value={translateText(project.contractSecuringMethodId)}
        />

        <label className="modal__input-title">
          Номер контракта:
          <Asterisk />
        </label>
        <input
          className="modal__input input"
          type="text"
          name="contractNumber"
          value={project.contractNumber}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">
          Дата заключения договора:
          <Asterisk />
        </label>
        <input
          className="modal__input input"
          type="date"
          name="conclusionContractDate"
          value={project.conclusionContractDate}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Плановая дата исполнения:</label>
        <input
          className="modal__input input"
          type="date"
          name="plannedExecutionDate"
          value={project.plannedExecutionDate}
          onChange={(e) => inputChangeHandler(e)}
        />

        <label className="modal__input-title">Число подразделений:</label>
        <input
          className="modal__input input"
          type="number"
          name="unitCount"
          // disabled={
          // 	values.assesmentTypeId === "ProductionControl" ||
          // 	(isOnStageOrPassed(5) && !isAdmin())
          // }
          value={project.unitCount}
          onChange={(e) => inputChangeHandler(e)}
          onWheel={(e) => e.target.blur()}
        />

        <h3 className="modal__description">
          Перевести проект
          <b>&nbsp;{project.customerName}&nbsp;</b>
          на следующий этап?
        </h3>
        <div className="modal__buttons-wrapper">
          <button
            className="button button--red"
            disabled={
              project.contractNumber === "" ||
              project.conclusionContractDate === "2010-01-01" ||
              project.conclusionContractDate === "0001-01-01" ||
              project.plannedExecutionDate === "0001-01-01" ||
              project.plannedExecutionDate === undefined ||
              project.plannedExecutionDate === null ||
              documentsQty.Contract <= 0
            }
            onClick={(e) => projectDataHandler(e)}
          >
            Да
          </button>
          <button
            className="button close-button"
            onClick={() => setOpenModal(false)}
          >
            Нет
          </button>
        </div>
      </form>
    </ModalWrapper>
  );
}
export default WonAndSigningTenderModal;
