import { useContext } from 'react';
import { Link } from 'react-router-dom';
import ProjectContext from '../../../context/ProjectsContext';
import Sidebar from '../../../components/Sidebar';
import ActionProjectButtons from '../../../components/ActionProjectButtons';
import SearchSortTabs from '../../../components/SearchSortTabs';

const ListOfProjects = () => {
  const { projects, isLoading } = useContext(ProjectContext);

  return (
    <main className="workspace">
      <Sidebar />
      <section className="workspace__wrapper"> 
        <SearchSortTabs />
        {
          (isLoading)
            ? <p>Загрузка....</p>
            : (!projects || projects.length === 0)
              ? <p>Нет проектов</p>
              : (<section className="workspace__table-wrapper">
                <div className="table-wrapper">
                  <table className="table workspace__table">
                    <thead>
                      <tr>
                        <td>ID</td>
                        <td>ID Архива</td >
                        <td>Заказчик</td>
                        <td>ИНН</td>
                        <td>Номер договора</td>
                        <td>Дата подписания</td>
                        <td>Цена</td>
                        <td>Действие</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        projects && projects.map(project =>
                          <tr key={project.id}>
                            <td>{project.id}</td><td>{project.archiveId}</td>
                            <td>{project.contract.customer.name}</td>
                            <td>{project.contract.customer.inn}</td>
                            <td>{project.contract.number}</td>
                            <td>{project.contract.conclusionContractDate}</td>
                            <td>{project.contract.price}</td>
                            <td className="table__stage-buttons-wrapper"><ActionProjectButtons state={project} /></td>
                          </tr>)
                      }
                    </tbody>
                  </table>
                </div>
              </section>
              )
        }
      </section>
    </main>
  )
};
export default ListOfProjects;
