import { api } from 'settings/Api';
const getContract = async (id) => {
    const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;
    let response = await api(`/api/sout/admin/contracts/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        // 'Authorization':  'String(authTokens.access) ,
      },
    })
    let data = await response.json();
    
    return data
  }
  
  export default getContract;