import ModalWrapper from "components/modals/ModalWrapper";
import ContractDepartmentFileInput from "pages/sout/accountant/common/ContractDepartmentFileInput";
import DocumentsListHandler from "pages/sout/accountant/common/DocumentsListHandler";
import { useState } from "react";

const DocumentsModal = ({
  setOpenModal,
  modelId,
  entity,
  documents,
  secondModelId,
  secondEntity,
}) => {
  return (
    <ModalWrapper>
      <button
        onClick={() => setOpenModal(false)}
        className="modal__close-button close-button"
      >
        &#10005;
      </button>
      <h2 className="modal__title">Документы</h2>
      {entity.link === "separated-projects" && (
        <DocumentsListHandler
          modelId={secondModelId}
          entity={secondEntity}
          readOnly={true}
        />
      )}

      {/* {
          type: 'Id',
          description: 'Файл с идентификационным номером',
          typeId: 2
      }, */}
      <div style={{ display: "flex" }}>
        <DocumentsListHandler
          modelId={modelId}
          entity={entity}
          documents={documents}
        />
        {entity.link === "contracts" && (
          <div style={{ width: 400 }}>
            <ContractDepartmentFileInput
              entity={"contracts"}
              link={"Id"}
              title={"Файл с идентификационным номером"}
              contractId={modelId}
            />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
export default DocumentsModal;
