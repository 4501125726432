import Sidebar from "components/Sidebar";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { manualData } from "settings/ManualData";

const ManualInfoPage = () => {
  const { id } = useParams();

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section
          className="workspace__wrapper"
          style={{ position: "relative" }}
        >
          <h3
            className="workspace__title workspace__page-title"
            style={{ textAlign: "center", fontSize: 24 }}
          >
            {manualData[id]?.title}
          </h3>
          <div className="manualText">{manualData[id]?.text}</div>
          <Link className="manLink" to={"/Manual"}>
            <button className={"manualBtn"}>Назад</button>
          </Link>
        </section>
      </div>
    </main>
  );
};

export default ManualInfoPage;
