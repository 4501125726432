import { addOrEditLocation } from "entities/locations/service/createLocation";
import UnassignedStage from "./stage/UnassignedStage";
import { useState, useEffect } from "react";
import PlannedStage from "./stage/PlannedStage";
import { dateToString } from "shared/formatDate";

export const ConfirmMesBlockActionModal = ({
  title,
  onYes,
  onNo,
  locType,
  colorThemeClass,
  setOpenModal,
  location,
  moveType,
  stage,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [worker, setWorker] = useState(
    locType === "Potential" ? null : location?.worker
  );
  const [date, setDate] = useState(location?.measurementsDate || null);

  const convertDate = (date) => {
    return date ? dateToString(date) : null;
  };

  const moveBtnHandler = () => {
    let type = !location?.contractId ? "Potential" : "Contract";

    addOrEditLocation(
      {
        ...location,
        measurementsDate: convertDate(date),
        worker: worker,
      },
      onYes,
      "edit",
      type
    );
  };

  useEffect(() => {
    if (moveType !== "moveNext") setIsDisabled(false);
    if (stage === "Finish" || stage === "Planned") setIsDisabled(false);
  });

  const modalContent = (stage) => {
    switch (stage) {
      case "Unassigned":
        return (
          <UnassignedStage
            worker={worker}
            setWorker={setWorker}
            setIsDisabled={setIsDisabled}
          />
        );
      case "InLeaving":
        return (
          <PlannedStage
            date={date}
            setDate={setDate}
            setIsDisabled={setIsDisabled}
          />
        );
      default:
        break;
    }
  };

  const writeMessage = () => {
    switch (moveType) {
      case "moveNext":
        return (
          <p>
            Перевести замерный блок <b>{location?.additionalName}</b>{" "}
            {stage == "Finish" ? "в архив" : "на следующий этап?"}{" "}
          </p>
        );
      case "movePrev":
        return (
          <p>
            Перевести замерный блок <b>{location?.additionalName}</b> на
            предыдущий этап?
          </p>
        );
      case "reject":
        return (
          <p>
            Вы уверены, что хотите удалить замерный блок{" "}
            <b>{location?.additionalName}</b>?
          </p>
        );
      default:
        break;
    }
  };

  return (
    <>
      <button
        className="modal__close-button close-button"
        onClick={() => setOpenModal(false)}
      >
        &#10005;
      </button>
      {moveType === "moveNext" && modalContent(stage)}
      <h3 className="modal__description">{writeMessage()}</h3>
      <div
        className="buttons"
        style={{
          width: "60%",
          display: "flex",
          justifyContent: "space-between",
          margin: "auto",
        }}
      >
        <button
          disabled={isDisabled}
          className={`button ${colorThemeClass}`}
          onClick={moveType === "moveNext" ? moveBtnHandler : onYes}
        >
          Да
        </button>
        <button className={`button ${colorThemeClass}`} onClick={onNo}>
          Нет
        </button>
      </div>
    </>
  );
};
