import fetchBranchOffices from "./fetchBranchOffices";

export const getBranchOffices = async (id, setBranchOffices) => {
  try {
    const branchOffices = await fetchBranchOffices(id);
    setBranchOffices(branchOffices);
  } catch (error) {
    console.error(error);
    return null;
  }
};
