import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { api } from "settings/Api";
import Spinner from "components/Spinner";
import IconSelect from "components/IconSelect";
import { getPictures } from "utils/getPictures";

const EditAgent = () => {
  const location = useLocation();

  const [agents, setAgents] = useState(null);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [icons, setIcons] = useState([]);

  const [activeIcon, setActiveIcon] = useState(null);
  const [name, setName] = useState("");
  const [inn, setInn] = useState(null);
  const [regionId, setRegionId] = useState("");
  const [listOfDep, setListOfDep] = useState([]);
  const [depName, setDepName] = useState("");
  const [regions, setRegions] = useState(null);
  const [regionName, setRegionName] = useState("");
  const [showDropDown, setShowDropdown] = useState(true);

  const handleCurrentAgent = (e) => {
    setCurrentAgent({
      ...currentAgent,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const currentName = location?.state?.user?.state?.name;
    setName(currentName);
  }, [location && location.state.user.state.name]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    const fetchEmployees = async () => {
      api("/api/sout/admin/organizations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // 'Authorization':  'String(authTokens.access) ,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const data = res;

          // setIsLoading(false)
          setAgents(data.data);
          setListOfDep(data.data?.branchOffices || []);
        });
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    if (name && agents) {
      const agent = agents.find((agent) => agent.name === name);
      setCurrentAgent(agent);
      setActiveIcon(agent?.picture?.name);
      setListOfDep(agent?.branchOffices);
    }
  }, [name && agents]);

  useEffect(() => {
    fetchRegions();

    getPictures().then((data) => {
      setIcons(data?.data);
    });
  }, []);

  console.log(icons);

  useEffect(() => {
    if (regions && location.state.user.state.regionId) {
      const currentRegion = regions.filter(
        (region) => region.id === location.state.user.state.regionId
      );
      setRegionName(currentRegion[0].name);
      setRegionId(currentRegion[0].id);
      setShowDropdown(false);
    }
  }, [regions && location.state.user.state.regionId]);
  console.log(currentAgent);
  const fetchRegions = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api("/api/sout/helpers/handbooks/regions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();
    setRegions(data.data);
  };
  console.log(listOfDep);
  const postData = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;

    delete currentAgent.regionName;

    currentAgent.regionId = parseInt(regionId);

    const updatedAgent = {
      ...currentAgent,
      isBlocked: false,
      branchOffices: listOfDep,
      isDeleted: false,
      id: currentAgent.id,
    };

    console.log(icons?.find((i) => i.id == activeIcon)?.name);

    api("/api/sout/admin/organizations/edit", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...updatedAgent,
        picture: {
          id: icons?.find((i) => i.name == activeIcon)?.id,
        },
      }),
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Данные успешно обновлены!");
        navigate("/ListOfAgents");
      } else if (res.status === 500 || !res.ok) {
        alert("Ошибка. Данные не обновлены!");
      }
    });
  };

  const handleRegionName = (e) => {
    setShowDropdown(true);
    setRegionName(e.target.value);
  };

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3 className="workspace__title workspace__page-title">
            Редактировать агента
          </h3>
          {currentAgent?.name ? (
            <form style={{ width: "100%" }}>
              <p className="workspace__input-title">
                {currentAgent?.organizationType === "Organization"
                  ? "Наименование"
                  : "ФИО"}
              </p>
              <input
                className="input"
                type="text"
                name="name"
                style={{ width: 400 }}
                value={currentAgent.name}
                onChange={(e) => handleCurrentAgent(e)}
              />
              {currentAgent?.organizationType === "Organization" && (
                <>
                  <p className="workspace__input-title">ИНН:</p>
                  <input
                    className="input"
                    type="text"
                    name="inn"
                    style={{ width: 400 }}
                    value={currentAgent.inn}
                    onChange={(e) => handleCurrentAgent(e)}
                  />

                  <p className="workspace__input-title">Регион</p>

                  <input
                    className="input"
                    type="text"
                    name="regionId"
                    style={{ width: 400 }}
                    value={regionName}
                    onChange={(e) => handleRegionName(e)}
                  />
                  <ul className="regions__list" style={{ width: 400 }}>
                    {regions &&
                      regionName.length >= 2 &&
                      regions
                        .filter((region) => {
                          return region.name
                            .toLowerCase()
                            .includes(regionName.trim().toLowerCase());
                        })
                        .map(
                          (region) =>
                            showDropDown && (
                              <li
                                className="regions__item"
                                onClick={() => {
                                  setRegionId(region.id);
                                  setRegionName(region.name);
                                  setShowDropdown(false);
                                }}
                                key={region.id}
                              >
                                {region.name}
                              </li>
                            )
                        )}
                  </ul>
                </>
              )}

              <div
                style={{
                  width: 400,
                  display: "flex",
                  marginTop: 10,
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: 100 }}>
                    <p className="workspace__input-title">Буквы агента:</p>
                    <input
                      className="input"
                      type="text"
                      maxLength="3"
                      name="code"
                      value={currentAgent?.code}
                      onChange={(e) => handleCurrentAgent(e)}
                      required={true}
                    />
                  </div>
                </div>
                <div>
                  {currentAgent?.withLaboratory && (
                    <IconSelect
                      icons={icons}
                      setActiveIcon={setActiveIcon}
                      activeIcon={activeIcon}
                      isDisabled={false}
                    />
                  )}
                </div>
              </div>

              <hr style={{ margin: "15px 0" }} />
              <h3 className="workspace__title">Добавление филиалов</h3>
              <p className="workspace__input-title">Наименование филиала</p>
              <div style={{ display: "flex" }}>
                <input
                  className="input"
                  type="text"
                  name="regionId"
                  style={{ width: 500 }}
                  value={depName}
                  onChange={(e) => setDepName(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => {
                    setListOfDep([...listOfDep, { name: depName }]);
                    setDepName("");
                  }}
                  disabled={depName.length < 1}
                  className="button"
                  style={{ padding: "4px 8px", marginLeft: 30, width: 220 }}
                >
                  Добавить филиал
                </button>
              </div>
              <div className="">
                {listOfDep?.map((item) => {
                  return (
                    <div className="branchItem">
                      <div>{item.name}</div>
                      <div
                        className="deleteBtn"
                        onClick={() =>
                          item.hasUsers
                            ? alert(
                                "В данном филиале числятся сотрудники. Невозможно удалить"
                              )
                            : setListOfDep([
                                ...listOfDep.filter(
                                  (i) => i.name !== item.name
                                ),
                              ])
                        }
                      >
                        x
                      </div>
                    </div>
                  );
                })}
              </div>
              <div style={{ display: "flex" }}>
                <input
                  type="button"
                  // disabled={name <= 0}
                  onClick={postData}
                  className="button staff__create-staff-button"
                  value="Обновить информацию"
                />
                <button
                  style={{ marginLeft: 20 }}
                  type="button"
                  onClick={() => navigate("/ListOfAgents")}
                  className="button staff__create-staff-button"
                >
                  Отмена
                </button>
              </div>
            </form>
          ) : (
            <Spinner />
          )}
        </section>
      </div>
    </main>
  );
};
export default EditAgent;
