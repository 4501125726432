import { useContext } from "react";
import ProjectContext from "../../context/ProjectsContext";
import { translateText } from "../../settings/stages";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

function ProjectTableHeaders({ departmentId }) {
  const { sortParam, additionalInfoValue, additionalInfoValue2 } =
    useContext(ProjectContext);

  const showedTenderTitles = ["ManagerINN", "Eis", "Id"];
  const showedProjectTitles = ["CustomerINN", "Id"];

  const returnTdTitle = (sortParam) => {
    return {
      ManagerINN: <td className="table__sort-column">ИНН</td>,
      Eis: <td className="table__sort-column">ЕИС ID</td>,
      CustomerINN: <td className="table__sort-column">ИНН</td>,
    }[sortParam];
  };

  const filterTitles = () => {
    if (sortParam === null) return;
    return sortParam === "Null" ? " " : sortParam;
  };

  return (
    <>
      <thead>
        <tr>
          <td></td>
          <td>#</td>
          <td className={sortParam === "Id" ? "table__sort-column" : null}>
            ID
          </td>
          {showedTenderTitles?.includes(sortParam) ? (
            returnTdTitle(sortParam)
          ) : translateText(filterTitles(sortParam))?.length > 1 ? (
            <td className={sortParam ? "table__sort-column" : null}>
              {translateText(filterTitles(sortParam))}
            </td>
          ) : null}

          {additionalInfoValue && additionalInfoValue !== "null" && (
            <td className="table__additional-column">
              {translateText(capitalizeFirstLetter(additionalInfoValue))}
            </td>
          )}
          {additionalInfoValue2 && additionalInfoValue2 !== "null" && (
            <td className="table__additional-column2">
              {translateText(capitalizeFirstLetter(additionalInfoValue2))}
            </td>
          )}
          {departmentId === "tender" && (
            <>
              <td>Заказчик</td>
              {showedTenderTitles.map((title) => {
                if (sortParam !== title) {
                  return (
                    <td>
                      {
                        {
                          ManagerINN: "ИНН",
                          Eis: "ЕИС ID",
                          OurPrice: "Наша цена",
                        }[title]
                      }
                    </td>
                  );
                }
                return null;
              })}
            </>
          )}
          {departmentId === "projects" && (
            <>
              <td>Заказчик</td>
              <td>Номер договора</td>
              {showedProjectTitles.map((title) => {
                if (sortParam !== title) {
                  return (
                    <td>
                      {
                        {
                          CustomerINN: "ИНН",
                        }[title]
                      }
                    </td>
                  );
                }
                return null;
              })}
            </>
          )}
          <td className="table__action-buttons-title">Действия</td>
        </tr>
      </thead>
    </>
  );
}

export default ProjectTableHeaders;
