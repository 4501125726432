import { generateToken } from 'shared/generateToken';
import { api } from 'settings/Api';

export const moveStage = async (entity, action, ID) => {
	const token = generateToken();
  try {
    let res = await api(`/api/sout/workflow/${entity}/${action}/${ID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await res.json();
    if (data.isFailure) throw new Error(res.status);
    return data;
  } catch(e){
    alert(`Ошибка: ${e.message}`);
  }

};