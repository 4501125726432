import React, { memo, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import StatisticTable from "../components/common/StatisticTable";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import {
  isAccountantUser,
  isLabEmployee,
  isLabHead,
  isLabUser,
  isMedUser,
  isTenderUser,
  isСhieftain,
} from "../utils/defineUser";
import { useContext } from "react";
import ProjectContext from "../context/ProjectsContext";
import { api } from "settings/Api";
import { getContractStatistic } from "utils/getContractStatistic";
import { useParams } from "react-router-dom";
import { Select } from "components/Select/Select";

const Statistics = memo(() => {
  const defaultValues = {
    from: `${new Date().getFullYear()}-01`,
    to: `${new Date().getFullYear()}-12`,
    on: null,
  };

  const STAT_TITLE = {
    conclusionDate: "Договоры, у которых даты заключения договора с",
    plannedDate: "Договоры, у которых плановая дата исполнения договора с",
  };

  const [typeStat, setTypeStat] = useState("conclusionDate");
  const [tmpStat, setTmpStat] = useState("conclusionDate");
  const [values, setValues] = useState(defaultValues);
  const [income, setIncome] = useState([]);
  const [returns, setReturns] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [incomeByPaid, setIncomeByPaid] = useState([]);

  const [activeFunds, setActiveFunds] = useState("");
  const [fundsAvailable, setFundsAvailable] = useState("");

  const { ecoStarDebt } = useContext(ProjectContext);

  const tableDataHandler = (tableType, data) => {
    switch (tableType) {
      case "income":
        setIncome(data);
        break;
      case "returns":
        setReturns(data);
        break;
      case "expenses":
        setExpenses(data);
        break;
      case "total_returns":
        setActiveFunds(data.activeFunds);
        setFundsAvailable(data.fundsAvailable);
        break;
      case "income_by_paid":
        setIncomeByPaid(data);
        break;
      default:
        alert("Данные для таблиц не получены");
        break;
    }
  };

  const [incomeTab, setIncomeTab] = useState("common");

  const incomeTabsHandler = (e) => {
    const currentTab = e.target.getAttribute("data-tab");

    setIncomeTab(currentTab);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const getQueryString = () => {
    let url = "";
    for (var key in values) {
      url += key;
      url += "=";
      url += values[key];
      url += "&";
    }
    return url;
  };

  const getProjectType = () => {
    if (localStorage.getItem("__user-area") === "med") return "Med";
    if (localStorage.getItem("__user-area") === "sout") return "Sout";
  };

  const fetchStatistics = async (tableType, dataType) => {
    const url = `/api/stats/${tableType}?${getQueryString()}projectType=${getProjectType()}`;
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    let response = await api(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();

    if (dataType === "total_returns") {
      tableDataHandler(dataType, data);
    } else {
      tableDataHandler(tableType, data.stats);
    }

    if (tableType === "income_by_paid") {
      setIncomeByPaid(data.stats);
    }
  };

  const fetchDataHandler = async (e) => {
    e.preventDefault();
    setTypeStat(tmpStat);
    getContractStatistic(tmpStat, values?.from, values?.to).then((res) => {
      setIncome(
        res?.stats
      );
      console.log(res?.stats);
    });
    // await fetchStatistics("income");
    // await fetchStatistics("returns");
    // await fetchStatistics("expenses");
    // await fetchStatistics("returns", "total_returns");
    // await fetchStatistics("income_by_paid");
  };

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3 className="workspace__title workspace__page-title">
            Общая статистика
          </h3>
          <div style={{ width: 300 }}>
            <Select
              dataSrc="local"
              alignLabel={"left"}
              value={tmpStat}
              onChange={(e) => setTmpStat(e.target.value)}
              data={{
                name: "typeOfStat",
                label: "Статистика по:",
                values: {
                  conclusionDate: "Дате заключения договора",
                  plannedDate: "Плановой дате исполнения",
                },
              }}
            />
          </div>
          <form onSubmit={fetchDataHandler}>
            <p style={{ marginBottom: "30px" }}>
              {STAT_TITLE[typeStat]}
              <input
                onChange={onChange}
                className="input"
                name="from"
                type="month"
                value={values.from}
                style={{
                  width: "140px",
                  minWidth: "0",
                  display: "inline",
                  padding: "5px",
                  margin: "0 10px",
                }}
              />
              по
              <input
                onChange={onChange}
                className="input"
                name="to"
                type="month"
                value={values.to}
                style={{
                  width: "140px",
                  minWidth: "0",
                  display: "inline",
                  padding: "5px",
                  margin: "0 10px",
                }}
              />
              <button
                className="button"
                type="submit"
                style={{
                  padding: "6px",
                }}
              >
                <FaSearch />
              </button>
            </p>
          </form>
          {isСhieftain() ? (
            <>
              <h4 className="workspace__title" onClick={incomeTabsHandler}>
                Таблица доходов [
                <span
                  data-tab="common"
                  className={`title__subcategory 
                  ${incomeTab === "common" && "subcategory--active"}`}
                >
                  Общие
                </span>
                &#183;
                <span
                  data-tab="commercial"
                  className={`title__subcategory 
                  ${incomeTab === "commercial" && "subcategory--active"}`}
                >
                  Коммерческие
                </span>
                &#183;
                <span
                  data-tab="tender"
                  className={`title__subcategory 
                  ${incomeTab === "tender" && "subcategory--active"}`}
                >
                  Тендерные
                </span>
                ]
              </h4>
              <StatisticTable
                tableType="income"
                projects={income}
                incomeTab={incomeTab}
              />
            </>
          ) : (
            <>
              <StatisticTable
                tableType="income"
                projects={income}
                type={typeStat}
              />
            </>
          )}
          {(isTenderUser() || isMedUser() || isAccountantUser()) && (
            <>
              <h4 className="workspace__title">
                Возвратные средства: [<span>{activeFunds || "0"}</span>&nbsp;
                &#8381;] &nbsp; - &nbsp; Свободные средства: [
                <span>{fundsAvailable || "0"}</span>&nbsp; &#8381;]
              </h4>
              <StatisticTable tableType="returns" projects={returns} />

              <h4 className="workspace__title">Расходы</h4>
              <StatisticTable tableType="total_expenses" projects={expenses} />
            </>
          )}
          {isLabUser() && (
            <>
              <h4 className="workspace__title">Расходы</h4>
              <StatisticTable tableType="total_expenses" projects={expenses} />
            </>
          )}
        </section>
      </div>
    </main>
  );
});
export default Statistics;
