import {fetchAgents} from "./fetchAgents";
import {fetchAccount} from "./fetchAccount";

export const userIsAgent = async () => {
    const account = await fetchAccount();
    // console.log(account)
    // if (account.organization.success) {
    //     return false;
    // }
    const organizationId = account.organizationId;
    const agents = await fetchAgents();
    let isAgent = false;
    agents.forEach((agent) => {
        if (agent.id === organizationId) {
            isAgent = true;
        }
    });
    return isAgent;
}