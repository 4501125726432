import Sidebar from "components/Sidebar";
import React from "react";
import { Link } from "react-router-dom";
import { manualList } from "settings/ManualData";

const Manual = () => {
  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3
            className="workspace__title workspace__page-title"
            style={{ textAlign: "center", fontSize: 24 }}
          >
            Руководство пользователя
          </h3>
          <div className="manualInfo">
            <div className="manualInfoBlock">
              <div className="manualInfoBlockTitle">Сущности</div>
              <div className="manualList">
                {manualList.Entities.map((item) => (
                  <Link to={item?.link} className="manualInfoBlockItem">
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
            <div className="manualInfoBlock">
              <div className="manualInfoBlockTitle">Пользователи</div>
              <div className="manualList">
                {manualList.Users.map((item) => (
                  <Link to={item?.link} className="manualInfoBlockItem">
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Manual;
