import { api } from 'settings/Api';
const moveToPrevStage = async (projectId) => {
	const token = JSON.parse(localStorage.getItem("authTokens")).data.accessToken;
	let res = await api(`/api/sout/workflow/return/${projectId}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
			// 'Authorization':  'String(authTokens.access) ,
		},
	});
	let data = await res.json();

	//
	return res;
};
export default moveToPrevStage;
