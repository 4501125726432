import { NavLink } from 'react-router-dom';

const SidebarLine = ({ type }) => {
  switch (type) {
    case 'h':
      return (
        <svg width="21" height="2" viewBox="0 0 21 2" fill="none" xmlns="http://www.w3.org/2000/svg"
          style={
            {
              marginLeft: '30px'
            }
          }
        >
          <line x1="0.962036" y1="0.786743" x2="20.962" y2="0.786743" stroke="white" />
        </svg>
      )
    case 'v':
      return (
        <svg width="1" height="19" viewBox="0 0 1 19" fill="none" xmlns="http://www.w3.org/2000/svg"
          style={
            {
              marginRight: '30px'
            }
          }
        >
          <line x1="0.5" x2="0.5" y2="19" stroke="white" />
        </svg>
      )
    default:
      return null;
  }
}

const SidebarListItem = ({ link, children, type, color }) => {
  switch (type) {
    case 'archive':
      return (
        <li className="sidebar__item sidebar__item--archive">
          <NavLink
            to={link}
            className={
              (navData) => (navData.isActive ? `link--${color}--active sidebar__link sidebar__link--${color} sidebar__link--archive` : `sidebar__link sidebar__link--${color} sidebar__link--archive`)
            }
            style={{position: 'relative',borderLeft: '1px solid white'}}
          >
            Архив
          </NavLink>
        </li>
      );
    case 'no-result':
      return (
        <li className="sidebar__item">
          <NavLink
            to={link}
            className={
              (navData) => (navData.isActive ? `link--${color}--active sidebar__link sidebar__link--${color} sidebar__no-result` : `sidebar__link sidebar__link--${color} sidebar__no-result`)
            }
          >
            {children}
          </NavLink>
        </li>
      )
      case 'sub-no-result':
      return (
        <li className="sidebar__item">
          <NavLink
            to={link}
            className={
              (navData) => (navData.isActive ? `link--${color}--active sidebar__link sidebar__link--${color} sidebar__no-result` : `sidebar__link sidebar__link--${color} sidebar__no-result`)
            }
            style={{marginLeft:5}}
          >
            -{children}
          </NavLink>
        </li>
      )
      case 'no-result-space':
      return (
        <li className="sidebar__item">
          <NavLink
            to={link}
            style={{marginTop: 15}}
            className={
              (navData) => (navData.isActive ? `link--${color}--active sidebar__link sidebar__link--${color} sidebar__no-result` : `sidebar__link sidebar__link--${color} sidebar__no-result`)
            }
          >
            {children}
          </NavLink>
        </li>
      )
      case 'space':
        return (
          <li className="sidebar__item" style={{height: 20}}>
              
          </li>
        )
    case 'line':
      return (
        <li className="sidebar__item"
          style={
            {
              padding: '0'
            }
          }
        >
          <NavLink to='#' className={'sidebar__link'}
            style={
              {
                padding: '0'
              }
            }
          >
            <SidebarLine type='h' />
          </NavLink>
        </li>
      )
    default:
      return (
        <li className="sidebar__item">
          <NavLink
            to={link}
            className={
              (navData) => (navData.isActive ? `link--active link--${color}--active sidebar__link sidebar__link--${color}` : `sidebar__link sidebar__link--${color}`)
            }
            style={{position: 'relative',borderLeft: '1px solid white'}}
          >
            {children}
          </NavLink>
        </li>
      );
  }
}

const classNames = (classNames) => {
  return classNames.join(' ')
}

export const SidebarList = ({ title, titleIsOpen, toggleSidebar, items, color }) => {
  return (
    <div className=''>
      <h4 className={
        classNames(
          [
            'sidebar__title',
            `sidebar__title--${color}`,
            'sidebar__arrow-icon',
            titleIsOpen ? `sidebar__title--active sidebar__title--${color}--active` : ''
          ])
      }
        style={{fontSize: 18}}
        onClick={toggleSidebar}>
        {title}
      </h4 >
      <ul className={`sidebar__list ${!titleIsOpen && 'sidebar__list--close'}`}>
        {
          items.map(item => (
            <SidebarListItem link={item.link} type={item.type} color={color}>
              {item.content}
            </SidebarListItem>
          ))
        }
      </ul>
    </div>
  );
}