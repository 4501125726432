import AuthContext, { AuthProvider } from "./context/AuthContext";
import { ProjectProvider } from "./context/ProjectsContext";
import Header from "./components/header/Header";
import AppRoutes from "./Routes";
import "./styles/scss/index.scss";
import { ContractProvider } from "context/ContractContext";
import { LocationProvider } from "./context/LocationContext";
import { DocumentProvider } from "context/DocumentsContext";
import {AgentProvider} from "context/ParticipantsOfProcessContext";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <AgentProvider>
        <DocumentProvider>
          <LocationProvider>
            <ContractProvider>
              <ProjectProvider>
                <Header />
                <AppRoutes />
              </ProjectProvider>
            </ContractProvider>
          </LocationProvider>
        </DocumentProvider>
        </AgentProvider>
      </AuthProvider>
    </div>
  );
}
export default App;
