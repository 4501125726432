function ActionButton({ onAction, modalType, iconType, title, isDisabled = false, styles, className }) {
  return <button disabled={isDisabled}
    onClick={onAction ? () => onAction(modalType) : () => {}}
    className={`button button--square action-button ${className}`}
    title={title}
    style={styles}
  >
    {iconType()}
  </button>
}
export default ActionButton