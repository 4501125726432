import ChangeContracts from 'entities/contracts/pages/ChangeContracts/ChangeContracts';
import ChangeLocations from 'entities/locations/pages/ChangeLocations/ChangeLocations';
import { useParams } from 'react-router-dom';


const ChangePage = () => {
  const { directionId, departmentId, type, entityID } = useParams();
  switch(departmentId) {
    case "contracts":
      return <ChangeContracts type={type} contractID={entityID}/>
    case 'locations':
      return <ChangeLocations type={type} locationID={entityID}/>
    default:
      return null;
  }
}

export default ChangePage;