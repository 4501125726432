import { useContext } from "react";
import ProjectContext from '../../../context/ProjectsContext';
import ModalWrapper from '../ModalWrapper';
import moveToNextStage from '../../../utils/moveToNextStage';
import patchProject from '../../../utils/patchProject';
import { removeDayFromDateHandler } from '../../../utils/addFirstDayToDate';

function ReportModal({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const projectDataHandler = async (e) => {
		e.preventDefault();
		await patchProject(e, project, project.id);
		const res = await moveToNextStage(project.id);
		if (res.status === "200" || res.ok) {
			setIsProjectMoved((prevState) => !prevState);
			setOpenModal(false);
		} else {
			alert("Error! Проект не был переведён на следующий этап");
			setOpenModal(false);
		}
	};

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<label className="modal__input-title">Дата отчета агента:</label>
				<input
					className="input modal__input"
					type="month"
					name='monthlyReportDate'
					value={removeDayFromDateHandler(project.monthlyReportDate)}
					onChange={(e) => inputChangeHandler(e)}
				/>
				<h3 className="modal__description">
					Перевести проект
					<b>&nbsp;{project.customerName}&nbsp;</b>
					на следующий этап?
				</h3>
				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={
							project?.paymentsSum < project?.actAmount ||
							project?.actHasReached === "false" ||
							project?.paymentsSum < 1
						}
						onClick={(e) => projectDataHandler(e)}
					>
						Да
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default ReportModal;
