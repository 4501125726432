import React, { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';

const Login = () => {
	let { loginUser, loading } = useContext(AuthContext);

	return (
		<>
			<main className="main-page">
				<div className="container">
					<Link to="/Auth" className='button' style={{ display: 'block', marginTop: '15vh' }}>Авторизоваться в ERP</Link>
				</div>
			</main>
			{
				loading
					? <div className="loading-wrapper">
						<Spinner className={'spinner loading-wrapper__spinner'} />
					</div>
					: null
			}
		</>
	)
};

export default Login; 
