import {
  isAccountantEmployee,
  isAccountantUser,
  isAdmin,
  isCommercialUser,
  isLabEmployee,
  isLabHead,
  isLabUser,
  isMedUser,
  isMedUserArea,
  isSoutUserArea,
  isTenderHead,
  isTenderUser,
} from "../../utils/defineUser";
import { translateText } from "../../utils/stages";
import { HiOutlineExternalLink } from "react-icons/hi";
import { toDateWithoutTime, toDateWithTime } from "../../utils/formatDate";
import { dateToTextDashed } from "../../utils/stages";

const TableViewModal = ({ setOpenModal, project }) => {
  const getUserInfo = (department) => {
    if (project?.users) {
      const user = project.users.filter(
        (user) => user.departmentId === department
      );
      if (user.length >= 1) {
        return `${user[0].name} ${user[0].lastName}`;
      } else if (user.length <= 0) {
        return ` - `;
      }
    }
  };

  const isTenderProject = () => project?.workFlow?.typeId === "TenderWorkFlow";

  const isCommercialProject = () =>
    project?.workFlow?.typeId === "CommercialWorkFlow";

  const isAssesmentSOUT = () =>
    project?.assesmentTypeId === "WorkingConditionsSpecialAssesment";

  const isAssesmentPC = () => project?.assesmentTypeId === "ProductionControl";

  const isNoAssesment = () => project?.assesmentTypeId === null;

  return (
    <section className="modal">
      <div className="modal__content modal__table-content">
        <button
          onClick={() => setOpenModal(false)}
          className="modal__close-button close-button"
        >
          &#10005;
        </button>
        <h2 className="modal__title">Информация о проекте</h2>
        {!isAdmin() && (
          <div className="modal__tables-grid">
            <div>
              <h3 className="table__title">Общие сведения</h3>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td className="table__id">{project?.id || "-"}</td>
                  </tr>
                  <tr>
                    <td>Отдел заключения договоров</td>
                    <td>
                      {isTenderProject()
                        ? "Тендерный отдел"
                        : "Коммерческий отдел"}
                    </td>
                  </tr>
                  <tr>
                    <td>Заказчик</td>
                    <td>{project?.contract?.customer?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>Дополнительное наименование</td>
                    <td>{project?.contract?.additionalCustomerName || "-"}</td>
                  </tr>
                  <tr>
                    <td>ИНН заказчика</td>
                    <td>{project?.contract?.customer?.inn || "-"}</td>
                  </tr>
                  <tr>
                    <td>Номер контракта</td>
                    <td>{project?.contract?.number || "-"}</td>
                  </tr>
                  {isTenderHead() && (
                    <tr>
                      <td>Идентификационный номер</td>
                      <td>{project?.alternateId || "-"}</td>
                    </tr>
                  )}
                  {(isLabHead() ||
                    isAccountantUser() ||
                    isCommercialUser()) && (
                    <>
                      <tr>
                        <td>Номер акта</td>
                        <td>{project?.contract?.actNumber || "-"}</td>
                      </tr>
                      <tr>
                        <td>Дата отчета агента</td>
                        <td>
                          {dateToTextDashed(project?.monthlyReportDate) || "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  {!isLabEmployee() && (
                    <>
                      <tr>
                        <td>Цена</td>
                        <td>{project?.contract?.price || "-"}</td>
                      </tr>
                      <tr>
                        <td>Сумма по акту</td>
                        <td>{project?.contract?.actAmount || "-"}</td>
                      </tr>
                    </>
                  )}
                  {(isCommercialUser() ||
                    isLabHead() ||
                    isAccountantEmployee()) && (
                    <tr>
                      <td>Акт получен?</td>
                      <td>{translateText(project?.actHasReached) || "-"}</td>
                    </tr>
                  )}
                  {(isLabHead() || isCommercialUser() || isTenderUser()) && (
                    <>
                      <tr>
                        <td>КПП</td>
                        <td>{project?.kpp || "-"}</td>
                      </tr>
                      <tr>
                        <td>Замеры</td>
                        <td>
                          {translateText(project?.measurementStatusId) || "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td>Этап проекта</td>
                    <td>
                      {translateText(project?.workFlow?.projectStatusId) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Контактные данные</td>
                    <td>{project?.contract?.customer?.contactData || "-"}</td>
                  </tr>
                  <tr>
                    <td>Регионы</td>
                    <td>
                      {project?.regions && project.regions.length > 0
                        ? project.regions
                            .map((region) => region.name)
                            .join(", ")
                        : " - "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {isTenderProject() && !isAccountantUser() && !isLabUser() && (
              <div>
                <h3 className="table__title">Тендерные сведения</h3>
                <table className="table__table-view-modal">
                  <tbody>
                    <tr>
                      <td>ЕИС (ID на площадке)</td>
                      <td>{project?.tender?.tenderId || "-"}</td>
                    </tr>
                    <tr>
                      <td>Площадка</td>
                      <td>
                        {project?.tender?.tradingFloor?.link ? (
                          <a
                            className="external-link"
                            href={project.tender.tradingFloor.link}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                          >
                            {project?.tender?.tradingFloor?.name}
                            <HiOutlineExternalLink />
                          </a>
                        ) : (
                          project?.tender?.tradingFloor?.name
                        )}
                        {!project?.tender?.tradingFloor?.name && (
                          <span>{" - "}</span>
                        )}
                      </td>
                    </tr>
                    {isTenderHead() && (
                      <tr>
                        <td>Идентификационный номер</td>
                        <td>{project?.alternateId || "-"}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Закон госзакупки</td>
                      <td>
                        {translateText(project?.tender?.legalBasesId) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Кто отыгрывает</td>
                      <td>
                        {project?.tender?.hasTradingRobot
                          ? "Робот"
                          : "Не робот"}
                      </td>
                    </tr>
                    <tr>
                      <td>Вид процедуры</td>
                      <td>
                        {translateText(project?.tender?.operationTypeId) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>НМЦК</td>
                      <td>{project?.tender?.startMaxPrice || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к заявке</td>
                      <td>{project?.tender?.description || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий о причине неподачи</td>
                      <td>{project?.tender?.failrueDescription || "-"}</td>
                    </tr>
                    <tr>
                      <td>Победитель</td>
                      <td>{project?.tender?.winner?.name || "-"}</td>
                    </tr>
                    <tr>
                      <td>Цена победителя</td>
                      <td>{project?.tender?.winnerPrice || "-"}</td>
                    </tr>
                    <tr>
                      <td>Наша цена</td>
                      <td>{project?.tender?.offerAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Ссылка от агрегатора</td>
                      <td>
                        {project?.aggregatorLink ? (
                          <a
                            className="external-link"
                            href={project.aggregatorLink}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                          >
                            Перейти по ссылке
                            <HiOutlineExternalLink />
                          </a>
                        ) : (
                          " - "
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение контракта</td>
                      <td>{project?.contract?.securityAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Тип обеспечения контракта</td>
                      <td>
                        {translateText(
                          project?.tender?.contractSecuringMethodId
                        ) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение заявки</td>
                      <td>{project?.contract?.offerSecurityAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комиссия площадки</td>
                      <td>
                        {project?.contract?.tradingFloorCommissionAmount || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение вернулось?</td>
                      <td>
                        {translateText(project?.tender?.securityReturned) ||
                          "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {isTenderProject() && isAccountantUser() && (
              <div>
                <h3 className="table__title">Тендерные сведения</h3>
                <table className="table__table-view-modal">
                  <tbody>
                    <tr>
                      <td>Обеспечение контракта</td>
                      <td>{project?.contract?.securityAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Тип обеспечения контракта</td>
                      <td>
                        {translateText(
                          project?.tender?.contractSecuringMethodId
                        ) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение заявки</td>
                      <td>{project?.contract?.offerSecurityAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комиссия площадки</td>
                      <td>
                        {project?.contract?.tradingFloorCommissionAmount || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение вернулось?</td>
                      <td>
                        {translateText(project?.tender?.securityReturned) ||
                          "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {isTenderProject() && isLabHead() && (
              <div>
                <h3 className="table__title">Тендерные сведения</h3>
                <table className="table__table-view-modal">
                  <tbody>
                    <tr>
                      <td>ЕИС (ID на площадке)</td>
                      <td>{project?.tender?.tenderId || "-"}</td>
                    </tr>
                    <tr>
                      <td>Площадка</td>
                      <td>{project?.tender?.tradingFloor?.name || "-"}</td>
                    </tr>
                    <tr>
                      <td>Вид процедуры</td>
                      <td>
                        {translateText(project?.tender?.operationTypeId) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>НМЦК</td>
                      <td>{project?.tender?.startMaxPrice || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к заявке</td>
                      <td>{project?.tender?.description || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий о причине неподачи</td>
                      <td>{project?.tender?.failrueDescription || "-"}</td>
                    </tr>
                    <tr>
                      <td>Наша цена</td>
                      <td>{project?.tender?.offerAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Ссылка от агрегатора</td>
                      <td>
                        {project?.aggregatorLink ? (
                          <a
                            className="external-link"
                            href={project.aggregatorLink}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                          >
                            Перейти по ссылке
                            <HiOutlineExternalLink />
                          </a>
                        ) : (
                          " - "
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div>
              <h3 className="table__title">Отдел исполнения</h3>
              <table className="table__table-view-modal">
                <tr>
                  <td>Тип оценки</td>
                  <td>{translateText(project?.assesmentTypeId) || "-"}</td>
                </tr>
                {isCommercialUser() && isCommercialProject() && (
                  <>
                    <tr>
                      <td>Число рабочих мест</td>
                      <td>{project?.workspaceCount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Идентификационный номер</td>
                      <td>{project?.alternateId || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к исполнению</td>
                      <td>{project?.executionDescription || "-"}</td>
                    </tr>
                  </>
                )}
                {isLabUser() && (
                  <>
                    <tr>
                      <td>Рабочая тетрадь</td>
                      <td>{project?.haveWorkbook ? "Есть" : "Нет"}</td>
                    </tr>
                    <tr>
                      <td>Программа выполнения</td>
                      <td>
                        {translateText(project?.executionProgramId) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Число рабочих мест</td>
                      <td>{project?.workspaceCount?.pk || "-"}</td>
                    </tr>
                    <tr>
                      <td>Идентификационный номер</td>
                      <td>{project?.alternateId || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к исполнению</td>
                      <td>{project?.executionDescription || "-"}</td>
                    </tr>
                    {isTenderProject() && (
                      <tr>
                        <td>Число подразделений</td>
                        <td>{project?.unitCount || "-"}</td>
                      </tr>
                    )}
                  </>
                )}
                {isAccountantUser() && isCommercialProject() && (
                  <>
                    <tr>
                      <td>Число рабочих мест</td>
                      <td>{project?.workspaceCount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к исполнению</td>
                      <td>{project?.executionDescription || "-"}</td>
                    </tr>
                  </>
                )}
                {isAccountantUser() && isTenderProject() && (
                  <>
                    <tr>
                      <td>Число рабочих мест</td>
                      <td>{project?.workspaceCount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к исполнению</td>
                      <td>{project?.executionDescription || "-"}</td>
                    </tr>
                  </>
                )}
                {isTenderProject() && isTenderUser() && (
                  <>
                    <tr>
                      <td>Число рабочих мест</td>
                      <td>{project?.workspaceCount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Число подразделений</td>
                      <td>{project?.unitCount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к исполнению</td>
                      <td>{project?.executionDescription || "-"}</td>
                    </tr>
                  </>
                )}
                {isMedUserArea() && (
                  <tr>
                    <td>Количество людей</td>
                    <td>
                      {translateText(
                        project?.med?.peopleForMedicalExamination
                      ) || "-"}
                    </td>
                  </tr>
                )}
              </table>
            </div>

            <div>
              <h3 className="table__title">Ответственные</h3>
              <table className="table__table-view-modal">
                {isTenderProject() && (
                  <tr>
                    <td>Тендерный отдел</td>
                    <td>{getUserInfo("TenderDepartment")}</td>
                  </tr>
                )}
                {isCommercialProject() && (
                  <tr>
                    <td>Коммерческий отдел</td>
                    <td>{getUserInfo("CommercialDepartment")}</td>
                  </tr>
                )}
                {isCommercialProject() && isCommercialUser() && (
                  <tr>
                    <td>Лаборатория</td>
                    <td>{getUserInfo("Laboratory")}</td>
                  </tr>
                )}
                {isLabUser() && (
                  <>
                    <tr>
                      <td>Лаборатория</td>
                      <td>{getUserInfo("Laboratory")}</td>
                    </tr>
                    <tr>
                      <td>Замерщик</td>
                      <td>
                        {project?.worker?.name || "-"} &nbsp;{" "}
                        {project?.worker?.lastName || "-"}
                      </td>
                    </tr>
                  </>
                )}
              </table>
            </div>

            <div className="tables-grid__dates">
              <h3 className="table__title">Даты</h3>
              <table className="table__table-view-modal">
                <tbody>
                  {isTenderUser() && (
                    <>
                      <tr>
                        <td>Дата публикации</td>
                        <td>
                          {toDateWithoutTime(
                            project?.tender?.publicationDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата окончания подачи заявки</td>
                        <td>
                          {toDateWithTime(
                            project?.tender?.acceptingOffersDeadline
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата аукциона</td>
                        <td>
                          {toDateWithTime(project?.tender?.auctionDate) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата итогового протокола</td>
                        <td>
                          {toDateWithoutTime(
                            project?.tender?.summaryReportDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата заключения договора</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.conclusionContractDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Плановая дата исполнения</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.plannedExecutionDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата акта</td>
                        <td>
                          {toDateWithoutTime(project?.contract?.actDate) || "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  {isCommercialUser() && (
                    <>
                      <tr>
                        <td>Дата заключения договора</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.conclusionContractDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Плановая дата исполнения</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.plannedExecutionDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата акта</td>
                        <td>
                          {toDateWithoutTime(project?.contract?.actDate) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата оплаты</td>
                        <td>
                          {toDateWithoutTime(project?.contract?.paidDate) ||
                            "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  {isLabUser() && (
                    <>
                      <tr>
                        <td>Дата заключения договора</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.conclusionContractDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Плановая дата исполнения</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.plannedExecutionDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата получения информации</td>
                        <td>
                          {toDateWithoutTime(
                            project?.informationCollectionDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата получения полной информации</td>
                        <td>
                          {toDateWithoutTime(project?.informationCollectedAt) ||
                            "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата замеров</td>
                        <td>
                          {toDateWithoutTime(project?.measurementsDate) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата назначения исполнителя</td>
                        <td>
                          {toDateWithoutTime(project?.performerAppointedDate) ||
                            "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата приема в работу</td>
                        <td>
                          {toDateWithoutTime(project?.acceptedToWorkDate) ||
                            "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата отправки на согласование</td>
                        <td>
                          {toDateWithoutTime(project?.approvalsDate) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата отправки отчета</td>
                        <td>{toDateWithoutTime(project?.sentDate) || "-"}</td>
                      </tr>
                      <tr>
                        <td>Дата подписания титульного листа</td>
                        <td>
                          {toDateWithoutTime(project?.titleSheetSignDate) ||
                            "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата выгрузки</td>
                        <td>
                          {toDateWithoutTime(project?.unloadingDate) || "-"}
                        </td>
                      </tr>
                      {isLabHead() && (
                        <>
                          <tr>
                            <td>Дата акта</td>
                            <td>
                              {toDateWithoutTime(project?.contract?.actDate) ||
                                "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Дата оплаты</td>
                            <td>
                              {toDateWithoutTime(project?.contract?.paidDate) ||
                                "-"}
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                  {isAccountantUser() && (
                    <>
                      <tr>
                        <td>Дата заключения договора</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.conclusionContractDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Плановая дата исполнения</td>
                        <td>
                          {toDateWithoutTime(
                            project?.contract?.plannedExecutionDate
                          ) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата акта</td>
                        <td>
                          {toDateWithoutTime(project?.contract?.actDate) || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Дата оплаты</td>
                        <td>
                          {toDateWithoutTime(project?.contract?.paidDate) ||
                            "-"}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {isAdmin() && (
          <div className="modal__tables-grid">
            <div>
              <h3 className="table__title">Общие сведения</h3>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td className="table__id">{project?.id || "-"}</td>
                  </tr>
                  <tr>
                    <td>Отдел заключения договоров</td>
                    <td>
                      {isTenderProject()
                        ? "Тендерный отдел"
                        : "Коммерческий отдел"}
                    </td>
                  </tr>
                  <tr>
                    <td>Заказчик</td>
                    <td>{project?.contract?.customer?.name || "-"}</td>
                  </tr>
                  <tr>
                    <td>ИНН заказчика</td>
                    <td>{project?.contract?.customer?.inn || "-"}</td>
                  </tr>
                  <tr>
                    <td>Номер контракта</td>
                    <td>{project?.contract?.number || "-"}</td>
                  </tr>
                  <tr>
                    <td>Цена</td>
                    <td>{project?.contract?.price || "-"}</td>
                  </tr>
                  <tr>
                    <td>Сумма по акту</td>
                    <td>{project?.contract?.actAmount || "-"}</td>
                  </tr>
                  <tr>
                    <td>Акт получен?</td>
                    <td>{translateText(project?.actHasReached) || "-"}</td>
                  </tr>
                  <tr>
                    <td>Этап проекта</td>
                    <td>
                      {translateText(project?.workFlow?.projectStatusId) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Контактные данные</td>
                    <td>{project?.contract?.customer?.contactData || "-"}</td>
                  </tr>
                  <tr>
                    <td>Регионы</td>
                    <td>
                      {project?.regions && project.regions.length > 0
                        ? project.regions
                            .map((region) => region.name)
                            .join(", ")
                        : " - "}
                    </td>
                  </tr>
                  <tr>
                    <td>КПП</td>
                    <td>{project?.kpp || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {isTenderProject() && (
              <div>
                <h3 className="table__title">Тендерные сведения</h3>
                <table className="table__table-view-modal">
                  <tbody>
                    <tr>
                      <td>ЕИС (ID на площадке)</td>
                      <td>{project?.tender?.tenderId || "-"}</td>
                    </tr>
                    <tr>
                      <td>Площадка</td>
                      <td>
                        {project?.tender?.tradingFloor?.link ? (
                          <a
                            className="external-link"
                            href={project.tender.tradingFloor.link}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                          >
                            {project?.tender?.tradingFloor?.name}
                            <HiOutlineExternalLink />
                          </a>
                        ) : (
                          project?.tender?.tradingFloor?.name
                        )}
                        {!project?.tender?.tradingFloor?.name && (
                          <span>{" - "}</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Закон госзакупки</td>
                      <td>
                        {translateText(project?.tender?.legalBasesId) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Кто отыгрывает</td>
                      <td>
                        {project?.tender?.hasTradingRobot
                          ? "Робот"
                          : "Не робот"}
                      </td>
                    </tr>
                    <tr>
                      <td>Вид процедуры</td>
                      <td>
                        {translateText(project?.tender?.operationTypeId) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>НМЦК</td>
                      <td>{project?.tender?.startMaxPrice || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий к заявке</td>
                      <td>{project?.tender?.description || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комментарий о причине неподачи</td>
                      <td>{project?.tender?.failrueDescription || "-"}</td>
                    </tr>
                    <tr>
                      <td>Победитель</td>
                      <td>{project?.tender?.winner?.name || "-"}</td>
                    </tr>
                    <tr>
                      <td>Цена победителя</td>
                      <td>{project?.tender?.winnerPrice || "-"}</td>
                    </tr>
                    <tr>
                      <td>Наша цена</td>
                      <td>{project?.tender?.offerAmount || "-"}</td>
                    </tr>

                    <tr>
                      <td>Ссылка от агрегатора</td>
                      <td>
                        {project?.aggregatorLink ? (
                          <a
                            className="external-link"
                            href={project.aggregatorLink}
                            target="_blank"
                            rel="noreferrer noopener nofollow"
                          >
                            Перейти по ссылке
                            <HiOutlineExternalLink />
                          </a>
                        ) : (
                          " - "
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение контракта</td>
                      <td>{project?.contract?.securityAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Тип обеспечения контракта</td>
                      <td>
                        {translateText(
                          project?.tender?.contractSecuringMethodId
                        ) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение заявки</td>
                      <td>{project?.contract?.offerSecurityAmount || "-"}</td>
                    </tr>
                    <tr>
                      <td>Комиссия площадки</td>
                      <td>
                        {project?.contract?.tradingFloorCommissionAmount || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Обеспечение вернулось?</td>
                      <td>
                        {translateText(project?.tender?.securityReturned) ||
                          "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <div>
              <h3 className="table__title">Отдел исполнения</h3>
              <table className="table__table-view-modal">
                <tr>
                  <td>Тип оценки</td>
                  <td>{translateText(project?.assesmentTypeId) || "-"}</td>
                </tr>
                <tr>
                  <td>Число рабочих мест</td>
                  <td>{project?.workspaceCount || "-"}</td>
                </tr>
                <tr>
                  <td>Идентификационный номер</td>
                  <td>{project?.alternateId || "-"}</td>
                </tr>
                <tr>
                  <td>Комментарий к исполнению</td>
                  <td>{project?.executionDescription || "-"}</td>
                </tr>
                <tr>
                  <td>Рабочая тетрадь</td>
                  <td>{project?.haveWorkbook ? "Есть" : "Нет"}</td>
                </tr>
                <tr>
                  <td>Программа выполнения</td>
                  <td>{translateText(project?.executionProgramId) || "-"}</td>
                </tr>
                <tr>
                  <td>Число рабочих мест</td>
                  <td>{project?.workspaceCount || "-"}</td>
                </tr>
                <tr>
                  <td>Идентификационный номер</td>
                  <td>{project?.alternateId || "-"}</td>
                </tr>
                <tr>
                  <td>Число подразделений</td>
                  <td>{project?.unitCount || "-"}</td>
                </tr>
                <tr>
                  <td>Количество людей</td>
                  <td>
                    {translateText(project?.med?.peopleForMedicalExamination) ||
                      "-"}
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <h3 className="table__title">Ответственные</h3>
              <table className="table__table-view-modal">
                {isTenderProject() && (
                  <tr>
                    <td>Тендерный отдел</td>
                    <td>{getUserInfo("TenderDepartment")}</td>
                  </tr>
                )}
                {isCommercialProject() && (
                  <tr>
                    <td>Коммерческий отдел</td>
                    <td>{getUserInfo("CommercialDepartment")}</td>
                  </tr>
                )}
                <tr>
                  <td>Лаборатория</td>
                  <td>{getUserInfo("Laboratory")}</td>
                </tr>
                <tr>
                  <td>Замерщик</td>
                  <td>
                    {project?.worker?.name || "-"} &nbsp;{" "}
                    {project?.worker?.lastName || "-"}
                  </td>
                </tr>
              </table>
            </div>
            <div className="tables-grid__dates">
              <h3 className="table__title">Даты</h3>
              <table className="table__table-view-modal">
                <tbody>
                  <tr>
                    <td>Дата публикации</td>
                    <td>
                      {toDateWithoutTime(project?.tender?.publicationDate) ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата окончания подачи заявки</td>
                    <td>
                      {toDateWithTime(
                        project?.tender?.acceptingOffersDeadline
                      ) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата аукциона</td>
                    <td>
                      {toDateWithTime(project?.tender?.auctionDate) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата итогового протокола</td>
                    <td>
                      {toDateWithoutTime(project?.tender?.summaryReportDate) ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата заключения договора</td>
                    <td>
                      {toDateWithoutTime(
                        project?.contract?.conclusionContractDate
                      ) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Плановая дата исполнения</td>
                    <td>
                      {toDateWithoutTime(
                        project?.contract?.plannedExecutionDate
                      ) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата акта</td>
                    <td>
                      {toDateWithoutTime(project?.contract?.actDate) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата оплаты</td>
                    <td>
                      {toDateWithoutTime(project?.contract?.paidDate) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата получения информации</td>
                    <td>
                      {toDateWithoutTime(project?.informationCollectionDate) ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата получения полной информации</td>
                    <td>
                      {toDateWithoutTime(project?.informationCollectedAt) ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата замеров</td>
                    <td>
                      {toDateWithoutTime(project?.measurementsDate) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата назначения исполнителя</td>
                    <td>
                      {toDateWithoutTime(project?.performerAppointedDate) ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата приема в работу</td>
                    <td>
                      {toDateWithoutTime(project?.acceptedToWorkDate) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата отправки на согласование</td>
                    <td>{toDateWithoutTime(project?.approvalsDate) || "-"}</td>
                  </tr>
                  <tr>
                    <td>Дата отправки отчета</td>
                    <td>{toDateWithoutTime(project?.sentDate) || "-"}</td>
                  </tr>
                  <tr>
                    <td>Дата подписания титульного листа</td>
                    <td>
                      {toDateWithoutTime(project?.titleSheetSignDate) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Дата выгрузки</td>
                    <td>{toDateWithoutTime(project?.unloadingDate) || "-"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        <button
          onClick={() => setOpenModal(false)}
          className="button button--blue close-button modal__close-button--bottom"
        >
          Закрыть окно
        </button>
      </div>
    </section>
  );
};
export default TableViewModal;
