import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import MovingProjectsModal from './MovingProjectsModal';
// import TableView from './TableView';
// import AddPaymentModal from './AddPaymenetModal';
import TablePaymentsView from './TablePaymentsView';
import { api } from 'settings/Api';

const ActionProjectButtons = (note) => {

  const isAccountingPage = note?.state?.accountType;

  const [openNextModal, setOpenNextModal] = useState(false);
  const [openPrevModal, setOpenPrevModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openTablePaymentsModal, setOpenTablePaymentsModal] = useState(false);


  const moveProjectToNextStage = async () => {
    const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;

    let response = await api(`/api/sout/workflow/continue/${note.state.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        // 'Authorization':  'String(authTokens.access) ,
      },
    })
    let data = await response.json();
    setOpenNextModal(false)

  };

  const moveProjectToPrevStage = async () => {
    const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;

    let response = await api(`/api/sout/workflow/return/${note.state.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        // 'Authorization':  'String(authTokens.access) ,
      },
    })
    let data = await response.json();
    setOpenPrevModal(false)

  }

  

  const [openTable, setOpenTable] = useState(false);

  const rejectProject = async (id) => {
    const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;

    const response = await api(`/api/sout/workflow/reject/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
        // 'Authorization':  'String(authTokens.access) ,
      },
    })
    let data = await response.json();
  }

  return <>
    <Link to='/EditProject' title={'Редактировать'} state={{ data: note }}>
      <button className="button button--square icon-edit" title='Редактировать'></button>
    </Link>

    <button className="button button--square icon-trash" title='Удалить'
      onClick={() => rejectProject(note.state.id)}
    ></button>

    <button
      onClick={() => setOpenTable(true)}
      className="button button--square icon-watch" title='Посмотреть подробную информацию'> </button>
    {/* {
      openTable ? <TableView closeModal={setOpenTable} user={note} /> : null
    } */}

    <button
      onClick={() => setOpenPrevModal(true)}
      className="button button--square icon-arrow-left" title='Перевести на предыдущий этап'></button>
    {
      (openPrevModal) ? <MovingProjectsModal move={moveProjectToPrevStage} moveType='prev' data={note} setOpenModal={setOpenPrevModal} /> : null
    }

    <button
      onClick={() => setOpenNextModal(true)}
      moveToNext={moveProjectToNextStage}
      className="button button--square icon-arrow-right" title='Перевести на следующий этап'></button>
    {
      (openNextModal) ? <MovingProjectsModal move={moveProjectToNextStage} moveType='next' data={note} setOpenModal={setOpenNextModal} /> : null
    }
    {
      (isAccountingPage === 'Accountant')
        ? (
          <>
            <button
              onClick={() => setOpenPaymentModal(true)}
              className="button button--square icon-ruble" title='Создать платёж'></button>
            {
              // (openPaymentModal) ? <AddPaymentModal data={note} setOpenModal={setOpenPaymentModal} /> : null
            }

            <button
              onClick={() => setOpenTablePaymentsModal(true)}
              className="button button--square icon-refresh" title='Просмотр списка платежей'></button>
            {
              (openTablePaymentsModal) ? <TablePaymentsView user={note} setOpenTablePaymentsModal={setOpenTablePaymentsModal} /> : null
            }
          </>
        )
        : null
  }
  </>;
};

export default ActionProjectButtons;
