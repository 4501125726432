import { api } from 'settings/Api';
const fetchCompetitors = async (setCompetitors) => {
  const token = JSON.parse(localStorage.getItem('authTokens')).data.accessToken;
  let response = await api(`/api/sout/handbooks/competitors`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
  let data = await response.json();
  if(setCompetitors) setCompetitors(data.data);
  return data.data
}

export default fetchCompetitors;