import Asterisk from "components/Asterisk";

export const Checkbox = ({ name, onChange, checked, label }) => {
  return (
    <div style={{ marginRight: "10px" }} key={name}>
      <input
        style={{ marginRight: "5px", cursor: "pointer" }}
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
      />
      <span>{label}</span>
    </div>
  );
};

export const Checkboxes = ({
  label,
  values,
  data,
  errorMessage,
  updateValues,
  isChecked,
  marginTop,
}) => {
  return (
    <div
      className="workspace__input-title contracts__input-title required"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "76px",
      }}
    >
      <div>
        <span>{label}</span>
        <Asterisk />
      </div>
      <div
        className="checkboxes"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: marginTop || "10px",
        }}
      >
        {data &&
          values.map(([name, label]) => (
            <Checkbox
              name={name}
              label={label}
              checked={isChecked(name)}
              onChange={() => updateValues(name)}
            />
          ))}
      </div>
      <span className="error-message">{errorMessage}</span>
    </div>
  );
};
