import { DatePicker } from "components/DateInput/DateInput";
import React, { useEffect } from "react";
import { LabelTitle } from "shared/ui/LabelTitle/LabelTitle";
import styled from "styled-components";

const DateModal = ({ setIsDisabled, date, setDate, title }) => {
    // useEffect(() => {
    //     setIsDisabled(!date);
	// }, []);

	useEffect(() => {
        setIsDisabled(!date);
	});

	const DatePickerWrapper = styled.div`
		width: 105px;
        margin: auto;
	`;

	return (
		<div className="modal__content-center">
			<div style={{ width: 320, marginTop: 15 }}>
				<LabelTitle>{title}</LabelTitle>
				{/* <label className="modal__input-title">
                    
                </label> */}
				<DatePickerWrapper>
					<DatePicker
						className="input"
						name="Date"
						value={date || null}
						onChange={(date) => setDate(date)}
						required={true}
						min={new Date("01.01.2000")}
					/>
				</DatePickerWrapper>
			</div>
		</div>
	);
};

export default DateModal;
