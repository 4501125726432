import { useState, useEffect, useContext } from "react";
import AttachButton from "./AttachButton";
import Spinner from "components/Spinner";
import { BsPlusLg } from "react-icons/bs";
import { AiOutlineMinus } from "react-icons/ai";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { ImCross } from "react-icons/im";
import DocumentContext from "context/DocumentsContext";
import { getDocuments } from "utils/getDocuments";
import { ConfirmActionModal } from "components/modals/ConfirmActionModal";
import ModalWrapper from "components/modals/ModalWrapper";
import { Portal } from "components/Portal/Portal";
import { DocActionModal } from "components/modals/DocActionModal";

const Colors = {
  tenders: "purple",
  contracts: "blue",
  "separated-projects": "orange",
  "measuring-blocks": "olive",
  "contract-measuring-blocks": "olive",
  "potential-measuring-blocks": "olive",
};

function DocumentsListHandler({ modelId, entity, readOnly = false }) {
  const { uploadDocument, downloadDocument, removeDocument } =
    useContext(DocumentContext);
  const [documents, setDocuments] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const [isOpenList, setIsOpenList] = useState({});

  const [files, setFiles] = useState([]);
  const [uploadedFiles] = useState([]);

  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);
  const [docIdForRemove, setDocIdForRemove] = useState(null);

  const color = Colors[entity.link];

  const docTypesList = entity.docTypes;

  const updateDocuments = () => {
    getDocuments(entity.link, modelId).then((res) => setDocuments(res));
  };
  console.log(documents);
  useEffect(() => {
    updateDocuments();
  }, []);

  const toggleDocumentsList = async (listTitle) => {
    setIsOpenList({ ...isOpenList, [listTitle]: !isOpenList[listTitle] });
  };

  const chooseFile = async (e, fileType) => {
    setFiles([
      ...files,
      {
        file: e.target.files[0],
        fileType: fileType,
        id: files.length,
        status: "chosen",
      },
    ]);
  };

  if (!modelId) return <Spinner />;

  const cleanUploadedFile = (id) => {
    setFiles(files.filter((item) => item.id !== id));
  };

  const getDocsQty = (type) => {
    if (documents) {
      const arrOfDocs = documents.filter((doc) =>
        doc?.url?.includes("_" + type + "_")
      );
      const qty = arrOfDocs.length.toString();
      return qty;
    }
  };

  const updateFilesState = (id, status) => {
    setFiles(
      files.map((item) => {
        if (item.id === id) {
          return { ...item, status: status };
        }
        return item;
      })
    );
  };

  const handleUpload = async (type, fileObj) => {
    updateFilesState(fileObj.id, "uploading");

    const formData = new FormData();
    formData.append("file", fileObj.file);
    await uploadDocument(
      entity.link,
      modelId,
      type,
      formData,
      setIsUploading,
      setIsSuccess
    );

    setTimeout(() => {
      updateFilesState(fileObj.id, "uploaded");
      // setFiles(files.filter((x) => x.id !== fileObj.id));
      setFiles([]);
      updateDocuments();
    }, [1000]);
  };

  const updateDocumentsState = (id, status) => {
    setDocuments(
      documents.map((item) => {
        if (item.id === id) {
          return { ...item, status: status };
        }
        return item;
      })
    );
  };

  const handleDownload = async (e, name, extention, link, id, type) => {
    updateDocumentsState(id, "downloading");
    await downloadDocument(e, name, extention, link, id, type);
    updateDocumentsState(id, "downloaded");
    // setTimeout(() => {
    // 	updateDocumentsState(id, "not-downloaded");
    // }, [1000]);
  };

  const handleRemove = async (id) => {
    await removeDocument(id);
    setConfirmActionModalOpen(false);
    updateDocuments();
  };

  const handleModal = (id) => {
    setDocIdForRemove(id);
    setConfirmActionModalOpen(true);
  };

  return (
    <div style={{ display: "block" }}>
      {confirmActionModalOpen ? (
        <Portal>
          <ModalWrapper>
            <DocActionModal
              title="Вы уверены, что хотите удалить документ"
              onYes={() => handleRemove(docIdForRemove)}
              onNo={() => setConfirmActionModalOpen(false)}
              colorThemeClass={`button--${color}`}
            />
          </ModalWrapper>
        </Portal>
      ) : (
        docTypesList.map((docType) => (
          <div key={docType.type} style={{ width: "400px" }}>
            <header className="modal__doc-header">
              <h4>
                {docType.description} [{getDocsQty(docType.type) || "-"}]
              </h4>
              <div className="doc-buttons">
                {!readOnly && (
                  <AttachButton
                    docType={docType.type}
                    isOpenList={isOpenList}
                    chooseFile={chooseFile}
                    color={color}
                  />
                )}
                <button
                  className={`button button--square button--${color} ${
                    isOpenList[docType.type] && `button--${color}--active`
                  }`}
                  type="button"
                  onClick={() => toggleDocumentsList(docType.type)}
                >
                  {isOpenList[docType.type] ? (
                    <AiOutlineMinus className="icon--medium" />
                  ) : (
                    <BsPlusLg className="icon--medium" />
                  )}
                </button>
              </div>
            </header>
            <ul
              className={`modal__doc-list ${
                !isOpenList[docType.type] && "collapse"
              }`}
            >
              {files &&
                files
                  .filter((item) => item.fileType === docType.type)
                  .map((item) => (
                    <li className="modal__doc-item" key={item.id}>
                      {item.file.name.length > 30
                        ? item.file.name.slice(0, 30) + "..."
                        : item.file.name}
                      <div className="doc-buttons">
                        {item.status === "chosen" && (
                          <>
                            <button
                              className={`button button--square button--${color}`}
                              onClick={() => handleUpload(docType.type, item)}
                            >
                              <FaArrowUp className="icon--medium" />
                            </button>
                            <button
                              className={`button button--square button--${color}`}
                              onClick={() => cleanUploadedFile(item.id)}
                            >
                              <ImCross className="icon--medium" />
                            </button>
                          </>
                        )}
                        {item.status === "uploading" && (
                          <Spinner className="icon--spinner" />
                        )}
                        {item.status === "uploaded" && (
                          <MdDone className="icon--done" />
                        )}
                      </div>
                    </li>
                  ))}
              {documents &&
                documents.length > 0 &&
                documents
                  .filter((doc) => doc?.url?.includes("_" + docType.type + "_"))
                  .map((doc) => (
                    <li key={doc.id} className="modal__doc-item">
                      {doc.name.length > 30
                        ? doc.name.slice(0, 30) + "..."
                        : doc.name}
                      {doc.extention}
                      <div className="doc-buttons">
                        {doc.status === "downloading" && (
                          <Spinner className="icon--spinner" />
                        )}
                        {doc.status === "downloaded" && (
                          <MdDone className="icon--done" />
                        )}
                        <button
                          className={`button button--square button--${color}`}
                          onClick={(e) =>
                            handleDownload(
                              e,
                              doc.name,
                              doc.extention,
                              entity.link,
                              doc.id,
                              docType.type
                            )
                          }
                        >
                          <FaArrowDown className="icon--medium" />
                        </button>
                        {!readOnly && (
                          <button
                            className={`button button--square button--${color}`}
                            onClick={() => handleModal(doc.id)}
                          >
                            <ImCross className="icon--medium" />
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
              {documents &&
                !files &&
                documents.filter((doc) => doc.typeId === docType.typeId)
                  .length <= 0 && (
                  <li className="modal__no-doc-message">
                    <i>Нет документов</i>
                  </li>
                )}
            </ul>
          </div>
        ))
      )}
    </div>
  );
}

export default DocumentsListHandler;
