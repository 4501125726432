import React, { useState, useContext, useEffect } from "react";
import AttachButton from "./AttachButton";
import DocumentContext from "context/DocumentsContext";
import { BsPlusLg } from "react-icons/bs";
import { getDocuments } from "utils/getDocuments";
import Spinner from "components/Spinner";
import { AiOutlineMinus } from "react-icons/ai";
import getContract from "utils/getContractById";
import ContracModalFileInput from "./ContractModalFileInput";

const Colors = {
  tenders: "purple",
  contracts: "blue",
  "separated-projects": "orange",
  "measuring-blocks": "olive",
};

const ContractDepartmentFileInput = ({
  entity,
  link,
  isShowDonwload = true,
  title,
  contractId,
}) => {
  const [isOpenList, setIsOpenList] = useState(false);
  const [dep, setDep] = useState([]);
  const [isUpload, setIsUpload] = useState([]);
  const color = Colors[entity];

  const toggleDocumentsList = () => {
    setIsOpenList(!isOpenList);
  };

  useEffect(() => {
    getContract(contractId).then((data) => {
      setDep([...data?.data?.departments]);
    });
  }, []);

  return (
    <div style={{ marginLeft: 10, maxWidth: 400 }}>
      <header className="modal__doc-header">
        <h4 style={{ width: 300 }}>{title}</h4>

        <button
          className={`button button--square button--${color} ${
            isOpenList[link] && `button--${color}--active`
          }`}
          type="button"
          onClick={() => toggleDocumentsList(link)}
        >
          {isOpenList ? (
            <AiOutlineMinus className="icon--medium" />
          ) : (
            <BsPlusLg className="icon--medium" />
          )}
        </button>
      </header>
      {isOpenList && (
        <div style={{ height: 200, overflowY: "scroll" }}>
          {dep?.map((item) => (
            <ContracModalFileInput
              contractId={contractId}
              entity={"contracts"}
              key={item?.departmentNumber}
              isUpload={isUpload}
              width={320}
              setIsUpload={setIsUpload}
              modelId={item?.departmentNumber}
              link={"Id"}
              title={`Ид номер: ${item?.departmentNumber}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ContractDepartmentFileInput;
