import { TENDER_STAGES } from "./stages";
import { COMMERCIAL_STAGES } from "./stages";

import TableViewModal from "../components/modals/TableViewModal";
import MovePrevStageModal from "../components/modals/MovePrevStageModal";
import RejectModal from "../components/modals/RejectModal";
import RejectWithCommentModal from "../pages/sout/tender/modals/rejects/RejectWithCommentModal";
import RejectSelectWinnerModal from "../pages/sout/tender/modals/rejects/RejectSelectWinnerModal";

import DraftTenderModal from "../pages/sout/tender/modals/move/DraftModal";
import AcceptedToWorkTenderModal from "../pages/sout/tender/modals/move/AcceptedToWorkModal";
import FiledTenderModal from "../pages/sout/tender/modals/move/FiledModal";
import WonAndSigningTenderModal from "../pages/sout/tender/modals/move/WonAndSigningModal";
import AlternateIdAssignmentTenderModal from '../pages/sout/tender/modals/move/AlternateIdAssignmentTenderModal';

import DraftComModal from "../pages/sout/commercial/modals/move/DraftModal";
import InfoCollectionAndSigningComModal from "../pages/sout/commercial/modals/move/InfoCollectionAndSigningModal";
import ActsComModal from "../pages/sout/commercial/modals/move/ActsModal";

import DraftLabChiefModal from "../pages/sout/lab-chief/modals/move/DraftModal";
import InformationCollectionLabChiefModal from "../pages/sout/lab-chief/modals/move/InformationCollectionModal";
import MeasurementsLabChiefModal from "../pages/sout/lab-chief/modals/move/MeasurementsModal";
import ActsLabChiefModal from "../pages/sout/lab-chief/modals/move/ActsModal";

import DraftLabAssistModal from "../pages/sout/lab-assist/modals/move/DraftModal";

import AddPaymentModal from "../pages/sout/accountant/modals/AddPaymentModal";
import ViewPaymentsModal from "../pages/sout/accountant/modals/ViewPaymentsModal";
import PaymentAccModal from "../pages/sout/accountant/modals/move/PaymentAccModal";

import InformationCollectionMedChiefModal from "../pages/sout/med-chief/modals/move/InformationCollectionModal";
import InfoCollectionAndSigningComModalMed from "../pages/sout/commercial/modals/move/InfoCollectionAndSigningModalMed";
import ActsModalMedChief from "../pages/sout/med-chief/modals/move/ActsModalMedChief";
import DocumentsComModal from "../pages/sout/commercial/modals/documents/DocumentsModal";

import AgreementLabAssistModal from "../pages/sout/lab-assist/modals/move/AgreementDocsModal";
import PrintedAndSentLabAssistModal from "../pages/sout/lab-assist/modals/move/PrintedAndSentLabAssistModal";
import UnloadingDocumentsLabAssistModal from "../pages/sout/lab-assist/modals/move/UnloadingDocumentsLabAssistModal";
import PerformerAppointedLabAssistModal from "./../pages/sout/lab-assist/modals/move/PerformerAppointed";
import AcceptedToWorkLabAssistModal from "./../pages/sout/lab-assist/modals/move/AcceptedToWork";
import PaymentLabChief from "./../pages/sout/lab-chief/PaymentLabChief";
import PaymentLabChiefModal from "./../pages/sout/lab-chief/modals/move/PaymentModal";
import PaymentComModal from "./../pages/sout/commercial/modals/move/PaymentModal";
import WaitingPayments from "../pages/sout/accountant/modals/move/WaitingPayments";
import ReturnOfAccSecurity from "./../pages/sout/accountant/modals/move/ReturnOfAccSecurity";
import ReportModal from './../components/modals/move/ReportModal';

//** SOUT */
const TenderModals = {
  [TENDER_STAGES[0]]: DraftTenderModal,
  [TENDER_STAGES[1]]: AcceptedToWorkTenderModal,
  [TENDER_STAGES[2]]: FiledTenderModal,
  [TENDER_STAGES[3]]: WonAndSigningTenderModal,
  [TENDER_STAGES[4]]: DraftTenderModal,
  [TENDER_STAGES[5]]: DraftTenderModal,
  [TENDER_STAGES[6]]: DraftTenderModal,
  [TENDER_STAGES[7]]: DraftTenderModal,
  [TENDER_STAGES[8]]: DraftTenderModal,
  [TENDER_STAGES[9]]: DraftTenderModal,
  [TENDER_STAGES[10]]: DraftTenderModal,
  [TENDER_STAGES[11]]: DraftTenderModal,
  [TENDER_STAGES[12]]: DraftTenderModal,
  [TENDER_STAGES[13]]: DraftTenderModal,
  [TENDER_STAGES[14]]: DraftTenderModal,
  [TENDER_STAGES[15]]: DraftTenderModal,
  [TENDER_STAGES[16]]: DraftTenderModal,
  [TENDER_STAGES[17]]: DraftTenderModal,
  [TENDER_STAGES[18]]: DraftTenderModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  documents: DocumentsComModal,
};
export const ActualTenderModal = ({ modalType, ...props }) => {
  const Modal = TenderModals[modalType] || DraftTenderModal;

  return <Modal {...props} />;
};

const TenderHeadModals = {
  [TENDER_STAGES[0]]: DraftTenderModal,
  [TENDER_STAGES[1]]: AcceptedToWorkTenderModal,
  [TENDER_STAGES[2]]: FiledTenderModal,
  [TENDER_STAGES[3]]: WonAndSigningTenderModal,
  [TENDER_STAGES[4]]: AlternateIdAssignmentTenderModal,
  [TENDER_STAGES[5]]: DraftTenderModal,
  [TENDER_STAGES[6]]: DraftTenderModal,
  [TENDER_STAGES[7]]: DraftTenderModal,
  [TENDER_STAGES[8]]: DraftTenderModal,
  [TENDER_STAGES[9]]: DraftTenderModal,
  [TENDER_STAGES[10]]: DraftTenderModal,
  [TENDER_STAGES[11]]: DraftTenderModal,
  [TENDER_STAGES[12]]: DraftTenderModal,
  [TENDER_STAGES[13]]: DraftTenderModal,
  [TENDER_STAGES[14]]: DraftTenderModal,
  [TENDER_STAGES[15]]: DraftTenderModal,
  [TENDER_STAGES[16]]: DraftTenderModal,
  [TENDER_STAGES[17]]: DraftTenderModal,
  [TENDER_STAGES[18]]: DraftTenderModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  documents: DocumentsComModal,
};
export const ActualTenderHeadModal = ({ modalType, ...props }) => {
  const Modal = TenderHeadModals[modalType];
  return <Modal {...props} />;
};

const CommercialModals = {
  [COMMERCIAL_STAGES[0]]: InfoCollectionAndSigningComModal,
  [COMMERCIAL_STAGES[1]]: DraftComModal,
  [COMMERCIAL_STAGES[2]]: DraftComModal,
  [COMMERCIAL_STAGES[3]]: DraftComModal,
  [COMMERCIAL_STAGES[4]]: DraftComModal,
  [COMMERCIAL_STAGES[5]]: DraftComModal,
  [COMMERCIAL_STAGES[6]]: DraftComModal,
  [COMMERCIAL_STAGES[7]]: DraftComModal,
  [COMMERCIAL_STAGES[8]]: DraftComModal,
  [COMMERCIAL_STAGES[9]]: DraftComModal,
  [COMMERCIAL_STAGES[10]]: ActsComModal,
  [COMMERCIAL_STAGES[11]]: PaymentComModal,
  [COMMERCIAL_STAGES[12]]: ReportModal,
  [COMMERCIAL_STAGES[13]]: DraftComModal,
  [COMMERCIAL_STAGES[14]]: DraftComModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  documents: DocumentsComModal,
  ReturnOfSecurity: PaymentAccModal,
  addPayment: AddPaymentModal,
  viewPayment: ViewPaymentsModal,
};
export const ActualCommercialModal = ({ modalType, ...props }) => {
  const Modal = CommercialModals[modalType] || DraftComModal;
  return <Modal {...props} />;
};

const LabChiefModals = {
  [TENDER_STAGES[0]]: DraftLabChiefModal,
  [TENDER_STAGES[1]]: DraftLabChiefModal,
  [TENDER_STAGES[2]]: DraftLabChiefModal,
  [TENDER_STAGES[3]]: DraftLabChiefModal,
  [TENDER_STAGES[4]]: DraftLabChiefModal,
  [TENDER_STAGES[5]]: InformationCollectionLabChiefModal,
  [TENDER_STAGES[6]]: MeasurementsLabChiefModal,
  [TENDER_STAGES[7]]: DraftLabChiefModal,
  [TENDER_STAGES[8]]: DraftLabChiefModal,
  [TENDER_STAGES[9]]: DraftLabChiefModal,
  [TENDER_STAGES[10]]: DraftLabChiefModal,
  [TENDER_STAGES[11]]: DraftLabChiefModal,
  [TENDER_STAGES[12]]: DraftLabChiefModal,
  [TENDER_STAGES[13]]: ActsLabChiefModal,
  [TENDER_STAGES[14]]: DraftLabChiefModal,
  [TENDER_STAGES[15]]: ReportModal,
  [TENDER_STAGES[16]]: DraftLabChiefModal,
  [TENDER_STAGES[17]]: DraftLabChiefModal,
  [TENDER_STAGES[18]]: DraftLabAssistModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  // addPayment: AddPaymentModal,
  viewPayment: ViewPaymentsModal,
  documents: DocumentsComModal,
};
export const ActualLabChiefModal = ({ modalType, ...props }) => {
  const Modal = LabChiefModals[modalType] || DraftLabChiefModal;
  return <Modal {...props} />;
};

const LabAssistModals = {
  [TENDER_STAGES[0]]: DraftLabAssistModal,
  [TENDER_STAGES[1]]: DraftLabAssistModal,
  [TENDER_STAGES[2]]: DraftLabAssistModal,
  [TENDER_STAGES[3]]: DraftLabAssistModal,
  [TENDER_STAGES[4]]: DraftLabAssistModal,
  [TENDER_STAGES[5]]: DraftLabAssistModal,
  [TENDER_STAGES[6]]: DraftLabAssistModal,
  [TENDER_STAGES[7]]: DraftLabAssistModal,
  [TENDER_STAGES[8]]: PerformerAppointedLabAssistModal,
  [TENDER_STAGES[9]]: AcceptedToWorkLabAssistModal,
  [TENDER_STAGES[10]]: AgreementLabAssistModal,
  [TENDER_STAGES[11]]: PrintedAndSentLabAssistModal,
  [TENDER_STAGES[12]]: UnloadingDocumentsLabAssistModal,
  [TENDER_STAGES[13]]: DraftLabAssistModal,
  [TENDER_STAGES[14]]: PaymentAccModal,
  [TENDER_STAGES[15]]: DraftLabAssistModal,
  [TENDER_STAGES[16]]: DraftLabAssistModal,
  [TENDER_STAGES[17]]: DraftLabAssistModal,
  [TENDER_STAGES[18]]: DraftLabAssistModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  addPayment: AddPaymentModal,
  viewPayment: ViewPaymentsModal,
  documents: DocumentsComModal,
};
export const ActualLabAssistModals = ({ modalType, ...props }) => {
  const Modal = LabAssistModals[modalType] || DraftLabAssistModal;
  return <Modal {...props} />;
};

const LabExpertModals = {
  [TENDER_STAGES[4]]: DraftLabAssistModal,
  [TENDER_STAGES[5]]: DraftLabAssistModal,
  [TENDER_STAGES[6]]: MeasurementsLabChiefModal,
  [TENDER_STAGES[7]]: DraftLabAssistModal,
  [TENDER_STAGES[8]]: PerformerAppointedLabAssistModal,
  [TENDER_STAGES[9]]: AcceptedToWorkLabAssistModal,
  [TENDER_STAGES[10]]: AgreementLabAssistModal,
  [TENDER_STAGES[11]]: PrintedAndSentLabAssistModal,
  [TENDER_STAGES[12]]: UnloadingDocumentsLabAssistModal,
  [TENDER_STAGES[13]]: DraftLabAssistModal,
  [TENDER_STAGES[14]]: PaymentAccModal,
  [TENDER_STAGES[15]]: DraftLabAssistModal,
  [TENDER_STAGES[16]]: DraftLabAssistModal,
  [TENDER_STAGES[17]]: DraftLabAssistModal,
  [TENDER_STAGES[18]]: DraftLabAssistModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  addPayment: AddPaymentModal,
  viewPayment: ViewPaymentsModal,
  documents: DocumentsComModal,
};
export const ActualLabExpertModals = ({ modalType, ...props }) => {
  const Modal = LabExpertModals[modalType] || DraftLabAssistModal;
  return <Modal {...props} />;
};

const LabHeadDeputyModals = {
  [TENDER_STAGES[0]]: DraftLabChiefModal,
  [TENDER_STAGES[1]]: DraftLabChiefModal,
  [TENDER_STAGES[2]]: DraftLabChiefModal,
  [TENDER_STAGES[3]]: DraftLabChiefModal,
  [TENDER_STAGES[4]]: AlternateIdAssignmentTenderModal,
  [TENDER_STAGES[5]]: InformationCollectionLabChiefModal,
  [TENDER_STAGES[6]]: MeasurementsLabChiefModal,
  [TENDER_STAGES[7]]: DraftLabChiefModal,
  [TENDER_STAGES[8]]: DraftLabChiefModal,
  [TENDER_STAGES[9]]: DraftLabChiefModal,
  [TENDER_STAGES[10]]: DraftLabChiefModal,
  [TENDER_STAGES[11]]: DraftLabChiefModal,
  [TENDER_STAGES[12]]: DraftLabChiefModal,
  [TENDER_STAGES[13]]: ActsLabChiefModal,
  [TENDER_STAGES[14]]: DraftLabChiefModal,
  [TENDER_STAGES[15]]: ReportModal,
  [TENDER_STAGES[16]]: DraftLabChiefModal,
  [TENDER_STAGES[17]]: DraftLabChiefModal,
  [TENDER_STAGES[18]]: DraftLabAssistModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  // addPayment: AddPaymentModal,
  viewPayment: ViewPaymentsModal,
  documents: DocumentsComModal,
};
export const ActualLabHeadDeputyModals = ({ modalType, ...props }) => {
  const Modal = LabHeadDeputyModals[modalType] || DraftLabChiefModal;
  return <Modal {...props} />;
};

const AccountantModals = {
  [TENDER_STAGES[0]]: DraftLabAssistModal,
  [TENDER_STAGES[1]]: DraftLabAssistModal,
  [TENDER_STAGES[2]]: DraftLabAssistModal,
  [TENDER_STAGES[3]]: DraftLabAssistModal,
  [TENDER_STAGES[4]]: DraftLabAssistModal,
  [TENDER_STAGES[5]]: DraftLabAssistModal,
  [TENDER_STAGES[6]]: DraftLabAssistModal,
  [TENDER_STAGES[7]]: DraftLabAssistModal,
  [TENDER_STAGES[8]]: PerformerAppointedLabAssistModal,
  [TENDER_STAGES[9]]: AcceptedToWorkLabAssistModal,
  [TENDER_STAGES[10]]: AgreementLabAssistModal,
  [TENDER_STAGES[11]]: PrintedAndSentLabAssistModal,
  [TENDER_STAGES[12]]: UnloadingDocumentsLabAssistModal,
  [TENDER_STAGES[13]]: DraftLabAssistModal,
  [TENDER_STAGES[14]]: WaitingPayments,
  [TENDER_STAGES[15]]: ReportModal,
  [TENDER_STAGES[16]]: ReturnOfAccSecurity,
  [TENDER_STAGES[17]]: DraftLabAssistModal,
  [TENDER_STAGES[18]]: DraftLabAssistModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  addPayment: AddPaymentModal,
  viewPayment: ViewPaymentsModal,
  documents: DocumentsComModal,
};
export const ActualAccountantModals = ({ modalType, ...props }) => {
  const Modal = AccountantModals[modalType] || DraftLabAssistModal;
  return <Modal {...props} />;
};

//** MED */
const CommercialModalsMed = {
  [COMMERCIAL_STAGES[0]]: InfoCollectionAndSigningComModalMed,
  [COMMERCIAL_STAGES[1]]: DraftComModal,
  [COMMERCIAL_STAGES[2]]: DraftComModal,
  [COMMERCIAL_STAGES[3]]: DraftComModal,
  [COMMERCIAL_STAGES[4]]: DraftComModal,
  [COMMERCIAL_STAGES[5]]: DraftComModal,
  [COMMERCIAL_STAGES[6]]: DraftComModal,
  [COMMERCIAL_STAGES[7]]: DraftComModal,
  [COMMERCIAL_STAGES[8]]: DraftComModal,
  [COMMERCIAL_STAGES[9]]: ActsComModal,
  [COMMERCIAL_STAGES[10]]: DraftComModal,
  [COMMERCIAL_STAGES[11]]: DraftComModal,
  [COMMERCIAL_STAGES[12]]: DraftComModal,
  [COMMERCIAL_STAGES[13]]: DraftComModal,
  [COMMERCIAL_STAGES[14]]: DraftComModal,
  [COMMERCIAL_STAGES[15]]: DraftComModal,
  [COMMERCIAL_STAGES[16]]: DraftComModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  documents: DocumentsComModal,
  ReturnOfSecurity: PaymentAccModal,
};
export const ActualCommercialModalMed = ({ modalType, ...props }) => {
  const Modal = CommercialModalsMed[modalType];
  return <Modal {...props} />;
};

const TenderModalsMed = {
  [TENDER_STAGES[0]]: DraftTenderModal,
  [TENDER_STAGES[1]]: AcceptedToWorkTenderModal,
  [TENDER_STAGES[2]]: FiledTenderModal,
  [TENDER_STAGES[3]]: WonAndSigningTenderModal,
  [TENDER_STAGES[4]]: DraftTenderModal,
  [TENDER_STAGES[5]]: DraftTenderModal,
  [TENDER_STAGES[6]]: DraftTenderModal,
  [TENDER_STAGES[7]]: DraftTenderModal,
  [TENDER_STAGES[8]]: DraftTenderModal,
  [TENDER_STAGES[9]]: DraftTenderModal,
  [TENDER_STAGES[10]]: DraftTenderModal,
  [TENDER_STAGES[11]]: DraftTenderModal,
  [TENDER_STAGES[12]]: DraftTenderModal,
  [TENDER_STAGES[13]]: DraftTenderModal,
  [TENDER_STAGES[14]]: DraftTenderModal,
  [TENDER_STAGES[15]]: DraftTenderModal,
  [TENDER_STAGES[16]]: DraftTenderModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  documents: DocumentsComModal,
};
export const ActualTenderModalMed = ({ modalType, ...props }) => {
  const Modal = TenderModalsMed[modalType];
  return <Modal {...props} />;
};

const MedChiefModals = {
  [TENDER_STAGES[0]]: DraftComModal,
  [TENDER_STAGES[1]]: DraftComModal,
  [TENDER_STAGES[2]]: DraftComModal,
  [TENDER_STAGES[3]]: DraftComModal,
  [TENDER_STAGES[4]]: InformationCollectionMedChiefModal,
  [TENDER_STAGES[5]]: DraftComModal,
  [TENDER_STAGES[6]]: DraftComModal,
  [TENDER_STAGES[7]]: DraftComModal,
  [TENDER_STAGES[8]]: DraftComModal,
  [TENDER_STAGES[9]]: DraftComModal,
  [TENDER_STAGES[10]]: DraftComModal,
  [TENDER_STAGES[11]]: DraftComModal,
  [TENDER_STAGES[12]]: ActsModalMedChief,
  [TENDER_STAGES[13]]: DraftComModal,
  [TENDER_STAGES[14]]: DraftComModal,
  [TENDER_STAGES[15]]: DraftComModal,
  [TENDER_STAGES[16]]: DraftComModal,
  movePrev: MovePrevStageModal,
  showInfo: TableViewModal,
  RejectModal: RejectModal,
  RejectWithCommentModal: RejectWithCommentModal,
  RejectSelectWinnerModal: RejectSelectWinnerModal,
  documents: DocumentsComModal,
};
export const ActualMedChiefModal = ({ modalType, ...props }) => {
  const Modal = MedChiefModals[modalType];
  return <Modal {...props} />;
};
