import { useContext, useEffect, useState } from 'react';
import ProjectContext from '../../../../../context/ProjectsContext';
import ModalWrapper from '../../../../../components/modals/ModalWrapper';
import patchProject from '../../../../../utils/patchProject';
import moveToNextStage from '../../../../../utils/moveToNextStage';
import Asterisk from '../../../../../components/Asterisk';
import fetchCompetitors from '../../../../../utils/fetchCompetitors';
import { isLabHeadDeputy, isTenderUser } from '../../../../../utils/defineUser';

function AlternateIdAssignmentTenderModal({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const projectDataHandler = async (e) => {
		e.preventDefault();

		await patchProject(e, project, project.id);

		const res = await moveToNextStage(project.id);
		if (res.status === '200' || res.ok) {
			setIsProjectMoved((prevState) => !prevState)
			setOpenModal(false);
		} else {
			alert('Error! Проект не был переведён на следующий этап');
			setOpenModal(false);
		}
	}

	return (
		<ModalWrapper>
			<button className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}>&#10005;</button>
			<form>
				<label className="modal__input-title">Идентификационный номер:<Asterisk /></label>
				{
					isTenderUser()
					&&
					<input className="modal__input input" type="number"
						name="alternateId"
						value={project.alternateId}
						onChange={(e) => inputChangeHandler(e)}
						onWheel={(e) => e.target.blur()}
					/>
				}
				{
					isLabHeadDeputy()
					&&
					<textarea className="modal__input input" type="number"
						rows="7"
						style={{ resize: 'vertical' }}
						name="alternateId"
						value={project.alternateId}
						onChange={(e) => inputChangeHandler(e)}
						onWheel={(e) => e.target.blur()}
					/>
				}
				<h3 className="modal__description">Перевести проект
					<b>&nbsp;{project.customerName}&nbsp;</b>
					на следующий этап?</h3>
				<div className="modal__buttons-wrapper">
					<button className="button button--red"
						disabled={project.winnerPrice <= 0}
						onClick={(e) => projectDataHandler(e)}>
						Да
					</button>
					<button className="button close-button"
						onClick={() => setOpenModal(false)}>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	)
}
export default AlternateIdAssignmentTenderModal


