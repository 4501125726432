import React, { useEffect, useState } from "react";
import fetchWorkers from "utils/fetchWorkers";

const UnassignedStage = ({ setIsDisabled, worker, setWorker }) => {
  const [measurers, setMeasurers] = useState([]);

  useEffect(() => {
    setIsDisabled(!worker?.id);
    if (measurers.length === 0) fetchWorkers(setMeasurers);
  });

  return (
    <div className="modal__content-center">
      <label className="modal__input-title">Назначение замерщика</label>
      <select
        className="input search-area__select"
        name="winner"
        value={worker?.id}
        onChange={(e) =>
          setWorker(measurers.find((item) => item.id == e.target.value))
        }
        style={{ width: 300, margin: "0 auto" }}
      >
        <option value="null">-</option>
        {measurers &&
          measurers?.map(({ id, name }) => (
            <option key={id} value={id}>
              {" "}
              {name}{" "}
            </option>
          ))}
      </select>
    </div>
  );
};

export default UnassignedStage;
