import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { formatDate } from "../../../../utils/formatDate";
import ActionButton from '../../../../components/ActionButton';

import { useState } from 'react';
import DeletePaymentModal from './payments/DeletePaymentModal';

import { ImCross } from 'react-icons/im';

import { TENDER_STAGES } from '../../../../utils/stages';
import { isAdmin } from '../../../../utils/defineUser';

const ViewPaymentsModal = ({ setOpenModal, project }) => {
	const [open, setOpen] = useState(false);
	const [paytId, setPaytId] = useState('');
	const [paytAmount, setPaytAmount] = useState('');

	const isOnStageOrPassed = (stageId) => {
		return TENDER_STAGES.indexOf(project?.workFlow?.projectStatusId) >= stageId
	}

	return (
		<>
			<ModalWrapper>
				<button className="modal__close-button close-button"
					onClick={() => setOpenModal(false)}>&#10005;</button>
				<div className="table-wrapper table-wrapper--flex-row">
					<table className="table__table-view-modal">
						<thead>
							<tr style={{ borderBottom: 'none' }}>
								<td colSpan="3" style={{ borderBottom: 'none' }}>
									<b>Сумма по актам: </b>
									<b>{project?.contract?.actAmount}&nbsp;₽</b>
								</td>
							</tr>
							<tr>
								<td colSpan="3">
									<b>Общая сумма платежей: </b>
									<b>
										{project?.contract?.paymentsSum}
										&nbsp;₽
									</b>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Сумма платежа</td>
								<td>Дата платежа</td>
								<td>Действие</td>
							</tr>
							{
								(project?.contract?.payments.length > 0)
									? project.contract.payments.map(payt =>
										<tr>
											<td>{payt?.amount}&nbsp;₽</td>
											<td>{formatDate(payt?.paymentDate)}</td>
											<td className='table__action-buttons'>
												<ActionButton
													isDisabled={isOnStageOrPassed(14) && !isAdmin()}
													onAction={() => {
														setOpen(true)
														setPaytId(payt.id)
														setPaytAmount(payt.amount)
													}}
													modalType='showInfo'
													iconType={<ImCross className='icon-delete-payment' />}
													title='Удалить платёж'
													styles={{ padding: '5px 5px 0' }}
												>
												</ActionButton>
											</td>
										</tr>)
									: (
										<tr style={{ borderBottom: 'none' }}>
											<td colSpan='3' style={{ borderBottom: 'none' }}><i>Нет платежей</i></td>
										</tr>
									)
							}
						</tbody>
					</table>
				</div>
				<div className="modal__buttons-wrapper">
					<button className="button close-button"
						onClick={() => setOpenModal(false)}>
						Закрыть
					</button>
				</div>
			</ModalWrapper>
			{
				open
					? <DeletePaymentModal setOpenModal={setOpenModal} setOpen={setOpen} paytId={paytId} paytAmount={paytAmount} />
					: null
			}
		</>
	)
}

export default ViewPaymentsModal