import fetchBranchOffices from "./fetchBranchOffices";

export const getBranchOfficeById = async (orgId, id) => {
  try {
    const branchOffices = await fetchBranchOffices(orgId);
    const branchOffice = branchOffices.find((elem) => elem.id == id);
    console.log(branchOffices);
    return branchOffice.name;
  } catch (error) {
    console.error(error);
    return null;
  }
};
