import { useContext, useState } from "react";
import ProjectContext from "../../../../../context/ProjectsContext";
import ModalWrapper from "../../../../../components/modals/ModalWrapper";
import moveToNextStage from "../../../../../utils/moveToNextStage";
import patchProject from "../../../../../utils/patchProject";

import DocumentsListHandler from "../../../accountant/common/DocumentsListHandler";
import { LAB_CHIEF_ACTS_DOCS } from "../../../../../utils/documentType";

function ActsLabChiefModal({ setOpenModal, inputChangeHandler, project }) {
	const { setIsProjectMoved } = useContext(ProjectContext);

	const [documentsQty, setDocumentsQty] = useState({
		Act: 0,
	});

	const projectDataHandler = async (e) => {
		e.preventDefault();
		await patchProject(e, project, project.id);
		const res = await moveToNextStage(project.id);
		if (res.status === "200" || res.ok) {
			setIsProjectMoved((prevState) => !prevState);
			setOpenModal(false);
		} else {
			alert("Error! Проект не был переведён на следующий этап");
			setOpenModal(false);
		}
	};

	const documentsQtyHandler = (updatedQty) =>
		Object.keys(updatedQty).length === 0 && updatedQty.constructor === Object
			? setDocumentsQty(documentsQty)
			: setDocumentsQty({ ...documentsQty, ...updatedQty });

	return (
		<ModalWrapper>
			<button
				className="modal__close-button close-button"
				onClick={() => setOpenModal(false)}
			>
				&#10005;
			</button>
			<form>
				<DocumentsListHandler
					projectId={project.id}
					docTypesList={LAB_CHIEF_ACTS_DOCS}
					documentsQtyHandler={documentsQtyHandler}
				/>
				<label className="modal__input-title">Номер акта:
					<input className="modal__input input" type="text"
						name="actNumber"
						value={project.actNumber}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</label>

				<label className="modal__input-title">Сумма по акту:</label>
				<input
					className="modal__input input"
					type="number"
					name="actAmount"
					value={project.actAmount}
					onChange={(e) => inputChangeHandler(e)}
					onWheel={(e) => e.target.blur()}
				/>

				<label className="modal__input-title" style={{ marginTop: "15px" }}>
					Дата акта:
					<input
						className="modal__input input"
						type="date"
						name="actDate"
						value={project.actDate}
						onChange={(e) => inputChangeHandler(e)}
					/>
				</label>

				<h3 className="modal__description">
					Перевести проект
					<b>&nbsp;{project.customerName}&nbsp;</b> на следующий этап?
				</h3>
				<div className="modal__buttons-wrapper">
					<button
						className="button button--red"
						disabled={
							project.actAmount <= 0 ||
							documentsQty.Act <= 0 ||
							project.actDate === undefined ||
							project.actDate === ""
						}
						onClick={(e) => projectDataHandler(e)}
					>
						Да
					</button>
					<button
						className="button close-button"
						onClick={() => setOpenModal(false)}
					>
						Нет
					</button>
				</div>
			</form>
		</ModalWrapper>
	);
}
export default ActsLabChiefModal;
