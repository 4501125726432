import React, { useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { useNavigate } from "react-router-dom";
import { api } from "settings/Api";

const AddCompetitor = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  const postData = async () => {
    const token = JSON.parse(localStorage.getItem("authTokens")).data
      .accessToken;
    api("/api/sout/handbooks/competitors", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: name,
        description: description,
      }),
    }).then((res) => {
      if (res.status === 200 || res.ok) {
        alert("Конкурент создан!");
        navigate("/ListOfCompetitors");
      } else if (res.status === 500 || !res.ok) {
        alert("Ошибка. Конкурент не создан!");
      }
    });
  };

  return (
    <main className="workspace">
      <Sidebar />
      <div className="workspace__content">
        <section className="workspace__wrapper">
          <h3 className="workspace__title workspace__page-title">
            Добавить конкурента
          </h3>
          <form style={{ width: 400 }}>
            <p className="workspace__input-title">Наименование:</p>
            <input
              className="input"
              type="text"
              name="name"
              onChange={(e) => setName(e.target.value)}
            />
            <p className="workspace__input-title">Описание</p>
            <input
              className="input"
              type="text"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
            />

            <div style={{ display: "flex" }}>
              <input
                type="button"
                disabled={name <= 0}
                onClick={postData}
                className="button staff__create-staff-button"
                value="Добавить"
              />
              <input
                type="button"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  navigate(-1);
                }}
                className="button staff__create-staff-button"
                value="Отмена"
              />
            </div>
          </form>
        </section>
      </div>
    </main>
  );
};
export default AddCompetitor;
