import React, { useState } from "react";
import { Link } from "react-router-dom";
// import BlockModal from './BlockUserModal';
// import UnblockModal from './UnblockModal';

import { MdEdit } from "react-icons/md";
import { FaBan, FaInfo } from "react-icons/fa";
import { FaUnlock } from "react-icons/fa";
// import BlockAgentModal from '../modals/BlockAgentModal';
import BlockAgentModal from "./../modals/BlockAgentModal";
import UnblockAgentModal from "../UnblockAgentModal";
import ModalWrapper from "components/modals/ModalWrapper";
import AgentInfoModal from "components/modals/AgentInfoModal";

const AdminAgentActionButtons = ({ note, isPRCOT }) => {
  const [openBlockModal, setBlockModal] = useState(false);
  const [openUnblockModal, setUnblockModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Link to="/EditAgent" title={"Редактировать"} state={{ user: note }}>
        <button className="button button--square" title="Редактировать">
          <MdEdit className="icon-edit" />
        </button>
      </Link>

      <button
        onClick={() => setOpenModal(true)}
        className="button button--square"
        title="Информация"
      >
        <FaInfo className="icon-info" />
      </button>

      {!isPRCOT && (
        <>
          <button
            onClick={() => setBlockModal(true)}
            className="button button--square"
            title="Заблокировать"
            disabled={note?.state.isBlocked !== false}
          >
            <FaBan className="icon-ban" />
          </button>
          {openBlockModal ? (
            <BlockAgentModal closeModal={setBlockModal} user={note} />
          ) : null}

          <button
            onClick={() => setUnblockModal(true)}
            className="button button--square"
            title="Разблокировать"
            disabled={note?.state.isBlocked !== true}
          >
            <FaUnlock className="icon-ban" />
          </button>
        </>
      )}
      {openUnblockModal ? (
        <UnblockAgentModal closeModal={setUnblockModal} user={note} />
      ) : null}
      {openModal && (
        <ModalWrapper>
          {
            <AgentInfoModal
              setOpenModal={setOpenModal}
              organization={note?.state}
            />
          }
        </ModalWrapper>
      )}
    </>
  );
};

export default AdminAgentActionButtons;
